import React, { useEffect, useState } from "react";
import "./orderCatalogue.css";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ApiClearAction } from "../ApiCallStatus/Actions/action";
import { DashboardWrapper } from "../Dashboard/Components/dashboarWrapper";

const options = [
  { value: "Provisions", label: "Provisions" },
  { value: "Deck", label: "Deck" },
  { value: "Engine", label: "Engine" },
  { value: "Galley / ", label: "Galley / " },
  { value: "laundry", label: "laundry" },
  { value: "Electrical", label: "Electrical" },
  { value: "Stationary", label: "Stationary" },
];

function SupplierOrderOverview(props) {
  const [state, setState] = useState({
    supplierList: [],
    activePage: 0,
    eta: "",
    totalCount: 0,
    isHitApi: false,
  });
  const handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
    {
      img: "Edit-icon.svg",
      className: "dark-green",
      name: "Send<br />RFQ",
      path: "/",
      isLogout: false,
    },
  ];
  return (
    <DashboardWrapper
      title="Orders"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
        <div className="table-optns-wrap mt-3">
          <ul className="list-unstyled suppliersTypeList w-100 d-flex">
            <li>
              <label className="customCheckBoxLabel">
                NEW
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="customCheckBoxLabel">
                CONFIRMED
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="customCheckBoxLabel">
                DELIVERED
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </li>
            <li>
              <label className="customCheckBoxLabel">
                OVERDUE
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </li>
          </ul>
        </div>
        {/* Table */}
        <div className="ordCatg-InnTable store-catgTable">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left">Date</th>
                  <th>Vessel</th>
                  <th>Management Company</th>
                  <th>Department</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Export to .xls</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  supplierReducer: state.supplierReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ApiClearAction: () => dispatch(ApiClearAction()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierOrderOverview);
