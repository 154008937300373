import React from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

export function SupplierForm(props) {
  const validateForm = () => {
    var form = document.getElementsByClassName("needs-validation")[0];
    let isValid = true;
    if (form.checkValidity() === false) {
      isValid = false;
      form.classList.add("was-validated");
    }
    return isValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validateForm();
    if (isValid) {
      props.handleSubmit();
    }
  };
  const extractFileName = (data) => {
    let val = "";
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      val += element.filename
        ? element.filename
        : element.name
        ? element.name
        : "";
      if (data.length !== 1 && index !== data.length - 1) {
        val += ", ";
      }
    }
    return val;
  };
  return (
    <form
      className="needs-validation"
      autocomplete="off"
      onSubmit={(e) => handleSubmit(e)}
      noValidate
    >
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group floating-label-wrap">
            <input
              type="text"
              name="name"
              className="form-control"
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              placeholder="Name"
              value={props.name}
              required
            />
            <lable className="foating-labels-sty">Name</lable>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group floating-label-wrap">
            <input
              type="text"
              name="title"
              className="form-control"
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              placeholder="Title"
              value={props.title}
              required
            />
            <lable className="foating-labels-sty">Title</lable>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group floating-label-wrap">
            <input
              type="email"
              name="email"
              className="form-control"
              autoComplete={false}
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              placeholder="Email"
              value={props.email}
              required
              readOnly={props.emailEdit}
            />
            <lable className="foating-labels-sty">Email</lable>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group floating-label-wrap">
            <input
              type="password"
              id="password"
              name="password"
              autoComplete="new-password"
              className="form-control"
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              placeholder="Password"
              value={props.password}
              required
              readOnly={props.isEdit}
            />
            <lable className="foating-labels-sty">Password</lable>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group floating-label-wrap">
            <input
              type="text"
              name="companyName"
              className="form-control"
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              placeholder="Company Name"
              value={props.companyName}
              required
            />
            <lable className="foating-labels-sty">Company Name</lable>
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group floating-label-wrap">
            <input
              type="text"
              name="websiteUrl"
              className="form-control"
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              placeholder="Website Url"
              value={props.websiteUrl}
              required
            />
            <lable className="foating-labels-sty">Website Url</lable>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select mr-2 mw-100 form-group">
            <Select
              className="custom-select-wrap"
              classNamePrefix="react-select"
              placeholder="Select Country"
              value={props.country === "" ? null : props.country}
              onChange={(e) =>
                props.handleFormChange({
                  target: { name: "country", value: e },
                })
              }
              options={props.countryList}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select mr-2 mw-100 form-group">
            <Select
              className="custom-select-wrap"
              classNamePrefix="react-select"
              placeholder="Select Port"
              value={null}
              onChange={(e) => props.handleSupplierPortChange("port", e)}
              options={props.portList}
            />
          </div>
        </div>
      </div>
      <div className="tags-par-wrap">
        <div className="row">
          <div className="col-sm-12 ">
            <label className={props.supplierPorts.length > 0 ? "d-none" : ""}>
              Ports
            </label>
            <div className="custom-select2 text-bx-wrap input-type-select p-0 form-group d-flex">
              <ul className="list-inline pl-0 mb-0 d-flex align-items-center flex-wrap">
                {props.supplierPorts.map((item, index) => {
                  return (
                    <li
                      key={"sPorts11-" + index}
                      className="theme-tag-wrap mb-0"
                    >
                      {item.label}
                      {props.isEdit &&
                      props.selectedPorts.findIndex(
                        (x) => x.value === item.value
                      ) !== -1 ? (
                        ""
                      ) : (
                        <span
                          onClick={() =>
                            props.handleSupplierPortChange("removePorts", item)
                          }
                          className="close"
                        >
                          &times;
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group floating-label-wrap">
            <textarea
              className="form-control"
              placeholder="Description"
              name="description"
              value={props.description}
              onChange={(e) => {
                props.handleFormChange(e);
              }}
              required
            ></textarea>
            <lable className="foating-labels-sty">Description</lable>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <div className="form-inputFile-wrap d-flex align-items-center justify-content-between">
              <div className="form-input-container">
                <input
                  type="file"
                  id="logo"
                  name="logo"
                  className="form-control"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={(e) => {
                    props.handleFormChange(e);
                  }}
                  placeholder="Logo"
                  required
                />
                <label htmlFor="logo" className="d-flex align-items-center">
                  <span className="text-truncate">
                    {props.logo.length > 0
                      ? extractFileName(props.logo)
                      : "Logo"}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <div className="form-inputFile-wrap d-flex align-items-center justify-content-between">
              <div className="form-input-container">
                <input
                  type="file"
                  id="images"
                  name="images"
                  className="form-control"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={(e) => {
                    props.handleFormChange(e);
                  }}
                  placeholder="Images"
                  multiple
                  required
                />
                <label htmlFor="images" className="d-flex align-items-center">
                  <span className="text-truncate">
                    {props.images.length > 0
                      ? extractFileName(props.images)
                      : "Images"}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.apiCallStatus.apiCallFor === props.apiName &&
      props.apiCallStatus.isCompleted &&
      props.apiCallStatus.isFailed ? (
        <div className="alert alert-danger">{props.apiCallStatus.message}</div>
      ) : (
        ""
      )}
      {/* Upload File */}
      <div className="d-flex align-items-center flex-column flex-sm-row">
        <div className="form-btn d-flex justify-content-end flex-fill">
          {props.apiCallStatus.apiCallFor === "createSupplier" &&
          !props.apiCallStatus.isCompleted &&
          !props.apiCallStatus.isFailed ? (
            <div className="loader-img text-center">
              <img
                style={{ width: "46px" }}
                src={require("../../../../assets/images/Spinner-1s-200px.gif")}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            className="btn btn themeReguler-btn bg-sky-blue"
          >
            {props.isEdit ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
}
