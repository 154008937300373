import React, { useEffect, useState } from "react";
import { portListing, deletePort } from "../ApiCalls/port";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { getCookie } from "../../../Services/cookies";
import { currencyFormatter, isAllowPermission } from "../../../Services/common";
import { topSupplyHub } from "./../../Common/ApiCalls/topSupplyHub";
import { vesselListing } from "./../../Vessel/ApiCalls/vessel";
import { getRoutePathBykey } from "../../../Constants/routesConfig";
import DatePicker from "react-datepicker";
import moment from "moment";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";
import { CsvDownload } from "../../Common/Components/CsvDownload";
const headers = [
  { label: "Port name", key: "port" },
  { label: "Country", key: "countryName" },
  { label: "Number of supplies", key: "pMeshCount" },
  // { label: "Number of supplies C-MESH", key: "cMeshCount" },
];
var csvLinkRef = React.createRef();
function PortList(props) {
  const [state, setState] = useState({
    portList: [],
    csvData: [],
    customerVessels: [],
    userType:
      props.userType === "supplier"
        ? "supplier"
        : props.subType === "captain"
          ? "captain"
          : props.subType === "customer"
            ? "customer"
            : "",
    activePage: 0,
    totalCount: 0,
    isHitApi: false,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
  });
  const query = new URLSearchParams(props.location.search);
  let topPort = query.get("topPort");
  let supplierId = query.get("supplierId");
  let itemCode = query.get("itemCode");
  if (topPort && headers.length < 5) {
    headers.push(
      { label: "Turnover P-MESH", key: "pMeshVolume" },
      { label: "Turnover C-MESH", key: "cMeshVolume" },
      { label: "Total Turnover", key: "totalVolume" }
    );
  }
  useEffect(() => {
    let data = { ...state };
    if (!state.isHitApi) {
      if (
        (props.isSubAdmin &&
          props.userType === "user" &&
          props.subType !== "sub-admin") ||
        isAllowPermission(window.location.pathname, props.roles)
      ) {
        if (topPort !== null) {
          if (state.userType !== "customer") {
            let filterQuery = {};
            if (supplierId) {
              filterQuery["supplierId"] = supplierId;
            }
            if (itemCode) {
              filterQuery["itemCode"] = itemCode;
            }
            props.topSupplyHub(
              props.userId,
              state.userType,
              state.startDate,
              state.endDate,
              filterQuery
            );
          } else {
            props.vesselListing(0, 50);
          }
        } else {
          props.portListing(0);
        }
        data.isHitApi = true;
        setState(data);
      } else if (!isAllowPermission(window.location.pathname, props.roles)) {
        props.history.push("/not-allowed");
      }
    }
    if (
      (props.apiCallStatus.apiCallFor === "getPorts" ||
        props.apiCallStatus.apiCallFor === "appendPortList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        portList: props.portReducer.list,
        totalCount: props.portReducer.count,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topSupplyHub" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      let newArray = [];
      let data = [...props.commonReducer.topSupplyHubList];
      setState({
        ...state,
        portList: props.commonReducer.topSupplyHubList,
        csvData: newArray,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "vesselListing" ||
      (props.apiCallStatus.apiCallFor === "appendVesselList" &&
        props.apiCallStatus.isCompleted &&
        !props.apiCallStatus.isFailed)
    ) {
      let customerVessels = [];
      if (props.subType === "customer" && props.vesselList.length > 0) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        let filterQuery = {};
        if (supplierId) {
          filterQuery["supplierId"] = supplierId;
        }
        if (itemCode) {
          filterQuery["itemCode"] = itemCode;
        }
        props.topSupplyHub(
          customerVessels,
          "customer",
          state.startDate,
          state.endDate,
          filterQuery
        );
      }
      setState({
        ...state,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "deletePort" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      Swal.fire("Deleted!", "Port has been deleted!", "success");
      props.portListing(0);
    }
  }, [props, state.isHitApi]);
  const fetchMoreData = () => {
    let { activePage } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    if (topPort !== null) {
      let filterQuery = {};
      if (supplierId) {
        filterQuery["supplierId"] = supplierId;
      }
      if (itemCode) {
        filterQuery["itemCode"] = itemCode;
      }
      if (state.userType !== "customer") {
        props.topSupplyHub(
          props.userDetail.userId,
          state.userType,
          state.startDate,
          state.endDate,
          filterQuery
        );
      } else {
        props.topSupplyHub(
          state.customerVessels,
          state.userType,
          state.startDate,
          state.endDate,
          filterQuery
        );
      }
    } else {
      props.portListing(activePage);
    }
  };
  const removeRecord = (id) => {
    if (!isAllowPermission("/delete-port", props.roles)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You don't have permission of this feature",
      });
    } else {
      Swal.fire({
        title: "Delete Port",
        text: "Are you sure you want to delete this Port?",
        icon: "warning",
        buttons: true,
        // showCancelButton: true,
        dangerMode: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.deletePort(id);
        }
      });
    }
  };
  const getNavbarOptions = () => {
    let options = [
      {
        img: "left-arrow.svg",
        className: "orange",
        name: "Go back",
        path: "/",
        isLogout: false,
        isCustom: true,
        onClick: () => props.history.goBack(),
      },
    ];
    if (
      props.userType === "user" &&
      (props.subType === "user" || props.subType === "subAdmin")
    ) {
      options.push({
        img: "Edit-icon.svg",
        className: "dark-green",
        name: "Create<br />Port",
        path: "/create-port",
        isLogout: false,
      });
      options.push({
        img: "Upload-link.svg",
        className: "dark-blue",
        name: "Upload<br />Ports",
        path: "/upload-ports",
        isLogout: false,
      });
    }
    return options;
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
    });
  };
  const applyFilters = () => {
    if (state.startDate !== null && state.endDate !== null) {
      setState({
        ...state,
        isHitApi: false,
      });
    }
  };
  const clearFilters = () => {
    setState({
      ...state,
      startDate: null,
      endDate: null,
      isHitApi: false,
    });
  };
  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    csvLinkRef.current.link.click();
  };
  return (
    <DashboardWrapper
      title={`Port ${topPort !== null ? "Overview" : ""}`}
      navbarOptions={getNavbarOptions()}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {topPort !== null ? (
        <div className="ordCatg-table-wrap mt-3 system-cat-filter arow-remove">
          <div className="card-bx-sty">
            <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
              <div className="col-md-5">
                <div className="date-range-picker date-range-holder d-flex align-items-center">
                  <span className="d-inline-block ml-0 datePicker customerListing form-control  field-bx-sty px-0 py-0">
                    <DatePicker
                      selected={state.startDate}
                      maxDate={new Date()}
                      onChange={(val) => handleChange("startDate", val)}
                      className="link-style"
                    />
                  </span>
                  to
                  <span className="d-inline-block mr-0 datePicker customerListing form-control field-bx-sty px-0 py-0">
                    <DatePicker
                      selected={state.endDate}
                      disabled={state.startDate === null ? true : false}
                      minDate={
                        state.startDate !== null
                          ? new Date(moment(state.startDate).add(1, "days"))
                          : new Date(moment().add(1, "days"))
                      }
                      onChange={(val) => handleChange("endDate", val)}
                      className="link-style"
                    />
                  </span>
                </div>
              </div>
              <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                <button
                  type="button"
                  className="btn btn-yellow ml-1"
                  onClick={() => applyFilters()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-yellow ml-1 mr-1"
                  onClick={() => clearFilters()}
                >
                  Clear
                </button>
                <CsvDownload
                  data={state.csvData}
                  csvLinkRef={csvLinkRef}
                  filename={"Top-Ports.csv"}
                  headers={headers}
                  apiCallForCSVData={apiCallForCSVData}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <InfiniteScroll
        dataLength={state.portList.length}
        next={fetchMoreData}
        height={"80vh"}
        hasMore={state.totalCount <= state.portList.length ? false : true}
        loader={
          <div
            colSpan="12"
            className="loadmore d-flex align-items-center justify-content-center"
          >
            {" "}
            <img src={loader} alt="" />
          </div>
        }
      >
        <div className=" table-responsive mt-4">
          <table className="table_overview table theme-table-wrap with-action-btns">
            <thead>
              <TableHeader topPort={topPort} userType={props.userType} />
            </thead>
            <tbody>
              {props.apiCallStatus.isStarted.indexOf("getPorts") !== -1 ||
                props.apiCallStatus.isStarted.indexOf("topSupplyHub") !== -1 ? (
                <td colSpan="10">
                  <div className="loader-img text-center ">
                    <img style={{ width: "46px" }} src={loader} alt="" />
                  </div>
                </td>
              ) : state.portList && state.portList.length === 0 ? (
                <td colSpan="40" className="text-center">
                  No Data Found
                </td>
              ) : (
                state.portList &&
                state.portList.length > 0 &&
                state.portList.map((item, index) => {
                  let isDisableEdit =
                    props.isSubAdmin &&
                      props.roles.length > 0 &&
                      !isAllowPermission(
                        getRoutePathBykey("editPort"),
                        props.roles
                      )
                      ? true
                      : false;
                  let isDisableDel =
                    props.isSubAdmin &&
                      props.roles.length > 0 &&
                      !isAllowPermission("/delete-port", props.roles)
                      ? true
                      : false;
                  return (
                    <TableContent
                      topPort={topPort}
                      isDisableEdit={isDisableEdit}
                      isDisableDel={isDisableDel}
                      key={index}
                      index={index}
                      item={item}
                      userType={props.userType}
                      isSubAdmin={props.isSubAdmin}
                      removeRecord={removeRecord}
                      roles={props.roles}
                    />
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </DashboardWrapper>
  );
}
const TableHeader = React.memo((props) => {
  if (props.topPort) {
    return (
      <tr>
        <th scope="col">#</th>
        {headers.map((item, inex) => {
          return <th>{item.label}</th>;
        })}
        {props.userType !== "supplier" ? <th>Suppliers</th> : ""}
      </tr>
    );
  } else {
    return (
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Port Code</th>
        <th scope="col">Country Name</th>
        <th scope="col">Continent</th>
        <th scope="col">Region</th>
        <th scope="col">Action</th>
      </tr>
    );
  }
});
const TableContent = React.memo((props) => {
  if (props.topPort) {
    return (
      <tr key={"tcontent0-" + props.index}>
        <td>{props.index + 1}</td>
        <td>{props.item?.port}</td>
        <td>{props.item?.countryName}</td>
        <td>{props.item?.count}</td>
        {/* <td>{props.item?.pMeshCount}</td>
        <td>{props.item?.cMeshCount}</td> */}
        <td>
          {currencyFormatter(props.item?.pMeshVolume, props.item.currency)}
        </td>
        <td>
          {currencyFormatter(props.item?.cMeshVolume, props.item.currency)}
        </td>
        <td>
          {currencyFormatter(props.item?.totalVolume, props.item.currency)}
        </td>
        {props.userType !== "supplier" ? (
          <td>
            <Link
              to={`/supplier-listing?topSupplier=1&portId=${props.item._id}`}
              className="d-flex  p-0 fz-12 dark-pink-clr"
            >
              View
            </Link>
          </td>
        ) : (
          ""
        )}
      </tr>
    );
  } else {
    return (
      <tr key={"tc9-" + props.index + 1}>
        <td>{props.index + 1}</td>
        <td>{props.item.port}</td>
        <td>{props.item.code}</td>
        <td>{props.item.countryName}</td>
        <td>{props.item.continent}</td>
        <td>{props.item.region}</td>
        {props.isDisableDel && props.isDisableEdit ? (
          ""
        ) : getCookie("userType") === "user" ? (
          <td>
            {props.isDisableEdit ? (
              ""
            ) : (
              <Link
                to={"/edit-port/" + props.item._id}
                className="btn btn-edit-tab mr-2"
              ></Link>
            )}
            {props.isDisableDel ? (
              ""
            ) : (
              <button
                type="button"
                onClick={() => props.removeRecord(props.item._id)}
                className="btn btn-dlt-tab"
              ></button>
            )}
          </td>
        ) : (
          ""
        )}
      </tr>
    );
  }
});
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  portReducer: state.portReducer,
  vesselList: state.vesselReducer.list,
  commonReducer: state.commonReducer,
  userType: state.authReducer.userType,
  subType: state.authReducer.subType,
  roles: state.authReducer.roles,
  isSubAdmin: state.authReducer.isSubAdmin,
  userId: state.authReducer.userId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  portListing: (offset) => dispatch(portListing(offset)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  deletePort: (portId) => dispatch(deletePort(portId)),
  topSupplyHub: (userId, userType, form, to, filterQuery) =>
    dispatch(topSupplyHub(userId, userType, form, to, filterQuery)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PortList);
