import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

const defaultState = {
    selectedFile: null,
    isLoading: false,
};

export function UploadQuotationModal(props) {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        if (props.apiCallStatus.apiCallFor === "uploadQuotation") {
            if (props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
                handleModalChange(true);
                props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
                let msg = "Quotation has been uploaded successfully.";
                Swal.fire("Success", msg, "success");
            } else if (props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
                let cloneState = { ...state };
                cloneState.message = props.apiCallStatus.message;
                cloneState.messageType = "danger";
                cloneState.messageFor = "uploadQuotation";
                setState(cloneState);
                props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            }
        }
    }, [props.apiCallStatus]);

    const onChangeHandler = (event) => {
        if (event.target !== undefined) {
            setState({
                ...state,
                selectedFile: event.target.files[0],
            });
        }
    };

    const handleModalChange = (showModal) => {
        if (!showModal) setState(defaultState);
        props.handleModalChange(showModal);
    };

    const uploadExcelFile = async (event) => {
        event.preventDefault();

        const { quotationDetailList } = props.state;
        const { selectedFile } = state;

        if (selectedFile) {
            const filename = selectedFile.name;
            const allowedFileTypes = ["xlsx", "xls"];
            const [extension] = filename.split(".").slice(-1);
            if (!extension || !allowedFileTypes.includes(extension)) {
                Swal.fire("Error!", "Invalid File type.", "error");
                return;
            }

            setState({
                ...state,
                isLoading: true,
            });

            const buffer = await selectedFile.arrayBuffer();
            const workbook = XLSX.read(buffer);
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(sheet);

            const length = quotationDetailList?.length;

            if (data?.length !== length) {
                setState({
                    ...state,
                    isLoading: false,
                });
                const message = `The file must contain ${length} records. Please check once!`;
                Swal.fire("Records mismatch", message, "error");
                return;
            }

            for (let index = 0; index < data.length; ++index) {
                const { IMPA, Quantity, Price, Remarks } = data[index];
                const rec = quotationDetailList.find(({ alternativeCode }) => alternativeCode === IMPA);
                if (rec) {
                    rec.order = Quantity?.toString();
                    rec.qtdPrice = Price?.toString();
                    rec.remark = Remarks;
                }
            }

            setState({
                ...state,
                selectedFile: null,
                isLoading: false,
            });

            props.setState({
                ...props.state,
                showModal: false,
                quotationDetailList
            });
        }
    };

    return (
        <Modal show={props.showModal} className="modal-centered" onHide={() => handleModalChange(false)}>
            <div className="change-header">
                <Modal.Header closeButton>
                    <h5 className="welcome-inn-content m-0 p-0 text-left">Upload Quotation</h5>
                </Modal.Header>
            </div>
            <Modal.Body>
                <form>
                    <div className="form-group form-input-container">
                        <input
                            type="file"
                            name="file"
                            id="uploadQuotation"
                            accept=".xlsx, .xls"
                            className="form-control"
                            onChange={onChangeHandler}
                        />
                        <label
                            htmlFor="uploadQuotation"
                            className="d-flex align-items-center"
                        >
                            <span className="text-truncate">
                                {state?.selectedFile?.name || "Upload File"}
                            </span>
                        </label>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <button
                                type="submit"
                                className={`btn tb-btn-rounded orng ${!state.selectedFile ? 'not-allowed' : ''}`}
                                onClick={(e) => uploadExcelFile(e)}
                                disabled={!state.selectedFile || state.isLoading}
                            >
                                Submit &rarr;
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}