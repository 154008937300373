import React, { Fragment, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import { Constant } from "../../../../Constants/constant";
import $ from "jquery";
import CompareOrderList from "./compareOrderList";

// import { Modal, Button } from "react-bootstrap";
export function CustomerDetailModal(props) {
  return (
    <div
      className="modal fade theme-squareModal modal-centered"
      id="customerDetailModal"
      tabIndex="-1"
      aria-labelledby="NutritaionModal"
      aria-hidden="true"
    >
      <div className="modal-dialog w-650">
        <div className="modal-content">
          <div className="model-body">
            {/* Modal Content */}
            <div className="modal-card d-flex align-items-center justify-content-between">
              <h1 className="modalCard-text m-0">Customer Detail</h1>
              <button
                type="button"
                className="close circle-btn-style"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="nutrTable-wrap table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Vessel Name</th>
                    <th>Vessel Rate</th>
                    <th>Currency</th>
                    <th>Captain</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {props.customerDetail.name}</td>
                    <td> {props.customerDetail.rate}</td>
                    <td> {props.customerDetail.currency}</td>
                    <td>
                      {" "}
                      {props.customerDetail.customerName
                        ? props.customerDetail.customerName
                        : "Captain"}
                    </td>
                    <td> {props.customerDetail.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className="modal-btn-wrap d-flex justify-content-end">
                            <button type="button" className="close footer-close-btn w-100" data-dismiss="modal" aria-label="Close">Close</button>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export function UploadDocModal(props) {
  const [state, setState] = useState({
    invoice: null,
    deliveryNote: null,
    reason: "",
  });

  const approveDoucment = (isApproved) => {
    const approvalText = isApproved ? "approved" : "rejected";
    setState({
      ...state,
      reason: "",
    });
    props.approveDoucment({
      orderId: props.orderId,
      reason: state.reason,
      isApproved,
      reason: state.reason,
    });
    $("#uploadDocumentModal").modal("hide");
    Swal.fire(
      "Success",
      `Documents have been ${approvalText} successfully.`,
      "success"
    );
  };
  const onChangeHandler = (event) => {
    let id = event.target.id;
    let val =
      event.target.type === "textarea"
        ? event.target.value
        : event.target.files[0];
    setState({
      ...state,
      [id]: val,
    });
  };

  const uploadFile = async (event) => {
    event.preventDefault();
    if (state.invoice && state.deliveryNote) {
      let data = {
        data: props.documentList,
        orderId: props.orderId,
        invoice: state.invoice,
        deliveryNote: state.deliveryNote,
        supplierId: props.userId,
      };
      props.uploadDocuments(data);
    } else {
      Swal.fire("Error", "Please select both documents before upload", "error");
    }
  };
  return (
    <div
      className="modal fade theme-squareModal modal-centered "
      id="uploadDocumentModal"
      tabIndex="-1"
      aria-labelledby="NutritaionModal"
      aria-hidden="true"
    >
      <div className="modal-dialog w-650">
        <div className="modal-content delivery-notes">
          <div className="model-body">
            {/* Modal Content */}
            <div className="modal-card d-flex align-items-center justify-content-between">
              <h1 className="modalCard-text m-0 ">
                {props.userType !== "supplier"
                  ? "View Document"
                  : "Upload Document"}
              </h1>
              <button
                type="button"
                className="close circle-btn-style"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {state.compare && (
              <UploadDocModal
                userType={props.userType}
                isSubAdmin={props.isSubAdmin}
                approveDoucment={props.approveDoucment}
                uploadDocuments={props.uploadDocuments}
                documentList={props.documentList}
                apiCallStatus={props.apiCallStatus}
                orderId={state.selectedOrder}
                userId={props.userId}
              />
            )}
            {props.userType === "supplier" ? (
              <div className="formBox">
                <form className="needs-validation">
                  <div className="row">
                    <InputFile
                      className="col-sm-6"
                      label="Delivery Note"
                      id="deliveryNote"
                      value={state.deliveryNote}
                      onChangeHandler={onChangeHandler}
                    />
                    <InputFile
                      className="col-sm-6"
                      label="Invoice"
                      id="invoice"
                      value={state.invoice}
                      onChangeHandler={onChangeHandler}
                    />
                  </div>
                  <div className="d-flex align-items-center flex-column flex-sm-row">
                    {props.apiCallStatus.isStarted.indexOf(
                      "uploadDocuments"
                    ) !== -1 ? (
                      <div className="loader-img text-center">
                        <img
                          style={{ width: "46px" }}
                          src={require("../../../../assets/images/Spinner-1s-200px.gif")}
                          alt=""
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-btn d-flex justify-content-end flex-fill">
                      <button
                        type="submit"
                        className="btn btn themeReguler-btn bg-sky-blue"
                        onClick={uploadFile}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
            <div className="nutrTable-wrap table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Status</th>
                    <th>Uploaded Date</th>
                    {/* <th>Reason</th> */}
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {props.documentList && props.documentList.length > 0 ? (
                    props.documentList.map((item, index) => {
                      return (
                        <tr key={"documentList3-" + index}>
                          <td>
                            <a
                              className={
                                item.status === "approved"
                                  ? "green"
                                  : item.status === "rejected"
                                  ? "red"
                                  : "yellow"
                              }
                              target="_blank"
                              href={item?.file?.filename}
                            >
                              {item?.fileName}&nbsp;
                            </a>
                          </td>
                          <td>
                            {item.status
                              ? item.status[0].toUpperCase() +
                                item.status.slice(1)
                              : ""}
                          </td>
                          <td>
                            {moment(item.createdAt).format("Do MMM YYYY")}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <Fragment>
                      <tr>
                        <td>
                          <a href="#">Delivery Note</a>
                        </td>
                        <td>Pending</td>
                        <td>--</td>
                      </tr>
                      <tr>
                        <td>
                          <a href="#">Invoice</a>
                        </td>
                        <td>Pending</td>
                        <td>
                          <span className="text-center">--</span>
                        </td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
            {props.apiCallStatus.isStarted.indexOf("getDocumentsList") !==
            -1 ? (
              <div className="align-items-center flex-column flex-sm-row">
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="modal-btn-wrap justify-content-center align-items-center p-3 ">
              {props.userType === "user" &&
              !props.isSubAdmin &&
              props.documentList &&
              props.documentList.length > 0 ? (
                <React.Fragment>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="col-10">
                      {props.apiCallStatus.isStarted.indexOf(
                        "approveDoucment"
                      ) !== -1 ? (
                        <div className="text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {console.log("props", props)}
                  <div className="d-flex justify-content-center mb-3">
                    <button
                      type="button"
                      className="btn tb-btn-rounded green px-5"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        props.history.push(
                          `/compare-orders/${props.orderId}/${props.supplierId}/${props.quotationId}`
                        );
                      }}
                    >
                      Compare
                    </button>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <div className="col-10">
                      <div className="md-form">
                        <textarea
                          id="reason"
                          className="md-textarea form-control"
                          rows="3"
                          placeholder="Reason to Reject (If any)"
                          value={state.reason}
                          onChange={onChangeHandler}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex  justify-content-center align-items-center m-2 approve-button">
                    <div className="col-5 text-right">
                      <button
                        className="btn tb-btn-rounded orng ml-2"
                        onClick={() => approveDoucment(true)}
                      >
                        Approve
                      </button>
                    </div>
                    <div className="col-5 text-left ">
                      <button
                        className="btn tb-btn-rounded red ml-2"
                        onClick={() => approveDoucment(false)}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InputFile(props) {
  return (
    <div className={props.className}>
      <label className="label-reg-sty text-truncate ">{props.label}</label>
      <div className="form-group form-input-container model_uploadfile">
        <input
          type="file"
          name="file"
          id={props.id}
          className="form-control"
          onChange={props.onChangeHandler}
          accept="application/pdf"
        />
        <label
          htmlFor={props.id}
          className="d-flex flex-row-reverse justify-content-between align-items-center  "
        >
          <span className="text-truncate">
            {props.value && props.value.name
              ? props.value.name
              : "Please select file "}
          </span>
        </label>
      </div>
      {/* <div className="form-group">
            <input type="file" name="file" id={props.id} className="form-control" onChange={props.onChangeHandler} accept="pdf" />
        </div> */}
    </div>
  );
}
