import cloneDeep from "lodash/cloneDeep";
import InitialState from "./../../../Constants/initialState";
import * as Action from "./../Actions/action";

export default function OrderReducer(state = InitialState.order, action) {
  switch (action.type) {
    case Action.addOrder:
      let s = cloneDeep(state);
      s.currentOrder = action.payload.data;
      return s;
    case Action.getProvision:
      let s1 = cloneDeep(state);
      s1.provisionList = action.payload.list;
      s1.totalProvision = action.payload.count;
      return s1;
    case Action.AppendProvisionList:
      var s2 = cloneDeep(state);
      var arr = s2.provisionList.slice();
      s2.provisionList = arr.concat(action.payload.list);
      return s2;
    case Action.getFilterDetail:
      let s3 = cloneDeep(state);
      s3.categoryList = action.payload.categoryList;
      s3.categoryCodeList = action.payload.categoryCodeList;
      s3.totalCategoryCode = action.payload.categoryCodeCount;
      return s3;
    case Action.AppendFilterDetail:
      var s4 = cloneDeep(state);
      var arr = s4.categoryCodeList.slice();
      s4.categoryCodeList = arr.concat(action.payload.categoryCodeList);
      return s4;
    case Action.getItemCode:
      let s5 = cloneDeep(state);
      s5.itemCodeList = action.payload.list;
      s5.totalItemCode = action.payload.count;
      return s5;
    case Action.appendItemCode:
      var s6 = cloneDeep(state);
      var arr = s6.itemCodeList.slice();
      s6.itemCodeList = arr.concat(action.payload.list);
      return s6;
    case Action.getItemGroup:
      var s7 = cloneDeep(state);
      s7.itemGroupList = action.payload.list;
      return s7;
    case Action.getOrder:
      let s8 = cloneDeep(state);
      s8.orderList = action.payload.list;
      s8.totalOrder = action.payload.count;
      return s8;
    case Action.AppendOrderList:
      var s9 = cloneDeep(state);
      var arr = s9.orderList.slice();
      s9.orderList = arr.concat(action.payload.list);
      return s9;
    case Action.supplierSelectionList:
      var s10 = cloneDeep(state);
      s10.supplierSelectionList = action.payload.list;
      s10.port = action.payload.port;
      return s10;
    case Action.getRfqList:
      var s11 = cloneDeep(state);
      s11.rfqList = action.payload.list;
      s11.orderStatus = action.payload.orderStatus;
      s11.orderDetail = action.payload.orderDetail;
      return s11;
    case Action.getDescription:
      let s12 = cloneDeep(state);
      s12.descriptionList = action.payload.list;
      s12.totalDescription = action.payload.count;
      return s12;
    case Action.appendDescription:
      var s13 = cloneDeep(state);
      var arr = s13.descriptionList.slice();
      s13.descriptionList = arr.concat(action.payload.list);
      return s13;
    case Action.getCustomerOrderDetail:
      var s14 = cloneDeep(state);
      s14.customerDetail = action.payload.detail;
      return s14;
    case Action.getTopItems:
      let s15 = cloneDeep(state);
      s15.topItemsList = action.payload.list;
      s15.totalTopItems = action.payload.count;
      return s15;
    case Action.AppendTopItems:
      var s16 = cloneDeep(state);
      var arr = s16.topItemsList.slice();
      s16.topItemsList = arr.concat(action.payload.list);
      return s16;
    case Action.getDocumentsList:
      let s17 = cloneDeep(state);
      s17.documentList = action.payload.list;
      return s17;

    case Action.udpateDoucmentStatus:
      let s18 = cloneDeep(state);
      for (let i = 0; i < s18.documentList.length; i++) {
        s18.documentList[i].isApproved = action.payload.status;
        s18.documentList[i].status = action.payload.status
          ? "approved"
          : "rejected";
      }
      return s18;
    case Action.appendDocument:
      let s19 = cloneDeep(state);
      let docIndex = s19.documentList.findIndex(
        (x) => x._id === action.payload.obj._id
      );
      if (docIndex !== -1) {
        s19.documentList[docIndex] = action.payload.obj;
      } else {
        s19.documentList.push(action.payload.obj);
      }
      return s19;
    case Action.GET_NEXT_ORDER_QTY:
      let s20 = cloneDeep(state);
      s20.nextOrderQty = action.payload.data;
      return s20;
    default:
      return state;
  }
}
