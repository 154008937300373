import React, { useEffect, useState } from "react";
import "./orderCatalogue.css";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ApiClearAction } from "../ApiCallStatus/Actions/action";
import { provisionListing } from "./../Orders/ApiCalls/provisionOrder";
import * as XLSX from "xlsx";
import { DashboardWrapper } from "../Dashboard/Components/dashboarWrapper";
const options = [
  { value: "Provisions", label: "Provisions" },
  { value: "Deck", label: "Deck" },
  { value: "Engine", label: "Engine" },
  { value: "Galley / ", label: "Galley / " },
  { value: "laundry", label: "laundry" },
  { value: "Electrical", label: "Electrical" },
  { value: "Stationary", label: "Stationary" },
];

function SupplierOrderCrud(props) {
  const [state, setState] = useState({
    catalougeList: [],
    totalProvision: 0,
    eta: "",
    totalCount: 0,
    isHitApi: false,
  });
  useEffect(() => {
    if (!state.isHitApi) {
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
      props.provisionListing(0, 1000);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getProvision" ||
        props.apiCallStatus.apiCallFor === "appendProvisionList") &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        catalougeList: props.orderReducer.provisionList,
        totalProvision: props.orderReducer.totalProvision,
      });
    }
  }, [props, state.isHitApi]);

  const handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
    {
      img: "Edit-icon.svg",
      className: "dark-green",
      name: "Send<br />RFQ",
      path: "/",
      isLogout: false,
    },
  ];
  // const exportToExcel = () => {
  //   const fileName = "test.xlsx";

  //   const ws = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(state.catalougeList));
  //   const wb = (XLSX.WorkBook = XLSX.utils.book_new());
  //   XLSX.utils.book_append_sheet(wb, ws, "test");

  //   XLSX.writeFile(wb, fileName);
  // };
  return (
    <DashboardWrapper
      title="ABC Ship Supplier - RFQ"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
        {/* Table */}
        <div className="ordCatg-InnTable store-catgTable">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left">Date</th>
                  <th>Vessel</th>
                  <th>Management Company</th>
                  <th>Department</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Export to .xls</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      // onClick={() => exportToExcel()}
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="text-left td-wrap-sty">DECK</span>
                  </td>
                  <td>
                    <span className="td-wrap-sty">174321</span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty">
                      ASH TRAY STAINLESS STEEL, WITH COVER DIAM 200MM
                    </span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>
                  <td>
                    <span className="text-left td-wrap-sty"></span>
                  </td>

                  <td align="center">
                    <button
                      type="button"
                      className="btn uploadFileBtn-wrap"
                    ></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  orderReducer: state.orderReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ApiClearAction: () => dispatch(ApiClearAction()),
  provisionListing: (offset, limit) =>
    dispatch(provisionListing(offset, limit)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SupplierOrderCrud);
