import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DonutChart from '../../charts/DonutChart';
import SingleColumnChart from '../../charts/SingleColumnChart';

const defaultState = {
    password: "",
    confirmPassword: "",
    message: "",
    messageType: "",
    messageFor: "",
    csvData: [],
}

export function VesselChartModal(props) {
    const handleModalChange = () => {
        props.handleModalChange('', '');

    }
    const { vesselWiseStatList } = props
    const consumptionPerDepartment = vesselWiseStatList?.consumptionPerDepartment?.data
    const budgetConclusion = vesselWiseStatList?.budgetConclusion?.data
    return (
        <Modal size="lg" show={props.showModal === "catalogList"} className="modal-centered" onHide={() => handleModalChange()}>
            <Modal.Header closeButton>
                <h5 className="welcome-inn-content m-0 p-0 text-left">Charts</h5>
            </Modal.Header>
            <Modal.Body>
                <div className="row p-2">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="row bg-white mb-2 mr-1">
                            <span className="chart-title text-center">Rate Conclusion</span>
                        </div>

                        <div className="row mr-1">
                            {/* <div className="d-flex ">
                                            <div>
                                                <ComposableMap>
                                                    <Geographies geography={geoUrl}>
                                                        {({ geographies }) =>
                                                            geographies.map((geo) => (
                                                                <Geography key={geo.rsmKey} geography={geo} />
                                                            ))
                                                        }
                                                    </Geographies>
                                                </ComposableMap>
                                            </div>
                                        </div> */}
                            <div className="col-12 bg-white ">
                                {budgetConclusion && <SingleColumnChart data={budgetConclusion} />}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="row bg-white mb-2 mr-1">
                            <span className="chart-title">Daily Consumption</span>
                        </div>

                        <div className="row mr-1">
                            <div className="col-12 bg-white ">
                                {consumptionPerDepartment && <DonutChart data={consumptionPerDepartment} />}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
