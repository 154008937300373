import React, { useEffect, useState } from 'react';
import { createReminder } from "../ApiCalls/reminder";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import Swal from 'sweetalert2';
import { ReminderForm } from "./reminderForm";
import { getRoutePathBykey } from '../../../Constants/routesConfig';
import { reminderNameList } from '../../../Constants/constant';
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';

const reminderInitialState = {
    nameList: reminderNameList,
    maxReminderList: [{
        label: 1, value: 1
    }, {
        label: 2, value: 2
    }],
    name: null,
    days: '',
    maxReminder: null,
    errorMessage: "",
    errorType: "",
    isHitApi: false,
    showDaysField: true
}
function CreateReminder(props) {
    const [state, setState] = useState(reminderInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            }
        }
        if ((props.apiCallStatus.apiCallFor === "createReminder") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(reminderInitialState)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            Swal.fire("Success", `The reminder has been created successfully.`, "success")
            let path = getRoutePathBykey('reminderList');
            if (props.isSubAdmin && !isAllowPermission(path, props.roles)) {
                props.history.push(getDashboardLink())
            } else {
                props.history.push(path)
            }
        }
    });
    const handleFormChange = (e, id) => {
        let stateClone = { ...state }
        if (e.label !== undefined) {
            stateClone[id] = e;
        } else {
            let name = e.target.name;
            let value = e.target.value;
            stateClone[name] = value;
        }
        if (id === undefined) {
            setState(stateClone)
        } else {
            if (e.value === 'Upcoming delivery' || e.value === 'Order delivery') {
                stateClone.showDaysField = false;
                setState(stateClone);
            } else {
                stateClone.showDaysField = true;
                setState(stateClone);
            }

        }
    }
    const createHandler = () => {
        let obj = {
            name: state.name.value,
            days: state.days,
            showDaysField: state.showDaysField
        }
        if (state.showDaysField === true) {
            obj.maxReminder = state.maxReminder.value;
        }
        setState({
            ...state,
            errorMessage: "",
            errorType: "",
        })
        props.createReminder(obj);
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <DashboardWrapper title="Create Reminder" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="formBox">
                <ReminderForm {...state} history={props.history} handleFormChange={handleFormChange} createHandler={createHandler} apiCallStatus={props.apiCallStatus} />
            </div>
        </DashboardWrapper>
    )
}


const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    countryReducer: state.countryReducer,
    commonReducer: state.commonReducer,
    isSubAdmin: state.authReducer.isSubAdmin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createReminder: (data) => dispatch(createReminder(data)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateReminder);
