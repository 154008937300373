import React, { useEffect, useState } from "react";
import { getCompanies, deleteCompany, updateCompany, getCompanyByDomain } from "../../Common/ApiCalls/company";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from "react-redux";
import moment from "moment";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { isAllowPermission } from "../../../Services/common";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";
import { CsvDownload } from "../../Common/Components/CsvDownload";

const csvLinkRef = React.createRef();

const getHeaders = () => ([
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Address", key: "address" },
    { label: "Domain", key: "domain" },
    { label: "Country", key: "country" },
    { label: "City", key: "city" },
]);

function ListCompanies(props) {
    const [state, setState] = useState({
        companyList: [],
        csvData: [],
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
        filterQuery: "",
        activePage: 0,
        totalCount: 0,
        isHitApi: false,
        showModal: "",
        headers: [],
    });

    useEffect(() => {
        let data = { ...state };
        if (!state.isHitApi) {
            if (
                (props.isSubAdmin &&
                    props.userType === "user" &&
                    props.subType !== "sub-admin") ||
                isAllowPermission(window.location.pathname, props.roles)
            ) {
                props.getCompanies("");
                data.isHitApi = true;
                setState({
                    ...data,
                    headers: getHeaders(),
                });
            }
        }

        if (
            props.apiCallStatus.apiCallFor === "getAllCompanies" &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed
        ) {
            const companies = props.companyReducer.list || [];
            const csvData = companies.map(({ name, email, phoneNumber, address, domain, country, port }) => ({
                name,
                email,
                phoneNumber,
                address,
                domain,
                country: country?.name || '',
                city: port?.name || '',
            }));
            setState({
                ...state,
                companyList: companies,
                totalCount: props.companyReducer.count,
                csvData,
                isHitApi: true,
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    }, [props, state.isHitApi]);

    const fetchMoreData = () => {
        let { activePage, filterQuery } = state;
        activePage += 1;
        filterQuery += `activePage=${activePage}`;
        setState({
            ...state,
            activePage,
            filterQuery,
        });
        props.getCompanies(filterQuery);
    };

    const editRecord = (id) => {
        if (!isAllowPermission("/edit-company", props.roles)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "You don't have permission of this feature!",
            });
        } else {
            props.history.push(`/edit-company/${id}`);
        }
    };

    const removeRecord = (id) => {
        if (!isAllowPermission("/delete-company", props.roles)) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "You don't have permission of this feature!",
            });
        } else {
            Swal.fire({
                title: "Delete Company",
                text: "Are you sure you want to delete this company?",
                icon: "warning",
                buttons: true,
                showCancelButton: true,
                dangerMode: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.deleteCompany(id);
                    Swal.fire("Deleted!", "Company has been deleted!", "success");
                    props.getCompanies("");
                    props.getCompanyByDomain(window.location.host);
                }
            });
        }
    };

    const handleChange = (id, val) => {
        setState({
            ...state,
            [id]: val,
        });
    };

    const applyFilters = () => {
        if (state.startDate !== null && state.endDate !== null) {
            const from = moment(state.startDate).format("YYYY-MM-DD");
            const to = moment(state.endDate).add(1, "days").format("YYYY-MM-DD");
            const filterQuery = `?from=${from}&to=${to}`;
            props.getCompanies(filterQuery);
            setState({
                ...state,
                filterQuery,
                activePage: 0,
                isHitApi: true,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select both date.",
            });
        }
    };

    const clearFilters = () => {
        let filterQuery = "";
        props.getCompanies(filterQuery);
        setState({
            ...state,
            startDate: null,
            endDate: null,
            filterQuery,
            activePage: 0,
            isHitApi: true,
        });
    };

    const navbarOptions = [
        {
            img: "left-arrow.svg",
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack(),
        },
        {
            img: "Edit-icon.svg",
            className: "dark-green",
            name: "Create<br />Company",
            path: "/create-company",
            isLogout: false,
        },
    ];

    const apiCallForCSVData = () => {
        /**
         * Requesting csv files data
         */
        csvLinkRef.current.link.click();
    };

    return (
        <DashboardWrapper
            title="Company Listing"
            navbarOptions={navbarOptions}
            className="mesh-board-wrap d-flex align-items-end"
        >
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="col-md-5">
                            <div className="date-range-picker date-range-holder d-flex align-items-center">
                                <span className="d-inline-block ml-0 datePicker customerListing form-control field-bx-sty px-0 py-0">
                                    <DatePicker
                                        selected={state.startDate}
                                        maxDate={new Date()}
                                        onChange={(val) => handleChange("startDate", val)}
                                        className="link-style"
                                    />
                                </span>
                                to
                                <span className="d-inline-block mr-0 datePicker customerListing form-control field-bx-sty px-0 py-0">
                                    <DatePicker
                                        selected={state.endDate}
                                        disabled={state.startDate === null}
                                        minDate={
                                            state.startDate !== null
                                                ? new Date(moment(state.startDate).add(1, "days"))
                                                : new Date(moment().add(1, "days"))
                                        }
                                        onChange={(val) => handleChange("endDate", val)}
                                        className="link-style"
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <button
                                type="button"
                                className="btn btn-yellow ml-1"
                                onClick={() => applyFilters()}
                            >
                                Search
                            </button>
                            <button
                                type="button"
                                className="btn btn-yellow ml-1 mr-1"
                                onClick={() => clearFilters()}
                            >
                                Clear
                            </button>
                            <CsvDownload
                                data={state.csvData}
                                csvLinkRef={csvLinkRef}
                                filename={"companies.csv"}
                                headers={state.headers}
                                apiCallForCSVData={apiCallForCSVData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <InfiniteScroll
                dataLength={state.companyList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={state.totalCount <= state.companyList.length ? false : true}
                loader={
                    <div
                        colSpan="12"
                        className="loadmore d-flex align-items-center justify-content-center"
                    >
                        {" "}
                        <img src={loader} alt="" />
                    </div>
                }
            >
                <div className="table-responsive mt-4">
                    <table className="table theme-table-wrap">
                        <thead>
                            <TableHeader
                                topCustomer={state.topCustomer}
                                subType={props.subType}
                                userType={props.userType}
                                headers={state.headers}
                            />
                        </thead>
                        <tbody>
                            {props.apiCallStatus.isStarted.indexOf("getAllCompanies") !== -1 ? (
                                <tr>
                                    <td colSpan="20">
                                        <div className="loader-img text-center">
                                            <img style={{ width: "46px" }} src={loader} alt="" />
                                        </div>
                                    </td>
                                </tr>
                            ) : state.companyList && state.companyList.length > 0 ? (
                                state.companyList.map((item, index) => {
                                    return (
                                        <TableContent
                                            key={index}
                                            index={index}
                                            item={item}
                                            roles={props.roles}
                                            removeRecord={removeRecord}
                                            editRecord={editRecord}
                                        />
                                    );
                                })
                            ) : state.companyList && state.companyList.length === 0 ? (
                                <tr>
                                    <td colSpan="40" className="text-center">
                                        <div>No data found!</div>
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                        </tbody>
                    </table>
                </div>
            </InfiniteScroll>
        </DashboardWrapper>
    );
}

const TableHeader = React.memo((props) => {
    return (
        <tr>
            <th scope="col">#</th>
            {props.headers.map((item, index) => (
                <th key={index}>{item.label}</th>
            ))}
            <th scope="col">Actions</th>
        </tr>
    );
});

const TableContent = React.memo((props) => {
    const { _id, name, email, phoneNumber, address, domain, country, port } = props.item;
    return (
        <tr key={_id}>
            <td>{props.index + 1}</td>
            <td>{name}</td>
            <td>{email}</td>
            <td>{phoneNumber}</td>
            <td>{address}</td>
            <td>{domain}</td>
            <td>{country?.name}</td>
            <td>{port?.name}</td>
            <td>
                {props.isSubAdmin &&
                    props.roles.length > 0 &&
                    !isAllowPermission("/edit-company", props.roles) ? (
                    ""
                ) : (
                    <button
                        type="button"
                        onClick={() => props.editRecord(_id)}
                        className="btn btn-edit-tab"
                    ></button>
                )}
                {props.isSubAdmin &&
                    props.roles.length > 0 &&
                    !isAllowPermission("/delete-company", props.roles) ? (
                    ""
                ) : (
                    <button
                        type="button"
                        onClick={() => props.removeRecord(_id)}
                        className="btn btn-dlt-tab"
                    ></button>
                )}
            </td>
        </tr>
    );
});

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    companyReducer: state.companyReducer,
    roles: state.authReducer.roles,
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
    isSubAdmin: state.authReducer.isSubAdmin,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getCompanies: (filterQuery) => dispatch(getCompanies(filterQuery)),
    updateCompany: (id, data) => dispatch(updateCompany(id, data)),
    deleteCompany: (id) => dispatch(deleteCompany(id)),
    getCompanyByDomain: (domain) => dispatch(getCompanyByDomain(domain)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListCompanies);
