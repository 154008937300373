import React, { Component } from 'react';
import { Wrapper } from '../../../Css/createOrder';
import { DeliveryDetail } from "./deliveryDetail";
import { OrderDetail } from "../orderDetail";
// import { VesselCrewDetail } from "./vesselCrewDetail";
import DashboardNavbar from './../../../../Common/Components/DashboardNavbar';
import { getAllPort } from "../../../../Common/ApiCalls/portList";
import { getAllCountry } from "../../../../Common/ApiCalls/countryList";
import { getVesselById } from "../../../../Common/ApiCalls/vesselList";
import { AddOrderAction } from '../../../Actions/action';
import { saveOrder, getOrderDetail } from './../../../ApiCalls/provisionOrder';
import { connect } from 'react-redux';
import { inventory } from '../../../../../Constants/constant';
import { getDashboardLink } from '../../../../../Services/common';
import DemoHeader from '../../../../Common/Components/demoHeader';
import $ from 'jquery';
import cloneDeep from 'lodash.clonedeep';
import Swal from 'sweetalert2';
var orderKind = null;
class CreateOrder extends Component {
	constructor(props) {
		super(props);
		const urlParams = new URLSearchParams(window.location.search);
		const orderId = urlParams.get('orderId');
		this.state = {
			// Order Detail
			orderId,
			orderTypesOptions: this.getOrderTypesOptions(props.userDetail.orderTypesOptions, props.userDetail.isSubAdmin, props.userDetail.userType, props.userDetail.subType),
			captainList: [],
			cookList: [],
			portList: [],
			countryList: [],//countryList,
			categories: [],
			orderKind: "",
			// Delivery Detail
			deliveryDetail: {
				imoNum: "",
				vessel: "",
				captain: "",
				cook: "",
				place: "",
				country: "",
				agentDetail: "",
				eta: "",
				etd: "",
				budgetPerDay: "",
				currency: "",
				numberOfCrew: {
					regular: 0,
					additional: 0
				},
				orderPeriod: {
					dryFrozen: {
						regular: 0,
						expectedDelay: 0
					},
					freshProvisions: {
						regular: 0,
						expectedDelay: 0
					},
				},
			},
			// Vessel Detail
			vesselDetail: {
				vesselType: "",
				nationality: "",
				nationalityRating: ""
			},
			// Error Handlers
			errorMessage: "",
			errorType: "",
			errorFor: "",
			isSaveAndClose: false

		};
	}
	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		orderKind = urlParams.get('orderKind');
		if (orderKind) {
			this.setState({
				orderKind
			});
		}
		this.props.getVesselById(this.props.match.params.vesselId);
		this.props.getAllCountry('');
		this.disableScroll();
		Swal.fire("Welcome to your provision order",
			"<p>MESH supports you to order the best possible provisioning of your good crew. This  includes a healthy and various nutrition.</p><p>Please be so kind to take some of your valued time to fill in the detailed information concerning your good vessel. The more information, the better</p><h6>THANK YOU!  Your MESH Team</h6>"
			, "success");
		if (this.state.orderId) {
			if (Object.size(this.props.currentOrder) > 0 && this.props.currentOrder._id === this.state.orderId) {
				this.upadteOrderState(this.props.currentOrder);
			} else {
				this.props.getOrderDetail(this.state.orderId);
			}
		} else {
			this.props.AddOrderAction({});
		}

	}
	getOrderTypesOptions = (orderTypesOptions, isSubAdmin, userType, subType) => {
		let options = [
			{ value: 'Main Order', label: 'Main Order' },
			{ value: 'Fresh Top Up', label: 'Fresh Top Up' },
			{ value: 'Additional', label: 'Additional' },
			{ value: 'Inventory', label: 'Inventory' }
		];
		if (!isSubAdmin) {
			if (userType === 'user') {
				options.push({ value: inventory, label: inventory });
			}
			return options;
		} else {
			let arr = [];
			if (orderTypesOptions && orderTypesOptions.length > 0) {
				if (orderTypesOptions.indexOf("Main & Fresh") !== -1) {
					arr.push(options[0]);
					arr.push(options[1]);
				}
				if (orderTypesOptions.indexOf("Additional") !== -1) {
					arr.push(options[2]);
				}
				if (orderTypesOptions.indexOf("Inventory") !== -1) {
					arr.push(options[3]);
				}
			} else {
				arr = options;
			}
			if (subType === "captain") {
				if (orderTypesOptions.indexOf(inventory) === -1) {
					arr.push({ value: inventory, label: inventory });
				}
			}
			return arr;
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.apiCallStatus.apiCallFor === "saveOrder" &&
			this.props.apiCallStatus.isCompleted &&
			this.props.apiCallStatus.isFailed &&
			navigator.onLine
		) {
			Swal.fire(
				"Dear Captain",
				this.props.apiCallStatus.message,
				"error"
			);
		}

		if (prevProps.apiCallStatus.isStarted.indexOf('getVesselById') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getVesselById') === -1) {
			let deliveryDetail = { ...this.state.deliveryDetail };
			const vesselDetail = this.props.vesselDetail || {};
			let keys = vesselDetail ? Object.keys(vesselDetail) : [];
			let cookList = [];
			let captainList = [];
			let categories = [];
			let countryList = [];
			if (keys.length > 0) {
				deliveryDetail["numberOfCrew"]["regular"] = vesselDetail.noOfCrew;
				deliveryDetail["imoNum"] = vesselDetail.imo;
				deliveryDetail["budgetPerDay"] = vesselDetail.rate;
				deliveryDetail["currency"] = vesselDetail.currency;
				deliveryDetail["vessel"] = { label: vesselDetail.name, value: vesselDetail._id, vesselType: vesselDetail?.vesselType };
				categories = vesselDetail.categories ? vesselDetail.categories : [];
				captainList = vesselDetail.captain && vesselDetail.captain.length > 0 ? vesselDetail.captain : [];
				cookList = vesselDetail.cook && vesselDetail.cook.length > 0 ? vesselDetail.cook : [];
				if (this.state.orderId && Object.size(this.props.currentOrder) > 0) {
					let captainIndex = captainList.findIndex(x => x.value === this.props.currentOrder.captain);
					if (captainIndex !== -1) {
						deliveryDetail["captain"] = captainList[captainIndex];
					}
					let cookIndex = cookList.findIndex(x => x.value === this.props.currentOrder.cook);
					if (cookIndex !== -1) {
						deliveryDetail["cook"] = cookList[cookIndex];
					}
				}
				if (this.props.vesselDetail.isAllCountry) {
					this.props.getAllCountry('');
				} else {
					this.props.vesselDetail.supplyCountries && this.props.vesselDetail.supplyCountries.forEach(element => {
						countryList.push({ label: element.name, value: element._id, countryCode: element.countryCode, name: element.name });
					});
				}
				// for (let index = 0; index < this.props.vesselDetail.supplyCountries.length; index++) {
				// 	let val = this.props.vesselDetail.supplyCountries[index];
				// 	countryList.push({ label: val.name, value: val._id, countryCode: val.countryCode, name: val.name })
				// }
			} else {
				this.setState({
					errorMessage: "No vessel found",
					errorType: "danger",
					errorFor: "orderDetail"
				});
			}
			this.setState({
				captainList,
				cookList,
				categories,
				deliveryDetail,
				countryList
			});
		}
		if (prevProps.apiCallStatus.isStarted.indexOf('getAllPort') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getAllPort') === -1) {
			let portList = [];
			for (let index = 0; index < this.props.portList.length; index++) {
				let val = this.props.portList[index];
				portList.push({ label: val.port, value: val._id });
			}
			this.setState({
				portList
			});
		}
		if (prevProps.apiCallStatus.isStarted.indexOf('getAllCountry') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getAllCountry') === -1) {
			let countryList = [];
			for (let index = 0; index < this.props.countryList.length; index++) {
				let val = this.props.countryList[index];
				countryList.push({ label: val.name, value: val._id, countryCode: val.countryCode, name: val.name });
			}
			this.setState({
				countryList
			});
		}
		if (prevProps.apiCallStatus.isStarted.indexOf('getOrderDetail') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('getOrderDetail') === -1 && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			if (Object.size(this.props.currentOrder) > 0) {
				this.upadteOrderState(this.props.currentOrder);
			}
		}

		if (prevProps.apiCallStatus.isStarted.indexOf('saveOrder') !== -1 && this.props.apiCallStatus.isStarted.indexOf('saveOrder') === -1) {
			const order = this.state.orderId;
			const orderId = navigator.onLine ? (this.props.apiCallStatus?.message || order) : order;
			const redirectUrl = `/provision-catalogue/${orderId}`;
			if (this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
				if (this.state.isSaveAndClose) {
					this.props.history.push(getDashboardLink());
				} else {
					this.props.history.push(redirectUrl);
				}
			} else {
				if (!navigator.onLine) {
					this.props.history.push(redirectUrl);
				}
			}
		}

		/*if ((prevProps.apiCallStatus.isStarted.indexOf('saveOrder') !== -1 &&
			this.props.apiCallStatus.isStarted.indexOf('saveOrder') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
			let id = this.props.currentOrder.orderId;
			if (this.state.isSaveAndClose) {
				this.props.history.push(getDashboardLink());
			} else {
				const oId = this.props.apiCallStatus?.message ? this.props.apiCallStatus.message : id;
				this.props.history.push('/provision-catalogue/' + oId);

			}

		}*/
	}
	handleSelectChange = (selectedOption, id) => {
		const mainOrder = this.state.orderTypesOptions;
		if (selectedOption.value === mainOrder[0].value) {
			const query = '?vesselId=' + this.props.match.params.vesselId;
			this.props.getAllCountry(query);
		} else {
			this.props.getAllCountry('');
		}
		this.setState({ [id]: selectedOption.value });
	};
	handleChange = (e, changeFor) => {
		let cloneObj = { ...this.state[changeFor] };
		let id = e.target.id;
		let val = e.target.value;
		let portList = this.state.portList;
		cloneObj[id] = val;
		if (id === "country") {
			cloneObj["place"] = "";
			if (!this.props.vesselDetail.isAllCountry) {
				// let query = "countryCode=" + val.countryCode;
				const query = `countryCode=${val.countryCode}&vesselId=${this.props.match.params.vesselId}`;
				this.props.getAllPort(query);
			} else {
				portList = [];
				for (let index = 0; index < this.props.vesselDetail.supplyPorts.length; index++) {
					let portElem = this.props.vesselDetail.supplyPorts[index];
					if (portElem.code === val.countryCode) {
						portList.push({ label: portElem.port, value: portElem._id });
					}
				}
			}
		} else if (id === 'eta') {
			cloneObj["etd"] = "";
		}
		this.setState({
			[changeFor]: cloneObj,
			portList
		});
	};
	validateForm = () => {
		var form = document.getElementsByClassName('needs-validation')[0];
		let isValid = true;
		let errorMessage = "";
		let errorType = "";
		let errorFor = "";

		const { deliveryDetail } = this.state;
		// const isSpotVessel = this.state.deliveryDetail?.vessel?.vesselType === 'spot';

		if (this.state.orderKind === "") {
			isValid = false;
			errorMessage = "Please select Order Type";
			errorType = "danger";
			errorFor = "orderDetail";
		} else if (Number(deliveryDetail?.orderPeriod?.freshProvisions?.regular) > 40) {
			isValid = false;
			errorMessage = "Due to Shorter durability of fresh, Please enter maximum of 40 days for fresh provision";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (Number(deliveryDetail?.orderPeriod?.freshProvisions?.expectedDelay) > 5) {
			isValid = false;
			errorMessage = "Please enter maximum of 5 days for Fresh Provisions' Expected Delays.";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (Number(deliveryDetail?.orderPeriod?.dryFrozen?.regular) > 90) {
			isValid = false;
			errorMessage = "Please enter maximum of 90 days for Dry and frozen provisions.";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (Number(deliveryDetail?.orderPeriod?.dryFrozen?.expectedDelay) > 5) {
			isValid = false;
			errorMessage = "Please enter maximum of 5 days for Dry & Frozen Provisions' Expected Delays.";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (form.checkValidity() === false) {
			isValid = false;
			form.classList.add('was-validated');
		} else if (this.state.deliveryDetail.vessel === "") {
			isValid = false;
			errorMessage = "Please select vessel";
			errorType = "danger";
			errorFor = "deliveryDetail";
		}
		// else if (this.state.deliveryDetail.captain === "") {
		// 	isValid = false;
		// 	errorMessage = "Please select captain";
		// 	errorType = "danger";
		// 	errorFor = "deliveryDetail";
		// } else if (this.state.deliveryDetail.captain.label === this.state.deliveryDetail.captain.value) {
		// 	isValid = false;
		// 	errorMessage = "Selected captain doesn't exist";
		// 	errorType = "danger";
		// 	errorFor = "deliveryDetail";
		// } else if (this.state.deliveryDetail.cook === "") {
		// 	isValid = false;
		// 	errorMessage = "Please select cook";
		// 	errorType = "danger";
		// 	errorFor = "deliveryDetail";
		// } else if (this.state.deliveryDetail.cook.label === this.state.deliveryDetail.cook.value) {
		// 	isValid = false;
		// 	errorMessage = "Selected cook doesn't exist";
		// 	errorType = "danger";
		// 	errorFor = "deliveryDetail";
		// }
		else if (this.state.orderKind !== inventory && this.state.deliveryDetail.place === "") {
			isValid = false;
			errorMessage = "Please select place of delivery";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.orderKind !== inventory && this.state.deliveryDetail.country === "") {
			isValid = false;
			errorMessage = "Please select country";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.deliveryDetail.eta === "") {
			isValid = false;
			errorMessage = this.state.orderKind !== inventory ? "Please select ETA" : "Please select date";
			errorType = "danger";
			errorFor = "deliveryDetail";
		} else if (this.state.orderKind !== inventory && this.state.deliveryDetail.etd === "") {
			isValid = false;
			errorMessage = this.state.orderKind !== inventory ? "Please select ETD" : "Please select Date";
			errorType = "danger";
			errorFor = "deliveryDetail";
		}
		// if (!isValid && errorMessage !== '') {
		// 	Swal.fire("Validation Error", errorMessage, "error");
		// }
		this.setState({
			errorMessage,
			errorType,
			errorFor
		});
		return isValid;
	};
	handleSubmit = (e) => {
		e.preventDefault();
		let isValid = this.validateForm();
		if (isValid) {
			let data = cloneDeep(this.state.deliveryDetail);
			if (this.state.orderKind !== inventory) {
				data.country.label = this.updateCountryLabel(data.country);
			}
			let deliveryDetailObj = {
				imoNum: data.imoNum,
				vessel: data.vessel.value,
				// captain: data.captain.value,
				// cook: data.cook.value,
				place: data.place,
				country: data.country,
				agentDetail: data.agentDetail,
				eta: data.eta,
				etd: data.etd,
				budgetPerDay: data.budgetPerDay,
				currency: data.currency,
				numberOfCrew: data.numberOfCrew,
				orderPeriod: data.orderPeriod,
				orderKind: this.state.orderKind,
				vesselRate: data.budgetPerDay,
				userId: this.props.userDetail.userId,
				orderProvisionType: this.state.orderKind === inventory ? "inventory" : "provision",
				categories: this.state.categories,
				orderDetail: this.props.currentOrder && this.props.currentOrder.orderDetail && this.state.orderKind === this.props.currentOrder.orderKind ? this.props.currentOrder.orderDetail : {},
				redirectionPath: "/create-provision-order/" + data.vessel.value + "?orderId="
			};
			if (this.state.orderId) {
				deliveryDetailObj.orderId = this.state.orderId;
			}
			this.props.AddOrderAction(deliveryDetailObj);
			let req = cloneDeep(deliveryDetailObj);
			req.categories = JSON.stringify(this.state.categories);
			this.props.saveOrder(req);
		}
	};
	disableScroll = () => {
		$('form').on('focus', 'input[type=number]', function (e) {
			$(this).on('wheel.disableScroll', function (e) {
				e.preventDefault();
			});
		});
	};
	updateCountryLabel = (country) => {
		let label = '';
		let countryLable = country.label.trim();
		if (countryLable === "Africa" || countryLable === "AFRICA") {
			label = "AFRICA";
		}
		else if (countryLable === "Caribbean" || countryLable === "CARIBBEAN") {
			label = "CARIBBEAN";
		}
		else if (countryLable === "Africa South" || countryLable === "AFRICA SOUTH") {
			label = "AFRICA SOUTH";
		}
		else if (countryLable === "Australia" || countryLable === "AUSTRALIA") {
			label = "AUSTRALIA";
		}
		else if (countryLable === "Brazil" || countryLable === "BRAZIL") {
			label = "BRAZIL";
		}
		else if (countryLable === "Chile" || countryLable === "CHILE") {
			label = "CHILE";
		}
		else if (countryLable === "China" || countryLable === "CHINA") {
			label = "CHINA";
		}
		else if (countryLable === "Corea" || countryLable === "COREA") {
			label = "COREA";
		}
		else if (countryLable === "Greece" || countryLable === "GREECE") {
			label = "GREECE";
		}
		else if (countryLable === "India" || countryLable === "INDIA") {
			label = "INDIA";
		}
		else if (countryLable === "Japan" || countryLable === "JAPAN") {
			label = "JAPAN";
		}
		else if (countryLable === "malaysia" || countryLable === "MALAYSIA") {
			label = "MALAYSIA";
		}
		else if (countryLable === "Malta" || countryLable === "MALTA") {
			label = "MALTA";
		}
		else if (countryLable === "Panama" || countryLable === "PANAMA") {
			label = "PANAMA";
		}
		else if (countryLable === "Singapore" || countryLable === "SINGAPORE") {
			label = "SINGAPORE";
		}
		else if (countryLable === "Slovenia" || countryLable === "SLOVENIA") {
			label = "SLOVENIA";
		}
		else if (countryLable === "Spain East" || countryLable === "SPAIN EAST") {
			label = "SPAIN EAST";
		}
		else if (countryLable === "Spain" || countryLable === "SPAIN") {
			label = "SPAIN";
		}
		else if (countryLable === "Sri Lanka" || countryLable === "SRI LANKA") {
			label = "SRI LANKA";
		}
		else if (countryLable === "Turkey" || countryLable === "TURKEY") {
			label = "TURKEY";
		}
		else if (countryLable === "UAE" || countryLable === "UAE") {
			label = "UAE";
		}
		else if (countryLable === "USA East" || countryLable === "USA EAST") {
			label = "USA EAST";
		}
		else if (countryLable === "USA South" || countryLable === "USA SOUTH") {
			label = "USA SOUTH";
		}
		else if (countryLable === "USA West" || countryLable === "USA West") {
			label = "USA WEST";
		}
		else if (countryLable === "New Zealand" || countryLable === "NEW ZEALAND") {
			label = "NEW ZEALAND";
		} else if (countryLable === "DEMO COUNTRY" || countryLable === "DEMO COUNTRY") {
			label = "DEMO COUNTRY";
		} else {
			// label = countryLable;
			label = "GERMANY";
		}
		return label;
	};
	upadteOrderState = (currentOrder) => {
		let obj = {};
		obj.orderKind = currentOrder.orderKind;
		obj.deliveryDetail = { ...this.state.deliveryDetail };
		obj.deliveryDetail.captain = currentOrder.captain;
		obj.deliveryDetail.cook = currentOrder.cook;
		obj.deliveryDetail.place = currentOrder.place;
		obj.deliveryDetail.country = currentOrder.country;
		obj.deliveryDetail.agentDetail = currentOrder.agentDetail;
		obj.deliveryDetail.eta = new Date(currentOrder.eta);
		obj.deliveryDetail.etd = new Date(currentOrder.etd);
		obj.deliveryDetail.numberOfCrew = currentOrder.numberOfCrew;
		obj.deliveryDetail.orderPeriod = currentOrder.orderPeriod;
		// obj.categories = currentOrder.categories;
		if (this.state.orderId) {
			if (this.state.captainList.length > 0) {
				let captainIndex = this.state.captainList.findIndex(x => x.value === currentOrder.captain);
				if (captainIndex !== -1) {
					obj.deliveryDetail["captain"] = this.state.captainList[captainIndex];
				}
			}
			if (this.state.cookList.length > 0) {
				let cookIndex = this.state.cookList.findIndex(x => x.value === currentOrder.cook);
				if (cookIndex !== -1) {
					obj.deliveryDetail["cook"] = this.state.cookList[cookIndex];
				}
			}
		}
		this.setState(obj);
	};
	saveAndClose = (e) => {
		e.preventDefault();
		this.setState({
			isSaveAndClose: true,
		}, () => this.handleSubmit(e));

	};
	render() {
		const navbarOptions = [
			{
				img: 'Monitor-icon.svg',
				className: "dark-blue",
				name: "Dash<br />board",
				path: getDashboardLink(),
				isLogout: false
			},
			// {
			// 	img: 'Message-icon.svg',
			// 	className: "green",
			// 	name: "Next",
			// 	path: "/provision-catalogue" + this.props.match.params.vesselId,
			// 	isLogout: false,
			// 	isCustom: true,
			// 	onClick: this.handleSubmit
			// }
		];
		return (
			<Wrapper>
				<div className="pageWrapper py-4">
					{/* <div className="d-flex justify-content-end">
						<a onClick={(e) => window.history.back()} className="pageGoBackBtn text-center">Back</a>
					</div> */}
					{/* <DemoHeader /> */}
					<div className="pageContainer mb-0">
						<div className="order-dlts-wrapper">
							<DashboardNavbar title={this.state.orderKind === inventory ? "Provision Inventory" : "Provision ORDER"} navbarOptions={navbarOptions} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
						</div>
						<form className="needs-validation" onSubmit={(e) => this.handleSubmit(e)} noValidate>
							{/* Order Details  */}
							<OrderDetail errorMessage={this.state.errorMessage}
								errorFor={this.state.errorFor} errorType={this.state.errorType} orderKind={this.state.orderKind} isOrderKindDisable={orderKind || this.state.orderId ? true : false} orderTypesOptions={this.state.orderTypesOptions} handleSelectChange={this.handleSelectChange} name="Kind of Order" placeholder="Select Order Kind" />
							{/* Delivery Details  */}
							<DeliveryDetail
								errorMessage={this.state.errorMessage}
								isInventory={this.state.orderKind === inventory}
								errorFor={this.state.errorFor}
								errorType={this.state.errorType}
								portList={this.state.portList}
								cookList={this.state.cookList}
								captainList={this.state.captainList}
								countryList={this.state.countryList}
								handleSelectChange={this.handleSelectChange}
								handleChange={this.handleChange}
								isStarted={this.props.apiCallStatus.isStarted}
								{...this.state.deliveryDetail}
							/>
							{/* Vessel & Crew Details  */}
							{/* <VesselCrewDetail handleSelectChange={this.handleSelectChange} /> */}
							<div className="d-flex justify-content-end sendOrder-wrap">
								{this.props.apiCallStatus.apiCallFor === "saveOrder" && !this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed ?
									<div className="loader-img text-center">
										<img style={{ width: "46px" }} src={require("../../../../../assets/images/Spinner-1s-200px.gif")} alt='' />
									</div>
									: ""}
								<button disabled={this.props.apiCallStatus.isStarted.indexOf("saveOrder") !== -1} type="button" className="btn themeReguler-btn bg-sky-blue mr-2" onClick={(e) => this.saveAndClose(e)}>Save & Close Order</button>
								<button disabled={this.props.apiCallStatus.isStarted.indexOf("saveOrder") !== -1} type="button" className="btn themeReguler-btn bg-dark-blue" onClick={(e) => this.handleSubmit(e)}>Next</button>
							</div>
						</form>
					</div>
					{/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
				</div>
			</Wrapper>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	apiCallStatus: state.apicallStatusReducer,
	vesselDetail: state.commonReducer.vesselById,
	captainList: state.commonReducer.captainList,
	cookList: state.commonReducer.cookList,
	portList: state.commonReducer.portList,
	countryList: state.commonReducer.countryList,
	currentOrder: state.orderReducer.currentOrder,
	userDetail: {
		userId: state.authReducer.userId,
		orderTypesOptions: state.authReducer.permission.provisionOrder,
		isSubAdmin: state.authReducer.isSubAdmin,
		userType: state.authReducer.userType,
		subType: state.authReducer.subType
	}
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	getVesselById: (id) => dispatch(getVesselById(id)),
	getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
	getAllCountry: (query) => dispatch(getAllCountry(query)),
	AddOrderAction: (data) => dispatch(AddOrderAction(data)),
	saveOrder: (data) => dispatch(saveOrder(data)),
	getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId))
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreateOrder);