import React, { useEffect, useState } from "react";
import { DashboardWrapper } from "./dashboarWrapper";
import { supplierListing } from "../../Supplier/ApiCalls/supplier";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";

function TopSuppliers(props) {
  const [state, setState] = useState({
    supplierList: [],
    activePage: 0,
    totalCount: 0,
    isHitApi: false,
  });
  useEffect(() => {
    if (!state.isHitApi) {
      props.supplierListing(0, "supplier");
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
    }
    if (
      (props.apiCallStatus.apiCallFor === "supplierListing" ||
        props.apiCallStatus.apiCallFor === "appendSupplierList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        supplierList: props.supplierReducer.list,
        totalCount: props.supplierReducer.count,
        isHitApi: true,
      });
    }
  }, [props]);
  const fetchMoreData = () => {
    let { activePage } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    props.supplierListing(activePage, "supplier");
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/upgrademesh",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
  ];
  return (
    <DashboardWrapper
      title="Top Suppliers - Containership 1"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="mt-lg-5 d-flex flex-column flex-lg-row">
        <div className="dashVesl-overview-wrap">
          <div className=" dash-content-bx">
            <div className="catalouge-details-wrap p-0">
              <InfiniteScroll
                dataLength={state.supplierList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={
                  state.supplierList.length <= state.supplierList.length
                    ? false
                    : true
                }
                loader={
                  <div
                    colSpan="12"
                    className="loadmore d-flex align-items-center justify-content-center"
                  >
                    {" "}
                    <img src={loader} alt="" />
                  </div>
                }
              >
                <table className="table theme-table-wrap2 short-table sticky-th-border">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Vessel Count</th>
                      <th>Website Url</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.supplierReducer.list.map((item, index) => {
                      return (
                        <tr key={"sr-" + index}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.companyName}</td>
                          <td>{item.email}</td>
                          <td className="semi-bold">{item.vesselCount}</td>
                          <td>{item.websiteUrl}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  supplierReducer: state.supplierReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  supplierListing: (offset, type) => dispatch(supplierListing(offset, type)),
  ApiClearAction: () => dispatch(ApiClearAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(TopSuppliers);
