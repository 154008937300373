import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { captainSignup } from "../ApiCalls/auth";
import { getVesselTypesDropdown } from "./../../Common/ApiCalls/vesselList";
import AuthWrapper from "./authWrapper";

const defaultState = {
  name: "",
  email: "",
  password: "",
  repeatPassword: "",
  vesselName: "",
  imo: "",
  vesselType: {},
  message: "",
  messageType: "",
  messageFor: "",
  termsAndConditions: false,
  termsAndConditionError: false,
  apiCalled: false,
};

function CaptainSignup(props) {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!state.apiCalled) {
      // props.getVesselDropdown();
      props.getVesselTypesDropdown();
      setState({
        ...state,
        apiCalled: true,
      });
    }

    if (props.apiCallStatus.apiCallFor === "captainSignup") {
      if (props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
        Swal.fire(
          "Success!",
          "Registration successful. Kindly wait some time until the administrator activates your account!",
          "success"
        );
        props.history.push("/login");
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      } else if (!props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed) {
        Swal.fire("Error!", props.apiCallStatus.message, "error");
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      }
    }
  }, [props.apiCallStatus]);

  const handleTermsAndConditions = (e) => {
    let cloneState = { ...state, termsAndConditions: e.target.checked };
    if (e.target.checked) {
      cloneState = {
        ...state,
        termsAndConditions: e.target.checked,
        termsAndConditionError: false,
      };
    }
    setState(cloneState);
  };

  const handleStateChange = (e, name) => {
    let cloneState = { ...state };
    if (name !== "vesselType") {
      const { id, value } = e.target;
      cloneState[id] = value;
    } else {
      cloneState["vesselType"] = {
        value: e.value,
        label: e.label
      };
    }
    setState(cloneState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      props.signup({
        name: state.name,
        email: state.email,
        password: state.password,
        vesselName: state.vesselName,
        imo: state.imo,
        kindOfVessel: state.vesselType,
      });
    }
  };

  const validateForm = () => {
    const form = document.getElementsByClassName("needs-validation")[0];
    let isValid = true;
    if (!form.checkValidity()) {
      isValid = false;
      form.classList.add("was-validated");
    } else if (state.password.length < 6) {
      isValid = false;
      Swal.fire(
        "Error!",
        "Password must be greater than 6 characters",
        "error"
      );
    } else if (state.password !== state.repeatPassword) {
      isValid = false;
      Swal.fire(
        "Error!",
        "Password and confirm password does not match",
        "error"
      );
    } else if (!state.termsAndConditions) {
      isValid = false;
      const cloneState = { ...state, termsAndConditionError: true };
      setState(cloneState);
      Swal.fire("Error!", "Please accept terms and use", "error");
    } else if (!state.imo.startsWith("9")) {
      isValid = false;
      Swal.fire("Error!", "IMO number must start with 9.", "error");
    } else if (state.imo.length !== 7) {
      isValid = false;
      Swal.fire("Error!", "IMO number must be of 7 digits.", "error");
    }
    return isValid;
  };

  return (
    <AuthWrapper>
      <div className="authHolder signUpHolder">
        <div className="textBlock">
          <div className="d-flex">
            <div className="textHolder w-100">
              <h2>Do you have an account?</h2>
              <Link
                className="btn btn-secondary d-block text-center bg-white mr-auto ml-0"
                to="/login"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
        <div className="formHolder">
          <h1>Sign Up</h1>
          <form
            className="authMeshForm needs-validation"
            onSubmit={(e) => handleSubmit(e)}
            noValidate
          >
            <div className="form-group floating-label-wrap">
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Name"
                value={state.name}
                onChange={(e) => handleStateChange(e, "name")}
                required
              />
              <label className="foating-labels-sty">Name *</label>
              <span className="animated-border"></span>
            </div>
            <div className="form-group floating-label-wrap">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                value={state.email}
                onChange={(e) => handleStateChange(e, "email")}
                required
              />
              <label className="foating-labels-sty">Email *</label>
              <span className="animated-border"></span>
            </div>
            <div className="form-group floating-label-wrap">
              <input
                type="password"
                className="form-control"
                id="password"
                autoComplete="new-password"
                placeholder="Passowrd"
                value={state.password}
                onChange={(e) => handleStateChange(e, "password")}
                required
              />
              <label className="foating-labels-sty">Password *</label>
              <span className="animated-border"></span>
            </div>
            <div className="form-group floating-label-wrap">
              <input
                type="password"
                className="form-control"
                id="repeatPassword"
                autoComplete="new-password"
                placeholder="Repeat passowrd"
                value={state.repeatPassword}
                onChange={(e) => handleStateChange(e, "repeatPassword")}
                required
              />
              <label className="foating-labels-sty">Repeat Password *</label>
              <span className="animated-border"></span>
            </div>
            <div className="form-group floating-label-wrap">
              <input
                type="text"
                className="form-control"
                id="vesselName"
                placeholder="Vessel Name *"
                value={state.vesselName}
                onChange={(e) => handleStateChange(e, "vesselName")}
                required
              />
              <label className="foating-labels-sty">Vessel Name *</label>
              <span className="animated-border"></span>
            </div>
            <div className="form-group floating-label-wrap">
              <input
                type="text"
                className="form-control"
                id="imo"
                placeholder="IMO *"
                value={state.imo}
                onChange={(e) => handleStateChange(e, "imo")}
                required
              />
              <label className="foating-labels-sty">IMO *</label>
              <span className="animated-border"></span>
            </div>
            <div className="form-group floating-label-wrap">
              <div className="form-group d-flex align-items-center select-z-Index">
                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100 no-border pl-1">
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Select Vessel Kind *"
                    options={props.vesselTypesDropdown}
                    onChange={(e) => handleStateChange(e, "vesselType")}
                    required
                  />
                </div>
              </div>
              {/* <label className="foating-labels-sty">Vessel Kind</label> */}
              <span className="animated-border"></span>
            </div>
            {/* <div className="form-group floating-label-wrap">
                            <input type="tel" className="form-control" id="phone" placeholder="Phone" value={state.phone} onChange={(e) => handleStateChange(e, 'phone')} />
                            <label className="foating-labels-sty">Phone</label>
                            <span className="animated-border"></span>
                        </div> */}
            {/* <div className="form-group">
                            <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                                <Select
                                    className="custom-select-wrap"
                                    classNamePrefix="react-select"
                                    placeholder="Select Vessel"
                                    value={state.selectedVessel}
                                    onChange={(e) => { handleStateChange(e, "vesselId"); }}
                                    options={props.vesselDropdown}
                                />
                            </div>
                        </div> */}
            <div className="form-group mb-0 mt-2 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="checked"
                onChange={(e) => handleTermsAndConditions(e)}
              />
              <a
                href={require("../../../assets/files/terms_and_use.pdf")}
                target="_blank"
                rel="noopener noreferrer"
                className={
                  state.termsAndConditionError
                    ? "foating-labels-chk-sty danger"
                    : "foating-labels-chk-sty"
                }
              >
                Terms and conditions
              </a>
            </div>
            {props.apiCallStatus.apiCallFor === "captainSignup" &&
              !props.apiCallStatus.isCompleted &&
              !props.apiCallStatus.isFailed ? (
              <div className="loader-img text-center">
                <img
                  style={{ width: "46px" }}
                  src={require("../../../assets/images/Spinner-1s-200px.gif")}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {props.apiCallStatus.apiCallFor === "captainSignup" &&
              props.apiCallStatus.isCompleted &&
              props.apiCallStatus.isFailed ? (
              <div className="alert alert-danger">
                {props.apiCallStatus.message}
              </div>
            ) : (
              ""
            )}
            <div className="formBtnHolder">
              <button
                className="btn btn-secondary d-block text-center w-100"
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </AuthWrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  user: { isLogin: state.authReducer.isLogin },
  vesselDropdown: state.commonReducer.vesselDropdown,
  vesselTypesDropdown: state.vesselReducer.vesselTypesDropdown,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  signup: (data) => dispatch(captainSignup(data)),
  // getVesselDropdown: () => dispatch(getVesselDropdown()),
  getVesselTypesDropdown: () => dispatch(getVesselTypesDropdown()),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaptainSignup);
