import React, { useState, useEffect } from "react";
import { getDashboardLink } from "../../../../Services/common";
import DashboardNavbar from "./../../../Common/Components/DashboardNavbar";
import { Wrapper } from "../../../Common/Components/CommonWrapper";
import { getOrderListing } from "../../ApiCalls/provisionOrder";
import { AddOrderAction } from "../../Actions/action";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import moment from "moment";

function InProgressOrderList(props) {
  const [state, setState] = useState({
    isHitApi: false,
    orderList: [],
    totalCount: 0,
    activePage: 0,
    limit: 20,
    query: "?status=inprogress&totalPrice=0",
  });
  useEffect(() => {
    if (!state.isHitApi) {
      props.getOrderListing(state.activePage, state.limit, state.query);
      props.AddOrderAction({});
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getOrder" ||
        props.apiCallStatus.apiCallFor === "appendOrderList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        orderList: props.orderList,
        totalCount: props.totalOrder,
        isHitApi: true,
      });
      props.ApiClearAction();
    }
  });
  const fetchMoreData = () => {
    let data = { ...state };
    data.activePage += 1;
    props.getOrderListing(data.activePage, data.limit, data.query);
    setState(data);
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
    {
      img: "Monitor-icon.svg",
      className: "dark-blue",
      name: "Dash<br />board",
      path: getDashboardLink(),
      isLogout: false,
    },
  ];
  return (
    <Wrapper>
      <DashboardNavbar
        title="InComplete Orders"
        navbarOptions={navbarOptions}
        className="mesh-board-wrap d-flex align-items-end"
      />
      {/* Order Status Content */}
      <div className="row mt-lg-4 align-content-stretch newDash-contentStyle">
        <div className="col-12">
          <div className="content-box-wrap dash-content-bx">
            <div className="flex-fil text-left table-default-sty">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={state.orderList.length}
                  next={fetchMoreData}
                  height={"80vh"}
                  hasMore={
                    state.totalCount <= state.orderList.length ? false : true
                  }
                  loader={
                    <div
                      colSpan="12"
                      className="loadmore d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <img src={loader} alt="" />
                    </div>
                  }
                >
                  <table className="table mt-4 table-light1 bg-transparent with-action-btns">
                    <thead className="rounded-dark-head">
                      <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th className="text-left">Order Type</th>
                        <th className="text-left">Created At</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="spacer">
                      {props.apiCallStatus.isStarted.indexOf("getOrder") !==
                      -1 ? (
                        <tr>
                          <td colSpan="40">
                            <div className="loader-img text-center">
                              <img
                                style={{ width: "46px" }}
                                src={loader}
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                      ) : state.orderList && state.orderList.length > 0 ? (
                        state.orderList.map((item, index) => {
                          let url =
                            (item.redirectionPath !== "/"
                              ? item.redirectionPath
                              : item.orderItems === 0
                              ? item.orderProvisionType === "store"
                                ? "/store-catalogue/"
                                : "/provision-catalogue/"
                              : "/supplier-selection/") + item._id;
                          return (
                            <tr key={"orderLists6-" + index}>
                              <td>{index + 1}</td>
                              <td>{item._id}</td>
                              <td className="semi-bold text-left">
                                {item.orderProvisionType[0].toUpperCase() +
                                  item.orderProvisionType.slice(1)}
                              </td>
                              <td className="semi-bold text-left">
                                {moment(item.createdAt).format(
                                  "DD MMMM YYYY hh:mm:ss"
                                )}
                              </td>

                              <td className="semi-bold text-left">
                                {item.status[0].toUpperCase() +
                                  item.status.slice(1)}
                              </td>

                              <td className="text-right">
                                <div className="tb-buttons-wrap">
                                  <Link
                                    to={url}
                                    className="btn tb-btn-rounded orng"
                                  >
                                    Continue Order
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : state.orderList && state.orderList.length === 0 ? (
                        <tr>
                          <td colSpan="40" className="text-center">
                            <div>No Data Found</div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  orderList: state.orderReducer.orderList,
  totalOrder: state.orderReducer.totalOrder,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ApiClearAction: () => dispatch(ApiClearAction()),
  AddOrderAction: (data) => dispatch(AddOrderAction(data)),
  getOrderListing: (offset, limit, filterQuery) =>
    dispatch(getOrderListing(offset, limit, filterQuery)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InProgressOrderList);
