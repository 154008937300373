import React, { useEffect } from "react";
import $ from "jquery";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import { Wrapper } from "../Css/customSelect";
export default function CustomSelect(props) {
  const scrollFunction = (o) => {
    if (
      Math.round($(o.target).scrollTop() + $(o.target).innerHeight()) >=
      $(o.target)[0].scrollHeight
    ) {
      if (props.pagesList.length < props.pageListTotal) {
        props.fetchMore();
      }
    }
  };
  return (
    <Wrapper>
      <div className="dropdown custom-data pagin-select-menu">
        <button
          className="btn w-100 dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          {props.value !== "" ? props.value : props.placeholder}
        </button>
        <ul
          id="pageName"
          className="dropdown-menu show-left filter-drop"
          aria-labelledby="dropdownMenuButton"
          onScroll={(e) => scrollFunction(e)}
        >
          <div className="filter-search-wrap">
            <input
              type="text"
              placeholder="Search...."
              value={props.inputValue}
              id={props.inputId}
              onChange={(e) =>
                props.handleChange(props.inputId, e.target.value)
              }
            />
          </div>
          <div className="starting-page-box">
            {props.pagesList && props.pagesList.length ? (
              <div>
                {
                  <ul className="filter-list-items">
                    {props.pagesList.map((item, index) => {
                      return (
                        <li
                          key={"pageList-" + index}
                          onClick={(e) =>
                            props.clickCustomeSelectHandler(
                              props.id,
                              !props.isReturnObj ? item.value : item
                            )
                          }
                        >
                          {item.label}
                        </li>
                      );
                    })}
                  </ul>
                }
                {props.pagesList.length < props.pageListTotal &&
                props.pageListTotal ? (
                  <div className="loadmore">
                    <img src={loader} alt="" />
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <ul>
                <li className="text-center no-page">
                  <p className="mb-0">No data found</p>
                </li>
              </ul>
            )}
          </div>
        </ul>
      </div>
    </Wrapper>
  );
}
