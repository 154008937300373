import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Wrapper } from "../../Common/Components/CommonWrapper";
import { packageListing, deletePackage } from "./../ApiCalls/package";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from "react-redux";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { isAllowPermission } from "../../../Services/common";
import DashboardNavbar from "./../../Common/Components/DashboardNavbar";
import { getRoutePathBykey } from "../../../Constants/routesConfig";
import Swal from "sweetalert2";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";
function PackageList(props) {
  const [state, setState] = useState({
    packageList: [],
    activePage: 0,
    totalCount: 0,
    isHitApi: false,
  });
  useEffect(() => {
    if (!state.isHitApi) {
      if (!isAllowPermission(window.location.pathname, props.roles)) {
        props.history.push("/not-allowed");
      } else {
        props.packageListing(0);
        let data = { ...state };
        data.isHitApi = true;
        setState(data);
      }
    }
    if (
      (props.apiCallStatus.apiCallFor === "getPackage" ||
        props.apiCallStatus.apiCallFor === "appendPackageList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        packageList: props.packageReducer.list,
        totalCount: props.packageReducer.count,
        isHitApi: true,
      });
    }
    if (
      props.apiCallStatus.apiCallFor === "deletePackage" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      Swal.fire("Deleted!", "Vessel has been deleted!", "success");
      props.packageListing(0);
    }
  }, [props]);
  const fetchMoreData = () => {
    let { activePage } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    props.packageListing(activePage);
  };
  const removeRecord = (id) => {
    if (!isAllowPermission("/delete-package", props.roles)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You don't have permission of this feature",
      });
    } else {
      Swal.fire({
        title: "Delete Package",
        text: "Are you sure you want to delete this Package?",
        icon: "warning",
        buttons: true,
        // showCancelButton: true,
        dangerMode: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.deletePackage(id);
        }
      });
    }
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
    {
      img: "Edit-icon.svg",
      className: "dark-green",
      name: "Create<br />Package",
      path: "/create-package",
      isLogout: false,
    },
  ];
  return (
    <DashboardWrapper
      title="Packages"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <InfiniteScroll
        dataLength={state.packageList.length}
        next={fetchMoreData}
        height={"80vh"}
        hasMore={state.totalCount <= state.packageList.length ? false : true}
        loader={
          <div
            colSpan="12"
            className="loadmore d-flex align-items-center justify-content-center"
          >
            {" "}
            <img src={loader} alt="" />
          </div>
        }
      >
        <div className="table-responsive mt-4">
          <table className="table theme-table-wrap with-action-btns">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Price</th>
                <th scope="col">Discount</th>
                <th scope="col">Commision</th>
                {props.isSubAdmin &&
                props.roles.length > 0 &&
                !isAllowPermission(
                  getRoutePathBykey("editPackage"),
                  props.roles
                ) &&
                !isAllowPermission("/delete-package", props.roles) ? (
                  ""
                ) : (
                  <th scope="col">Action</th>
                )}
              </tr>
            </thead>
            <tbody>
              {props.apiCallStatus.isStarted.indexOf("getPackage") !== -1 ||
              props.apiCallStatus.isStarted.indexOf("appendPackageList") !==
                -1 ? (
                <td colSpan="20">
                  <div className="loader-img text-center">
                    <img
                      style={{ width: "46px" }}
                      src={require("../../../assets/images/Spinner-1s-200px.gif")}
                      alt=""
                    />
                  </div>
                </td>
              ) : state.packageList && state.packageList.length > 0 ? (
                state.packageList.map((item, index) => {
                  let isDisableEdit =
                    props.isSubAdmin &&
                    props.roles.length > 0 &&
                    !isAllowPermission(
                      getRoutePathBykey("editPackage"),
                      props.roles
                    )
                      ? true
                      : false;
                  let isDisableDel =
                    props.isSubAdmin &&
                    props.roles.length > 0 &&
                    !isAllowPermission("/delete-package", props.roles)
                      ? true
                      : false;
                  return (
                    <tr key={"PL-" + index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.price}</td>
                      <td>{item.discount}</td>
                      <td>{item.commision}</td>
                      {isDisableDel && isDisableEdit ? (
                        ""
                      ) : (
                        <td>
                          {isDisableEdit ? (
                            ""
                          ) : (
                            <Link
                              to={"/edit-package/" + item._id}
                              className="btn btn-edit-tab"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                          )}
                          {isDisableDel ? (
                            ""
                          ) : (
                            <button
                              type="button"
                              onClick={() => removeRecord(item._id)}
                              className="btn btn-dlt-tab"
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : state.packageList && state.packageList.length === 0 ? (
                <td colSpan="40" className="text-center">
                  No Data Found
                </td>
              ) : (
                ""
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  roles: state.authReducer.roles,
  isSubAdmin: state.authReducer.isSubAdmin,
  packageReducer: state.packageReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  packageListing: (offset) => dispatch(packageListing(offset)),
  deletePackage: (id) => dispatch(deletePackage(id)),
  ApiClearAction: () => dispatch(ApiClearAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PackageList);
