import React, { useEffect, useState } from 'react';
import { updateReminder, getReminderDetail } from "./../ApiCalls/reminder";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { isAllowPermission } from '../../../Services/common';
import Swal from 'sweetalert2';
import { ReminderForm } from "./reminderForm";
import { reminderNameList } from '../../../Constants/constant';
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';

const reminderInitialState = {
    nameList: reminderNameList,
    maxReminderList: [{
        label: 1, value: 1
    }, {
        label: 2, value: 2
    }],
    name: null,
    days: '',
    maxReminder: null,
    errorMessage: "",
    errorType: "",
    isHitApi: false,
    showDaysField: true
}
function EditReminder(props) {
    const [state, setState] = useState(reminderInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        if (!isHitOnLoadApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getReminderDetail(props.match.params.id)
                setIsHitOnLoadApi(true)
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getReminderDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            if (Object.size(props.detail) > 0) {
                let detail = {
                    ...state,
                    name: { label: props.detail.name, value: props.detail.name },
                    maxReminder: { label: props.detail.maxReminder, value: props.detail.maxReminder },
                    days: props.detail.days,
                    showDaysField: props.detail.showDaysField
                }
                setState(detail)
            }
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "updateReminder") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(reminderInitialState)
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            Swal.fire("Success", `The reminder has been updated successfully.`, "success")
            props.history.push('/reminder-listing')
        }
    });
    const handleFormChange = (e, id) => {
        let stateClone = { ...state }
        if (e.label !== undefined) {
            stateClone[id] = e;
        } else {
            let name = e.target.name;
            let value = e.target.value;
            stateClone[name] = value;
        }
        setState(
            stateClone
        )
    }
    const updateReminder = () => {
        let obj = {
            id: props.match.params.id,
            name: state.name.value,
            days: state.days,
            maxReminder: state.maxReminder.value
        }
        props.updateReminder(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <DashboardWrapper title="Edit Reminder" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="formBox">
                <ReminderForm {...state} isEdit={true} history={props.history} handleFormChange={handleFormChange} updateReminder={updateReminder} apiCallStatus={props.apiCallStatus} />
            </div>
        </DashboardWrapper>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    countryReducer: state.countryReducer,
    roles: state.authReducer.roles,
    detail: state.reminderReducer.detail,
    commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateReminder: (data) => dispatch(updateReminder(data)),
    getReminderDetail: (id) => dispatch(getReminderDetail(id)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditReminder);
