import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function DonutChart({ data }) {
  const options = {
    chart: {
      renderTo: "container",
      type: "pie",
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
      },
    },
    tooltip: {
      formatter: function () {
        return "<b>" + this.point.name + "</b>: " + this.y + " USD";
      },
    },
    series: [
      {
        name: "Browsers",
        data: data,
        size: "60%",
        innerSize: "60%",
        showInLegend: true,
        colors: [
          "#70AD46",
          "#4473C5",
          "#FFC000",
          "#42682B",
          "#AC4D00",
          "#505255",
          "#1C4A72",
        ],
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default DonutChart;
