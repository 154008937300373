import React, { Component,  useState  } from 'react';
import { Wrapper } from '../../Css/checkout';
import  DashboardNavbar  from './../../../Common/Components/DashboardNavbar';
import { OrderOverview } from "./orderOverview";
import { getDashboardLink } from '../../../../Services/common';

export default function Checkout(props) {
	const [shouldCopy, setShouldCopy] = useState(false);
	const navbarOptions = [
		{
			img: 'left-arrow.svg',
			className: "dark-pink",
			name:  "Go back",
			path: "/checkout",
			isLogout: false,
			isCustom: true,
			onClick: function () {
				props.history.goBack()
			}
		},
		{
			img: 'Monitor-icon.svg',
			className: "dark-blue",
			name: "Dash<br />board",
			path: getDashboardLink(),
			isLogout: false
		}
	];

	return (
		<Wrapper>
			<div className="pageWrapper py-4">
				<div className="pageContainer mb-0">
					<div className="order-dlts-wrapper">
						<DashboardNavbar title="MESH* YOUR PROVISION ORDER" navbarOptions={navbarOptions} isShowLogo={true} logoUrl="logo.png" logoType="Order" className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
					</div>
					{/* Order Overview */}
					<div className="ordCatg-wrapper">
						{/* <OrderOverview /> */}
						{/* Order Card */}
						<div className="order-card-dtls">
							<div className="order-card-dtls-item">
								<div className="check-icon-cir d-flex align-items-center justify-content-center">
									<img className="img-fluid" src={require('../../../../assets/images/correct-icon.svg')} alt="icon" />
								</div>
								<h1>You were succesful!<br /><span>Thank you for your order.</span></h1>
								<h2>Your order no. is</h2>
								<button type="button" className="btn btn-rounded btn-copied" onClick={() => setShouldCopy(!shouldCopy)}>
									<span className={`orderNo-wrap d-block ${shouldCopy ? "copied":""}`}>
										<span className="d-flex justify-content-between w-100"><span className="text-truncate">{props.match.params.id}</span> <i className="far fa-clone"></i></span>
										<span className="d-block mt-3 text-center">Copied</span>
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
			</div>
		</Wrapper>
	)
}