import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { vesselListing, deleteVessel } from "./../ApiCalls/vessel";
import {
  ApiClearAction,
  ClearApiByNameAction,
} from "../../ApiCallStatus/Actions/action";
import { connect } from "react-redux";
import { getAllVessel, getVesselKind } from "../../Common/ApiCalls/vesselList";
import { topVessel } from "../../Common/ApiCalls/topVessel";
import { getCookie, setCookie } from "../../../Services/cookies";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { currencyFormatter, isAllowPermission } from "../../../Services/common";
import { getRoutePathBykey } from "../../../Constants/routesConfig";
import moment from "moment";
import { Filters } from "./Filters";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";

var csvLinkRef = React.createRef();

function getHeaders(userType) {
  let headers = [];
  const query = new URLSearchParams(window.location.search);
  let topVessel = query.get("topVessel");
  let fleetOverview = query.get("fleetOverview");
  if (topVessel) {
    headers.push(
      { label: "Vessel Name", key: "name" },
      // { label: "Vessel Type", key: "type" },
      { label: "Customer Name", key: "customerName" },
      // { label: "Number of supplies", key: "suppliedCount" },
      { label: "Turnover P-Mesh", key: "pMeshVolume" },
      { label: "Turnover C-Mesh", key: "cMeshVolume" },
      { label: "Turnover Total", key: "orderCount" }
    );
    if (userType !== "customer" && userType !== "supplier") {
      headers.splice(1, 0, { label: "Suplier Name", key: "supplierName" });
    }

    if (userType !== "customer" && userType !== "supplier") {
      headers.splice(
        3,
        0,
        { label: "Port", key: "port" }
        // { label: "Turnover C-Mesh", key: "cMeshVolume" }
      );
    }
    if (userType === "customer" || userType === "supplier") {
      headers.splice(4, 0, {
        label: "Number of supplies",
        key: "suppliedCount",
      });
    }

    if (userType === "supplier") {
      headers.splice(2, 0, { label: "Cutomer Name", key: "customerName" });
    }
  } else if (fleetOverview) {
    headers.push(
      { label: "Vessel Name", key: "name" },
      { label: "Type", key: "kindOfVessel" },
      { label: "Cutomer Name", key: "customerName" },
      { label: "Number of supplies", key: "suppliedCount" },
      { label: "P-MESH Turnover", key: "pMeshVolume" },
      { label: "C-MESH Turnover", key: "cMeshVolume" },
      { label: "Total Turnover", key: "totalVolume" }
    );
  } else {
    headers.push(
      { label: "IMO", key: "imo" },
      { label: "Name", key: "name" },
      { label: "Rate", key: "rate" },
      { label: "Number of Crew", key: "noOfCrew" },
      { label: "PIC", key: "pic" }
    );
  }
  return headers;
}

function VesselList(props) {
  const [state, setState] = useState({
    vesselList: [],
    vesselNameOptions: [],
    vesselTypeOptions: [],
    vesselCustomerOptions: [],
    activePage: 0,
    userType:
      props.userType === "supplier"
        ? "supplier"
        : props.subType === "captain"
          ? "captain"
          : props.subType === "customer"
            ? "customer"
            : "",
    customerVessels: [],
    startDate: null,
    endDate: null,
    totalCount: 0,
    csvData: [],
    isCsVDownload: false,
    isHitApi: false,
    name: "",
    type: "",
    customerName: "",
    nextDeliveryDate: null,
    headers: [],
    topVessel: "",
    itemCode: "",
    fleetOverview: "",
    supplierId: "",
    portId: "",
    filterQuery: "",
  });

  const query = new URLSearchParams(props.location.search);
  let topVessel = query.get("topVessel");
  let fleetOverview = query.get("fleetOverview");
  let supplierId = query.get("supplierId");
  const portId = query.get("portId");
  let itemCode = query.get("itemCode");
  let customerId = query.get("customerId");
  let customerEmail = query.get("customerEmail");
  useEffect(() => {
    if (
      state.topVessel !== topVessel ||
      state.fleetOverview !== fleetOverview ||
      state.supplierId !== supplierId ||
      !state.isHitApi
    ) {
      if (
        (props.isSubAdmin &&
          props.userType === "user" &&
          props.subType !== "sub-admin") ||
        isAllowPermission(window.location.pathname, props.roles)
      ) {
        let data = { ...state };
        if (fleetOverview) {
          data.filterQuery = "?fleetOverview=1&";
        }
        if (customerId && customerEmail) {
          data.filterQuery += `userId=${customerId}&`;
          data.customerName = { label: customerEmail, value: customerId };
        }
        data.topVessel = topVessel;
        data.itemCode = itemCode;
        data.fleetOverview = fleetOverview;
        data.supplierId = supplierId;
        data.portId = portId;
        data.headers = getHeaders(props.userType);
        data.isHitApi = true;
        // props.getAllVessel();
        // props.getVesselKind();
        if (topVessel !== null) {
          if (state.userType !== "customer") {
            let filterQuery = {};
            if (supplierId) {
              filterQuery["supplierId"] = supplierId;
            }
            if (portId) {
              filterQuery["portId"] = portId;
            }
            if (itemCode) {
              filterQuery["itemCode"] = itemCode;
            }
            props.topVessel(props.userId, state.userType, "", "", filterQuery);
          } else {
            props.vesselListing(0, 50, data.filterQuery);
          }
        } else {
          props.vesselListing(0, 20, data.filterQuery);
        }
        setState(data);
      } else if (!isAllowPermission(window.location.pathname, props.roles)) {
        props.history.push("/not-allowed");
      }
    }
    if (
      (props.apiCallStatus.apiCallFor === "vesselListing" ||
        props.apiCallStatus.apiCallFor === "appendVesselList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let csvData = [];
      let customerVessels = [];
      for (let index = 0; index < props.vesselReducer.list.length; index++) {
        const element = props.vesselReducer.list[index];
        if (
          props.subType === "customer" &&
          props.vesselReducer.list.length > 0
        ) {
          const id = element._id;
          customerVessels.push(id);
        }
        if (state.fleetOverview) {
          let storeBudget = "";
          if (element.departments && element.departments.length > 0) {
            let departments = element.departments;
            storeBudget = departments
              .map((s) => parseInt(s.budget))
              .reduce((prev, next) => parseInt(prev) + parseInt(next));
          }
          let pMeshVolume =
            element.stats && element.stats.length > 0
              ? element.stats[0].pMeshVolume
              : 0;
          let cMeshVolume =
            element.stats && element.stats.length > 0
              ? element.stats[0].cMeshVolume
              : 0;
          let totalVolume =
            element.stats &&
              element.stats.length > 0 &&
              element.stats[0].totalVolume
              ? element.stats[0].totalVolume.toFixed(2)
              : 0;
          let rate = element.rate ? element.rate : 0;
          storeBudget = storeBudget ? storeBudget : 0;
          let csvObj = {
            name: element.name,
            kindOfVessel:
              element.kindOfVessel && element.kindOfVessel.label
                ? element.kindOfVessel.label
                : "",
            customerName:
              element.userId && element.userId.email
                ? element.userId.email
                : "",
            rate: rate + " " + element.currency,
            storeBudget: storeBudget + " " + element.currency,
            arrivalTime: element.arrivalTime
              ? moment(element.arrivalTime).format("DD-MM-YYYY")
              : "",
            placeOfDelivery: element.placeOfDelivery,
            pMeshVolume: pMeshVolume,
            cMeshVolume: cMeshVolume,
            totalVolume: totalVolume + " " + element.currency,
          };
          csvData.push(csvObj);
        } else {
          let csvObj = {
            imo: element.imo,
            name: element.name,
            rate: element.rate,
            noOfCrew: element.noOfCrew,
            pic: element.pic,
            status: element.active === true ? "Active" : "In-active",
          };
          csvData.push(csvObj);
        }
      }
      if (props.subType === "customer" && props.vesselReducer.list.length > 0) {
        let filterQuery = {};
        if (state.supplierId) {
          filterQuery["supplierId"] = state.supplierId;
        }
        if (state.portId) {
          filterQuery["portId"] = state.portId;
        }
        if (state.itemCode) {
          filterQuery["itemCode"] = state.itemCode;
        }
        if (topVessel) {
          props.topVessel(customerVessels, "customer", "", "", filterQuery);
        }
      }
      setState({
        ...state,
        vesselList: props.vesselReducer.list,
        totalCount: props.vesselReducer.count,
        customerVessels,
        csvData,
        isCsVDownload: false,
        isHitApi: true,
      });
    }
    if (
      props.apiCallStatus.apiCallFor === "topVessel" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      let csvData = [];
      for (
        let index = 0;
        index < props.commonReducer.topVesselList.length;
        index++
      ) {
        const element = props.commonReducer.topVesselList[index];
        if (topVessel) {
          let csvObj = {
            name: element.name,
            type: element.type,
            customerName: element.customerName,
            orderCount: element.orderCount,
          };
          csvData.push(csvObj);
        }
      }
      setState({
        ...state,
        vesselList: props.commonReducer.topVesselList,
        totalCount: props.commonReducer.topVesselList.length,
        csvData,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "deleteVessel" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let vesselCount = parseInt(getCookie("vesselCount")) - 1;
      setCookie("vesselCount", vesselCount);
      Swal.fire("Deleted!", "Vessel has been deleted!", "success");
      if (topVessel !== null) {
        let filterQuery = {};
        if (state.supplierId) {
          filterQuery["supplierId"] = state.supplierId;
        }
        if (state.portId) {
          filterQuery["portId"] = state.portId;
        }
        if (state.itemCode) {
          filterQuery["itemCode"] = state.itemCode;
        }
        if (state.userType !== "customer") {
          props.topVessel(props.userId, state.userType, "", "", filterQuery);
        } else {
          props.topVessel(
            state.customerVessels,
            state.userType,
            "",
            "",
            filterQuery
          );
        }
      } else {
        props.vesselListing(0, 20, state.filterQuery);
      }
    }
    if (
      props.apiCallStatus.apiCallFor === "getVesselKind" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let vesselTypeOptions = [];
      let arr = props.commonReducer.vesselKindList;
      for (let i = 0; i < arr.length; i++) {
        vesselTypeOptions.push({ label: arr[i].name, value: arr[i].name });
      }
      setState({
        ...state,
        vesselTypeOptions,
        isHitApi: true,
      });
      props.ClearApiByNameAction("getVesselKind");
    }
    if (
      props.apiCallStatus.apiCallFor === "getAllVessel" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      let vesselNameOptions = [];
      let vesselCustomerOptions = [];
      for (
        let index = 0;
        index < props.commonReducer.vesselList.length;
        index++
      ) {
        let val = props.commonReducer.vesselList[index];
        vesselNameOptions.push({ label: val.name, value: val.name });
      }
      for (
        let index = 0;
        index < props.commonReducer.vesselList.length;
        index++
      ) {
        let val = props.commonReducer.vesselList[index];
        let userName = val.userId ? val.userId : "";
        let currentIndex = vesselCustomerOptions.findIndex(
          (x) => x.label === userName.email
        );
        if (currentIndex === -1) {
          if (userName) {
            vesselCustomerOptions.push({
              label: userName.email,
              value: userName._id,
            });
          }
        }
      }
      setState({
        ...state,
        vesselNameOptions,
        vesselCustomerOptions,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
  }, [props]);
  const fetchMoreData = () => {
    let { activePage } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    if (state.topVessel !== null) {
      let filterQuery = {};
      if (state.supplierId) {
        filterQuery["supplierId"] = state.supplierId;
      }
      if (state.portId) {
        filterQuery["portId"] = state.portId;
      }
      if (state.itemCode) {
        filterQuery["itemCode"] = state.itemCode;
      }
      if (state.userType !== "customer") {
        props.topVessel(props.userId, state.userType, "", "", filterQuery);
      } else {
        props.topVessel(
          state.customerVessels,
          state.userType,
          "",
          "",
          filterQuery
        );
      }
    } else {
      props.vesselListing(activePage, 20, state.filterQuery);
    }
  };
  const removeRecord = (id) => {
    if (!isAllowPermission("/delete-vessel", props.roles)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You don't have permission of this feature",
      });
    } else {
      Swal.fire({
        title: "Delete Vessel",
        text: "Are you sure you want to delete this Vessel?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        // showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.deleteVessel(id);
        }
      });
    }
  };
  const getNavbarOptions = () => {
    let options = [
      {
        img: "left-arrow.svg",
        className: "orange",
        name: "Go back",
        path: "/",
        isLogout: false,
        isCustom: true,
        onClick: () => props.history.goBack(),
      },
    ];
    if (
      props.userType === "user" &&
      (props.subType === "user" || props.subType === "subAdmin")
    ) {
      options.push({
        img: "Edit-icon.svg",
        className: "dark-green",
        name: "Create<br />Vessel",
        path: "/create-vessel",
        isLogout: false,
      });
      options.push({
        img: "Upload-link.svg",
        className: "dark-blue",
        name: "Upload<br />Vessels",
        path: "/upload-vessels",
        isLogout: false,
      });
    }
    return options;
  };
  const handleChange = (id, val) => {
    let filterQuery = "";
    if (state.fleetOverview) {
      filterQuery = "?fleetOverview=1&";
    }
    if (state.startDate || id === "startDate") {
      let from = id ? val : state.startDate;
      from = moment(from).format("YYYY-MM-DD");
      filterQuery += `from=${from}&`;
    }
    if ((state.startDate && state.endDate) || id === "endDate") {
      let to = id ? val : state.endDate;
      to = moment(to).add(1, "days").format("YYYY-MM-DD");
      filterQuery += `to=${to}&`;
    }
    if (state.name || id === "name") {
      let name = state.name ? state.name : val;
      filterQuery += `name=${name}&`;
    }
    if (state.type || id === "type") {
      let type = state.type ? state.type : val;
      filterQuery += `kindOfVessel=${type}&`;
    }
    if (state.customerName || id === "customerName") {
      let customerName = state.customerName ? state.customerName : val;
      filterQuery += `userId=${customerName.value}&`;
    }
    if (state.nextDeliveryDate || id === "nextDeliveryDate") {
      let nextDeliveryDate = id ? val : state.nextDeliveryDate;
      nextDeliveryDate = moment(nextDeliveryDate).format("YYYY-MM-DD");
      filterQuery += `arrivalTime=${nextDeliveryDate}&`;
    }

    if (state.topVessel !== null) {
      if (state.userType !== "customer") {
        let filterQuery = {};
        if (state.supplierId) {
          filterQuery["supplierId"] = state.supplierId;
        }
        if (state.portId) {
          filterQuery["portId"] = state.portId;
        }
        if (state.itemCode) {
          filterQuery["itemCode"] = state.itemCode;
        }
        props.topVessel(props.userId, state.userType, "", "", filterQuery);
      } else {
        props.vesselListing(0, 50, filterQuery);
      }
    } else {
      props.vesselListing(0, 20, filterQuery);
    }
    setState({
      ...state,
      [id]: val,
      filterQuery,
    });
  };
  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    if (state.topVessel !== null) {
      let filterQuery = {};
      if (state.supplierId) {
        filterQuery["supplierId"] = state.supplierId;
      }
      if (state.portId) {
        filterQuery["portId"] = state.portId;
      }
      if (state.itemCode) {
        filterQuery["itemCode"] = state.itemCode;
      }
      if (state.userType !== "customer") {
        props.topVessel(props.userId, state.userType, "", "", filterQuery);
      } else {
        props.topVessel(
          state.customerVessels,
          state.userType,
          "",
          "",
          filterQuery
        );
      }
    } else {
      props.vesselListing(0, state.totalCount, state.filterQuery);
    }
    csvLinkRef.current.link.click();
  };
  const clearFilter = () => {
    // props.getAllVessel();
    let filterQuery = "";
    if (state.fleetOverview) {
      filterQuery = "?fleetOverview=1&";
    }
    if (state.topVessel !== null) {
      if (state.userType !== "customer") {
        let filterQuery = {};
        if (state.supplierId) {
          filterQuery["supplierId"] = state.supplierId;
        }
        if (state.portId) {
          filterQuery["portId"] = state.portId;
        }
        if (state.itemCode) {
          filterQuery["itemCode"] = state.itemCode;
        }
        props.topVessel(props.userId, state.userType, "", "", filterQuery);
      } else {
        props.vesselListing(0, 50, filterQuery);
      }
    } else {
      props.vesselListing(0, 20, filterQuery);
    }
    setState({
      ...state,
      name: "",
      type: "",
      customerName: "",
      startDate: null,
      endDate: null,
      nextDeliveryDate: "",
      filterQuery,
    });
  };
  return (
    <DashboardWrapper
      title={
        state.topVessel || state.fleetOverview ? "Vessel Overview" : "Vessels"
      }
      navbarOptions={getNavbarOptions()}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <InfiniteScroll
        dataLength={state.vesselList.length}
        next={fetchMoreData}
        height={"90vh"}
        hasMore={state.totalCount <= state.vesselList.length ? false : true}
      // loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
      >
        <div className=" mt-4 card-bx-sty">
          {state.fleetOverview ? (
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
              <Filters
                {...state}
                handleChange={handleChange}
                clearFilter={clearFilter}
                csvLinkRef={csvLinkRef}
                filename={"Vessel.csv"}
                headers={state.headers}
                apiCallForCSVData={apiCallForCSVData}
              />
            </div>
          ) : (
            ""
          )}
          <div className="table-responsive">
            <table className="table_overview table theme-table-wrap with-action-btns">
              <thead>
                <TableHeader
                  topVessel={state.topVessel}
                  headers={state.headers}
                  fleetOverview={state.fleetOverview}
                  userType={props.userType}
                  subType={props.subType}
                />
              </thead>
              <tbody>
                {props.apiCallStatus.isStarted.indexOf("vesselListing") !==
                  -1 ||
                  props.apiCallStatus.isStarted.indexOf("topVessel") !== -1 ? (
                  <tr>
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img style={{ width: "46px" }} src={loader} alt="" />
                      </div>
                    </td>
                  </tr>
                ) : state.vesselList && state.vesselList.length === 0 ? (
                  <tr>
                    <td colSpan="40" className="text-center">
                      <div>No Data Found</div>
                    </td>
                  </tr>
                ) : (
                  state.vesselList &&
                  state.vesselList.length > 0 &&
                  state.vesselList?.map((item, index) => {
                    return (
                      <TableContent
                        topVessel={state.topVessel}
                        fleetOverview={state.fleetOverview}
                        key={index}
                        index={index}
                        item={item}
                        userType={props.userType}
                        subType={props.subType}
                        isSubAdmin={props.isSubAdmin}
                        removeRecord={removeRecord}
                        roles={props.roles}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </InfiniteScroll>
    </DashboardWrapper>
  );
}
const TableHeader = React.memo((props) => {
  if (props.topVessel) {
    return (
      <tr>
        <th scope="col">Nr.</th>
        {props.headers?.map((item, index) => {
          return <th key={"h33-" + index}>{item.label}</th>;
        })}
      </tr>
    );
  } else if (props.fleetOverview) {
    return (
      <tr>
        <th scope="col">Nr.</th>
        {props.headers?.map((item, index) => {
          return <th key={"h44-" + index}>{item.label}</th>;
        })}
      </tr>
    );
  } else {
    return (
      <tr>
        <th scope="col">Nr.</th>
        {props.headers?.map((item, index) => {
          return <th key={"headers55-" + index}>{item.label}</th>;
        })}
        {(props.userType === "user" && props.subType === "user") ||
          props.subType === "subAdmin" ||
          props.subType === "customer" ? (
          <th scope="col">Status</th>
        ) : (
          ""
        )}
        {(props.userType === "user" && props.subType === "user") ||
          props.subType === "subAdmin" ||
          props.subType === "customer" ||
          props.userType === "customer" ? (
          <th scope="col">Action</th>
        ) : (
          ""
        )}
      </tr>
    );
  }
});
const TableContent = React.memo((props) => {
  if (props.topVessel) {
    let pMeshVolume = props.item.pMeshVolume
      ? props.item.pMeshVolume.toFixed(2)
      : 0;
    let cMeshVolume = props.item.cMeshVolume
      ? props.item.cMeshVolume.toFixed(2)
      : 0;
    let totalVolume = props.item.totalVolume
      ? props.item.totalVolume.toFixed(2)
      : 0;
    const currency = props.item.currency;

    return (
      <tr key={"currency-" + props.index}>
        <td>{props.index + 1}</td>
        <td>{props.item.name}</td>
        {props.userType !== "customer" && props.userType !== "supplier" && (
          <td>{props.item.supplierName}</td>
        )}
        {props.userType !== "customer" && props.userType === "supplier" && (
          <td>{props.item.supplierName}</td>
        )}
        {props.userType !== "supplier" && <td>{props.item.customerName}</td>}

        {props.userType !== "customer" && props.userType !== "supplier" && (
          <td>{props.item.port}</td>
        )}

        {props.userType === "customer" && props.userType === "supplier" && (
          <td>{props.item.suppliedCount}</td>
        )}

        <td>{currencyFormatter(pMeshVolume, currency)}</td>
        <td>{currencyFormatter(cMeshVolume, currency)}</td>
        {props.userType === "customer" && <td>{props.item.suppliedCount}</td>}
        <td>{currencyFormatter(totalVolume, currency)}</td>
      </tr>
    );
  } else if (props.fleetOverview) {
    let storeBudget = "";

    if (props.item.departments && props.item.departments.length > 0) {
      let departments = props.item.departments;
      storeBudget = departments
        .map((s) => parseInt(s.budget))
        .reduce((prev, next) => parseInt(prev) + parseInt(next));
    }

    let pMeshVolume =
      props.item && props.item.stats && props.item.stats[0]
        ? props.item.stats[0].pMeshVolume?.toFixed(2)
        : 0;
    let cMeshVolume =
      props.item && props.item.stats && props.item.stats[0]
        ? props.item.stats[0].cMeshVolume?.toFixed(2)
        : 0;
    let totalVolume =
      props.item && props.item.stats && props.item.stats[0]
        ? props.item.stats[0].totalVolume?.toFixed(2)
        : 0;

    return (
      <tr key={"departmentsBudget-" + props.index}>
        <td>{props.index + 1}</td>
        <td>{props.item.name}</td>
        <td>
          {props.item?.kindOfVessel?.label && props.item.kindOfVessel?.label}
        </td>
        <td>{props.item.customerName}</td>
        <td>{props.item.suppliedCount}</td>
        <td>{currencyFormatter(pMeshVolume, props.item.currency)}</td>
        <td>{currencyFormatter(cMeshVolume, props.item.currency)}</td>
        <td>{currencyFormatter(totalVolume, props.item.currency)}</td>
      </tr>
    );
  } else {
    return (
      <tr key={"departmentsBudget2-" + props.index}>
        <td>{props.index + 1}</td>
        <td>{props.item.imo}</td>
        <td>{props.item.name}</td>
        <td>{props.item.rate}</td>
        <td>{props.item.noOfCrew}</td>
        <td>{props.item.pic}</td>
        {(props.userType === "user" && props.subType === "user") ||
          props.subType === "subAdmin" ||
          props.subType === "customer" ? (
          <td>
            {props.item.active === true ? (
              <span className="text-success">Active</span>
            ) : (
              <span className="text-danger">In-Active</span>
            )}
          </td>
        ) : (
          ""
        )}
        {(props.userType === "user" && props.subType === "user") ||
          props.subType === "subAdmin" ||
          props.subType === "customer" ||
          props.userType === "customer" ? (
          <td>
            <div className="actions-btns">
              <Link
                to={"/vessel-info/" + props.item._id}
                className="btn mr-2 btn-info-tab"
              ></Link>
              {(props.isSubAdmin &&
                props.roles.length > 0 &&
                !isAllowPermission(
                  getRoutePathBykey("editVessel"),
                  props.roles
                )) ||
                !(props.userType === "user" && props.subType === "user") ||
                props.subType === "subAdmin" ? (
                ""
              ) : (
                <Link
                  to={"/edit-vessel/" + props.item._id}
                  className="btn btn-edit-tab mr-2"
                ></Link>
              )}
              {(props.isSubAdmin &&
                props.roles.length > 0 &&
                !isAllowPermission("/delete-vessel", props.roles)) ||
                !(props.userType === "user" && props.subType === "user") ||
                props.subType === "subAdmin" ? (
                ""
              ) : (
                <button
                  type="button"
                  onClick={() => props.removeRecord(props.item._id)}
                  className="btn btn-dlt-tab"
                ></button>
              )}
            </div>
          </td>
        ) : (
          ""
        )}
      </tr>
    );
  }
});
const mapStateToProps = (state, ownProps) => {
  return {
    apiCallStatus: state.apicallStatusReducer,
    commonReducer: state.commonReducer,
    vesselReducer: state.vesselReducer,
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
    isSubAdmin: state.authReducer.isSubAdmin,
    userId: state.authReducer.userId,
    roles: state.authReducer.roles,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  vesselListing: (offset, limit, filterQuery) =>
    dispatch(vesselListing(offset, limit, filterQuery)),
  topVessel: (userId, userType, from, to, filterQuery) =>
    dispatch(topVessel(userId, userType, from, to, filterQuery)),
  deleteVessel: (id) => dispatch(deleteVessel(id)),
  getVesselKind: () => dispatch(getVesselKind()),
  ApiClearAction: () => dispatch(ApiClearAction()),
  getAllVessel: () => dispatch(getAllVessel()),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(VesselList);
