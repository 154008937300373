import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
// import { Wrapper } from '../../../Orders/Css/previsionCatalouge';
import $ from "jquery";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { currencyFormatter } from "../../../../Services/common";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import { CsvDownload } from "../../../Common/Components/CsvDownload";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { getQuotationDetail, saveQuotation, sendQuotation } from "../../Apicalls/quotations";
import { UploadQuotationModal } from "./UploadQuotationModal";

const headers = [
  { label: "ItemCode", key: "itemCode" },
  { label: "IMPA", key: "alternativeCode" },
  { label: "Department", key: "department" },
  { label: "Description", key: "description" },
  { label: "Explanation", key: "explanation" },
  // { label: "Availability", key: "availability" },
  { label: "Quantity", key: "order" },
  // { label: "Estimated Price", key: "estimatedPrice" },
  { label: "Unit", key: "standardPackagingUnit" },
  { label: "Price", key: "pricePerBaseUnit" },
  { label: "Total Price", key: "totalPrice" },
  { label: "Remark", key: "remark" },
];

var csvLinkRef = React.createRef();

function ConfirmOrder(props) {
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [totalMeshC, setTotalMeshC] = useState(0);
  const [totalPrice2, setTotalPrice2] = useState(0);
  const [qtnBtn, setQtnBtn] = useState(false);

  const [CaptaintotalAmount, setCaptainTotalAmount] = useState(0.0);
  const [CaptaintotalMeshC, setCaptainTotalMeshC] = useState(0);
  const [CaptaintotalPrice2, setCaptainTotalPrice2] = useState(0);

  const [state, setState] = useState({
    isHitApi: false,
    price: 0,
    basketId: "",
    deliveryDays: "",
    deliveryCharges: 0.0,
    orderDetail: {},
    changedItem: [],
    quotationDetailList: [],
    csvData: [],
    adminCatalogs: [],
    countryName: "",
    isButtonShow: true,
    savingQuotation: false,
    sendingQuotation: false,
    showModal: false
  });
  const GrandTotalMeshC = totalMeshC - totalPrice2;

  useEffect(() => {
    // CaptainTotalPrice(state.quotationDetailList);
    ReactTooltip.rebuild();
    if (!state.isHitApi) {
      let query = new URLSearchParams(window.location.search);
      let status = query.get("status");
      props.getQuotationDetail(props.match.params.quotationId, props.userId);
      let data = { ...state };
      data.isHitApi = true;
      data.isButtonShow = status ? false : true;
      setState(data);
    }
    if (
      props.apiCallStatus.apiCallFor === "getQuotationDetail" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let csvData = [];
      for (
        let index = 0;
        index < props.quotationReducer.quotationDetailList.length;
        index++
      ) {
        const element = props.quotationReducer.quotationDetailList[index];
        // const { estimatedPrice: estimatedPriceArr } = element;
        // const estimatedPrice = estimatedPriceArr[0]?.pricePerCountry[0]?.price || "";

        const csvObj = {
          itemCode: element.itemCode,
          alternativeCode: element.alternativeCode,
          department: element.department,
          description: element.description,
          explanation: element.explanation,
          // availability: element.availability,
          order: element.order,
          // estimatedPrice: estimatedPrice ? estimatedPrice.toFixed(2) : "",
          standardPackagingUnit:
            element.standardPackagingUnit === ""
              ? element.pricePerPackagingUnit
              : element.standardPackagingUnit,
          pricePerBaseUnit: state.isButtonShow ? "" : element.pricePerBaseUnit,
          // totalPrice: state.isButtonShow
          //   ? ""
          //   : element.order * element.pricePerBaseUnit,
          totalPrice: state.isButtonShow ? "" : `=(F${[index + 2]}*H${[index + 2]})`,
          remark: element.remark || "",
        };
        csvData.push(csvObj);
      }

      // EMPTY ROW
      csvData.push({
        itemCode: '',
        alternativeCode: '',
        department: '',
        description: '',
        explanation: '',
        order: '',
        standardPackagingUnit: '',
        pricePerBaseUnit: '',
        totalPrice: '',
        remark: '',
      });

      // SHOW TOTAL SUM
      csvData.push({
        itemCode: '',
        alternativeCode: '',
        department: '',
        description: '',
        explanation: '',
        order: '',
        standardPackagingUnit: '',
        pricePerBaseUnit: 'Total Sum',
        totalPrice: `=SUM(I2:I${csvData.length})`,
        remark: '',
      });

      const quotationDetailList = props.quotationReducer.quotationDetailList;
      let price = 0.0;
      for (let index = 0; index < quotationDetailList.length; ++index) {
        const { qtdPrice, order } = quotationDetailList[index];
        price += (+qtdPrice || 0.0) * (+order);
      }

      setState({
        ...state,
        quotationDetailList,
        orderDetail: props.quotationReducer.orderDetail,
        basketId: props.quotationReducer.basketId,
        adminCatalogs: props.quotationReducer.adminCatalogs,
        csvData,
        price,
        isHitApi: true,
      });
      setTimeout(() => {
        $("input[type=number]").on("focus", function () {
          $(this).on("keydown", function (event) {
            if (
              event.keyCode === 38 ||
              event.keyCode === 40 ||
              event.key === "-"
            ) {
              event.preventDefault();
            }
          });
        });
      }, 500);
      props.ApiClearAction();
    }

    if (props.apiCallStatus.apiCallFor === "sendQuotation") {
      if (props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
        Swal.fire(
          "Success",
          "Your quotation has been uploaded successfully",
          "success"
        );
        // Swal.fire('Success', "RFQ send succesfully", 'success');
        props.history.push("/inprogress-quotations");
        props.ApiClearAction();
      } else {
        setState({
          ...state,
          sendingQuotation: false,
        });
      }
    }

    if (props.apiCallStatus.apiCallFor === "saveQuotation") {
      if (props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
        Swal.fire(
          "Success",
          `Your quotation has been saved successfully!`,
          "success"
        );
        props.history.push("/inprogress-quotations");
        props.ApiClearAction();
      } else {
        setState({
          ...state,
          savingQuotation: false,
        });
      }
    }
  }, [state.isHitApi, props.apiCallStatus]);

  const handleTableChange = (
    itemCode,
    price,
    orderId,
    order,
    packageUnit,
    remark,
    index
  ) => {
    let orderDetail = cloneDeep(state.orderDetail);
    let quotationDetailList = cloneDeep(state.quotationDetailList);
    let newItem = state.changedItem;
    if (price === "") {
      const add = newItem.filter((item) => {
        return item.itemCode !== itemCode;
      });
      delete orderDetail[index];
      delete newItem[index];
      newItem = add;
    } else {
      if (order) {
        let obj = {
          itemCode,
          price,
          orderId,
          order,
          packageUnit,
          remark,
        };
        if (!orderDetail[index]) {
          newItem.push(obj);
        }
        orderDetail[index] = obj;
      } else {
        let inputId = `#price${index}`;
        $(inputId).val("");
        Swal.fire("Error", "Please add the number of quantity", "error");
      }
    }
    const sum = calculateSum(orderDetail);
    quotationDetailList[index]["remark"] = remark;
    quotationDetailList[index]["price"] = !!price ? price : 0;
    quotationDetailList[index]["qtdPrice"] = !!price ? price : 0;
    quotationDetailList[index]["packageUnit"] = packageUnit;
    quotationDetailList[index]["order"] = order;
    setState({
      ...state,
      price: sum,
      orderDetail,
      changedItem: newItem,
      quotationDetailList,
    });
  };

  const handleQuantityChange = (index, order, name, subIndex) => {
    let orderDetail = cloneDeep(state.orderDetail);
    let quotationDetailList = cloneDeep(state.quotationDetailList);
    let newItem = state.changedItem;
    if (!order) {
      const add = newItem.filter((_, idx) => idx !== index);
      delete orderDetail[index];
      delete newItem[index];
      newItem = add;
    } else {
      if (name === "order") {
        quotationDetailList[index][name] = order;
      } else if (name === "standardPackagingUnit") {
        quotationDetailList[index][name] = order;
      } else {
        quotationDetailList[index]["estimatedPrice"][subIndex][
          "pricePerCountry"
        ][0][name] = order;
      }
    }
    const rec = quotationDetailList[index];
    const { _id, itemCode, qtdPrice, standardPackagingUnit, remark } = rec;
    const obj = {
      orderDetailId: _id,
      itemCode,
      price: !!qtdPrice ? qtdPrice : 0,
      order: rec?.order,
      packageUnit: standardPackagingUnit,
      remark,
    };
    if (!orderDetail[index]) {
      newItem.push(obj);
    }
    orderDetail[index] = obj;
    setState({
      ...state,
      orderDetail,
      quotationDetailList,
      changedItem: newItem,
    });
  };

  const calculateSum = (data) => {
    let keys = Object.keys(data);
    let price = 0;
    let totalPrices = 0.0;
    let totalPrice2 = 0.0;
    let totalmeshprice = 0.0;
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      if (data[element] && data[element].price !== "") {
        const priceInt =
          data[element].price !== "undefined"
            ? parseFloat(data[element].price)
            : data[element].price;
        const orderInt =
          data[element].order !== "undefined"
            ? parseFloat(data[element].order)
            : data[element].order;
        if (!isNaN(priceInt) && !isNaN(orderInt)) {
          totalPrice2 += priceInt;
          totalPrices += ((priceInt * 100) / 97.5) * orderInt;
          price += priceInt * orderInt;
          totalmeshprice += (priceInt * 100) / 97.5;

          // setTotalAmount(totalPrices.toFixed(2));
          setTotalAmount(price.toFixed(2));
          setTotalPrice2(totalPrice2);
          setTotalMeshC(totalmeshprice);
        }
      }
    }
    return price.toFixed(2);
  };

  const prepareQuotationItems = () => {
    let items = [];
    // const keys = Object.keys(state.orderDetail);

    // for (let index = 0; index < keys.length; ++index) {
    //   const element = keys[index];
    //   const obj = state?.orderDetail[element];
    //   if (obj?.price) {
    //     const unitName =
    //       obj.standardPackagingUnit === "" ? "pricePerPackagingUnit" : "standardPackagingUnit";
    //     const unitObj = {
    //       [unitName]:
    //         obj.standardPackagingUnit === "" ? obj.pricePerPackagingUnit : obj.standardPackagingUnit,
    //     };
    //     const quoteElement = state.quotationDetailList[element];
    //     const finalObj = {
    //       itemCode: obj?.itemCode,
    //       alternativeCode: quoteElement?.alternativeCode,
    //       price: parseFloat(obj?.price || 0),
    //       order: obj?.order,
    //       packageUnit: obj?.packageUnit,
    //       remark: obj?.remark,
    //       department: quoteElement?.department || "",
    //       prefixedItemCode: quoteElement?.prefixedItemCode || "",
    //       orderDetailId: quoteElement?._id || "",
    //       availability: quoteElement?.availability || "",
    //       ...unitObj,
    //     };
    //     items.push(finalObj);
    //   }
    // }

    /*
    When supplier sends the quotation without changing any items, the above `items` variable would be [].
    Hence, pass all the records for this quotation.
    */
    if (items.length === 0) {
      const list = state.quotationDetailList;
      for (let index = 0; index < list.length; ++index) {
        const obj = list[index];
        const finalObj = {
          baseQuantityAvg: obj?.baseQuantityAvg,
          itemCode: obj?.itemCode,
          alternativeCode: obj?.alternativeCode,
          price: parseFloat(obj?.qtdPrice || 0),
          order: obj?.order,
          orderId: obj?.orderId,
          packageUnit: obj?.standardPackagingUnit,
          remark: obj?.remark,
          department: obj?.department || "",
          prefixedItemCode: obj?.prefixedItemCode || "",
          orderDetailId: obj?._id || "",
          availability: obj?.availability || "",
          priceType: obj?.priceType || "",
        };
        items.push(finalObj);
      }
    }

    return items;
  };

  const sendQuotation = () => {
    const proceedToSend = (items) => {
      setState({
        ...state,
        sendingQuotation: true,
      });
      props.sendQuotation({
        items,
        quotationId: props.match.params.quotationId,
        orderId: state.basketId,
        totalPrice: state.price,
        deliveryDays: state.deliveryDays,
        deliveryCharges: state.deliveryCharges,
      });
    };

    const allPricesExist = state.quotationDetailList.every(({ qtdPrice }) => !!qtdPrice || qtdPrice == "0");
    const deliveryDaysEntered = state.deliveryDays !== "";

    if (!allPricesExist || !deliveryDaysEntered) {
      let msg = "Please enter the delivery days";
      if (!allPricesExist) {
        msg = "Please enter the prices of all the items";
      }
      Swal.fire("Error", msg, "error");
    } else {
      const items = prepareQuotationItems();
      const errors = checkPriceVariance(items, state.adminCatalogs);
      if (Array.isArray(errors) && errors.length > 0) {
        // let message = `
        //   <div>
        //     <p>Following items' prices vari by 30%. Are you sure you have entered the correct prices?</p>
        //     <ul style='list-style: decimal;'>
        // `;
        // errors.forEach(({ itemCode, alternativeCode, description }) => {
        //   message += `<li class='text-left'>${itemCode} - ${alternativeCode} - ${description}</li>`;
        // });
        // message += '</ul></div>';
        Swal.fire({
          title: 'Prices Confirmation',
          text: "Are you sure you have entered the correct prices?",
          icon: 'warning',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          denyButtonColor: 'green',
        }).then((result) => {
          if (result.isConfirmed) {
            proceedToSend(items);
          }
        });
      } else {
        proceedToSend(items);
      }
    }
  };

  const checkPriceVariance = (quotedDetails, adminCatalogs) => {
    const { countryName } = state.orderDetail;
    const PERCENTAGE = 0.3;

    let errors = [];

    for (let index = 0; index < quotedDetails.length; index++) {
      const quote = quotedDetails[index];
      const qtdPrice = quote.price;
      const rec = adminCatalogs.find(({ alternativeCode }) => alternativeCode === quote.alternativeCode);
      if (rec) {
        const { itemCode, alternativeCode, description, priceAvg, pricePerCountry } = rec;
        const reqCountryPrice = [...pricePerCountry].find(({ name }) => name === countryName);
        let price = 0.0;
        if (reqCountryPrice) {
          price = reqCountryPrice?.price || priceAvg;
        } else {
          price = priceAvg;
        }
        const range = price * PERCENTAGE;
        const rangeStart = price - range;
        const rangeEnd = price + range;
        if (qtdPrice <= rangeStart || qtdPrice >= rangeEnd) {
          errors.push({
            itemCode,
            alternativeCode,
            description
          });
        }
      }
    }

    return errors;
  };

  const saveQuotation = () => {
    const items = prepareQuotationItems();
    setState({
      ...state,
      savingQuotation: true,
    });
    props.saveQuotation({
      quotationId: props.match.params.quotationId,
      items,
    });
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    setState({
      ...state,
      [name]: val,
    });
  };

  // const declineQuotation = () => {
  //     let orderDetail = [];
  //     let keys = Object.keys(state.orderDetail);
  //     for (let index = 0; index < keys.length; index++) {
  //         const element = keys[index];
  //         if (state.orderDetail[element].price !== "") {
  //             orderDetail.push({
  //                 itemCode: state.orderDetail[element].itemCode, price: state.orderDetail[element].price
  //             })
  //         }
  //     }
  // }

  const getNavbarOptions = () => {
    let options = [
      {
        img: "left-arrow.svg",
        className: `orange ${state.savingQuotation || state.sendingQuotation ? 'btn-disable' : ''}`,
        name: "Go back",
        path: "/",
        isLogout: false,
        isCustom: true,
        onClick: () => props.history.goBack(),
      },
    ];

    const { userType } = props;

    if (["supplier", "customer"].includes(userType) && state.isButtonShow) {
      options.push({
        img: "correct-icon.svg",
        className: `dark-blue ${state.savingQuotation || state.sendingQuotation ? 'btn-disable' : ''}`,
        name: "Save<br /> QTN",
        path: `/prepare-quotation/${props.match.params.quotationId}`,
        isLogout: false,
        isCustom: true,
        onClick: () => saveQuotation(),
      }, {
        img: "Edit-icon.svg",
        className: `dark-green ${state.deliveryDays === "" || state.savingQuotation || state.sendingQuotation ? 'btn-disable' : ''}`,
        name: "Send<br /> QTN",
        path: `/prepare-quotation/${props.match.params.quotationId}`,
        isLogout: false,
        isCustom: true,
        onClick: () => sendQuotation(),
      });
    }
    return options;
  };
  const apiCallForCSVData = () => {
    csvLinkRef.current.link.click();
  };

  const CaptainTotalPrice = (data) => {
    let totalPrice = 0;
    let totalnetprices = 0;
    let totalmeshprices = 0;
    data &&
      data.length > 0 &&
      data.map((item) => {
        let price = item?.qtdPrice || 0;
        let order = item?.order || 0;
        totalmeshprices =
          totalmeshprices + Number(parseFloat((price * 100) / 97.5).toFixed(2));
        totalPrice = totalPrice + Number(parseFloat(order * price).toFixed(2));
        totalnetprices = totalnetprices + Number(parseFloat(price).toFixed(2));
      });

    setCaptainTotalAmount(totalPrice);
    setCaptainTotalMeshC(totalmeshprices);
    setCaptainTotalPrice2(totalnetprices);
  };

  const handleModalChange = (showModal) => {
    setState({
      ...state,
      showModal,
    });
  };

  const isSupplier = props.userType === 'supplier';

  return (
    <DashboardWrapper
      title="Quotation"
      navbarOptions={getNavbarOptions()}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {/* RFQ Detials Section */}
      <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap d-flex align-items-start">
        <div className="rfq-durations rfq-details-items  descriptrfq">
          <h2 className="mt-2 font-weight-bold">Vessel Name:</h2>
          <div className="inner-content text-left">
            <h2 className="font-weight-bold">
              Del. Place:{" "}
              <span className="font-weight-bold mt-1 d-inline-block"></span>
            </h2>
            <h2 className=" font-weight-bold">
              Del. date:{" "}
              <span className="font-weight-bold mt-1 d-inline-block"></span>
            </h2>
            <h2 className="font-weight-bold">
              Agent Detail:{" "}
              <span className="font-weight-bold mt-1 d-inline-block"></span>
            </h2>
            <h2 className="font-weight-bold">
              Creation Date:{" "}
              <span className="font-weight-bold mt-1 d-inline-block"></span>
            </h2>
          </div>
        </div>
        {Object.size(props.quotationReducer.orderDetail) > 0 ? (
          <div className="rfq-rating rfq-details-items">
            <h2 className="mt-2">
              {props.quotationReducer.orderDetail
                ? props.quotationReducer.orderDetail.vesselName
                : ""}
            </h2>
            <h2 className="mt-2">
              {props.quotationReducer.orderDetail &&
                props.quotationReducer.orderDetail.placeOfDelivery
                ? props.quotationReducer.orderDetail.placeOfDelivery.label
                : ""}
            </h2>
            <h2 className="mt-2">
              {props.quotationReducer.orderDetail
                ? moment(props.quotationReducer.orderDetail.arrivalTime)
                  .utc()
                  .format("YYYY-MM-DD")
                : ""}
            </h2>
            <h2 className="mt-2">
              {props.quotationReducer.orderDetail &&
                props.quotationReducer.orderDetail.agentDetail
                ? props.quotationReducer.orderDetail.agentDetail
                : "--"}
            </h2>
            <h2 className="mt-2">
              {props.quotationReducer.orderDetail
                ? moment(props.quotationReducer.orderDetail.createdAt)
                  .utc()
                  .format("YYYY-MM-DD")
                : ""}
            </h2>
          </div>
        ) : (
          ""
        )}
        <div className="rfq-durations rfq-details-items  descriptrfq ml-10">
          <div className="inner-content text-left">
            <h2 className="mt-0 font-weight-bold"></h2>
            <h2 className="mt-0 font-weight-bold">Total items: </h2>
            <h2 className="mt-0 font-weight-bold">Total Price: </h2>
          </div>
        </div>
        <div className="rfq-rating rfq-details-items">
          <h2 className="mt-2"></h2>
          <h2 className="mt-2">{state.quotationDetailList.length}</h2>
          {/* <h2 className="mt-2">{parseFloat(CaptaintotalAmount).toFixed(2)} USD</h2> */}
          {/* <h2 className="mt-2">
            {props.userType !== "supplier" || !state.isButtonShow
              ? currencyFormatter(CaptaintotalAmount)
              : currencyFormatter(totalAmount)}
          </h2> */}
          <h2 className="mt-2">{currencyFormatter(state.price)}</h2>
        </div>
      </div>
      <div className="ordCatg-table-wrap mt-3 system-cat-filter">
        <div className="card-bx-sty">
          <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
              <CsvDownload
                data={state.csvData}
                csvLinkRef={csvLinkRef}
                filename={"Quotation.csv"}
                headers={headers}
                apiCallForCSVData={() => apiCallForCSVData()}
              />
              {isSupplier && state.isButtonShow && <button className="btn btn-blue ml-2" onClick={() => {
                setState({
                  ...state,
                  showModal: true,
                });
              }}>Upload Quotation</button>}
            </div>
          </div>
        </div>
      </div>
      {/* RFQ Detials Section End */}

      <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
        {/* Table */}
        <div className="ordCatg-InnTable store-catgTable py-3 sendRfq-t">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left">Sr. No.</th>
                  <th className="text-left">Item Code</th>
                  <th className="text-center">IMPA</th>
                  <th className="text-center">Department</th>
                  <th className="text-center">Description</th>
                  <th className="text-center">Explanation</th>
                  {/* <th className="text-center">Availability</th> */}
                  <th className="text-left">Quantity</th>
                  {/* {props.userType !== 'supplier' ? <th className="text-left">Estimated Price</th> : ''} */}
                  <th className="text-center">Unit</th>
                  <th className="text-center">Package Size</th>
                  <th className="text-center">Net Price</th>
                  {/* <th className="text-left">Mesh Price</th> */}
                  <th className="text-center">Total Price</th>
                  <th className="text-center">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {props.apiCallStatus.isStarted.indexOf("getQuotationDetail") !==
                  -1 ? (
                  <tr>
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img style={{ width: "46px" }} src={loader} alt="" />
                      </div>
                    </td>
                  </tr>
                ) : state.quotationDetailList &&
                  state.quotationDetailList.length > 0 ? (
                  state.quotationDetailList
                    .sort((a, b) => a.alternativeCode - b.alternativeCode)
                    .map((item, index) => {
                      let subIndex = 0;
                      if (
                        item.estimatedPrice &&
                        item.estimatedPrice.length > 0
                      ) {
                        subIndex = item.estimatedPrice.findIndex(
                          (x) => x.itemCode === item.itemCode
                        );
                        if (subIndex === -1) {
                          subIndex = 0;
                        }
                      }
                      {
                        /* const supplierPriceValue = !state?.isButtonShow ? item?.estimatedPrice[subIndex]?.pricePerCountry[0]?.price :
                                            state?.orderDetail && state?.orderDetail[index] ? state.orderDetail[index]['price'] : ''; */
                      }

                      // const supplierPriceValue = !state?.isButtonShow
                      //   ? item?.qtdPrice
                      //   : state?.orderDetail && state?.orderDetail[index]
                      //     ? state.orderDetail[index]["price"]
                      //     : "";
                      // const captainPriceValue = item?.estimatedPrice[subIndex]?.pricePerCountry[0]?.price || 0.0;

                      const supplierPriceValue = item?.price || item?.qtdPrice || "0";
                      const captainPriceValue = item?.price || item?.qtdPrice || "0";

                      const priceValue = isSupplier ? supplierPriceValue : captainPriceValue;

                      // let order = item?.estimatedPrice[subIndex]?.pricePerCountry[0]?.baseQuantity;
                      let order = item?.order;
                      // let packageUnit = item?.estimatedPrice[subIndex]?.pricePerCountry[0]?.packageUnit;
                      let packageUnit = item.standardPackagingUnit;
                      // let remark = !state.isButtonShow ? item.estimatedPrice[subIndex].pricePerCountry.remark :
                      //     state.orderDetail && state.orderDetail[item.itemCode] ? state.orderDetail[item.itemCode]['remark'] : '';
                      let remark = item.remark;
                      //let newTotalPrice = order * priceValue
                      let MeshPrice = (100 * priceValue) / 97.5;

                      let newTotalPrice = order * priceValue;

                      return (
                        <tr key={"quotationDetailList00-" + index}>
                          <td>
                            <span className="text-center td-wrap-sty">
                              {index + 1}
                            </span>
                          </td>
                          <td>
                            <span className="text-center td-wrap-sty">
                              {item.itemCode}
                            </span>
                          </td>
                          <td>
                            <span className="td-wrap-sty text-center">
                              {item.alternativeCode}
                            </span>
                          </td>
                          <td>
                            <span className="text-center td-wrap-sty">
                              {item.department}
                            </span>
                          </td>
                          <td>
                            <span className="text-left td-wrap-sty">
                              {item.description}
                            </span>
                          </td>
                          <td>
                            <span className="text-left td-wrap-sty mw-15">
                              {item.explanation}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-center td-wrap-sty">
                              {item.availability}
                            </span>
                          </td> */}

                          <td className="button-round-td">
                            {props.userType === "supplier" &&
                              state.isButtonShow ? (
                              <input
                                className="whi-round td-wrap-sty text-right"
                                type="number"
                                value={order}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    index,
                                    e.target.value,
                                    "order",
                                    subIndex
                                  )
                                }
                              />
                            ) : (
                              <span className="td-wrap-sty text-right">
                                {order}
                              </span>
                            )}
                          </td>

                          {/* {props.userType !== 'supplier' ? <td><span className="text-center td-wrap-sty">{item.estimatedPrice && item.estimatedPrice[0] ? (item.estimatedPrice[0].pricePerCountry.price).toFixed(2) : ""}</span></td> : ''} */}

                          {/* {props.userType !== 'supplier' ? <td><span className="text-center td-wrap-sty">{12 || ""}</span></td> : ''} */}

                          <td>
                            <span className="td-wrap-sty text-center">
                              {packageUnit}
                            </span>
                          </td>

                          {/* <td>
                            {props.userType === "supplier" &&
                              state.isButtonShow ? (
                              <input
                                className="whi-round td-wrap-sty text-right"
                                type="text"
                                value={packageUnit}
                                onChange={(e) =>
                                  handleQuantityChange(
                                    index,
                                    e.target.value,
                                    "standardPackagingUnit",
                                    subIndex
                                  )
                                }
                              />
                            ) : (
                              <span className="td-wrap-sty text-center">
                                {packageUnit}
                              </span>
                            )}
                          </td> */}

                          <td>
                            <span className="td-wrap-sty text-center">
                              {item.baseQuantityAvg}
                            </span>
                          </td>

                          <td className="button-round-td">
                            {props.userType === "supplier" && state.isButtonShow ? (
                              <input
                                className="whi-round text-right"
                                type="number"
                                value={
                                  priceValue || (!state.isButtonShow && "0")
                                }
                                id={`price${index}`}
                                onChange={(e) =>
                                  handleTableChange(
                                    item.itemCode,
                                    e.target.value,
                                    item.orderId,
                                    order,
                                    packageUnit,
                                    remark,
                                    index
                                  )
                                }
                              />
                            ) : (
                              <span className="td-wrap-sty text-right">
                                {item?.qtdPrice || "0"}
                              </span>
                            )}
                          </td>

                          {/* <td><span className="text-center td-wrap-sty">{MeshPrice.toFixed(2)}</span></td> */}

                          {/* <td><span className="td-wrap-sty text-right">{!state.isButtonShow ? (order * item.pricePerBaseUnit).toFixed(2) : state.orderDetail[item.itemCode] ? (state.orderDetail[item.itemCode].price * order).toFixed(2) : 0}</span></td> */}

                          {/* <td><span className="td-wrap-sty text-right">{newTotalPrice.toFixed(2)}</span></td>
                           */}

                          <td>
                            <span className="td-wrap-sty text-right">
                              {newTotalPrice.toFixed(2)}
                            </span>
                          </td>

                          <td className="button-round-td" data-tip={remark}>
                            {props.userType === "supplier" &&
                              state.isButtonShow ? (
                              <input
                                className="whi-round text-right"
                                type="text"
                                value={remark}
                                id={`remark${index}`}
                                onChange={(e) =>
                                  handleTableChange(
                                    item.itemCode,
                                    priceValue,
                                    item.orderId,
                                    order,
                                    packageUnit,
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            ) : (
                              <span className="td-wrap-sty text-right">
                                <ReactTooltip />
                                {remark}
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                ) : state.quotationDetailList &&
                  state.quotationDetailList.length === 0 ? (
                  <tr>
                    <td colSpan="40" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
          {props.userType === "supplier" && state.isButtonShow ? (
            <div className="tableFoot-content mt-4">
              <ul className="list-inline pl-0 d-flex align-items-center justify-content-end deli-info-list mb-2">
                <li>
                  Total items:<span>{state.quotationDetailList.length}</span>
                </li>
                <li>
                  Quoted items:<span>{state?.changedItem?.length}</span>
                </li>
                {/* <li>Net Price<span>{totalPrice2.toFixed(2)}</span></li> */}
                {/* <li>Total Mesh<span>{GrandTotalMeshC.toFixed(2)}</span></li> */}
                <li>
                  Grand Total:<span>{totalAmount} USD</span>
                </li>
              </ul>
              <div className="deli-info-ctn d-flex justify-content-end">
                <div className="deli-info-items-ctn">
                  <div className="form-group mb-1 d-flex align-items-center justify-content-end deli-day-info">
                    <label>Delivery time:</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={state.deliveryDays}
                      name="deliveryDays"
                      onChange={handleChange}
                    />
                    <label className="ml-2">days</label>
                  </div>
                  <div className="form-group mb-1 d-flex align-items-center deli-day-info">
                    <label>Delivery Charges:</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={state.deliveryCharges}
                      name="deliveryCharges"
                      onChange={handleChange}
                    />
                    <label className="ml-2">USD</label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="tableFoot-content mt-4">
              <ul className="list-inline pl-0 d-flex align-items-center justify-content-end deli-info-list mb-2">
                <li>
                  Total items:<span>{state.quotationDetailList.length}</span>
                </li>
                <li>
                  Total Price:
                  {/* <span>{currencyFormatter(CaptaintotalAmount)}</span> */}
                  <span>{currencyFormatter(state.price)}</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <UploadQuotationModal
        showModal={state.showModal}
        handleModalChange={handleModalChange}
        ClearApiByNameAction={props.ClearApiByNameAction}
        apiCallStatus={props.apiCallStatus}
        quotationId={props.match.params.quotationId}
        status={new URLSearchParams(window.location.search).get('status') || ''}
        state={state}
        setState={setState}
      />
    </DashboardWrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  quotationReducer: state.quotationReducer,
  userId: state.authReducer.userId,
  userType: state.authReducer.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ApiClearAction: () => dispatch(ApiClearAction()),
  sendQuotation: (data) => dispatch(sendQuotation(data)),
  saveQuotation: (data) => dispatch(saveQuotation(data)),
  getQuotationDetail: (quotationId, supplierId) => dispatch(getQuotationDetail(quotationId, supplierId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);
