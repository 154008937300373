import React, { useEffect, useState } from 'react';
import { RatingStars } from "./ratingStars";
import $ from 'jquery';
import { getCookie, setCookie } from '../../../Services/cookies';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

export function FeedbackModal(props) {
    const [rate, setRate] = useState(0);
    const [rate2, setRate2] = useState(0);
    const [rate3, setRate3] = useState(0);
    const [rate4, setRate4] = useState(0);
    const [rate5, setRate5] = useState(0);
    const [message, setMessage] = useState('');
    const [supplierId, setSupplierId] = useState("0");

    useEffect(() => {
        if (props.apiCallStatus.apiCallFor === 'rateSupplier' && props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            $('#feedbackModal').modal('hide');
            setRate(0);
            props.ClearApiByNameAction('rateSupplier')
        }
        if (getCookie("orderFeedback") !== "" || (props.apiCallStatus.apiCallFor === 'orderFeedback' && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed)) {
            let data = JSON.parse(getCookie("orderFeedback"));
            const urlParams = new URLSearchParams(data.url);
            let supplierId = urlParams.get('supplierId');
            setSupplierId(supplierId)
            $('#feedbackModal').modal('show');
            setCookie("orderFeedback", "", "")
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    }, [props.apiCallStatus])

    const rateSupplier = () => {
        const TotalRate = (rate + rate2 + rate3 + rate4 + rate5) / 5;
        props.rateSupplier({ customerId: props.userId, supplierId: supplierId, rate: TotalRate })
    }
    const handleChange = (val) => {
        setRate(val)
    }
    const handleChange2 = (val) => {
        setRate2(val)
    }
    const handleChange3 = (val) => {
        setRate3(val)
    }
    const handleChange4 = (val) => {
        setRate4(val)
    }
    const handleChange5 = (val) => {
        setRate5(val)
    }
    return (
        <div className=" ratingmodel modal fade themeRounded-modal thankyou-image-modal" id="feedbackModal" tabIndex="-1" aria-labelledby="NutritaionModal" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog thankyou-modal-dialog">
                <div className="modal-content theme-modal-innr rat-us-mod thankyou-background">
                    {/* <div className="modal-header"> */}
                    {/* <button type="button" className="close pull-left" data-dismiss="modal">&times;</button> */}
                    {/* <button type="button" className="close circle-btn-style mr-1" data-dismiss="modal" aria-label="Close"></button> */}
                    {/* </div> */}
                    <div className="model-body">
                        <Row>
                            <Col lg={3} xs={3}>
                                <div className='thankyou-image'>
                                    <Image src='/thankyou.png' width={50} alt='thankyou' fluid />
                                </div>
                            </Col>
                            <Col lg={9} xs={9}>
                                <Card.Title className='thankyou-header'>
                                    Please give us your feedback!
                                </Card.Title>
                            </Col>
                            <Col lg={12} xs={12} md={12} xl={12}>
                                <div className='thankyou-spacing'>
                                    <p className="thankyou-p">
                                        Dear Captain,
                                    </p>
                                    <p className="thankyou-p">We kindly ask you to rate your latest supply of (Department) on (Delivery date) in (Delivery place) from (Supplier name).</p>
                                </div>
                            </Col>
                            <Col lg={12} xs={12} md={12} xl={12}>
                                <Card className='thankyou-card'>
                                    <Container fluid className='thankyou-container'>
                                        <Row>
                                            <Col lg={2} md={2} sm={2}>
                                                <div className='thankyou-number-1'><Card.Text className='thankyou-number'>
                                                    1
                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6}>
                                                <Card.Text className='thankyou-text'>
                                                    Quality of supplied goods
                                                </Card.Text>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <Card.Text>
                                                    <RatingStars id="input" className="rating-star-wrap-1" isEdit={true} ratingAvg={rate} handleChange={handleChange} />
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='thankyou-card'>
                                    <Container fluid className='thankyou-container'>
                                        <Row>
                                            <Col lg={2} md={2} sm={2}>
                                                <div className='thankyou-number-1'><Card.Text className='thankyou-number'>
                                                    2

                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6}>
                                                <Card.Text className='thankyou-text'>
                                                    Service & performance on board
                                                </Card.Text>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <Card.Text>
                                                    <RatingStars id="input2" className="rating-star-wrap-1" isEdit={true} ratingAvg={rate2} handleChange={handleChange2} />
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='thankyou-card'>
                                    <Container fluid className='thankyou-container'>
                                        <Row>
                                            <Col lg={2} md={2} sm={2}>
                                                <div className='thankyou-number-1'><Card.Text className='thankyou-number'>
                                                    3

                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6}>
                                                <Card.Text className='thankyou-text'>
                                                    Quality of packaging
                                                </Card.Text>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <Card.Text>
                                                    <RatingStars id="input3" className="rating-star-wrap-1" isEdit={true} ratingAvg={rate3} handleChange={handleChange3} />
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='thankyou-card'>
                                    <Container fluid className='thankyou-container'>
                                        <Row>
                                            <Col lg={2} md={2} sm={2}>
                                                <div className='thankyou-number-1'><Card.Text className='thankyou-number'>
                                                    4

                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6}>
                                                <Card.Text className='thankyou-text'>
                                                    Waste management
                                                </Card.Text>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <Card.Text>
                                                    <RatingStars id="input4" className="rating-star-wrap-1" isEdit={true} ratingAvg={rate4} handleChange={handleChange4} />
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='thankyou-card'>
                                    <Container fluid className='thankyou-container'>
                                        <Row>
                                            <Col lg={2} md={2} sm={2}>
                                                <div className='thankyou-number-1'><Card.Text className='thankyou-number'>
                                                    5

                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6}>
                                                <Card.Text className='thankyou-text'>
                                                    Documentation of orders
                                                </Card.Text>
                                            </Col>
                                            <Col lg={4} md={4} sm={4}>
                                                <Card.Text>
                                                    <RatingStars id="input5" className="rating-star-wrap-1" isEdit={true} ratingAvg={rate5} handleChange={handleChange5} />
                                                </Card.Text>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <Card className='thankyou-card-comment'>
                                    <Container fluid className='thankyou-container'>
                                        <Row>
                                            <Col lg={2} md={2} sm={2}>
                                                <div className='thankyou-number-comment'><Card.Text className='thankyou-number'>
                                                    6

                                                </Card.Text>
                                                </div>
                                            </Col>
                                            <Col lg={10}>
                                                <Card.Text className='thankyou-text-comment'>
                                                    Your comments:
                                                </Card.Text>
                                                <div>
                                                    <textarea maxLength={250} rows="4" cols="50" value={message}
                                                        className='thankyou-comment-box'
                                                        placeholder='type your comments here' onChange={(e) => setMessage(e.target.value)} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card>
                            </Col>
                            <Col lg={12}>
                                <div className='thankyou-spacing'>
                                    <p className="thankyou-p">
                                        Your feedback helps us to provide you a reliable platform that ensures a good quality and performance of our integrated suppliers worldwide.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <p className="thankyou-p">
                                    Thank you very much for your kind assistance.
                                </p>
                            </Col>
                            <Col>
                                <Card.Footer className='thankyou-footer'>
                                    <Row>
                                        <Col lg={8} md={8} sm={8} xs={8}>
                                            <div className='thankyou-footer-text'>
                                                <div>
                                                    <p className="thankyou-p-footer">
                                                        Best regards,
                                                    </p>
                                                    <p className="thankyou-p-footer">
                                                        Your MESH Team
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            {props.apiCallStatus.apiCallFor === "rateSupplier" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                                                <div className="alert alert-danger">
                                                    {props.apiCallStatus.message}
                                                </div>
                                                : ""}
                                            <button type="button" onClick={() => rateSupplier()} className="btn tb-btn-rounded thankyou-blue rounded thankyou-button mb-2">SEND</button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Col>
                        </Row>
                        {/* <RatingStars id="input" className="rating-star-wrap" isEdit={true} ratingAvg={rate} handleChange={handleChange} />
                        <h5 className="mb-2">Thank you for using MESH.</h5>
                        <p className="grey">Please be so kind to give us a short feedback regarding your selected supplier.We will only use this given feedback for the rating of the supplier, he will not be informed about it.Thank you for your kind assistance. We wish you a nice trip ahead.</p>
                        <p className="grey">Take care & best regards,</p>
                        <p className="grey">your MESH team.</p>
                        {props.apiCallStatus.isStarted.indexOf("rateSupplier") !== -1 ? <div className="loader-img text-center w-100">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div> : ""}
                        {props.apiCallStatus.apiCallFor === "rateSupplier" && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                            <div className="alert alert-danger">
                                {props.apiCallStatus.message}
                            </div>
                            : ""}
                        <button type="button" onClick={() => rateSupplier()} className="btn tb-btn-rounded orng rounded mb-2">Rate & Send feedback</button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}