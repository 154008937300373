import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  supplierListing,
  deleteSupplier,
  getCatalogList,
} from "../../ApiCalls/supplier";
import { changePassword } from "../../../User/ApiCalls/user";
import { topSupplier } from "../../../Common/ApiCalls/topSupplier";
import { ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { connect } from "react-redux";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";

import {
  currencyFormatter,
  isAllowPermission,
} from "../../../../Services/common";
import { getRoutePathBykey } from "../../../../Constants/routesConfig";
import { ChangePasswordModal } from "../../../Common/Components/changePasswordModal";
import { CatalogListModal } from "../../../Common/Components/catalogListModal";
import { vesselListing } from "./../../../Vessel/ApiCalls/vessel";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { CsvDownload } from "../../../Common/Components/CsvDownload";

const headers = [
  { label: "Supplier name", key: "name" },
  { label: "Port name", key: "name" },
  { label: "Country", key: "countryName" },
  { label: "No. of supplies", key: "totalOrder" },
  { label: "Turnover P-MESH", key: "pMeshVolume" },
  { label: "Turnover C-MESH", key: "cMeshVolume" },
  { label: "Total Turnover", key: "totalVolume" },
];
var csvLinkRef = React.createRef();
function SupplierList(props) {
  const [state, setState] = useState({
    supplierList: [],
    csvData: [],
    customerVessels: [],
    userType:
      props.userType === "supplier"
        ? "supplier"
        : props.subType === "captain"
          ? "captain"
          : props.subType === "customer"
            ? "customer"
            : "",
    activePage: 0,
    totalCount: 0,
    filterQuery: "",
    isHitApi: false,
    catalogs: "",
    selectedUser: "",
    showCatalogModal: "",
    showModal: "",
    topSupplier: "",
    startDate: null,
    endDate: null,
  });
  const query = new URLSearchParams(props.location.search);
  let topSupplier = query.get("topSupplier");
  let portId = query.get("portId");
  let itemCode = query.get("itemCode");
  useEffect(() => {
    let data = { ...state };
    if (state.topSupplier !== topSupplier || !state.isHitApi) {
      if (
        (props.isSubAdmin &&
          props.userType === "user" &&
          props.subType !== "sub-admin") ||
        isAllowPermission(window.location.pathname, props.roles)
      ) {
        if (topSupplier !== null) {
          if (state.userType !== "customer") {
            let filterQuery = {};
            if (portId) {
              filterQuery["portId"] = portId;
            }
            if (itemCode) {
              filterQuery["itemCode"] = itemCode;
            }
            props.topSupplier(
              props.userId,
              state.userType,
              "",
              "",
              filterQuery
            );
          } else {
            props.vesselListing(0, 50);
          }
        } else {
          props.supplierListing(0, "supplier");
        }
        data.isHitApi = true;
        data.topSupplier = topSupplier;
        setState(data);
      } else if (!isAllowPermission(window.location.pathname, props.roles)) {
        props.history.push("/not-allowed");
      }
    }
    if (
      (props.apiCallStatus.apiCallFor === "supplierListing" ||
        props.apiCallStatus.apiCallFor === "appendSupplierList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        supplierList: props.supplierReducer.list,
        totalCount: props.supplierReducer.count,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "vesselListing" ||
      (props.apiCallStatus.apiCallFor === "appendVesselList" &&
        props.apiCallStatus.isCompleted &&
        !props.apiCallStatus.isFailed)
    ) {
      let customerVessels = [];
      if (props.subType === "customer" && props.vesselList.length > 0) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        let filterQuery = {};
        if (portId) {
          filterQuery["portId"] = portId;
        }
        if (itemCode) {
          filterQuery["itemCode"] = itemCode;
        }
        props.topSupplier(customerVessels, "customer", "", "", filterQuery);
      }
      setState({
        ...state,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topSupplier" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      let newArray = [];
      let data = [...props.commonReducer.topSupplierList];
      for (let index = 0; index < data.length; index++) {
        let obj = {
          name: data[index].name,
          countryName: data[index].countryName,
          pMeshCount: data[index].pMeshCount,
          cMeshCount: data[index].cMeshCount,
          pMeshVolume:
            data[index].pMeshVolume.toFixed(2) + " " + data[index].currency,
          cMeshVolume:
            data[index].cMeshVolume.toFixed(2) + " " + data[index].currency,
          totalVolume:
            data[index].totalVolume.toFixed(2) + " " + data[index].currency,
        };
        newArray.push(obj);
      }
      setState({
        ...state,
        supplierList: props.commonReducer.topSupplierList,
        totalCount: props.commonReducer.topSupplierList.length,
        csvData: newArray,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "deleteSupplier" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      Swal.fire("Deleted!", "Supplier has been deleted!", "success");
      props.supplierListing(0, "supplier");
    }
  }, [props, state.isHitApi]);

  const fetchMoreData = async () => {
    let { activePage } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    if (topSupplier !== null) {
      let filterQuery = {};
      if (portId) {
        filterQuery["portId"] = portId;
      }
      if (itemCode) {
        filterQuery["itemCode"] = itemCode;
      }
      if (state.userType !== "customer") {
        props.topSupplier(props.userId, state.userType, "", "", filterQuery);
      } else {
        props.topSupplier(
          state.customerVessels,
          state.userType,
          "",
          "",
          filterQuery
        );
      }
    } else {
      props.supplierListing(activePage, "supplier");
    }
  };
  const removeRecord = (id) => {
    if (!isAllowPermission("/delete-supplier", props.roles)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You don't have permission of this feature",
      });
    } else {
      Swal.fire({
        title: "Delete Supplier",
        text: "Are you sure you want to delete this Supplier?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        dangerMode: true,
      }).then((result) => {
        if (result.isConfirmed) {
          props.deleteSupplier(id);
        }
      });
    }
  };
  const applyFilters = () => {
    if (state.startDate !== null && state.endDate !== null) {
      setState({
        ...state,
        isHitApi: false,
      });
    }
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
    });
  };
  const clearFilters = () => {
    setState({
      ...state,
      startDate: null,
      endDate: null,
      isHitApi: false,
    });
  };
  const getNavbarOptions = () => {
    let options = [
      {
        img: "left-arrow.svg",
        className: "orange",
        name: "Go back",
        path: "/",
        isLogout: false,
        isCustom: true,
        onClick: () => props.history.goBack(),
      },
    ];
    if (
      props.userType === "user" &&
      (props.subType === "user" || props.subType === "subAdmin")
    ) {
      options.push({
        img: "Edit-icon.svg",
        className: "dark-green",
        name: "Create<br />Supplier",
        path: "/create-supplier",
        isLogout: false,
      });
    }
    return options;
  };

  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    csvLinkRef.current.link.click();
  };
  const handleModalChange = (val, userId) => {
    setState({
      ...state,
      selectedUser: userId,
      showModal: val,
    });
  };
  const handleCatalogModalChange = (val, userId, catalog) => {
    setState({
      ...state,
      catalogs: catalog,
      selectedUser: userId,
      showCatalogModal: val,
    });
  };
  return (
    <DashboardWrapper
      title={topSupplier ? "Supplier Overview" : "Supplier"}
      navbarOptions={getNavbarOptions()}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {topSupplier === null ? (
        <div className="ordCatg-table-wrap mt-3 system-cat-filter arow-remove">
          <div className="card-bx-sty">
            <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
              <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                <button
                  type="button"
                  className="btn btn-yellow ml-1"
                  onClick={() => applyFilters()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-yellow ml-1 mr-1"
                  onClick={() => clearFilters()}
                >
                  Clear
                </button>
                <CsvDownload
                  data={state.csvData}
                  csvLinkRef={csvLinkRef}
                  filename={"Top-Supplier.csv"}
                  headers={headers}
                  apiCallForCSVData={apiCallForCSVData}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <InfiniteScroll
        dataLength={state.supplierList.length}
        next={fetchMoreData}
        height={"80vh"}
        hasMore={state.totalCount <= state.supplierList.length ? false : true}
      // loader={
      //   state.totalCount > 20 ? (
      //     ""
      //   ) : (
      //     <div
      //       colSpan="12"
      //       className="loadmore d-flex align-items-center justify-content-center"
      //     >
      //       <img src={loader} alt="" />
      //     </div>
      //   )
      // }
      >
        <div className="table-responsive mt-4">
          <table className="table_overview table theme-table-wrap with-action-btns">
            <thead>
              <TableHeader
                topSupplier={topSupplier}
                roles={props.roles}
                userType={props.userType}
                isSubAdmin={props.isSubAdmin}
              />
            </thead>
            <tbody>
              {props.apiCallStatus.isStarted.indexOf("supplierListing") !==
                -1 ||
                props.apiCallStatus.isStarted.indexOf("topSupplier") !== -1 ? (
                <tr>
                  <td colSpan="20">
                    <div className="loader-img text-center">
                      <img
                        style={{ width: "46px" }}
                        src={require("../../../../assets/images/Spinner-1s-200px.gif")}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              ) : state.supplierList && state.supplierList.length === 0 ? (
                <tr>
                  <td colSpan="40" className="text-center">
                    No Data Found
                  </td>
                </tr>
              ) : (
                state.supplierList &&
                state.supplierList.length > 0 &&
                state.supplierList.map((item, index) => {
                  let isDisableEdit =
                    props.isSubAdmin &&
                      props.roles.length > 0 &&
                      !isAllowPermission(
                        getRoutePathBykey("editSupplier"),
                        props.roles
                      )
                      ? true
                      : false;
                  let isDisableDel =
                    props.isSubAdmin &&
                      props.roles.length > 0 &&
                      !isAllowPermission("/delete-supplier", props.roles)
                      ? true
                      : false;
                  return (
                    <TableContent
                      topSupplier={topSupplier}
                      isDisableEdit={isDisableEdit}
                      isDisableDel={isDisableDel}
                      index={index}
                      item={item}
                      userType={props.userType}
                      isSubAdmin={props.isSubAdmin}
                      removeRecord={removeRecord}
                      handleModalChange={handleModalChange}
                      handleCatalogModalChange={handleCatalogModalChange}
                      roles={props.roles}
                    />
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      <ChangePasswordModal
        ClearApiByNameAction={props.ClearApiByNameAction}
        apiCallStatus={props.apiCallStatus}
        changePassword={props.changePassword}
        selectedUser={state.selectedUser}
        showModal={state.showModal}
        handleModalChange={(val, userId) => handleModalChange(val, userId)}
      />
      <CatalogListModal
        catalogs={state.catalogs}
        ClearApiByNameAction={props.ClearApiByNameAction}
        apiCallStatus={props.apiCallStatus}
        getCatalogList={props.getCatalogList}
        selectedUser={state.selectedUser}
        showModal={state.showCatalogModal}
        handleModalChange={(val, userId) =>
          handleCatalogModalChange(val, userId)
        }
      />
    </DashboardWrapper>
  );
}
const TableHeader = React.memo((props) => {
  if (props.topSupplier) {
    return (
      <tr>
        <th>#</th>
        {headers.map((item, index) => {
          return <th key={"hed11-" + index}>{item.label}</th>;
        })}
        <th>Action</th>
      </tr>
    );
  } else {
    return (
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Title</th>
        <th scope="col">Rating</th>
        <th scope="col">Company Name</th>
        {/* <th scope="col">Catalogs</th> */}
        {props.userType === "user" ? (
          props.isSubAdmin &&
            props.roles.length > 0 &&
            !isAllowPermission(getRoutePathBykey("editSupplier"), props.roles) &&
            !isAllowPermission("/delete-supplier", props.roles) ? (
            ""
          ) : (
            <th scope="col">Action</th>
          )
        ) : (
          ""
        )}
      </tr>
    );
  }
});
const TableContent = React.memo((props) => {
  if (props.topSupplier) {
    return (
      <tr key={"tSuppliers22-" + props.index}>
        <td>{props.index + 1}</td>
        <td>{props.item.name}</td>
        <td>{props?.item?.port}</td>
        <td>{props.item.countryName}</td>
        <td>{props.item.totalOrder}</td>
        <td>
          {currencyFormatter(props.item.pMeshVolume, props.item.currency)}
        </td>
        <td>
          {currencyFormatter(props.item.cMeshVolume, props.item.currency)}
        </td>
        <td>
          {currencyFormatter(props.item.totalVolume, props.item.currency)}
        </td>
        <td className="form-inline">
          <Link
            to={`/port-listing?topPort=1&supplierId=${props.item._id}`}
            className="d-flex p-0 fz-12 dark-pink-clr"
          >
            View
          </Link>
        </td>
      </tr>
    );
  } else {
    return (
      <tr key={"pI22-" + props.index}>
        <td>{props.index + 1}</td>
        <td>{props.item.name}</td>
        <td>{props.item.email}</td>
        <td>{props.item.title}</td>
        <td>
          {props.item.ratingAvg && props.item.ratingAvg > 0
            ? props.item.ratingAvg.toFixed(2)
            : "0"}
        </td>
        <td>{props.item.companyName}</td>
        {/* <td>
          <button
            type="button"
            onClick={() =>
              props.handleCatalogModalChange(
                "catalogList",
                props.item.id,
                props.item.catalogs
              )
            }
            className="btn"
          >
            <i className="fa fa-download"></i>
          </button>
        </td> */}
        {props.isDisableDel && props.isDisableEdit ? (
          ""
        ) : props.userType === "user" ? (
          <td>
            {!props.isSubAdmin ? (
              <button
                className="btn btn-change-password-tab"
                onClick={() =>
                  props.handleModalChange("changePassword", props.item.id)
                }
              ></button>
            ) : (
              ""
            )}
            {props.isDisableEdit ? (
              ""
            ) : (
              <Link
                to={"/edit-supplier/" + props.item.id}
                className="btn btn-edit-tab mr-2"
              ></Link>
            )}
            {props.isDisableDel ? (
              ""
            ) : (
              <button
                type="button"
                onClick={() => props.removeRecord(props.item.id)}
                className="btn btn-dlt-tab"
              ></button>
            )}
          </td>
        ) : (
          ""
        )}
      </tr>
    );
  }
});
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  supplierReducer: state.supplierReducer,
  vesselList: state.vesselReducer.list,
  commonReducer: state.commonReducer,
  userType: state.authReducer.userType,
  subType: state.authReducer.subType,
  roles: state.authReducer.roles,
  isSubAdmin: state.authReducer.isSubAdmin,
  userId: state.authReducer.userId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  supplierListing: (offset, type) => dispatch(supplierListing(offset, type)),
  getCatalogList: (offset, type) => dispatch(getCatalogList(offset, type)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  deleteSupplier: (id) => dispatch(deleteSupplier(id)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  changePassword: (data) => dispatch(changePassword(data)),
  topSupplier: (userId, userType, from, to, filterQuery) =>
    dispatch(topSupplier(userId, userType, from, to, filterQuery)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SupplierList);
