import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllPortAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { get, set } from "idb-keyval";

const fetchCountryCodeFromQuery = (filteredQuery) => {
    let countryCode = "";

    if (filteredQuery) {
        if (filteredQuery.includes("&")) {
            const ret = filteredQuery.split("&").filter((r) => r.includes("countryCode"));
            const cc = ret[0].replaceAll("?", "").split("=");
            countryCode = Array.isArray(cc) ? cc[1] : "";
        } else {
            countryCode = filteredQuery.split("countryCode")[1].replaceAll("=", "");
        }
    }

    return countryCode;
};

export function getAllPort(filterQuery) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllPort" }));
        let url = "";
        let fetchingAllPorts = true;
        const countryCode = fetchCountryCodeFromQuery(filterQuery);
        if (filterQuery) {
            url = Constant.apiURl + "/port?" + filterQuery;
            fetchingAllPorts = false;
        } else {
            url = Constant.apiURl + "/port";
        }
        const myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            if (fetchingAllPorts) {
                await set("allPorts", myJson);
            }
            dispatch(GetAllPortAction(myJson.data));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllPort",
                    message: myJson.message,
                })
            );
        } else {
            const allPorts = await get("allPorts");
            if (allPorts) {
                let { data, message } = allPorts;
                if (!fetchingAllPorts) {
                    data = data.filter(({ code }) => code === countryCode);
                }
                dispatch(GetAllPortAction(data));
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getAllPort",
                        message,
                    })
                );
            } else {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: allPorts.statusCode,
                        apiCallFor: "getAllPort",
                        message: allPorts.message
                    })
                );
            }
        }
    };
}