import React, { Component } from "react";
import "./customerJourney.css";
import Select from "react-select";
import { Link } from "react-router-dom";

const options = [
  { value: "Provisions", label: "Provisions" },
  { value: "Deck", label: "Deck" },
  { value: "Engine", label: "Engine" },
  { value: "Galley / ", label: "Galley / " },
  { value: "laundry", label: "laundry" },
  { value: "Electrical", label: "Electrical" },
  { value: "Stationary", label: "Stationary" },
];

export default class MedicineInventory extends Component {
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <div className="pageWrapper">
        <div className="pageContainer mb-0">
          <div className="order-dlts-wrapper">
            <div className="pageHeader d-flex align-items-center flex-column flex-lg-row">
              <div className="d-flex flex-fill align-items-center mesh-logoWrap flex-column flex-sm-row ">
                <div className="logoHolder mr-sm-4 text-sm-left text-center">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/logo.png")}
                    alt="Logo"
                  />
                </div>
                <h1 className="btn-sty-title flex-fill my-3 my-lg-0">
                  MESH* YOUR PROVISION ORDER
                </h1>
              </div>
              <div className="mesh-control-btns d-flex align-items-center">
                <button type="button" className="btn btn-polygon-sty dark-pink">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/left-arrow.svg")}
                    alt="icon"
                  />
                  <span>Go back</span>
                </button>
                <Link to="/dashboard" className="btn btn-polygon-sty dark-blue">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/Monitor-icon.svg")}
                    alt="icon"
                  />
                  <span>
                    Dash
                    <br />
                    board
                  </span>
                </Link>
                <Link to="/checkout" className="btn btn-polygon-sty green">
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/Message-icon.svg")}
                    alt="icon"
                  />
                  <span>
                    send
                    <br />
                    order
                  </span>
                </Link>
              </div>
            </div>
          </div>
          {/* Order Overview */}
          <div className="ordCatg-wrapper">
            <div className="card-bx-sty d-flex align-items-center flex-column flex-sm-row">
              <div className="d-flex align-items-center box-item-inn-wrap flex-fill w-100 pb-1">
                <div className="card-bx-sty box-item-inn">
                  <h1 className="text-nowrap">MEDICINE INVENTORY</h1>
                  <p className="text-nowrap">Date: 2020-07-10</p>
                </div>
                <div className="card-bx-sty box-item-inn bg-dark-green">
                  <h1 className="white-clr text-left">
                    Dear (rank),
                    <br />
                    following you see our medicine & hospital inventory table.
                    We provided some tools to lower your effort of
                    inventory-taking. You have several possibilities to filter
                    the items or assort them. After saving the inventory you can
                    directly generate an order if you like.
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {/* Provision Catalogue Table */}
          <div className="ordCatg-table-wrap mt-3">
            <div className="card-bx-sty">
              <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-sm-row">
                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                  <Select
                    className="custom-select-wrap small-select-sty"
                    classNamePrefix="react-select"
                    placeholder="Category"
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={options}
                  />
                </div>
                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                  <Select
                    className="custom-select-wrap small-select-sty"
                    classNamePrefix="react-select"
                    placeholder="Category"
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={options}
                  />
                </div>
                <h6 className="text-white semi-bold m-0 text-nowrap">
                  Item catalogue
                </h6>
              </div>
            </div>
            {/* Table */}
            <div className="ordCatg-InnTable">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item group</th>
                      <th>Item No.</th>
                      <th>Description</th>
                      <th>Remarks</th>
                      <th>
                        <span className="bg-rounded white">Inventory</span>
                      </th>
                      <th>
                        <span className="bg-rounded white">Order</span>
                      </th>
                      <th>Standard pack. unit</th>
                      <th>Points per kg</th>
                      <th>Used points</th>
                      <th>
                        <span className="bg-rounded green">Nutrition</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">Box app. 5 kg</span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span>
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">Box app. 5 kg</span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">Box app. 5 kg</span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">Box app. 5 kg</span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="td-wrap-sty">MEAT</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">1101</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">BEEF BONES DF.</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="whi-round"></span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">kg</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">16,91</span>
                      </td>
                      <td>
                        <span className="td-wrap-sty">0,00</span>
                      </td>
                      <td>
                        <span className="td-btn-wrap">
                          <button type="button" className="btn btn-green-round">
                            details
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
        {/* Order Details Modal */}
        <div
          className="modal fade theme-Roundedmodal"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    className="img-fluid"
                    src={require("../../assets/images/cancel-icon.svg")}
                    alt="icon"
                  />
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-card d-flex align-items-center">
                  <label>Order details:</label>
                  <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                    <Select
                      className="custom-select-wrap small-select-sty"
                      classNamePrefix="react-select"
                      placeholder="Category"
                      value={selectedOption}
                      onChange={this.handleChange}
                      options={options}
                    />
                  </div>
                </div>
                <div className="modal-card d-flex align-items-center">
                  <h1 className="modalCard-text d-flex align-items-center w-100 m-0">
                    Remaining Pts:{" "}
                    <span className="modalCard-value flex-fill text-center">
                      7.532 / 10.000
                    </span>
                    <span className="font-weight-bold">75%</span>
                  </h1>
                </div>
                <div className="modal-card d-flex align-items-center">
                  <h1 className="modalCard-text font-weight-bold m-0">
                    Provision needed until next order in kg:
                  </h1>
                </div>
                <div className="modalTable-wrap table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Products</th>
                        <th>Needed</th>
                        <th>Ordered</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Meat</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap bg-pink">
                            370,00
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Fish</td>
                        <td>95,00</td>
                        <td>
                          <span className="rounded-td-wrap">0,00</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Ham/Sausage</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap">370,00</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Dairy Products</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap">370,00</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Fruits/Vegetables</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap">370,00</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Baking goods</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap">370,00</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Flour/Rice/Noodles</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap">370,00</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Dry cereals/Muesli/Sem.</td>
                        <td>361,00</td>
                        <td>
                          <span className="rounded-td-wrap">370,00</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="model-footer custom-footer ">
                <div className="modal-card d-flex align-items-center nutri-head-wrap">
                  <h1 className="modalCard-text font-weight-bold m-0 text-black">
                    Nutrition per crew per day
                  </h1>
                </div>
                <div className="nutrTable-wrap d-flex align-items-start">
                  <div className="nutr-items-wrap">
                    <h2 className="nutr-dtls-info nutr-type">Type</h2>
                    <ul className="nutr-dtls-info">
                      <li>Energy Kcal</li>
                      <li> Protein gr.</li>
                      <li>Carb. gr.</li>
                      <li>Fat gr.</li>
                    </ul>
                  </div>
                  <div className="nutr-items-wrap">
                    <ul className="nutr-indicator-wrap">
                      <li className="semi-bold">Indication</li>
                      <li>2800</li>
                      <li>101</li>
                      <li>370</li>
                      <li>92</li>
                    </ul>
                  </div>
                  <div className="nutr-items-wrap">
                    <ul className="nutr-order-wrap">
                      <li className="semi-bold">Ordered</li>
                      <li>632</li>
                      <li>78</li>
                      <li>7</li>
                      <li>32</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
