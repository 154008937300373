import React, { useEffect, useState } from 'react';
import { countryListing, updateCountry, getCountryDetail } from "./../ApiCalls/country";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { isAllowPermission } from '../../../Services/common';
import Swal from 'sweetalert2'
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';
const countryInitialState = {
    name: '',
    countryCode: '',
}
function EditCountry(props) {
    const [state, setState] = useState(countryInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        if (!isHitOnLoadApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getCountryDetail(props.match.params.id)
                setIsHitOnLoadApi(true)
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getCountryDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(props.countryReducer.detail)
            props.ApiClearAction();
        }
        if ((props.apiCallStatus.apiCallFor === "updateCountry") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(countryInitialState)
            props.ApiClearAction();
            Swal.fire("Success", `The country has been updated successfully.`, "success")
            props.history.push('/country-listing')

        }
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let formDetail = { ...state }
        formDetail[name] = value;
        setState(formDetail)
    }
    const editCountry = () => {
        let obj = {
            name: state.name,
            countryCode: state.countryCode,
            countryId: props.match.params.id,
            isActive: true
        }
        props.updateCountry(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <DashboardWrapper title="Edit Country" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="formBox">
                <CountryForm {...state} history={props.history} handleFormChange={handleFormChange} editCountry={editCountry} apiCallStatus={props.apiCallStatus} />
            </div>
        </DashboardWrapper>
    )
}

function CountryForm(props) {
    const validateForm = () => {
        var form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            props.editCountry();
        }
    }
    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">

                    <div className="form-group floating-label-wrap">
                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Country Name"
                            value={props.name} required />
                        <lable className="foating-labels-sty">Country Name</lable>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="countryCode" className="form-control" onChange={(e) => { props.handleFormChange(e) }} placeholder="Country Code"
                            value={props.countryCode} required />
                        <lable className="foating-labels-sty">Country Code</lable>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === "editVessel" && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">Update</button>
                </div>
            </div>
        </form>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    countryReducer: state.countryReducer,
    roles: state.authReducer.roles,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    countryListing: () => dispatch(countryListing()),
    updateCountry: (data) => dispatch(updateCountry(data)),
    getCountryDetail: (id) => dispatch(getCountryDetail(id)),
    ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCountry);
