import React, { Component } from 'react';
import $ from "jquery";
import Sidebar from '../Common/Components/sidebar';


export default class RfqRequest extends Component {
    state = {
        selectedOption: null,
    };
    componentDidMount() {
        $('.sidebar-toggle').click(function () {
            $('.dash-menubar').toggleClass('show')
        })
        $('.dropdown').on('show.bs.dropdown', function (e) {
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
        });

        $('.dropdown').on('hide.bs.dropdown', function (e) {
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp(300);
        });
    }
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    render() {
        const { selectedOption } = this.state;
        return (
            <div className="dash-content-ctn d-flex">
                {/* Slide Bar Content */}
                <Sidebar />
                {/* Body Content */}
                <div className="dash-page-content supplierDash-ctn-wrap">
                    <div className="dash-pg-inn-wrap">
                        <div className="mesh-board-wrap d-flex align-items-end">
                            <h1 className="btn-sty-title flex-fill">RFQ Request</h1>
                            <div className="mesh-control-btns d-flex align-items-center">
                                <button type="button" className="btn btn-polygon-sty orange">
                                    <img className="img-fluid" src={require('../../assets/images/left-arrow.svg')} alt="icon" />
                                    <span>Go back</span>
                                </button>
                                <button type="button" className="btn btn-polygon-sty dark-blue">
                                    <img className="img-fluid" src={require('../../assets/images/Monitor-icon.svg')} alt="icon" />
                                    <span>Profile</span>
                                </button>
                                <button type="button" className="btn btn-polygon-sty dark-green">
                                    <img className="img-fluid" src={require('../../assets/images/Dashboard-icon.svg')} alt="icon" />
                                    <span>upgrade</span>
                                </button>
                            </div>
                        </div>
                        {/* Order Status Content */}
                        <div className="row mt-lg-4 align-content-stretch newDash-contentStyle">
                            <div className="col-12">
                                <div className="content-box-wrap dash-content-bx">
                                    <div className="flex-fil text-left table-default-sty">
                                        <div className="table-responsive">
                                            <table className="table mt-4 table-light1 bg-transparent">
                                                <thead className="rounded-dark-head">
                                                    <tr>
                                                        <th>Seller</th>
                                                        <th className="text-center">Status</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="spacer">
                                                    <tr>
                                                        <td>M/V Containership 2</td>
                                                        <td className="semi-bold text-center">Provisions</td>
                                                        <td className="text-right">
                                                            <div className="tb-buttons-wrap">
                                                                <button type="button" className="btn tb-btn-rounded green mr-2">Accept</button>
                                                                <button type="button" className="btn tb-btn-rounded red mr-2">Cancel</button>
                                                                <button type="button" className="btn tb-btn-rounded orng">View</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>M/V Containership 2</td>
                                                        <td className="semi-bold text-center">Provisions</td>
                                                        <td className="text-right">
                                                            <div className="tb-buttons-wrap">
                                                                <button type="button" className="btn tb-btn-rounded green mr-2">Accept</button>
                                                                <button type="button" className="btn tb-btn-rounded red mr-2">Cancel</button>
                                                                <button type="button" className="btn tb-btn-rounded orng">View</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
                </div>
            </div>
        )
    }
}
