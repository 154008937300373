import React, { Component, useState } from "react";
import DonutChart from "../../charts/DonutChart";
import SingleColumnChart from "../../charts/SingleColumnChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FETCH } from "../../../Services/fetch";
import { Constant } from "../../../Constants/constant";
import { useEffect } from "react";
import { currencyFormatter } from "../../../Services/common";

const NewDashboard = (props) => {
  const myCalendarstyle = {
    overflow: "visible",
  };
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [storeConsumption, setStoreConsumption] = useState();
  const [storeBudget, setStoreBudget] = useState();
  const [provisionConsumption, setProvisionConsumption] = useState();
  const [provisionBudget, setProvisionBudget] = useState();

  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (startDate && endDate) {
      getAllVessel();
    }
  }, [startDate, endDate]);

  const getAllVessel = async () => {
    let formatStartDate = null;
    let formatEndDate = null;
    if (startDate) {
      formatStartDate = moment(startDate).format("YYYY-MM-DD");
    }
    if (endDate) {
      formatEndDate = moment(endDate).format("YYYY-MM-DD");
    }

    try {
      let myJson = await FETCH(
        "GET",
        Constant.apiURl +
          `/dashboard/get-vessel-overall-stats?from=${formatStartDate}&to=${formatEndDate}`
      );
      if (myJson && myJson.code === 200) {
        setStoreConsumption(myJson.data.store.consumptionPerDepartment);
        setStoreBudget(myJson.data.store.budgetConclusion);
        setProvisionConsumption(myJson.data.provision.consumptionPerDepartment);
        setProvisionBudget(myJson.data.provision.budgetConclusion);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleModal = (id, orderProvisionType) => {
    const { setVesselWiseStatList, handleCatalogModalChange } = props;
    handleCatalogModalChange("catalogList");
    setVesselWiseStatList(id, orderProvisionType);
  };

  const handleChangeStartDate = (val) => {
    if (val) {
      setStartDate(val);
    }
  };

  const handleChangeEndDate = (val) => {
    if (val) {
      setEndDate(val);
    }
  };

  const { vesselWiseStats } = props;
  return (
    <div className="mt-lg-1 d-flex contract-dash-wrap cont-ves-wrap">
      <div className="dashVesl-overview-wrap">
        <h3 className="btn-sty-title flex-fill mr-0">Fleet Overview Store</h3>
        <div className="content-box-wrap dash-content-bx mb-2">
          <div className="row px-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
              <div className="row">
                <div className="table-responsive" style={myCalendarstyle}>
                  <div className="date-range-picker d-flex align-items-center justify-content-center mb-2">
                    <span className="d-inline-block mr-1">
                      <DatePicker
                        selected={startDate}
                        onChange={(val) => handleChangeStartDate(val)}
                        className="link-style"
                      />
                    </span>
                    to
                    <span className="d-inline-block ml-1">
                      <DatePicker
                        selected={endDate}
                        onChange={(val) => handleChangeEndDate(val)}
                        className="link-style"
                      />
                    </span>
                  </div>
                  <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash">
                    <thead>
                      <tr>
                        <th scope="col">Vessel Name</th>
                        <th scope="col">Monitoring periods in days</th>
                        <th scope="col">Budget for period</th>
                        <th scope="col">Spent incl. system fees</th>
                        <th scope="col">Next planned order</th>
                        {/* <th scope="col">Theor. inventory on board</th>
                        <th scope="col">Savings</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {vesselWiseStats.store?.map((element, i) => (
                        <tr key={"Store-" + i}>
                          <td>
                            <span
                              style={{ color: "#0056B3", cursor: "pointer" }}
                              onClick={() => handleModal(element?._id, "store")}
                            >
                              {element?.vesselName}
                            </span>
                          </td>
                          <td>{element?.monitoringDays}</td>
                          <td>{currencyFormatter(element?.budgetForPeriod)}</td>
                          <td>{currencyFormatter(element?.spendSystemFees)}</td>
                          <td>{element?.nextPlannedOrder}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 pr-0 pr-md-2 mt-1 mt-md-2">
              <div className="row bg-white mb-0 mb-md-2 mr-0 mr-md-1">
                <span className="chart-title text-center">Rate Conclusion</span>
              </div>
              {/* Bar Graph */}
              <div className="row">
                <div className="col-12 bg-white">
                  {storeBudget && <SingleColumnChart data={storeBudget} />}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-2">
              <div className="row bg-white mb-0 mb-md-2">
                <span className="chart-title">Daily Consumption</span>
              </div>

              <div className="row">
                <div className="col-12 bg-white ">
                  {storeConsumption && <DonutChart data={storeConsumption} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="btn-sty-title flex-fill mr-0">
          Fleet Overview Provision
        </h3>
        <div className="content-box-wrap dash-content-bx mb-2">
          <div className="row px-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-2">
              <div className="row">
                <div className="table-responsive" style={myCalendarstyle}>
                  <div className="date-range-picker d-flex align-items-center justify-content-center mb-2">
                    <span className="d-inline-block mr-1">
                      <DatePicker
                        selected={startDate}
                        onChange={(val) => handleChangeStartDate(val)}
                        className="link-style"
                      />
                    </span>
                    to
                    <span className="d-inline-block ml-1">
                      <DatePicker
                        selected={endDate}
                        onChange={(val) => handleChangeEndDate(val)}
                        className="link-style"
                      />
                    </span>
                  </div>
                  <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash">
                    <thead>
                      <tr>
                        <th scope="col">Vessel Name</th>
                        <th scope="col">Monitoring periods in days</th>
                        <th scope="col">Budget for period</th>
                        <th scope="col">Spent incl. system fees</th>
                        <th scope="col">Next planned order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vesselWiseStats.provision?.map((element, i) => (
                        <tr key={"Provision-" + i}>
                          <td>
                            <span
                              style={{ color: "#0056B3", cursor: "pointer" }}
                              onClick={() =>
                                handleModal(element._id, "provision")
                              }
                            >
                              {element?.vesselName}
                            </span>
                          </td>
                          <td>{element?.monitoringDays}</td>
                          <td>{currencyFormatter(element?.budgetForPeriod)}</td>
                          <td>{currencyFormatter(element?.spendSystemFees)}</td>
                          <td>{element?.nextPlannedOrder}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 pr-0 pr-md-2 mt-1 mt-md-2">
              <div className="row bg-white mb-0 mb-md-2 mr-0 mr-md-1">
                <span className="chart-title text-center">Rate Conclusion</span>
              </div>

              <div className="row mr-1">
                <div className="col-12 bg-white ">
                  {provisionBudget && (
                    <SingleColumnChart data={provisionBudget} />
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-3 mt-md-2">
              <div className="row bg-white mb-0 mb-md-2">
                <span className="chart-title">Daily Consumption</span>
              </div>

              <div className="row">
                <div className="col-12 bg-white ">
                  {provisionConsumption && (
                    <DonutChart data={provisionConsumption} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDashboard;
