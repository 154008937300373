import cloneDeep from "lodash/cloneDeep";
import InitialState from "./../../../Constants/initialState";
import * as Action from "./../Actions/action";

export default function OrderReducer(state = InitialState.quotation, action) {
  switch (action.type) {
    case Action.getQuotation:
      let s1 = cloneDeep(state);
      s1.quotationList = action.payload.list;
      s1.totalQuotation = action.payload.count;
      return s1;
    case Action.AppendQuotationList:
      var s2 = cloneDeep(state);
      var arr = s2.quotationList.slice();
      s2.quotationList = arr.concat(action.payload.list);
      return s2;
    case Action.getQuotationDetail:
      let s3 = cloneDeep(state);
      s3.quotationDetailList = action.payload.list;
      s3.basketId = action.payload.basketId;
      s3.orderDetail = action.payload.orderDetail;
      s3.adminCatalogs = action.payload.adminCatalogs;
      return s3;
    case Action.AppendQuotationOrderList:
      var s4 = cloneDeep(state);
      var arr = s4.quotationOrderList.slice();
      s4.quotationOrderList = arr.concat(action.payload.list);
      return s4;
    case Action.getQuotationOrder:
      let s5 = cloneDeep(state);
      s5.quotationOrderList = action.payload.list;
      s5.totalQuotation = action.payload.count;
      return s5;
    default:
      return state;
  }
}
