import React from 'react';
import { Alert } from "react-bootstrap";

export const AlertBox = ({ text, variant, className }) => {
    return (
        <>
            <Alert className={className} variant={variant}>
                {text}
            </Alert>
        </>
    )
}