import styled from "styled-components";

export const Wrapper = styled.div`
.card-bx-sty {padding: 15px 15px;background: #ebebeb;border: 1px solid #000;-webkit-box-shadow: 0 4px 4px rgba(0,0,0,.25);box-shadow: 0 4px 4px rgba(0,0,0,.25);border-radius: 10px;}
.ordCatg-wrapper .card-bx-sty.box-item-inn{margin-right:15px;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;padding: 2px !important;}
.ordCatg-wrapper .update-statics-wrap .card-bx-sty.box-item-inn {-webkit-box-flex: 1;-ms-flex: 1 1 25%;flex: 1 1 25%;min-width: 265px;padding: 4px 10px !important;} .ordCatg-wrapper .update-statics-wrap .card-bx-sty.box-item-inn {-webkit-box-flex: 1;-ms-flex: 1 1 25%;flex: 1 1 25%;min-width: max-content;padding: 0px 10px !important;}
.ordCatg-wrapper .card-bx-sty.box-item-inn:last-child{margin-right:0}
.card-bx-sty.box-item-inn {background: #EBEAE8;text-align: center;padding: 8px 20px !important;}
.ordCatg-dtl-btn h1{color:#F9F9F9!important;font-size:14px;font-weight:600}
.card-bx-sty.box-item-inn.ordCatg-dtl-btn{background:#94989E;border:2px solid #979797;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;max-width:180px;min-width:150px}
.card-bx-sty.table-card-wrap{background:#979797;padding:10px}
.custom-select-menu.custom-select2 .custom-select-wrap.small-select-sty .react-select__placeholder,.custom-select-menu .react-select__single-value{font-size:12px;color:#6F7276}
.custom-select-menu.custom-select2 .custom-select-wrap.small-select-sty .react-select__indicator.react-select__dropdown-indicator{border-top-color:#6F7276;border-width:5px}
.custom-select-menu.custom-select2 .custom-select-wrap.small-select-sty .react-select__option{font-size:12px}
.ordCatg-InnTable{margin-top:20px}
.ordCatg-InnTable table thead tr th{background:#979797;overflow:hidden;-webkit-box-shadow:inset 0 -2px 0 #4A4A4A,inset 0 2px 0 #4A4A4A;box-shadow:inset 0 -2px 0 #4A4A4A,inset 0 2px 0 #4A4A4A;font-weight:500;font-size:14px;color:#fff;text-align:left;vertical-align:middle;white-space: nowrap}
.ordCatg-InnTable table tbody:before{width:5px;height:5px;content:"";display:block}
.ordCatg-InnTable table tbody tr td{background:#979797;padding:12px;max-width:320px}
.ordCatg-InnTable table tbody tr:first-child td{-webkit-box-shadow:inset 0 1.5px 0 #4A4A4A;box-shadow:inset 0 1.5px 0 #4A4A4A}
.ordCatg-InnTable table tbody tr:last-child td{-webkit-box-shadow:inset 0 -1.5px 0 #4A4A4A;box-shadow:inset 0 -1.5px 0 #4A4A4A}
.ordCatg-InnTable table tbody tr:first-child td:first-child{-webkit-box-shadow:inset 1.5px 0 0 #4A4A4A,inset 0 1.5px 0 #4A4A4A;box-shadow:inset 1.5px 0 0 #4A4A4A,inset 0 1.5px 0 #4A4A4A;border-top-left-radius:10px}
.ordCatg-InnTable table tbody tr:first-child td:last-child{-webkit-box-shadow:inset -1.5px 0 0 #4A4A4A,inset 0 1.5px 0 #4A4A4A;box-shadow:inset -1.5px 0 0 #4A4A4A,inset 0 1.5px 0 #4A4A4A;border-top-right-radius:10px}
.ordCatg-InnTable table tbody tr:last-child td:first-child{-webkit-box-shadow:inset 1.5px 0 0 #4A4A4A,inset 0 -1.5px 0 #4A4A4A;box-shadow:inset 1.5px 0 0 #4A4A4A,inset 0 -1.5px 0 #4A4A4A;border-bottom-left-radius:10px}
.ordCatg-InnTable table tbody tr:last-child td:last-child{-webkit-box-shadow:inset -1.5px 0 0 #4A4A4A,inset 0 -1.5px 0 #4A4A4A;box-shadow:inset -1.5px 0 0 #4A4A4A,inset 0 -1.5px 0 #4A4A4A;border-bottom-right-radius:10px}
.ordCatg-InnTable table tbody tr td:first-child span{border-left:10px solid red}
.ordCatg-InnTable table tbody tr td:first-child{-webkit-box-shadow:inset 1.5px 0 0 #4A4A4A;box-shadow:inset 1.5px 0 0 #4A4A4A}
.ordCatg-InnTable table tbody tr td:last-child{-webkit-box-shadow:inset -1.5px 0 0 #4A4A4A;box-shadow:inset -1.5px 0 0 #4A4A4A}
.ordCatg-InnTable table thead tr th:first-child{border-top-left-radius:10px;border-bottom-left-radius:10px;-webkit-box-shadow:inset 0 -2px 0 #4A4A4A,inset 0 2px 0 #4A4A4A,inset 2px 0 0 #4A4A4A;box-shadow:inset 0 -2px 0 #4A4A4A,inset 0 2px 0 #4A4A4A,inset 2px 0 0 #4A4A4A}
.ordCatg-InnTable table thead tr th:last-child{border-top-right-radius:10px;border-bottom-right-radius:10px;-webkit-box-shadow:inset 0 -2px 0 #4A4A4A,inset 0 2px 0 #4A4A4A,inset -2px 0 0 #4A4A4A;box-shadow:inset 0 -2px 0 #4A4A4A,inset 0 2px 0 #4A4A4A,inset -2px 0 0 #4A4A4A;}
.bg-rounded.white,.bg-rounded.green{background:#FFF;border:none;-webkit-box-shadow:0 0 6px 0px #00000061;box-shadow:0 0 6px 0px #00000061;border-radius:5px;color:#4A4A4A;padding:2px 10px}
.bg-rounded.green{background:#C9FB90;font-weight:600;padding:6px 20px}
.ordCatg-InnTable table tbody tr td .td-wrap-sty{background:#EBEAE8;border:1px solid #4A4A4A;border-radius:0;display:block;font-size:12px;padding:4px 5px;color:#000;min-height:28px;text-align: center;overflow: hidden;-o-text-overflow: ellipsis;text-overflow: ellipsis;max-height: 44px;cursor: default;}
.ordCatg-InnTable table thead tr th,.ordCatg-InnTable table tbody tr td{border:none!important}
.ordCatg-InnTable table tbody tr td .btn.btn-green-round{background:#C9FB90;font-weight:500;padding:7px 30px 4px;-webkit-box-shadow:inset 3px 3px 4px rgba(0,0,0,0.5);box-shadow:inset 3px 3px 4px rgba(0,0,0,0.5);border:none}
.ordCatg-InnTable table tbody tr td .btn.btn-green-round:hover {background:#fff;}
.ordCatg-InnTable table tbody tr td .whi-round{background:#FFF;border:1px solid #4A4A4A;-webkit-box-shadow:0px 0px 4px rgb(0 0 0 / 42%);box-shadow:0px 0px 4px rgb(0 0 0 / 42%);border-radius:0;display:block;height:100%;min-height:28px;font-weight: 600;font-size: 14px;line-height: 28px;text-align: center;padding: 0 10px;width: 120px;margin: 0 auto;}
.td-btn-wrap{padding:1px}
// .ordCatg-InnTable table thead tr th:nth-child(1),.ordCatg-InnTable table thead tr th:nth-child(3),.ordCatg-InnTable table thead tr th:nth-child(4){min-width:200px}
// .ordCatg-InnTable table thead tr th:nth-child(2),.ordCatg-InnTable table thead tr th:nth-child(5),.ordCatg-InnTable table thead tr th:nth-child(6),.ordCatg-InnTable table thead tr th:nth-child(7),.ordCatg-InnTable table thead tr th:nth-child(8),.ordCatg-InnTable table thead tr th:nth-child(9){width:105px;min-width:105px}
.box-item-inn-wrap::-webkit-scrollbar{display:none}

/* Store CatgTable */
.ordCatg-InnTable.store-catgTable table tbody tr td:last-child {background: #979797;padding: 12px 12px 12px 10px;}
.ordCatg-InnTable.store-catgTable table tbody tr td:first-child span {border-left: none;}
.storecatg-dropMenu {max-width: 700px;width: 100%;}

/* Supplier RFQ Wrap Style */
.ordCatg-table-wrap.sup-rfq-wrap{background:#D3D3D3;border:2px solid #000;-webkit-box-sizing:border-box;box-sizing:border-box;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;padding:10px}
.card-bx-sty.table-card-wrap .btn-yellow{width:-webkit-max-content;width:-moz-max-content;width:max-content;-webkit-box-shadow:none;box-shadow:none;padding:4px 15px;font-weight:500}
.sup-rfq-wrap .ordCatg-InnTable table thead tr th,.ordCatg-InnTable table tbody tr td{-webkit-box-shadow:none!important;box-shadow:none!important;border-radius:0!important}
.sup-rfq-wrap .ordCatg-InnTable table tbody:before{content:none}
.sup-rfq-wrap .ordCatg-InnTable{margin-top:0;background:#979797;border:1px solid #4A4A4A;border-radius:5px;overflow:hidden}
// .ordCatg-InnTable table tbody tr td:first-child{width:8%}
.sup-Oview-list{font-weight:600;font-size:18px;color:#FFF;list-style:none;padding:0;background:#979797;border:2px solid #4A4A4A;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px}
.sup-Oview-list li{padding:10px;-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.sup-Oview-list li .btn-sm-rounded{margin-left:10px}
.supp-Oview-wrap .ordCatg-InnTable table thead tr th{background:transparent;-webkit-box-shadow:none!important;box-shadow:none!important}
.supp-Oview-wrap .ordCatg-InnTable table tbody:before{content:none}
.supp-Oview-wrap .ordCatg-InnTable table tbody tr td{background:transparent}
.supp-Oview-wrap .ordCatg-InnTable .table-outWrap{background:#979797;border:1px solid #4A4A4A;border-radius:10px;overflow:hidden;margin-top:5px}
.supp-Oview-wrapper .box-items-wrap{border:2px solid #000;-webkit-box-sizing:border-box;box-sizing:border-box;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;padding:15px;width:calc(100% + 4px);margin-left:-2px;margin-top:-2px}
.supp-Oview-wrapper .card-bx-sty{border:2px solid #000}
.supp-Oview-wrapper .ordCatg-InnTable table tbody tr td:first-child span{border-left:none}
.supp-Oview-wrapper .ordCatg-InnTable table tbody tr td{background:#979797;padding:12px}
.supp-Oview-wrapper .ordCatg-InnTable table tbody tr td:nth-last-of-type(3){text-align:center}
.form-group.cst-checkBx-sty{display:inline-block}
.cst-checkBx-sty.form-group input{padding:0;height:initial;width:initial;margin-bottom:0;display:none;cursor:pointer}
.cst-checkBx-sty.form-group label{position:relative;cursor:pointer}
.cst-checkBx-sty.form-group label:before{content:'';-webkit-appearance:none;background:#3C6B04;border:1px solid #4A4A4A;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:5px;padding:10px;display:inline-block;position:relative;vertical-align:middle;cursor:pointer}
.cst-checkBx-sty.form-group input:checked + label:before{background:#C8061D}
.custom-select2.text-bx-wrap .sc-AxhUy.jfuTGD{height:27px}
.custom-select2.text-bx-wrap .sc-AxhUy.jfuTGD button{padding:4px 20px;text-align:left;font-size:12px;color:#6F7276;font-weight:600;font-family:'Montserrat'}
.custom-select2.text-bx-wrap .sc-AxhUy.jfuTGD .dropdown-toggle::after{float:right;margin-top:7px;font-size:17px}
.jfuTGD .custom-data .dropdown-menu.show-left{margin:0;padding:0}
.jfuTGD .custom-data .dropdown-menu.filter-drop .input-tag.custom-select{height:auto;max-height:100%;margin:0;padding:0;border:none}
.jfuTGD .custom-data .dropdown-menu.filter-drop .input-tag.custom-select .starting-page-box{max-height:244px;padding:5px 10px;border-radius:0}
.ordCatg-InnTable table tbody tr td:first-child {width: auto;}
.ordCatg-InnTable table thead tr th:nth-child(2),
.ordCatg-InnTable table thead tr th:nth-child(5),
.ordCatg-InnTable table thead tr th:nth-child(6),
.ordCatg-InnTable table thead tr th:nth-child(7),
.ordCatg-InnTable table thead tr th:nth-child(8),
.ordCatg-InnTable table thead tr th:nth-child(9) {width: auto;min-width: auto;}
.ordCatg-InnTable table thead tr th:nth-child(1),
.ordCatg-InnTable table thead tr th:nth-child(3),
.ordCatg-InnTable table thead tr th:nth-child(4),
.ordCatg-InnTable table thead tr th:last-child {min-width: auto;}
.ordCatg-InnTable table tbody tr td {min-width:80px;padding: 8px 3px 0 !important;}
.ordCatg-InnTable table thead tr th {min-width:80px;padding: 4px 3px!important;}
.ordCatg-InnTable table tbody tr td:first-child,.ordCatg-InnTable table thead tr th:first-child {padding-left: 15px !important;}
.ordCatg-InnTable table tbody tr td:last-child,,.ordCatg-InnTable table thead tr th:last-child {padding-right: 15px !important;}
.ordCatg-InnTable table thead tr th:nth-child(5),.ordCatg-InnTable table thead tr th:nth-child(10),.ordCatg-InnTable table thead tr th:nth-child(6) {width: 120px !important;}
.ordCatg-InnTable table thead tr th:nth-child(5) .bg-rounded.white,
.ordCatg-InnTable table thead tr th:nth-child(10) .bg-rounded.white,
.ordCatg-InnTable table tbody tr td:nth-child(10) span,
.ordCatg-InnTable table thead tr th:nth-child(6) .bg-rounded.white 
{width: 100%; display: inline-block}
.ordCatg-InnTable table tbody tr td:nth-child(10) .btn.tb-btn-rounded.orng{background:#e6a935;border-color:#e6a935;padding:3px 10px;font-size:13px;border-radius:5px}
.ordCatg-InnTable table tbody tr td:nth-child(10) .btn.tb-btn-rounded.orng:hover{background:#fff;color:#e6a935}
.prov-upgrade-info .table thead th{vertical-align:middle;border-bottom:none;border-top:none;border-left:1px solid #03aec5;font-size:14px;color:#fff;background:#0399ac;font-weight:600;padding-bottom:0;padding-top:2px !important}
.prov-upgrade-info .table tbody td{border-top:none;font-size:14px;border-left:1px solid #03aec5;background:#0399ac;color:#fff;padding:1px 12px;white-space:nowrap}
.prov-upgrade-info table{background-color:#0399ac}
.prov-upgrade-info table.nutri-table-wrap{background-color:#7eb43e}
.border-left-none,.prov-upgrade-info .table tbody td:nth-child(2),.prov-upgrade-info table:first-child tbody td:first-child,.prov-upgrade-info table:first-child thead th:first-child{border-left:0!important}
.prov-upgrade-info .table.nutri-table-wrap thead th,.prov-upgrade-info .table.nutri-table-wrap tbody td{background:#7eb43e;border-left:1px solid #95d14d}
.prov-upgrade-info .table tbody tr:last-child td{border-left:1px solid #fdc04d;background:#E6A935;font-size:16px;font-weight:600;padding:2px 12px}
.prov-upgrade-info table{width:33.33%}
.prov-upgrade-info table .arrow-control{opacity:.5}
.prov-upgrade-info .table thead th:nth-of-type(2){width:130px}
.prov-upgrade-info {min-width: 1095px;}
.figue_value_sty{display:inline-block;min-width:50px;text-align:left}
.prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap{min-width:230px}
.prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width:400px}
.arrow-down .fa-arrow-up:before{content:"\f063"}
// .update-statics-wrap .card-bx-sty.box-item-inn h1{color:#E6A935}
.stat-value-sty{color:#0399ac!important;font-weight:600}
.pagin-select-menu .dropdown-toggle {background: transparent;border: none;height: 100%;}

// RFQ Details Style
.rfq-details-items {
    padding: 0 15px 
}
.rfq-durations.rfq-details-items {
    margin-right: 20px
}
.rfq-details-items h2 {
    font-size: 18px;
    font-weight: 400;
}
.rfq-details-items .inner-content h4 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    max-width: max-content;
}

.rfq-details-items .inner-content h4 span {
    font-size: 14px;
}
.rfq-details-items .btn.btn-theme-blue {
    font-size: 13px;
    max-width: 200px;
    padding: 7px 30px;
}
.rfq-rating.rfq-details-items .inner-content h4 {
    margin-top: 25px;
}
.star-checkbox {
    display: none;
}
.rating-checks label {
    color: #fff;
    opacity: 0.5;
    margin: 0;
    position: sticky;
}
.star-checkbox:checked + label {
    opacity: 1
}
.rfq-rating.rfq-details-items .rounded-tag{
    font-size: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #559709;
    border-radius: 40px;
    color: #fff;
    padding: 4px 11px;
}
.rfq-rating.rfq-details-items .rounded-tag.orng-clr {
    background: #E6A935;
}
.rfq-rating li {
    padding: 0 2px;
}
.rfq-rating .rounded-tag.progress-bar-wrap {
    width: 106px;
    background: #00539CFF!important;
    box-shadow: 0 0 0 1px #b7b7b7;
    padding: 0
}
.rfq-rating .rounded-tag.progress-bar-wrap .rounded-tag{
   width: 85%
}
.rfq-details-items p{
    font-size: 13px;
    text-align: right;
}
.contact-info.rfq-details-items ul li {
    font-size: 14px;
    color: #4c4c4c;
    margin-bottom: 5px
}
.contact-info.rfq-details-items ul li:last-child {
    margin-bottom: 0
}
.contact-info.rfq-details-items ul li span {
    display: inline-block;
    margin-right: 10px
}
.total-value-count {
    font-size: 18px;
    text-align: right;
}
.ordCatg-InnTable table tbody tr td .td-wrap-sty,.ordCatg-InnTable table tbody tr td .whi-round {min-height:30px;line-height:12px}
.ordCatg-InnTable table tbody tr td {padding: 0px 0px 0 !important;} 
@media only screen and (max-width:1600px){
    .ordCatg-dtl-btn h1 {font-size: 11px;}
    .card-bx-sty.box-item-inn.ordCatg-dtl-btn .btn.btn-yellow {padding: 4px 15px;font-size: 12px;}
    .ordCatg-InnTable table thead tr th,.ordCatg-InnTable table tbody tr td .whi-round {font-size: 12px;}
    .bg-rounded.white,.bg-rounded.green {padding: 1px 10px;}
    .ordCatg-InnTable table tbody tr td .td-wrap-sty {font-size: 10px;max-height: 20px}
    .ordCatg-InnTable table tbody tr td .btn.btn-green-round {font-size: 12px;padding: 4px 30px 3px;}
    .ordCatg-InnTable table tbody tr td .td-wrap-sty,.ordCatg-InnTable table tbody tr td .whi-round {min-height: 22px;}
    .ordCatg-InnTable table thead tr th {padding: 16px 10px;}
    .ordCatg-InnTable table tbody tr td .whi-round {width: 90px;padding: 2px 10px;line-height: normal;}
    .ordCatg-InnTable table thead tr th:nth-child(5),.ordCatg-InnTable table thead tr th:nth-child(10),.ordCatg-InnTable table thead tr th:nth-child(6) {width: 90px !important;}
    .ordCatg-InnTable table tbody tr td:nth-child(10) .btn.tb-btn-rounded.orng {padding: 1px 10px;font-size: 11px;}
    .prov-upgrade-info .table thead th{font-size: 12px;}
    .prov-upgrade-info .table tbody td {font-size: 10px;}
    .prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap {min-width: 180px;}
    .prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width: 360px;}
    .prov-upgrade-info .table tbody tr:last-child td {font-size: 13px;}
    .figue_value_sty {min-width: 30px;}
    .prov-upgrade-info .table thead th:nth-of-type(2) {width: 95px;}
    .ordCatg-InnTable table tbody tr td:first-child {padding-left: 10px !important;}
    .ordCatg-InnTable table tbody tr td:last-child {padding-right: 10px !important;}
    // .ordCatg-InnTable table tbody tr td {padding: 5px 3px 0 !important;} 
}
@media only screen and (max-width:1367px){
    .prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap {min-width: 170px;}
    .prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width: 340px;}
    .ordCatg-InnTable table tbody tr td {max-width: 220px;}
}
@media only screen and (max-width:1360px){
    .ordCatg-InnTable table tbody tr td {max-width: 190px;}
}
@media only screen and (max-width:1200px){
    .prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap {min-width: 160px;}
    .prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width: 225px;}
}
@media only screen and (max-width:992px){
	.pageWrapper{padding:20px 10px;}
    .pageContainer{padding: 15px;margin: 0 0 20px;}
    .fil-wrap-ctn {margin-bottom: 10px;width: 100%}
    .prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap {max-width: 25%;}
    .prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width: 205px;max-width: 48%;margin-right: 0 !important;}
    .prov-cat-filters .fil-btn-wrap-ctn {width: 100%}
}
@media only screen and (max-width:768px){
    .storecatg-dropMenu {width: 100%;}
    .sup-Oview-list {font-size: 16px;}
    .sup-Oview-list li .btn-sm-rounded {font-size: 14px !important;font-weight: 500 !important;}
    .ordCatg-InnTable table tbody tr td .td-wrap-sty {max-width: 220px !important}
    .prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap { min-width: 140px;}
    .prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3){min-width: 180px;}
}
@media only screen and (max-width:576px){
    .ordCatg-table-wrap .custom-select-menu.text-bx-wrap {-webkit-box-ordinal-group: unset;-ms-flex-order: unset;order: unset;min-width: 100%;max-width: 100%;margin-bottom: 10px;margin-right: 0 !important;}
    .card-bx-sty.box-item-inn h1 {font-size: 12px;}
    .card-bx-sty.box-item-inn.ordCatg-dtl-btn {min-width: 110px;padding: 6px 10px;}
    .card-bx-sty.box-item-inn.ordCatg-dtl-btn .btn.btn-yellow {padding: 4px 11px 2px;font-size: 12px;}
    .box-item-inn-wrap {width: 100%;margin-bottom: 10px;}
    .pageContainer {padding: 30px 15px !important;}
    .prov-cat-filters .fil-wrap-ctn .custom-select-menu.text-bx-wrap,.prov-cat-filters .custom-select-menu.text-bx-wrap:nth-of-type(3) {max-width: 100%;margin-bottom: 3px !important;}
    .fil-btn-wrap-ctn .btn-yellow {padding: 5px 12px;font-weight: 500;font-size: 12px;}
}
`