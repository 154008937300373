import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../Common/Components/CommonWrapper';
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import Select from 'react-select';
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import DashboardNavbar from './../../Common/Components/DashboardNavbar';
import Swal from 'sweetalert2';
import { getRoutePathBykey } from '../../../Constants/routesConfig';
import { createCompany, updateCompany, getCompanyDetail } from "../../Common/ApiCalls/company";
import { getAllCountry } from "../../Common/ApiCalls/countryList";
import { getAllPort } from "../../Common/ApiCalls/portList";

const initialState = {
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    domain: '',
    countryOptions: [],
    portOptions: [],
    selectedCountry: null,
    countryId: '',
    countryName: '',
    selectedPort: null,
    portId: '',
    portName: '',
    errorMessage: '',
    errorType: '',
    isHitApi: false,
    logo: null,
    backgroundImage: null,
    logoURL: '',
    backgroundImageURL: '',
    sidebarBackgroundColor: '#5E5A5A',
    isEditing: false,
};

function CreateCompany(props) {
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed');
            } else {
                let data = { ...state };
                props.getAllCountry();
                const { id } = props.match.params;
                if (id) {
                    props.getCompanyDetail(id);
                    data.isEditing = true;
                }
                data.isHitApi = true;
                setState(data);
            }
        }

        if ((props.apiCallStatus.apiCallFor === "getCompanyDetail") &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed) {
            const { detail } = props.companyReducer;
            setState({
                ...state,
                ...detail,
                selectedCountry: {
                    value: detail?.country?.id,
                    label: detail?.country?.name,
                },
                selectedPort: {
                    value: detail?.port?.id,
                    label: detail?.port?.name,
                },
                logoURL: detail?.configuration?.logo,
                backgroundImageURL: detail?.configuration?.mainBackgroundImage,
                sidebarBackgroundColor: detail?.configuration?.sidebarBackgroundColor,
            });
            props.ClearApiByNameAction("getCompanyDetail");
        }

        if (props.apiCallStatus.apiCallFor === "getAllCountry" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            const countryOptions = props.countryList.map(({ _id, name, countryCode }) => ({
                value: _id,
                label: name,
                countryCode,
            }));
            setState({
                ...state,
                countryOptions,
                isHitApi: true
            });
            props.ApiClearAction();
        }

        if (props.apiCallStatus.apiCallFor === "getAllPort" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            const portOptions = props.portList.map(({ _id, port }) => ({
                value: _id,
                label: port
            }));
            setState({
                ...state,
                portOptions,
                isHitApi: true
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }

        if ((props.apiCallStatus.apiCallFor === "createCompany") &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed) {
            setState(initialState);
            props.ApiClearAction();
            Swal.fire("Success", "Company has been created successfully!", "success");
            if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('companyList'), props.roles)) {
                props.history.push(getDashboardLink());
            } else {
                props.history.push('/company-listing');
            }
        }


        if ((props.apiCallStatus.apiCallFor === "updateCompany") &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed) {
            setState(initialState);
            props.ApiClearAction();
            Swal.fire("Success", "Company has been updated successfully!", "success");
            if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('companyList'), props.roles)) {
                props.history.push(getDashboardLink());
            } else {
                props.history.push('/company-listing');
            }
        }

    }, [props.apiCallStatus, state.isHitApi]);

    const handleFormChange = (e, name) => {
        if (["logo", "backgroundImage"].includes(name)) {
            if (e.target.files.length > 0) {
                const obj = { ...state };
                const name = e.target.name;
                obj[name] = e.target.files[0];
                setState(obj);
            }
        } else if (["country", "port"].includes(name)) {
            const obj = { ...state };
            const { label, value } = e;
            if (name === "country") {
                obj["selectedCountry"] = e;
                obj["countryId"] = value;
                obj["countryName"] = label;
                const query = `countryCode=${e.countryCode}`;
                props.getAllPort(query);
            } else if (name === "port") {
                obj["selectedPort"] = e;
                obj["portId"] = value;
                obj["portName"] = label;
            }
            setState(obj);
        } else {
            const { name, value } = e.target;
            setState({
                ...state,
                [name]: value
            });
        }
    };

    const createHandler = () => {
        const {
            name, email, phoneNumber, address, domain, countryId, countryName, portId, portName,
            backgroundImage, logo, sidebarBackgroundColor
        } = state;

        setState({
            ...state,
            errorMessage: "",
            errorType: "",
        });

        props.createCompany({
            name,
            email,
            phoneNumber,
            address,
            domain,
            country: {
                id: countryId,
                name: countryName,
            },
            port: {
                id: portId,
                name: portName,
            },
            configuration: {
                sidebarBackgroundColor,
            },
            logo,
            backgroundImage,
        });
    };

    const updateHandler = () => {
        const {
            name, email, phoneNumber, address, domain, countryId, countryName, portId, portName,
            backgroundImage, logo, sidebarBackgroundColor, isEditing, logoURL, backgroundImageURL
        } = state;

        setState({
            ...state,
            errorMessage: "",
            errorType: "",
        });

        const payload = {
            name,
            email,
            phoneNumber,
            address,
            domain,
            logo,
            backgroundImage,
        };

        if (state.countryId) {
            payload.country = {
                id: countryId,
                name: countryName,
            };
        }

        if (state.portId) {
            payload.port = {
                id: portId,
                name: portName,
            };
        }

        if (state.sidebarBackgroundColor) {
            payload.configuration = {
                sidebarBackgroundColor,
            };
        }

        if (isEditing) {
            payload.configuration.logo = logoURL;
            payload.configuration.mainBackgroundImage = backgroundImageURL;
        }

        props.updateCompany(props.match.params.id, payload);
    };

    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];

    const { isEditing } = state;
    const title = isEditing ? "Edit Company" : "Create Company";

    return (
        <Wrapper>
            <DashboardNavbar title={title} navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox mt-4">
                <CompanyForm
                    {...state}
                    history={props.history}
                    handleFormChange={handleFormChange}
                    createHandler={createHandler}
                    updateHandler={updateHandler}
                    apiCallStatus={props.apiCallStatus}
                />
            </div>
        </Wrapper>
    );
}

function CompanyForm(props) {
    const {
        isEditing, logoURL, backgroundImageURL, name, email, phoneNumber, address,
        domain, selectedCountry, selectedPort, countryOptions, portOptions, sidebarBackgroundColor,
        logo, backgroundImage
    } = props;

    const validateForm = () => {
        const form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (isEditing) {
                props.updateHandler();
            } else {
                props.createHandler();
            }
        }
    };

    const ImagefieldsCreateForm = () => (
        <div className="row">
            <div className="col-sm-6">
                <div className="form-group">
                    <div className="form-inputFile-wrap d-flex align-items-center justify-content-between">
                        <div className="form-input-container">
                            <input
                                type="file"
                                id="logo"
                                name="logo"
                                className="form-control"
                                accept="image/jpg, image/jpeg, image/png, image/svg"
                                onChange={(e) => {
                                    props.handleFormChange(e, "logo");
                                }}
                                placeholder="Company Logo"
                            />
                            <label htmlFor="logo" className="d-flex align-items-center">
                                <span className="text-truncate">{logo?.name || "Logo"}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group">
                    <div className="form-inputFile-wrap d-flex align-items-center justify-content-between">
                        <div className="form-input-container">
                            <input
                                type="file"
                                id="backgroundImage"
                                name="backgroundImage"
                                className="form-control"
                                accept="image/jpg, image/jpeg, image/png, image/svg"
                                onChange={(e) => {
                                    props.handleFormChange(e, "backgroundImage");
                                }}
                                placeholder="Main Background Image"
                            />
                            <label htmlFor="backgroundImage" className="d-flex align-items-center">
                                <span className="text-truncate">{backgroundImage?.name || "Main Background Image"}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const ImagefieldsEditForm = () => (
        <>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <div className="form-inputFile-wrap d-flex align-items-center justify-content-between">
                            <div className="form-input-container">
                                <input
                                    type="file"
                                    id="logo"
                                    name="logo"
                                    className="form-control"
                                    accept="image/jpg, image/jpeg, image/png, image/svg"
                                    onChange={(e) => {
                                        props.handleFormChange(e, "logo");
                                    }}
                                    placeholder="Company Logo"
                                />
                                <label htmlFor="logo" className="d-flex align-items-center">
                                    <span className="text-truncate">{logo?.name || "Logo"}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <img src={logoURL} alt="Logo" width={100} height={100} loading="lazy" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <div className="form-inputFile-wrap d-flex align-items-center justify-content-between">
                            <div className="form-input-container">
                                <input
                                    type="file"
                                    id="backgroundImage"
                                    name="backgroundImage"
                                    className="form-control"
                                    accept="image/jpg, image/jpeg, image/png, image/svg"
                                    onChange={(e) => {
                                        props.handleFormChange(e, "backgroundImage");
                                    }}
                                    placeholder="Main Background Image"
                                />
                                <label htmlFor="backgroundImage" className="d-flex align-items-center">
                                    <span className="text-truncate">{backgroundImage?.name || "Main Background Image"}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <img src={backgroundImageURL} alt="Logo" width={100} height={100} loading="lazy" />
                    </div>
                </div>
            </div>
        </>
    );

    const formSubmitted = ["createCompany", "updateCompany"].includes(props.apiCallStatus.apiCallFor);
    const apiStatus = !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed;
    const companyFormSubmitted = formSubmitted && apiStatus;

    return (
        <form className="needs-validation floating-form" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Name"
                            value={name} required />
                        <label className="foating-labels-sty">Name</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="email" name="email" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Email"
                            value={email} />
                        <label className="foating-labels-sty">Email</label>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="phoneNumber" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Phone Number"
                            value={phoneNumber} />
                        <label className="foating-labels-sty">Phone Number</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <textarea type="text" name="address" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Address"
                            value={address}></textarea>
                        <label className="foating-labels-sty">Address</label>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" name="domain" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="domain"
                            value={domain} required />
                        <label className="foating-labels-sty">Domain</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                value={selectedCountry}
                                onChange={(e) => { props.handleFormChange(e, "country"); }}
                                options={countryOptions}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Port"
                                value={selectedPort}
                                onChange={(e) => { props.handleFormChange(e, "port"); }}
                                options={portOptions}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isEditing ? <ImagefieldsEditForm /> : <ImagefieldsCreateForm />}

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input
                            type="color"
                            id="sidebarBackgroundColor"
                            name="sidebarBackgroundColor"
                            className="form-control"
                            onChange={(e) => { props.handleFormChange(e, "sidebarBackgroundColor"); }}
                            placeholder="Sidebar Background Color"
                            value={sidebarBackgroundColor}
                        />
                        <label className="foating-labels-sty">Sidebar Background Color</label>
                    </div>
                </div>
            </div>

            {formSubmitted && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}

            {props.errorMessage !== "" && props.errorType !== "" ?
                <div className="alert alert-danger">
                    {props.errorMessage}
                </div>
                : ""}

            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {companyFormSubmitted ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="btn btn themeReguler-btn bg-sky-blue"
                        disabled={companyFormSubmitted}
                    >
                        {isEditing ? "Update" : "Submit"}
                    </button>
                </div>
            </div>
        </form>
    );
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    companyReducer: state.companyReducer,
    countryList: state.commonReducer.countryList,
    portList: state.commonReducer.portList,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    createCompany: (data) => dispatch(createCompany(data)),
    updateCompany: (id, data) => dispatch(updateCompany(id, data)),
    getCompanyDetail: (id) => dispatch(getCompanyDetail(id)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getAllCountry: () => dispatch(getAllCountry()),
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateCompany);
