import React, { Component } from "react";
import { SupplierWrapper } from "./supplierWrapper";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { supplierListing } from "../../../Supplier/ApiCalls/supplier";
import { getSupplierSelectionList } from "../../ApiCalls/supplier";
import { getOrderDetail, updateOrderType } from "../../ApiCalls/provisionOrder";
import { connect } from "react-redux";
import { ProfileItem } from "./profileItem";
import Swal from "sweetalert2";
import { getRoutePathBykey } from "../../../../Constants/routesConfig";
import { isAllowPermission } from "../../../../Services/common";

class Selection extends Component {
  state = {
    selectedPortOption: null,
    selectedSortOption: null,
    portOptions: [
      { value: "singapore", label: "SingaPore" },
      { value: "china", label: "China" },
      { value: "russia", label: "Russia" },
    ],
    sortByOptions: [
      { value: "bestRate", label: "Best Rate" },
      { value: "fastest", label: "Fastes" },
      { value: "lowPrice", label: "Low Price" },
    ],
    selectedItem: "",
    selectionType: "",
    activePage: 0,
    isAllowRfq: true,
    isAllowOrder: true,
  };
  componentDidMount() {
    this.props.getSupplierSelectionList(this.props.match.params.id);
    this.props.getOrderDetail(this.props.match.params.id);
    if (
      this.props.isSubAdmin &&
      this.props.roles &&
      this.props.roles.length > 0
    ) {
      let path = getRoutePathBykey("SendRfq");
      let rfq = path.replace(":type", "rfq");
      let order = path.replace(":type", "order");
      let isAllowRfq = isAllowPermission(rfq, this.props.roles);
      let isAllowOrder = isAllowPermission(order, this.props.roles);
      this.setState({
        isAllowRfq,
        isAllowOrder,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.apiCallStatus.isStarted.indexOf("updateOrderType") !== -1 &&
      this.props.apiCallStatus.isStarted.indexOf("updateOrderType") === -1 &&
      this.props.apiCallStatus.isCompleted &&
      !this.props.apiCallStatus.isFailed
    ) {
      this.props.history.push(
        "/send/" +
          this.state.selectionType +
          "/" +
          this.state.selectedItem +
          "/" +
          this.props.match.params.id
      );
    }
    if (
      prevProps.apiCallStatus.isStarted.indexOf("getOrderDetail") !== -1 &&
      this.props.apiCallStatus.isStarted.indexOf("getOrderDetail") === -1 &&
      this.props.apiCallStatus.isCompleted &&
      !this.props.apiCallStatus.isFailed &&
      this.props.currentOrder &&
      Object.size(this.props.currentOrder) > 0
    ) {
      this.setState({
        selectedItem: this.props.currentOrder.supplierId,
        selectionType:
          this.props.currentOrder.isOrder === false
            ? ""
            : this.props.currentOrder.isOrder
            ? "order"
            : "rfq",
      });
    }
  }
  handlePortChange = (selectedPortOption) => {
    this.setState({ selectedPortOption });
  };
  handleSortChange = (selectedSortOption) => {
    this.setState({ selectedSortOption });
  };
  handleItemSelect = (selectedItem, selectionType) => {
    if (
      this.state.selectedItem === selectedItem &&
      this.state.selectionType === selectionType
    ) {
      this.setState({
        selectedItem: "",
        selectionType: "",
      });
    } else {
      this.setState({
        selectedItem,
        selectionType,
      });
    }
  };
  sendOrder = () => {
    if (this.state.selectionType === "") {
      Swal.fire(
        "Error",
        "Pleas select supplier before sending the order",
        "error"
      );
    } else {
      this.props.updateOrderType({
        orderId: this.props.match.params.id,
        supplierId: this.state.selectedItem,
        isOrder: this.state.selectionType === "order",
        redirectionPath: "/supplier-selection/",
      });
      this.props.history.push(
        "/send/" +
          this.state.selectionType +
          "/" +
          this.state.selectedItem +
          "/" +
          this.props.match.params.id
      );
    }
  };
  goBack = () => {
    if (this.props.currentOrder && Object.size(this.props.currentOrder) > 0) {
      let url = "/provision-catalogue/" + this.props.match.params.id;
      if (this.props.currentOrder.orderProvisionType === "store") {
        url = "/store-catalogue/" + this.props.match.params.id;
      }
      this.props.history.push(url);
    } else {
      this.props.history.goBack();
    }
  };
  render() {
    return (
      <SupplierWrapper
        page="suppl-select-wrapper"
        handleSortChange={this.handleSortChange}
        selectedSortOption={this.state.selectedSortOption}
        portOptions={this.state.portOptions}
        sortByOptions={this.state.sortByOptions}
        sendOrder={this.sendOrder}
        selectionType={this.state.selectionType}
        port={this.props.port}
        isAllowRfq={this.state.isAllowRfq}
        isAllowOrder={this.state.isAllowOrder}
        goBack={this.goBack}
      >
        {this.props.apiCallStatus.isStarted.indexOf(
          "getSupplierSelectionList"
        ) !== -1 ? (
          <div className="loader-img text-center w-100">
            <img
              style={{ width: "46px" }}
              src={require("../../../../assets/images/Spinner-1s-200px.gif")}
              alt=""
            />
          </div>
        ) : this.props.supplierSelectionList.length === 0 ? (
          <div>No Data Found</div>
        ) : (
          this.props.supplierSelectionList.map((item, index) => {
            return (
              <ProfileItem
                {...item}
                currentId={this.props.match.params.id}
                key={"ssl-" + index}
                handleItemSelect={this.handleItemSelect}
                selectedItem={this.state.selectedItem}
                selectionType={this.state.selectionType}
                isAllowRfq={this.state.isAllowRfq}
                isAllowOrder={this.state.isAllowOrder}
                userType={this.props.userType}
                isSubAdmin={this.props.isSubAdmin}
                roles={this.props.roles}
              />
            );
          })
        )}
      </SupplierWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  roles: state.authReducer.roles,
  userType: state.authReducer.userType,
  subType: state.authReducer.subType,
  isSubAdmin: state.authReducer.isSubAdmin,
  supplierSelectionList: state.orderReducer.supplierSelectionList,
  port: state.orderReducer.port,
  currentOrder: state.orderReducer.currentOrder,
  userId: state.authReducer.userId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSupplierSelectionList: (orderId) =>
    dispatch(getSupplierSelectionList(orderId)),
  supplierListing: (offset, type) => dispatch(supplierListing(offset, type)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId)),
  updateOrderType: (data) => dispatch(updateOrderType(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Selection);
