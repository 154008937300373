import React from "react";

export function OrderDetailModal(props) {
  // console.log(props.orderDetail);
  // let orderDetails = props.orderDetail;
  // let orderArray = [];
  // for (let index = 0; index < orderDetails.length; index++) {
  //     const element = orderDetails[index];
  //     let orderType = element.orderType;
  //     if (orderType) {
  //     }
  // }
  // const calculateOrderedProduct = (category) => {
  //     let sum = 0;
  //     let keys = Object.keys(props.orderDetail)
  //     for (let index = 0; index < keys.length; index++) {
  //         const element = keys[index];
  //         if (props.orderDetail[element].category === category && props.orderDetail[element].order !== '') {
  //             sum += parseFloat(props.orderDetail[element].order)
  //         }
  //     }
  //     return sum;
  // }
  let catKey = Object.keys(props.totalUsedPoints.catOrderDetail);
  return (
    <div
      className="modal fade theme-Roundedmodal "
      id="orderDetailModal"
      tabIndex="-1"
      aria-labelledby="orderDetailModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="modal-card d-flex align-items-center">
              <label>Order details:</label>
            </div>
            <div className="modal-card d-flex align-items-center">
              <h1 className="modalCard-text d-flex align-items-center w-100 m-0">
                Remaining Budget:{" "}
                <span className="modalCard-value flex-fill text-center">
                  {props.totalUsedPoints.total} / {props.deptBudget}
                </span>
                <span className="font-weight-bold">
                  {100 - props.totalUsedPoints.percentage}%
                </span>
              </h1>
            </div>
            <div className="modalTable-wrap table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Products</th>
                    <th className="text-center">Ordered</th>
                    <th className="text-center">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {catKey.length > 0 ? (
                    catKey.map((item, index) => {
                      return (
                        <tr key={"catKey-" + index}>
                          <td>
                            {props.totalUsedPoints.catOrderDetail[item].name}
                          </td>
                          <td className="text-right">
                            {props.totalUsedPoints.catOrderDetail[item].count}
                          </td>
                          <td className="text-center">
                            {props.totalUsedPoints.catOrderDetail[
                              item
                            ].totalPrice.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="40" className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-btn-wrap d-flex justify-content-end">
              <button
                type="button"
                className="close footer-close-btn w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
