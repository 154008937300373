import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetSupplierAction,
  AppendSupplierAction,
  GetSupplierDetailAction,
  SupplierComparisonListAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { get, set } from 'idb-keyval';

const getSupplierComparisonKey = (supplierIdExists, payload) => {
  let key = "";

  const { isProvision, isStore, isProvisionAdditional } = payload;

  if (isProvision) key = "supplierComparisonProvision";
  else if (isStore) key = "supplierComparisonStore";
  else if (isProvisionAdditional) key = "supplierComparisonProvisionAdditional";
  else key = "supplierComparisonStoreAdditional";

  if (supplierIdExists) {
    key += "Individual";
  }

  return key;
};

export function createSupplier(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "createSupplier" }));
    var formData = new FormData();
    formData.append("userId", data.userId);
    formData.append("type", "supplier");
    formData.append("subType", "supplier");
    formData.append("name", data.name);
    formData.append("title", data.title);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("description", data.description);
    formData.append("companyName", data.companyName);
    formData.append("websiteUrl", data.websiteUrl);
    formData.append("countryId", data.countryId);
    formData.append("supplierPorts", JSON.stringify(data.supplierPorts));
    if (data.logo.length > 0) {
      formData.append("logo", data.logo[0]);
    }
    if (data.images.length > 0) {
      for (let index = 0; index < data.images.length; index++) {
        const element = data.images[index];
        formData.append("images", element);
      }
    }
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/users",
      formData,
      true
    );
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "createSupplier",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "createSupplier",
          message: myJson.message,
        })
      );
    }
  };
}
export function supplierListing(offset, type, filterQuery, limit) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendSupplierList" })
      );
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "supplierListing" }));
    }
    let limitList = 20;
    if (limit === 0) {
      limitList = limit;
    }
    let url =
      Constant.apiURl + "/users/" + offset + `/${limitList}?type=${type}`;
    if (filterQuery !== undefined && filterQuery !== "") {
      url = url + `&` + filterQuery;
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendSupplierAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendSupplierList",
            message: "",
          })
        );
      } else {
        dispatch(GetSupplierAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "supplierListing",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendSupplierList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "supplierListing",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function getSupplierDetail(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getSupplierDetail" }));
    let myJson = await FETCH("GET", Constant.apiURl + "/users/" + id);
    if (myJson && myJson.code === 201) {
      let logo = [];
      logo.push(myJson.data.logo);
      dispatch(
        GetSupplierDetailAction({
          name: myJson.data.name,
          email: myJson.data.email,
          password: myJson.data.password,
          title: myJson.data.title,
          description: myJson.data.description,
          supplierPorts: myJson.data.supplierPorts,
          logo: logo,
          companyName: myJson.data.companyName,
          websiteUrl: myJson.data.websiteUrl,
          images: myJson.data.images,
          id: myJson.data.id,
          ratingAvg: myJson.data.ratingAvg,
        })
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getSupplierDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getSupplierDetail",
          message: myJson.message,
        })
      );
    }
  };
}
export function editSupplier(id, data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "editSupplier" }));
    var formData = new FormData();
    formData.append("userId", data.userId);
    formData.append("type", "supplier");
    formData.append("subType", "supplier");
    formData.append("name", data.name);
    formData.append("title", data.title);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("supplierPorts", JSON.stringify(data.supplierPorts));
    formData.append("description", data.description);
    formData.append("companyName", data.companyName);
    formData.append("websiteUrl", data.websiteUrl);
    if (data.logo.length > 0) {
      formData.append("logo", data.logo[0]);
    }
    if (data.images.length > 0) {
      for (let index = 0; index < data.images.length; index++) {
        const element = data.images[index];
        formData.append("images", element);
      }
    }
    let myJson = await FETCH(
      "PATCH",
      Constant.apiURl + "/users/" + id,
      formData,
      true
    );
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "editSupplier",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "editSupplier",
          message: myJson.message,
        })
      );
    }
  };
}
export function deleteSupplier(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteSupplier" }));
    let myJson = await FETCH("DELETE", Constant.apiURl + "/users/" + id);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deleteSupplier",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deleteSupplier",
          message: myJson.message,
        })
      );
    }
  };
}
export function getCatalogList(id, type) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "catalogListing" }));
    let url = `${Constant.apiURl}/users/get/supplier/catalogs?supplierId=${id}&availability=${type}`;

    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 200) {
      dispatch(GetSupplierAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "catalogListing",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "catalogListing",
          message: myJson.message,
        })
      );
    }
    return myJson;
  };
}
export function rateSupplier(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "rateSupplier" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/users/rate", data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "rateSupplier",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "rateSupplier",
          message: myJson.message,
        })
      );
    }
  };
}
export function getUserRate(supplierId, customerId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getUserRate" }));
    let myJson = await FETCH(
      "GET",
      Constant.apiURl + "/users/rate/" + supplierId + "/" + customerId
    );
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getUserRate",
          message: myJson.data.rate,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getUserRate",
          message: myJson.message,
        })
      );
    }
  };
}

const cacheAllSuppliersResponse = async (basketId, data) => {
  if (!basketId || !data) return;

  const supplierIdExists = true;
  // const key = getSupplierComparisonKey(supplierIdExists, data);
  // const supplierComparisonIndividual = await get(key);

  // if (!supplierComparisonIndividual) {
  //   await set(key, {});
  // }

  const basketIdsMapping = await get("basketIdsMapping") || {};
  const basketPayload = basketIdsMapping[basketId] || {};
  const key = getSupplierComparisonKey(supplierIdExists, basketPayload);
  let ret = await get(key);

  if (!ret) {
    ret = {};
  }

  const url = `${Constant.apiURl}/users/suppliere/selection2`;

  for (let index = 0; index < data.length; index++) {
    const { isRfqExist, supplier } = data[index];
    const supplierId = supplier?._id;
    const payload = {
      basketId,
      supplierId,
      type: isRfqExist ? "rfq" : "order",
    };

    if (isRfqExist) payload.priceType = "estimated";

    if (!(supplierId in ret)) {
      const response = await FETCH("POST", url, payload);
      ret = {
        ...ret,
        [supplierId]: response
      };
    }
    await set(key, ret);
  }

  return true;
};

export function SupplierComparisonListing(data, type) {
  return async (dispatch) => {
    dispatch(
      Actions.ApiRequestedAction({ apiCallFor: "SupplierComparisonListing" })
    );
    const url = `${Constant.apiURl}/users/suppliere/selection2`;
    const { basketId } = data;
    const supplierIdExists = "supplierId" in data;

    const key = getSupplierComparisonKey(supplierIdExists, data);

    const myJson = await FETCH("POST", url, data);

    if (myJson && myJson.code === 201) {
      await set(key, myJson);
      await cacheAllSuppliersResponse(basketId, myJson?.data || []);
      dispatch(
        SupplierComparisonListAction({
          data: myJson.data,
          orderIds: myJson.orderIds,
          vesselDetail: myJson.vesselDetail,
          deliveryDate: myJson.deliveryDate,
          departments: myJson.departments,
          portName: myJson.portName,
          totalItems: myJson.totalItems,
          type: myJson.type,
          agentDetail: myJson.agentDetail,
        })
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "SupplierComparisonListing",
          message: myJson.message,
        })
      );
    } else {
      let response = await get(key);
      if (supplierIdExists) {
        // Captain is trying to send rfq/order to supplier, whose response has already been cached. So fetch it from there.
        response = response[data.supplierId];
      }
      if (response) {
        const {
          data, orderIds, vesselDetail, deliveryDate, departments, portName, totalItems, type, agentDetail, message
        } = response;
        dispatch(
          SupplierComparisonListAction({
            data,
            orderIds,
            vesselDetail,
            deliveryDate,
            departments,
            portName,
            totalItems,
            type,
            agentDetail,
          })
        );
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "SupplierComparisonListing",
            message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "SupplierComparisonListing",
            message: myJson.message,
          })
        );
      }
    }
  };
}
function compareSupplier(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.supplier.name.toUpperCase();
  const nameB = b.supplier.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}