import React, { useEffect, useState } from "react";
import { SupplierComparisonListing } from "../../ApiCalls/supplier";
import { ClearApiByNameAction } from "../../../ApiCallStatus/Actions/action";
import { ComparisonWrapper } from "../../Css/comparison.jsx";
import {
  getDashboardLink,
  getFormatedPricing,
} from "../../../../Services/common";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import { connect } from "react-redux";
import { RatingStars } from "../../../Common/Components/ratingStars";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { get } from 'idb-keyval';

function CalculateTotalPrice(props) {
  // let totalPrice = 0;
  // list && list.forEach(element => {
  //     if (priceType) {
  //         if (element.priceType === priceType)
  //             totalPrice += element.totalPrice;
  //     } else {
  //         totalPrice += element.totalPrice;
  //     }
  // });
  // return totalPrice.toFixed(2);
  let total = 0;
  props.departments.map((item, index) => {
    let supplierDepIndex = props.supplierDep
      ? props.supplierDep.findIndex((x) => x.itemCode === item.itemCode)
      : -1;
    let elem = {};
    if (supplierDepIndex !== -1) {
      elem = props.supplierDep[supplierDepIndex];
    }
    let price = elem.itemPrice ? parseFloat(elem.itemPrice).toFixed(2) : 0;
    let totalPrice = price * item.order;
    total += totalPrice;
  });
  return total.toFixed(2);
}
function compareDepartment(a, b) {
  // Use toUpperCase() to ignore character casing
  const itemCodeA = a.department.toUpperCase();
  const itemCodeB = b.department.toUpperCase();

  let comparison = 0;
  if (itemCodeA > itemCodeB) {
    comparison = 1;
  } else if (itemCodeA < itemCodeB) {
    comparison = -1;
  }
  return comparison;
}

function SupplierComparison(props) {
  const [state, setState] = useState({
    data: [],
    vesselDetail: {},
    departments: {},
    totalItems: 0,
    activeDepartment: "",
    basketId: props.match.params.basketId,
    filters: {
      priceType: "",
    },
    depList: [],
    type: "",
    showModal: "",
    selectedSupplier: "",
    isSelectRfqExist: true,
    isHitApi: false,
    totalPrice: 0,
    isProvision: false,
    isStore: false,
    isAdditional: false,
  });

  useEffect(() => {
    if (!state.isHitApi) {
      get("basketIdsMapping").then((mapping) => {
        const basketId = props.match.params.basketId;
        const resp = mapping[basketId];
        props.SupplierComparisonListing({
          basketId,
          ...resp,
        },
          "rfq"
        );
      });

      let data = { ...state };
      data.isHitApi = true;
      setState(data);
    }
    if (
      props.apiCallStatus.apiCallFor === "SupplierComparisonListing" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let activeDepartment = "";
      let depList = [];
      let departments = {};
      if (
        props.comparison.departments &&
        Object.size(props.comparison.departments) > 0
      ) {
        depList = Object.keys(props.comparison.departments).sort();
        activeDepartment = depList[0];
        let fixedItems = 0;
        depList.forEach((element) => {
          departments[element] =
            props.comparison.departments[element].sort(compareDepartment);
          if (props.comparison.data.length > 0) {
            let supplierDep;
            props.comparison.data.map((suppliers, index) => {
              supplierDep =
                props.comparison.type === "store"
                  ? props.comparison.departments[element]
                  : suppliers.orderedItems;
              departments[element].map((item, index) => {
                let supplierDepIndex = supplierDep
                  ? supplierDep.findIndex((x) => x.itemCode === item.itemCode)
                  : -1;
                let elem = {};
                if (supplierDepIndex !== -1) {
                  elem = supplierDep[supplierDepIndex];
                }
                if (elem.priceType === "fixed") fixedItems = fixedItems + 1;
              });
              props.comparison.data[index]["fixedItems"] = fixedItems;
            });
          }
        });
      }
      setState({
        ...state,
        ...props.comparison,
        activeDepartment,
        depList,
        departments,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
  });
  const handleDepChange = (val) => {
    if (val === state.activeDepartment) {
      val = "";
    }
    setState({
      ...state,
      activeDepartment: val,
    });
  };
  const handleFilterChange = (val) => {
    if (val === state.filters.priceType) {
      val = "";
    }
    setState({
      ...state,
      filters: {
        priceType: val,
      },
    });
    let obj = { basketId: state.basketId };
    if (val !== "") {
      obj["priceType"] = val;
    }
    props.SupplierComparisonListing(obj);
  };
  const redirectToPath = (path) => {
    props.history.push(path);
  };
  const sendRfqOrder = (url) => {
    setState({
      ...state,
      showModal: false,
      selectedSupplier: "",
      isSelectRfqExist: true,
    });
    redirectToPath(url);
  };
  const handleModalChange = (val, selectedSupplier, isSelectRfqExist) => {
    setState({
      ...state,
      showModal: val,
      selectedSupplier,
      isSelectRfqExist,
    });
  };

  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.push(getDashboardLink()),
    },
  ];
  let currency = state.vesselDetail.currency ? state.vesselDetail.currency : "";
  return (
    <DashboardWrapper
      title="Supplier Comparison"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <ComparisonWrapper className="supplier-comparison-wrap">
        {props.apiCallStatus.isStarted.indexOf("SupplierComparisonListing") !==
          -1 ? (
          <div className="loader-img text-center">
            <img
              style={{ width: "46px" }}
              src={require("../../../../assets/images/Spinner-1s-200px.gif")}
              alt=""
            />
          </div>
        ) : Object.size(state.vesselDetail) === 0 ? (
          <div colSpan="40" className="text-center">
            No Data Found
          </div>
        ) : (
          <div className="ordCatg-table-wrap sup-rfq-wrap bg-light-grey pr-0 overflow-auto">
            <div className="comp-first-row diff-details-wrap d-flex">

              <div className="left-section">
                <div className="mr-10 h-15">
                  <VesselDetail
                    {...state.vesselDetail}
                    port={props.comparison.portName}
                    deliveryDate={props.comparison.deliveryDate}
                    totalItems={state.totalItems}
                    handleFilterChange={handleFilterChange}
                    priceType={state.filters.priceType}
                  />
                </div>
                <div>
                  {state.depList.map((item, index) => {
                    return (
                      <div
                        key={"depList-" + index}
                        className={`compar-table-items d-flex mt-3 ${state.activeDepartment === item
                          ? "collapse-content"
                          : ""
                          }`}
                      >
                        <DepartmentDetail
                          comparison={props.comparison}
                          depName={item}
                          items={state.departments[item]}
                          handleDepChange={handleDepChange}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="right-section">
                <div className="d-flex">
                  {state.data.map((item, index) => {
                    return (
                      <SupplierDetail
                        departments={state.departments.Items}
                        supplierDep={item.orderedItems}
                        {...item.supplier}
                        isRfqExist={item.isRfqExist}
                        totalItems={state.totalItems}
                        itemFixedValue={item.supplier.fixedItems}
                        port={props.comparison.portName}
                        deliveryDate={props.comparison.deliveryDate}
                        currency={currency}
                        key={"SupplierDetail+" + index}
                        basketId={state.basketId}
                        redirectToPath={redirectToPath}
                        handleModalChange={handleModalChange}
                      />
                    );
                  })}
                </div>
                <div>
                  {state.depList.map((item, index) => {
                    return (
                      <div
                        key={"depList-" + index}
                        className={`compar-table-items d-flex mt-3 ${state.activeDepartment === item
                          ? "collapse-content"
                          : ""
                          }`}
                      >
                        {state.data.map((suppliers, index) => {
                          let supplierDep =
                            state.type === "store"
                              ? props.comparison.departments[item]
                              : suppliers.orderedItems;
                          return (
                            <PricingDetail
                              currency={currency}
                              departments={state.departments[item]}
                              supplierDep={supplierDep}
                              key={"PricingDetail2-" + index}
                              priceType={state.filters.priceType}
                              orderProvisionType={suppliers?.supplier?.orderProvisionType}
                            />
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* <div className="comp-second-row compar-table-wrap">
              <div className="compar-table-head-wrap">

              </div>
            </div> */}
          </div>
        )}
      </ComparisonWrapper>
      <Modal
        open={state.showModal === "rfq"}
        onClose={() => handleModalChange("", "")}
        center
      >
        <div className="formBox request-price">
          <div className="col-12">
            <h5>
              Requesting prices always takes time (1-2 days).
              {/* You can also order these items directly to save time and efforts. */}
            </h5>
            <div className="row">
              <div className="col-sm-12 text-center">
                <button
                  onClick={() =>
                    sendRfqOrder(
                      `/send/rfq/${state.selectedSupplier}/${state.basketId}`
                    )
                  }
                  className="btn themeReguler-btn bg-sky-blue m-0 mr-1 mt-1"
                >
                  Send RFQ
                </button>
                {/* </div> */}
                {/* <div className="col-sm-6 text-center"> */}
                {/* <button onClick={() => sendRfqOrder(`/send/order/${state.selectedSupplier}/${state.basketId}`)} className="btn themeReguler-btn bg-sky-blue m-0 mt-1">Send ORDER</button> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={state.showModal === "order"}
        onClose={() => handleModalChange("", "")}
        center
      >
        <div className="formBox request-price">
          <div className="col-12">
            <h5>
              Some prices are estimated. Actual prices can vary. How do you want
              to proceed ?
            </h5>
            <div className="row">
              <div className="col-sm-12 text-center">
                {state.isSelectRfqExist ? (
                  <button
                    onClick={() =>
                      sendRfqOrder(
                        `/send/rfq/${state.selectedSupplier}/${state.basketId}`
                      )
                    }
                    className="btn themeReguler-btn bg-sky-blue mr-1 mr-0  mt-1"
                  >
                    Send RFQ
                  </button>
                ) : (
                  ""
                )}
                {/* <div className="col-sm-6 text-center"> */}
                <button
                  onClick={() =>
                    sendRfqOrder(
                      `/send/order/${state.selectedSupplier}/${state.basketId}`
                    )
                  }
                  className="btn themeReguler-btn bg-sky-blue m-0  mt-1"
                >
                  Send ORDER
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </DashboardWrapper>
  );
}

const VesselDetail = React.memo((props) => {
  return (
    <div className="rfq-durations rfq-details-items text-left">
      <h2 className="mt-1 dark-blue-clr semi-bold">{props.name}</h2>
      <div className="inner-content text-left">
        <div className="d-flex flex-wrap justify-content-between">
          <h4>
            <span className="font-weight-bold mt-1 d-inline-block mr-1">
              Del. Place:{" "}
            </span>
            {props.port}
          </h4>
        </div>
        <div className="d-flex flex-wrap justify-content-between">
          <h4>
            <span className="font-weight-bold mt-1 d-inline-block mr-1">
              Del. Date:{" "}
            </span>
            {moment(props.deliveryDate).format("YYYY-MM-DD")}
          </h4>
        </div>
        <div>
          <h4 className="mt-1">
            <span className="font-weight-bold d-inline-block mr-1">
              {" "}
              Items ordered:
            </span>
            {props.totalItems}
          </h4>
        </div>
        <div className="check_contnet_wrap">
          <p className="mt-1 mb-1 d-flex align-items-center">
            <span className="customCheckBoxLabel">
              <input
                name="priceType"
                checked={props.priceType === "fixed"}
                type="checkbox"
                className="star-checkbox"
                id="fixed"
                onChange={() => props.handleFilterChange("fixed")}
              />
              <label htmlFor="fixed" className="checkmark"></label>
            </span>
            Items with fixed prices
          </p>
          <p className="mb-1 d-flex align-items-center">
            <span className="customCheckBoxLabel">
              <input
                name="priceType"
                type="checkbox"
                checked={props.priceType === "estimated"}
                className="star-checkbox"
                id="estimated"
                onChange={() => props.handleFilterChange("estimated")}
              />
              <label htmlFor="estimated" className="checkmark"></label>
            </span>
            Items with estimated prices
          </p>
        </div>
      </div>
    </div>
  );
});

const SupplierDetail = React.memo((props) => {
  const totalPrice = CalculateTotalPrice(props);
  const handleSendOrder = () => {
    if (!props.isRfqExist) {
      props.redirectToPath(`/send/order/${props._id}/${props.basketId}`);
    } else {
      props.handleModalChange("order", props._id, props.isRfqExist);
    }
  };

  let { currency, deliveryCharges } = props;

  const finalDeliveryCharges = deliveryCharges
    ? getFormatedPricing(Number(deliveryCharges).toFixed(2), currency)
    : 0;
  const finalTotalPrice =
    deliveryCharges || totalPrice
      ? getFormatedPricing(
        Number(+totalPrice + +deliveryCharges).toFixed(2),
        currency
      )
      : 0;

  return (
    <div className="rfq-rating rfq-details-items h-15">
      <button type="button" className="btn themeReguler-btn bg-dark-blue m-0">
        {props.name}
      </button>
      <div className="inner-content text-center">
        <div className="rating-wrap d-flex flex-row align-items-center mt-2">
          <h3 className="rounded-tag mb-0 mr-2">Best Rating</h3>
          <RatingStars
            id="input"
            className="rating-checks list-inline pl-0 d-flex align-items-center mb-0"
            isEdit={true}
            ratingAvg={props.ratingAvg}
            handleChange={() => { }}
          />
        </div>
        <div className="inner-content text-center d-flex align-items-center mt-1 rounder-new">
          {/* <h3 className="rounded-tag mr-2 orng-clr mb-0">Most prices</h3> */}
          <div className="rounded-tag progress-bar-wrap">
            <h3
              className="rounded-tag mb-0"
              style={{
                width:
                  props.itemFixedValue !== 0 && props.estimatedItems !== 0
                    ? (props.itemFixedValue /
                      (props.itemFixedValue + props.estimatedItems)) *
                    100
                    : 0,
              }}
            >{`${props.itemFixedValue}/${props.totalItems}`}</h3>
          </div>
        </div>
        <h3 className="semi-bold text-left total-value-count mb-0 mt-3">
          <span className="d-inline-block mr-2">Delivery Charges:</span>{" "}
          {finalDeliveryCharges} {currency}
        </h3>
        <h3 className="semi-bold text-left total-value-count mb-0 mt-3">
          <span className="d-inline-block mr-2">Total:</span> {finalTotalPrice}{" "}
          {currency}
        </h3>
        {/* Order Buttons */}
        <div className="next-process-btns d-flex align-items-center justify-content-center mt-3">
          {props.isRfqExist ? (
            <button
              onClick={() =>
                props.handleModalChange("rfq", props._id, props.isRfqExist)
              }
              className="btn themeReguler-btn bg-sky-blue m-0 mr-2"
            >
              Send RFQ
            </button>
          ) : (
            ""
          )}
          {!props.isRfqExist ? (
            <button
              onClick={() => handleSendOrder()}
              className="btn themeReguler-btn bg-sky-blue m-0"
            >
              Send ORDER
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
});

const PricingDetail = React.memo((props) => {
  // let totalPrice = CalculateTotalPrice(props.supplierDep, props.priceType);
  let totalPrice = CalculateTotalPrice(props);
  return (
    <div className="compar-table-item">
      <h2 className="table-title-wrap text-center">
        {!isNaN(totalPrice)
          ? getFormatedPricing(Number(totalPrice).toFixed(2), props.currency) +
          " " +
          props.currency
          : "-"}
      </h2>
      <div className="table-responsive">
        <table className="table theme-table-wrap2 short-table sticky-th-border">
          <thead>
            <tr>
              <th>Price / unit</th>
              <th>Total price</th>
            </tr>
          </thead>
          <tbody>
            {props.departments.map((item, index) => {
              let supplierDepIndex = props.supplierDep
                ? props.supplierDep.findIndex(
                  (x) => x.itemCode === item.itemCode
                )
                : -1;
              let elem = {};
              if (supplierDepIndex !== -1) {
                elem = props.supplierDep[supplierDepIndex];
              }
              let price = elem.itemPrice
                ? parseFloat(elem.itemPrice).toFixed(2)
                : 0;
              let totalPrice = price * item.order;
              if (
                props.priceType !== "" &&
                props.priceType !== elem.priceType
              ) {
                price = "--";
                totalPrice = "--";
              }
              return (
                <tr key={"departments2-" + index}>
                  <td className="text-start">
                    {elem.priceType === "estimated" &&
                      props.priceType === "" ? (
                      <React.Fragment>
                        <span
                          className="price-text"
                          data-effect="float"
                          data-tip="Estimated Price"
                        >
                          <img
                            className="img-fluid"
                            src={require("../../../../assets/images/circle_outline_with_exclamation.svg")}
                            alt="icon"
                            width={10}
                            height={10}
                          />
                        </span>
                        <span className="price-text">
                          {!isNaN(price)
                            ? getFormatedPricing(
                              Number(price).toFixed(2),
                              props.currency
                            ) + props.currency
                            : "-"}
                        </span>
                      </React.Fragment>
                    ) : !isNaN(price) ? (
                      getFormatedPricing(
                        Number(price).toFixed(2),
                        props.currency
                      ) +
                      " " +
                      props.currency
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {!isNaN(totalPrice)
                      ? getFormatedPricing(
                        Number(totalPrice).toFixed(2),
                        props.currency
                      ) +
                      " " +
                      props.currency
                      : "-"}{" "}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

const DepartmentDetail = React.memo((props) => {
  return (
    <div className="compar-table-item">
      <div className="table-title-wrap d-flex align-items-center">
        <button
          type="button"
          className="btn btn-link"
          onClick={() => props.handleDepChange(props.depName)}
        ></button>
        <h2 className="mb-0">{props.depName}</h2>
      </div>
      <div className="table-responsive">
        <table className="table theme-table-wrap2 short-table sticky-th-border suppliertable">
          <thead>
            <tr>
              <th className="text-center">No</th>
              <th className="text-left">Item No</th>
              <th className="text-left">Description</th>
              <th className="text-center">Quantity</th>
              <th className="text-left">Unit</th>
              <th className="text-left">Department</th>
            </tr>
          </thead>
          <tbody>
            {props.items
              .sort((a, b) => a.alternativeCode - b.alternativeCode)
              .map((item, index) => {
                return (
                  <tr key={"pi00-" + index}>
                    <td className="text-center">{index + 1}</td>
                    {/* <td className="text-left">{props.comparison.type === "store" ? item.alternativeCode : item.itemCode}</td> */}
                    <td className="text-left">{item.alternativeCode}</td>
                    <td className="text-nowrap text-left">
                      {item.description}
                    </td>
                    <td className="text-center">{item.order}</td>
                    <td className="text-left">{item.standardPackagingUnit}</td>
                    <td className="text-left">{item.department}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  comparison: state.supplierReducer.comparison,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  SupplierComparisonListing: (data, type) =>
    dispatch(SupplierComparisonListing(data, type)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplierComparison);