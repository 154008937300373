import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import { GetCountryAction, AppendCountryAction, GetCountryDetailAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function createCountry(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "createCountry" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/country", data);
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "createCountry",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "createCountry",
                    message: myJson.message,
                })
            );
        }
    };
}
export function countryListing(offset) {
    return async (dispatch) => {
        if (offset !== 0) {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendCountryList" }));
        } else {
            dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCountry" }));
            dispatch(GetCountryAction([], 0))
        }
        let myJson = await FETCH("GET", Constant.apiURl + "/country/" + offset + "/20");
        if (myJson && myJson.code === 201) {
            if (offset !== 0) {
                dispatch(AppendCountryAction(myJson.data));
                dispatch(Actions.ApiFulfilledAction({ apiCallFor: "appendCountryList", message: '' }));

            } else {
                dispatch(GetCountryAction(myJson.data, myJson.count))
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getCountry",
                        message: myJson.message,
                    }))
            }

        } else {
            if (offset !== 0) {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "appendCountryList", message: myJson.errorMessage ? myJson.errorMessage : myJson.message }));
            } else {
                dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getCountry", message: myJson.message }))
            }
        }
    };
}
export function updateCountry(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCountry" }));
        let myJson = await FETCH("PUT", Constant.apiURl + "/country",data);
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateCountry",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateCountry",
                    message: myJson.message,
                })
            );
        }
    };
}
export function deleteCountry(countryId) {
    return async dispatch => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteCountry" }));
        let myJson = await FETCH('DELETE', Constant.apiURl + "/country", {
            countryId
        });
        if (myJson && myJson.code === 201) {
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteCountry",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteCountry",
                    message: myJson.message,
                })
            );
        }
    }
}
export function getCountryDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCountryDetail" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/country/" + id);
        if (myJson && myJson.code === 201) {
            dispatch(
                GetCountryDetailAction({
                    name: myJson.data.name,
                    countryCode: myJson.data.countryCode
                })
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCountryDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getCountryDetail",
                    message: myJson.message,
                })
            );
        }
    };
}