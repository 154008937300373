import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllRoleAction, GetOrderCountAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function getAllRoles() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllRoles" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/role/all");
        if (myJson && myJson.code === 201) {
            dispatch(GetAllRoleAction(myJson.data))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllRoles",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getAllRoles", message: myJson.message }))
        }
    };
}
export function getOrderCount(userId,vesselId,department,orderType) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrderCount" }));
        let myJson = await FETCH("POST", Constant.apiURl + "/orders/orderCount", { userId,vesselId,department,orderType });
        if (myJson && myJson.code === 201) {
            dispatch(GetOrderCountAction(myJson.count, myJson.userOrderId))
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getOrderCount",
                    message: myJson.message,
                }))
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getOrderCount", message: myJson.message }))
        }
    };
}