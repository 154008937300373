import React, { useEffect, useState } from 'react';
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { getRoleDetail, updateRole } from '../ApiCalls/role'
import { AddRoleForm } from './addRoleForm';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { getRoutesDetail } from '../../../Constants/routesConfig';
import '../css/roles.css';
import "react-datepicker/dist/react-datepicker.css";
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';
const initialPermission = {
    department: [],
    provisionOrder: []
}
const addRoleInitialState = {
    roleName: '',
    allRoutes: {},
    roles: [],
    permission: initialPermission,
    permissionAll: false,
    roleId: '',
    isHitApi: false
}
function EditRole(props) {
    const [state, setState] = useState(addRoleInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (props.isSubAdmin) {
                props.history.push('/not-allowed')
            } else {
                props.getRoleDetail(props.match.params.id)
                let data = { ...state };
                data.isHitApi = true;
                data.roleId = props.match.params.id;
                data.allRoutes = getRoutesDetail()
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getRoleDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let data = props.roleReducer.detail;
            setState({
                ...state,
                roleName: data.roleName,
                roles: data.roles,
                permission: data.permission,
                permissionAll: data.permissionAll,
                roleId: data.roleId,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "updateRole") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let msg = `The ${state.roleName} has been updated successfully.`
            Swal.fire("Success", msg, "success");
            props.history.push('/role-listing')
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
    })
    const dropDownHandler = (e, fieldName) => {
        let data = { ...state }
        let name = e.target.name === 'AdditionalPro' ? 'Additional' : e.target.name;
        let permissionName = data.permission[fieldName];
        let index = permissionName.findIndex(x => x === name);
        if (index === -1) {
            permissionName.push(name)
        } else {
            permissionName.splice(index, 1)

        }
        data.permission[fieldName] = permissionName
        setState(data)
    }
    const handleFormChange = (e, listName) => {
        let data = { ...state }
        let roles = data.roles;
        let name = e.target.name;
        if (name === 'permissionAll') {
            let value = e.target.checked
            setState({
                ...state,
                roles: [],
                permission: {
                    department: [],
                    provisionOrder: []
                },
                permissionAll: value
            })
        } else {
            let value = '';
            if (name === 'roleName') {
                value = e.target.value
                data[name] = value
                setState(data)
            } else {
                if (name === '/delete-vessel' || name === '/delete-cook' || name === '/delete-captain' || name === '/delete-port' || name === '/delete-country' || name === '/delete-department' || name === "/delete-supplier" || name === '/delete-package' || name === '/delete-catalogue' || name === '/delete-customer') {
                    value = {
                        key: name,
                        path: name
                    }
                } else if (state.allRoutes[name]) {
                    value = {
                        key: name,
                        path: state.allRoutes[name]
                    };

                } else {
                    if (name === 'sendOrder') {
                        value = {
                            key: "sendOrder",
                            path: '/send/order/:supplierId/:orderId'
                        }
                    } else if (name === 'sendRfq') {
                        value = {
                            key: "sendRfq",
                            path: '/send/rfq/:supplierId/:orderId'
                        }
                    }
                }
                if (listName) {
                    let index = roles.findIndex(x => x.key === listName);
                    if (index === -1) {
                        let obj = {
                            key: listName,
                            path: state.allRoutes[listName]
                        };

                        roles.push(obj)
                    }
                }
                let index = roles.findIndex(x => x.key === name);
                if (index === -1) {
                    if (name === 'contractOffice' || name === 'userDashboard') {
                        let removedDashboard = name === 'contractOffice' ? 'userDashboard' : 'contractOffice';
                        let newIndex = roles.findIndex(x => x.key === removedDashboard);
                        if (newIndex !== -1) {
                            roles.splice(newIndex, 1)
                        }
                    }
                    roles.push(value)
                } else {
                    if (name === 'contractOffice') {
                        roles.push({
                            key: "userDashboard",
                            path: state.allRoutes["userDashboard"]
                        })
                    } else if (name === 'userDashboard') {
                        roles.push({
                            key: "contractOffice",
                            path: state.allRoutes["contractOffice"]
                        })
                    }
                    roles.splice(index, 1)
                }
                setState({
                    ...state,
                    roles
                })
            }


        }

    }
    const updateHandler = () => {
        let data = { ...state }
        let obj = {
            roleName: data.roleName,
            roles: data.roles,
            permission: data.permission,
            permissionAll: data.permissionAll,
            roleId: data.roleId
        }
        props.updateRole(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
    ];
    return (
        <DashboardWrapper DashboardNavbar title="Edit Role" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="">
                <AddRoleForm {...state} history={props.history} handleFormChange={handleFormChange} dropDownHandler={dropDownHandler} isEdit={true} handleSubmit={updateHandler} apiCallStatus={props.apiCallStatus} apiName="updateRole" />
            </div>
        </DashboardWrapper >
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roleReducer: state.roleReducer,
    isSubAdmin: state.authReducer.isSubAdmin
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getRoleDetail: (id) => dispatch(getRoleDetail(id)),
    updateRole: (data) => dispatch(updateRole(data))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditRole);