export const getQuotationDetail = "GET_QUOTATION_DETAIL";
export const getQuotation = "GET_QUOTATION";
export const getQuotationOrder = "GET_QUOTATION_ORDER";
export const AppendQuotationList = "APPEND_QUOTATION_LIST";
export const AppendQuotationOrderList = "APPEND_QUOTATION_ORDER_LIST";

export function GetQuotationAction(list, count) {
  return {
    type: getQuotation,
    payload: { list, count },
  };
}
export function GetQuotationOrderAction(list, count) {
  return {
    type: getQuotationOrder,
    payload: { list, count },
  };
}
export function AppendQuotationAction(list) {
  return {
    type: AppendQuotationList,
    payload: { list },
  };
}
export function AppendQuotationOrderAction(list) {
  return {
    type: AppendQuotationOrderList,
    payload: { list },
  };
}
export function getQuotationDetailAction(list, basketId, orderDetail, adminCatalogs) {
  return {
    type: getQuotationDetail,
    payload: { list, basketId, orderDetail, adminCatalogs },
  };
}
