import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./dashboard-style.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { cartListing } from "../ApiCalls/orderListing";
import { topItemsListing } from "./../../Orders/ApiCalls/provisionOrder";
import { supplierListing } from "../../Supplier/ApiCalls/supplier";
import { quotationListing } from "../../Quotations/Apicalls/quotations";
import {
  ApiClearAction,
  ClearApiByNameAction,
} from "../../ApiCallStatus/Actions/action";
import { topSupplyHub } from "./../../Common/ApiCalls/topSupplyHub";
import {
  getAllVessel,
  getCurrentUserVessel,
} from "./../../Common/ApiCalls/vesselList";
import { topVessel } from "./../../Common/ApiCalls/topVessel";
import { getAllPort } from "../../Common/ApiCalls/portList";
import { topCustomer } from "../../Common/ApiCalls/topSupplier";
import { customerListing } from "../../Common/ApiCalls/customerList";

import { connect } from "react-redux";
import CustomSelect from "../../Common/Components/CustomSelect";
// import Map from "../../Common/Components/jvectorMap";
import {
  capitalizeFirstLetter,
  currencyFormatter,
  getFormatedPricing,
} from "../../../Services/common";
import { DashboardWrapper } from "./dashboarWrapper";

function SupplierDashboard(props) {
  const [state, setState] = useState({
    orderList: [],
    topItemsList: [],
    topCustomerList: [],
    topVesselList: [],
    portList: [],
    vesselList: [],
    vesselOption: [],
    qoutationList: [],
    customerList: [],
    totalCustomer: 0,
    totalQuotation: 0,
    searchCustomer: "",
    customerId: "",
    selectedOption: null,
    activePage: 0,
    totalOrderCount: 0,
    totalTopItems: 0,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    vesselDate: null,
    isTopCustomer: false,
    isTopVessel: false,
    isTopPort: false,
    isTopItem: false,
    filterQuery: "",
    isHitApi: false,
  });
  useEffect(() => {
    if (!state.isHitApi) {
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
      let from = moment(data.startDate).format("YYYY-MM-DD");
      let to = moment(data.endDate).add(1, "days").format("YYYY-MM-DD");
      props.cartListing(
        0,
        `?offset=${0}&limit=${5}&supplierId=${props.userDetail.userId}`
      );
      props.topCustomer(props.userDetail.userId, "supplier", from, to);
      props.topItemsListing(props.userDetail.userId, "supplier", from, to);
      props.getAllVessel();
      props.topSupplyHub(props.userDetail.userId, "supplier", from, to);
      props.topVessel(props.userDetail.userId, "supplier", from, to);
      props.quotationListing(0, 4, "rfq", false);
      props.quotationListing(0, 4, "order", true);
      props.customerListing(
        0,
        "customer",
        state.searchCustomer ? `&email=${state.searchCustomer}` : ""
      );
    }
    if (
      props.apiCallStatus.apiCallFor === "getCart" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        orderList: props.dashboardReducer.cartListing,
        totalOrderCount: props.dashboardReducer.totalCart,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    // if (props.apiCallStatus.apiCallFor === "getAllPort" &&
    //     props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
    //     setState({
    //         ...state,
    //         portList: props.commonReducer.portList
    //     })
    // }
    if (
      props.apiCallStatus.apiCallFor === "topSupplyHub" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        portList: props.commonReducer.topSupplyHubList,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getTopItems" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topItemsList: props.orderReducer.topItemsList,
        totalTopItems: props.orderReducer.totalTopItems,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getAllVessel" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        vesselList: props.commonReducer.vesselList,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topVessel" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topVesselList: props.commonReducer.topVesselList,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getCustomer" ||
        props.apiCallStatus.apiCallFor === "appendCustomerList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let data = [];
      for (let i = 0; i < props.commonReducer.customerList.length; i++) {
        const element = props.commonReducer.customerList[i];
        data.push({ label: element.email, value: element.id });
      }
      setState({
        ...state,
        customerList: data,
        totalCustomer: props.commonReducer.totalCustomer,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getCurrentUserVessel" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        vesselList: props.commonReducer.currentUserVessel,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    // if (props.apiCallStatus.apiCallFor === "supplierListing" &&
    //     props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
    //     setState({
    //         ...state,
    //         supplierList: props.supplierReducer.list,
    //     })
    // }
    if (
      props.apiCallStatus.apiCallFor === "topCustomer" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topCustomerList: props.commonReducer.topCustomerList,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getQuotation" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        qoutationList: props.quotationList.slice(0, 5),
        totalQuotation: props.totalQuotation,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
  }, [props, state.isHitApi]);
  const handleChange = (id, val) => {
    if (id === "vesselDate") {
      setState({
        ...state,
        [id]: val,
      });
      props.getCurrentUserVessel(state.customerId, "", val);
    } else {
      setState({
        ...state,
        [id]: val,
        isHitApi: false,
      });
    }
  };
  const handleSelectChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
    });
    props.customerListing(
      state.activePage,
      "customer",
      val ? `&email=${val}` : ""
    );
  };
  const clickCustomeSelectHandler = (id, val) => {
    setState({
      ...state,
      ["searchCustomer"]: val.label,
      ["customerId"]: val.value,
    });
    let vesselDate = state.vesselDate !== null ? state.vesselDate : undefined;
    props.getCurrentUserVessel(val.value, "", vesselDate);
  };
  const fetchMoreUserData = () => {
    let { activePage } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    props.customerListing(
      activePage,
      "customer",
      state.searchCustomer ? `&email=${state.searchCustomer}` : ""
    );
  };
  const navbarOptions = [
    {
      img: "Cancel.svg",
      className: "red",
      name: "Logout",
      path: "/",
      isLogout: true,
    },
  ];
  const collapsHandle = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  return (
    <DashboardWrapper
      title="Supplier Dashboard "
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {/* Supplier Dashboad */}
      {/* Order Status Content */}
      <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left">
        <OrderStatusWrapper
          label="Current Orders"
          link={state.orderList.length === 0 ? "#" : "/orderOverview"}
        >
          {props.apiCallStatus.isStarted.indexOf("getCart") !== -1 ? (
            <td colSpan="20">
              <div className="loader-img text-center">
                <img
                  style={{ width: "46px" }}
                  src={require("../../../assets/images/Spinner-1s-200px.gif")}
                  alt=""
                />
              </div>
            </td>
          ) : props.quotationOrderList &&
            props.quotationOrderList.length === 0 ? (
            <tr>
              <td colSpan="40" className="text-center">
                <div>No Data Found</div>
              </td>
            </tr>
          ) : (
            props.quotationOrderList.map((item, index) => {
              let status =
                item.isSupplied && item.status === "confirmed"
                  ? "Supplied"
                  : item.status &&
                    item.status[0].toUpperCase() + item.status.slice(1);
              return (
                <tr key={"quotationOrderList-" + index}>
                  <td>
                    {item.vesselId && item.vesselId.name
                      ? item.vesselId.name
                      : ""}
                  </td>
                  <td>{item.customerName}</td>
                  <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                  <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                  <td>{item.nextDeliveryPlace}</td>
                  <td>
                    {item.supplierId && item.supplierId.name
                      ? item.supplierId.name
                      : ""}
                  </td>
                  <td>{currencyFormatter(item.rfqValue, item.currency)}</td>
                  <td>
                    <td>{status}</td>
                  </td>
                </tr>
              );
            })
          )}
        </OrderStatusWrapper>
        <OrderStatusWrapper
          label="Current RFQ's"
          link={
            state.qoutationList.length === 0 ? "#" : "/inprogress-quotations"
          }
        >
          {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ? (
            <td colSpan="20">
              <div className="loader-img text-center">
                <img
                  style={{ width: "46px" }}
                  src={require("../../../assets/images/Spinner-1s-200px.gif")}
                  alt=""
                />
              </div>
            </td>
          ) : state.qoutationList && state.qoutationList.length === 0 ? (
            <td colSpan="40" className="text-center">
              <div>No Data Found</div>
            </td>
          ) : (
            state.qoutationList.map((item, index) => {
              let status = item.status;
              return (
                <tr key={"q4-" + index}>
                  <td>
                    {item.vesselId && item.vesselId.name
                      ? item.vesselId.name
                      : ""}
                  </td>
                  <td>{item.customerName}</td>
                  <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                  <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                  <td>{item.nextDeliveryPlace}</td>
                  <td>
                    {item.rfqValue
                      ? getFormatedPricing(item.rfqValue, item.currency)
                      : "0"}{" "}
                    {item.rfqValue ? item.currency : ""}
                  </td>
                  <td>
                    {item.status === "inprogress" ||
                    item.status === "pending" ? (
                      <>
                        <span>Open</span>
                      </>
                    ) : (
                      <>
                        <span>Quoted</span>
                      </>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </OrderStatusWrapper>
      </div>
      {/* <div className="mt-lg-1 d-flex supplier_view contract-dash-wrap">
        <div className="dashNew-ord-wrap d-flex flex-column align-items-start mr-lg-1 mr-0">
         
        </div>
      </div> */}
      <div className=" d-flex contract-dash-wrap cont-ves-wrap mt-3">
        <div className="dashVesl-overview-wrap">
          <div className="content-box-wrap dash-content-bx">
            <div className="vessel-overview-wrap d-flex align-items-start">
              <div className="content-box-head d-flex align-items-center justify-content-center head-transparent w-100 mb-3 flex-wrap">
                {/* Date Range Picker */}
                <div className="date-range-picker d-flex align-items-center">
                  <span className="d-inline-block mr-1">
                    <DatePicker
                      selected={state.startDate}
                      onChange={(val) => handleChange("startDate", val)}
                      className="link-style"
                    />
                  </span>
                  to
                  <span className="d-inline-block ml-1">
                    <DatePicker
                      selected={state.endDate}
                      onChange={(val) => handleChange("endDate", val)}
                      className="link-style"
                    />
                  </span>
                </div>
                {/* <button type="button" className="btn btn-link d-flex align-items-center p-0 dark-clr">View complete list</button> */}
              </div>
            </div>
            <CollapsWrapper>
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopCustomer", !state.isTopCustomer)
                      }
                    >
                      <span className="dropdown-icon d-inline-block cur-poi">
                        <i
                          className={
                            state.isTopCustomer
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopCustomer === false ? (
                      <th className="text-center">Top Customer</th>
                    ) : (
                      <>
                        <th>Customer Name</th>
                        <th>Number of Supplies</th>
                        <th>Turnover P-MESH</th>
                        <th>Turnover C-MESH</th>
                        <th>Total Turnover</th>
                        <th>Vessels</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${
                    state.isTopCustomer ? "show" : ""
                  }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("topCustomer") !==
                  -1 ? (
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img
                          style={{ width: "46px" }}
                          src={require("../../../assets/images/Spinner-1s-200px.gif")}
                          alt=""
                        />
                      </div>
                    </td>
                  ) : state.topCustomerList &&
                    state.topCustomerList.length === 0 ? (
                    <td colSpan="40" className="text-center">
                      <div>No Data Found</div>
                    </td>
                  ) : (
                    state.topCustomerList &&
                    state.topCustomerList.length > 0 &&
                    state.topCustomerList.map((item, index) => {
                      if (index < 5)
                        return (
                          <tr key={"topCustomerList2-" + index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.count}</td>
                            <td>
                              {currencyFormatter(
                                item?.pMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item?.cMeshVolume,
                                item.currency
                              )}
                            </td>

                            <td>
                              {currencyFormatter(
                                item?.totalVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {" "}
                              <Link
                                to={`/vessel-listing?fleetOverview=1&customerId=${item?._id}&customerEmail=${item?.email}`}
                                className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                    })
                  )}
                  {state.topCustomerList && state.topCustomerList.length > 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/customer-listing?topCustomer=1"
                            className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                          >
                            View all
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </CollapsWrapper>
            <CollapsWrapper>
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopVessel", !state.isTopVessel)
                      }
                    >
                      <span className="dropdown-icon d-inline-block cur-poi">
                        <i
                          className={
                            state.isTopVessel
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopVessel === false ? (
                      <th className="text-center">Top Vessel</th>
                    ) : (
                      <>
                        <th>Vessel Name</th>
                        <th>Vessel Type</th>
                        {props.userDetail.userType !== "customer" && (
                          <th>Customer Name</th>
                        )}
                        <th>Number of Supplies</th>
                        <th>Turnover P-MESH</th>
                        <th>Turnover C-MESH</th>
                        <th>Total Turnover</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${
                    state.isTopVessel ? "show" : ""
                  }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("topCustomer") !==
                  -1 ? (
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img
                          style={{ width: "46px" }}
                          src={require("../../../assets/images/Spinner-1s-200px.gif")}
                          alt=""
                        />
                      </div>
                    </td>
                  ) : state.topVesselList &&
                    state.topVesselList.length === 0 ? (
                    <td colSpan="40" className="text-center">
                      <div>No Data Found</div>
                    </td>
                  ) : (
                    state.topVesselList &&
                    state.topVesselList.length > 0 &&
                    state.topVesselList.map((item, index) => {
                      if (index < 5)
                        return (
                          <tr key={"topVesselList2-" + index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            {props.userDetail.userType !== "customer" && (
                              <td>{item.customerName}</td>
                            )}
                            <td>{item.suppliedCount}</td>
                            <td>
                              {currencyFormatter(
                                item.pMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.cMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.totalVolume,
                                item.currency
                              )}
                            </td>
                          </tr>
                        );
                    })
                  )}
                  {state.topVesselList && state.topVesselList.length > 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/vessel-listing?topVessel=1"
                            className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                          >
                            View all
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </CollapsWrapper>
            <CollapsWrapper>
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopPort", !state.isTopPort)
                      }
                    >
                      <span className="dropdown-icon d-inline-block cur-poi">
                        <i
                          className={
                            state.isTopPort
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopPort === false ? (
                      <th className="text-center">Top Port</th>
                    ) : (
                      <>
                        <th>Port Name</th>
                        <th>Country</th>
                        <th>Number of supplies</th>
                        <th>Turnover P-MESH</th>
                        <th>Turnover C-MESH</th>
                        <th>Total Turnover</th>
                        <th>Suppliers</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${
                    state.isTopPort ? "show" : ""
                  }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("topSupplyHub") !==
                  -1 ? (
                    <tr>
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    </tr>
                  ) : state.portList && state.portList.length === 0 ? (
                    <tr>
                      <td colSpan="40" className="text-center">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  ) : (
                    state.portList &&
                    state.portList.length > 0 &&
                    state.portList.map((item, index) => {
                      if (index < 5)
                        return (
                          <tr key={"portList2-" + index}>
                            <td>{index + 1}</td>
                            <td>{item.port}</td>
                            <td>{item.countryName}</td>
                            <td>{item.orderCount}</td>
                            <td>
                              {currencyFormatter(
                                item.pMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.cMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.totalVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/supplier-listing?topSupplier=1&portId=${item._id}`}
                                className="d-flex  p-0 fz-12 dark-pink-clr"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                    })
                  )}
                  {state.portList && state.portList.length > 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/port-listing?topPort=1"
                            className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                          >
                            View all
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </CollapsWrapper>
            <CollapsWrapper>
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopItem", !state.isTopItem)
                      }
                    >
                      <span className="dropdown-icon d-inline-block">
                        <i
                          className={
                            state.isTopItem
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopItem === false ? (
                      <th className="text-center">Top Items</th>
                    ) : (
                      <>
                        <th>Item Description</th>
                        <th>Item group</th>
                        <th>IMPA</th>
                        <th>Unit</th>
                        <th>Number of Supplies</th>
                        <th>Quantity</th>
                        <th>Total Turnover</th>
                        {props.userDetail.userType !== "supplier" && (
                          <th>Top Supplier</th>
                        )}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${
                    state.isTopItem ? "show" : ""
                  }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("getTopItems") !==
                  -1 ? (
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img
                          style={{ width: "46px" }}
                          src={require("../../../assets/images/Spinner-1s-200px.gif")}
                          alt=""
                        />
                      </div>
                    </td>
                  ) : state.topItemsList && state.topItemsList.length === 0 ? (
                    <td colSpan="40" className="text-center">
                      No Data Found
                    </td>
                  ) : (
                    state.topItemsList.map((item, index) => {
                      if (index < 5)
                        return (
                          <tr key={"topItemsList2-" + index}>
                            <td>{index + 1}</td>
                            <td>
                              <span className="td-wrap-sty">
                                {item.topItems.description}
                              </span>
                            </td>
                            <td>
                              <span className="td-wrap-sty">
                                {item.topItems.category}
                              </span>
                            </td>
                            <td>
                              <span className="td-wrap-sty">
                                {item.topItems.alternativeCode}
                              </span>
                            </td>
                            <td>
                              <span className="td-wrap-sty">
                                {item.topItems.standardPackagingUnit}
                              </span>
                            </td>
                            <td>
                              <span className="td-wrap-sty">
                                {item.supplies}
                              </span>
                            </td>
                            <td>
                              <span className="td-wrap-sty">
                                {item.topItems.quantity}
                              </span>
                            </td>
                            <td>
                              <span className="td-wrap-sty">
                                {currencyFormatter(
                                  item.topItems.turnover,
                                  item.currency
                                )}
                              </span>
                            </td>
                            {props.userDetail.userType !== "supplier" ? (
                              <td>
                                <Link
                                  to={`/supplier-listing?topSupplier=1`}
                                  className="d-flex  p-0 fz-12 dark-pink-clr"
                                >
                                  View
                                </Link>
                              </td>
                            ) : (
                              ""
                            )}
                            {/* <td>
                              <Link
                                to={`/port-listing?topPort=1`}
                                className="d-flex  p-0 fz-12 dark-pink-clr"
                              >
                                View
                              </Link>
                            </td> */}
                          </tr>
                        );
                    })
                  )}
                  <tr>
                    <td colSpan="11">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/catalogue-overview"
                          className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                        >
                          View all
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CollapsWrapper>
          </div>

          {/* Order Status Content */}
        </div>
      </div>
    </DashboardWrapper>
  );
}
const CollapsWrapper = React.memo((props) => {
  return (
    <div className="row dash-content-bx-columns dashborddemo">
      <div className="col-md-12">
        <div className="table-responsive">
          <div className="dash-chartInfo-table contr-figure-info dropdown">
            <div className="">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
const OrderStatusWrapper = React.memo((props) => {
  const [state, setState] = useState(false);
  return (
    <div className="d-flex  vessel-order ">
      <div className="content-box-wrap dash-content-bx mt-2 w-100">
        <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
          <span className="dropdown-icon d-inline-block">
            <i
              onClick={() =>
                state === false ? setState(true) : setState(false)
              }
              className={
                state === true ? "fa fa-angle-down" : "fa fa-angle-right"
              }
            ></i>
          </span>
          <div className="flex-fill justify-content-start ">
            <h2>{props.label}</h2>
          </div>
        </div>
        {state && (
          <div className="d-flex dash-ord-content ordContent-table cust-turnover text-left">
            <div className="table-responsive">
              <table className="table mt-1 table-light1 theme-table-wrap2 short-table">
                <tbody>{props.children}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  dashboardReducer: state.dashboardReducer,
  orderReducer: state.orderReducer,
  commonReducer: state.commonReducer,
  userDetail: {
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
    userId: state.authReducer.userId,
  },
  quotationList: state.quotationReducer.quotationList,
  quotationOrderList: state.quotationReducer.quotationOrderList,
  totalQuotation: state.quotationReducer.totalQuotation,
  supplierReducer: state.supplierReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  cartListing: (offset, filterQuery) =>
    dispatch(cartListing(offset, filterQuery)),
  supplierListing: (offset, type, filterQuery) =>
    dispatch(supplierListing(offset, type, filterQuery)),
  customerListing: (offset, type, filterQuery) =>
    dispatch(customerListing(offset, type, filterQuery)),
  topItemsListing: (userId, userType, from, to) =>
    dispatch(topItemsListing(userId, userType, from, to)),
  topVessel: (userId, userType, from, to) =>
    dispatch(topVessel(userId, userType, from, to)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  getAllVessel: () => dispatch(getAllVessel()),
  getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
  topSupplyHub: (userId, userType, from, to) =>
    dispatch(topSupplyHub(userId, userType, from, to)),
  topCustomer: (userId, userType, from, to) =>
    dispatch(topCustomer(userId, userType, from, to)),
  quotationListing: (offset, limit, type, isDetail) =>
    dispatch(quotationListing(offset, limit, type, isDetail)),
  getCurrentUserVessel: (userId, vesselId, dateFilter) =>
    dispatch(getCurrentUserVessel(userId, vesselId, dateFilter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SupplierDashboard);
