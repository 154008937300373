import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DashboardWrapper } from "./dashboarWrapper";
import { connect } from "react-redux";
import { getAllVessel } from "../../Common/ApiCalls/vesselList";
import { vesselListing } from "../../Vessel/ApiCalls/vessel";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import Sidebar from "../../Common/Components/sidebar";
import DashboardNavbar from "./../../Common/Components/DashboardNavbar";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import "./dashboard-style.css";

const fleetOverViewInitialState = {
  vesselOption: [],
  vessel: "",
  selectedOption: null,
  isHitApi: false,
  activePage: 0,
};
function FleetOverview(props) {
  const [state, setState] = useState(fleetOverViewInitialState);
  useEffect(() => {
    if (!state.isHitApi) {
      // props.getAllVessel();
      props.vesselListing(state.activePage);
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
    }
    // if (props.apiCallStatus.apiCallFor === "getAllVessel" &&
    //     props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
    //     let newArr = [];
    //     let arr = props.allVesselList;
    //     for (let i = 0; i < arr.length; i++) {
    //         newArr.push({ value: arr[i]._id, label: arr[i].name });
    //     }
    //     setState({
    //         ...state,
    //         vesselOption: newArr,
    //         isHitApi: true
    //     })
    //     props.ApiClearAction();
    // }
  });

  const handleSlectChange = (e) => {
    let data = { ...state };
    data["selectedOption"] = e;
    data["vessel"] = e.value;
    setState(data);
  };
  const fetchMoreData = () => {
    let state = { ...state };
    state.activePage += 1;
    setState(state);
    props.vesselListing(state.activePage);
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "green",
      name: "Go back",
      path: "/upgrademesh",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
  ];
  return (
    <DashboardWrapper
      title="Fleet overview"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="mt-lg-5 contract-dash-wrap fleet-overview-wrap">
        <div className="dashNew-ord-wrap">
          {/* YOUR NEW ORDER */}
          <div className="content-box-wrap dash-content-bx mb-2 d-flex flex-column contBx-2">
            {/* <div className="content-box-head ord-status-head d-flex align-items-center justify-content-start px-3">
                            <h2 className="font-weight-bold">FLEET OVERVIEW</h2>
                            <Select
                                        className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select One Vessel"
                                        value={state.selectedOption}
                                        onChange={(e) => handleSlectChange(e)}
                                        options={state.vesselOption}
                                    />
                            <div className="d-flex justify-content-end flex-fill">
                                        <button type="button" className="btn btn-link d-flex align-items-center p-0">View all</button>
                                    </div>
                        </div> */}
            {/* Order buttons wrap */}
            <div className="catalouge-details-wrap p-0">
              <InfiniteScroll
                dataLength={props.vesselReducer.list.length}
                next={fetchMoreData}
                height={"70vh"}
                hasMore={
                  props.vesselReducer.count <= props.vesselReducer.list.length
                    ? false
                    : true
                }
                loader={
                  <div
                    colSpan="20"
                    className="loadmore d-flex align-items-center justify-content-center"
                  >
                    {" "}
                    <img src={loader} alt="" />
                  </div>
                }
              >
                <table className="table theme-table-wrap2 short-table">
                  <thead>
                    <tr>
                      <th>VESSEL</th>
                      <th> RATE</th>
                      <th>OWNER</th>
                      <th>Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.vesselReducer.list.map((item, index) => {
                      return (
                        <tr key={"vesselReducer-" + index}>
                          <td>{item.name}</td>
                          <td className="semi-bold">{item.rate}</td>
                          <td>{item.owner}</td>
                          <td>{item.currency}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  allVesselList: state.commonReducer.vesselList,
  vesselReducer: state.vesselReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAllVessel: () => dispatch(getAllVessel()),
  vesselListing: (offset) => dispatch(vesselListing(offset)),
  ApiClearAction: () => dispatch(ApiClearAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FleetOverview);
