import React, { useEffect, useState } from 'react';
import { CsvDownload } from '../../Common/Components/CsvDownload';
import { getRfqList } from "../../Orders/ApiCalls/supplier";
import { connect } from 'react-redux';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import Select from 'react-select';
import './dashboard-style.css';
import { DashboardWrapper } from './dashboarWrapper';
const headers = [
    { label: 'Item Code', key: 'itemCode' },
    { label: 'IMPA', key: 'alternativeCode' },
    { label: 'Description', key: 'description' },
    { label: 'Quantity', key: 'quantity' },
    { label: 'Unit', key: 'standardPackagingUnit' },
    { label: 'Price Per Unit', key: 'pricePerCountry' },
    { label: 'Total Price', key: 'totalPrice' },
];
var csvLinkRef = React.createRef();
function OrderDetailDescription(props) {
    const [state, setState] = useState({
        currentOrder: {},
        search: null,
        selectedItemCode: null,
        itemCodeOptions: [
        ],
        orderDetail: [],
        csvData: [],
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (props.match.params.supplierId === '1234') {
                props.getRfqList(props.match.params.orderId, '', '', 'order')
            } else {
                props.getRfqList(props.match.params.orderId, props.match.params.supplierId, '', 'order')
            }
            let data = { ...state };
            data.isHitApi = true;
            setState(data);
        }
        if (props.apiCallStatus.apiCallFor === "getRfqList" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let itemCodeOptions = [];
            let csvData = [];
            for (let index = 0; index < props.rfqList.length; index++) {
                const element = props.rfqList[index];
                let order = element.quantity[0] && element.quantity[0].order ? element.quantity[0].order : 0;
                let obj = { label: element.itemCode, value: element.itemCode }
                let pricePerCountry = element.quantity && element.quantity[0] ? element.pricePerCountry.price.toFixed(2) : 0
                let csvObj = {
                    itemCode: element.itemCode,
                    alternativeCode: element.alternativeCode,
                    description: element.description,
                    quantity: order,
                    standardPackagingUnit: element.standardPackagingUnit,
                    pricePerCountry: pricePerCountry,
                    totalPrice: (order * pricePerCountry).toFixed(2)
                }
                itemCodeOptions.push(obj)
                csvData.push(csvObj)
            }
            setState({
                ...state,
                orderDetail: props.rfqList,
                csvData,
                itemCodeOptions: itemCodeOptions,
                isHitApi: true
            })
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor)
        }
    }, [props])
    const handleChange = (e) => {
        let data = { ...state };
        data.selectedItemCode = e;
        data.search = e.value;
        setState(data)
    }
    const clearFilters = () => {
        let data = { ...state };
        data.search = null;
        data.selectedItemCode = null;
        setState(data)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    const apiCallForCSVData = () => {
        csvLinkRef.current.link.click()
    }
    return (
        <DashboardWrapper title="Orders Details" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                            <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                                <Select
                                    className="custom-select-wrap small-select-sty" classNamePrefix="react-select" placeholder="Item Code"
                                    value={state.selectedItemCode}
                                    onChange={handleChange}
                                    options={state.itemCodeOptions}
                                />
                            </div>
                        </div>
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <button type="button" className="btn btn-yellow ml-1 mr-1" onClick={clearFilters} >Clear</button>
                            {
                                props.catalogueDetailLength > 0 ? <div className="d-flex flex-fill justify-content-end ml-1">
                                    <button type="button" className="btn btn-yellow bg-dark-red" onClick={() => props.removeAllCatalogues()} >Delete Catalogue</button>
                                </div> : ''
                            }
                            <CsvDownload data={state.csvData} csvLinkRef={csvLinkRef} filename={"Recent-Orders-Detail.csv"} headers={headers} apiCallForCSVData={() => apiCallForCSVData()} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-lg-5 d-flex flex-column flex-lg-row">
                <div className="dashVesl-overview-wrap">
                    <div className=" dash-content-bx">
                        <div className="catalouge-details-wrap p-0">
                            <table className="table theme-table-wrap2 short-table sticky-th-border">
                                <thead>
                                    <tr>
                                        <th className="text-left">Sr. No.</th>
                                        <th className="text-left">Item Code.</th>
                                        <th className="text-left">IMPA</th>
                                        <th className="text-left">Description</th>
                                        <th className="text-left">Quantity</th>
                                        <th className="text-left">Unit</th>
                                        <th className="text-left">Price Per Unit</th>
                                        <th className="text-left">Total Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.apiCallStatus.isStarted.indexOf("getRfqList") !== -1 ?
                                            <td colSpan="20">
                                                <div className="loader-img text-center">
                                                    <img style={{ width: "46px" }} src={loader} alt='' />
                                                </div>
                                            </td>
                                            : state.orderDetail && state.orderDetail.length > 0 ?
                                                state.orderDetail.filter((data) => {
                                                    if (state.search == null)
                                                        return data
                                                    else if (data.itemCode.includes(state.search)) {
                                                        return data
                                                    }
                                                }).sort((a, b) => a.alternativeCode - b.alternativeCode).map((item, index) => {
                                                    let price = item.quantity && item.quantity[0] ? parseFloat(item.pricePerCountry.price).toFixed(2) : 0
                                                        ;
                                                    return (
                                                        <tr keys={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.itemCode}</td>
                                                            <td>{item.alternativeCode}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item?.quantity[0]?.order}</td>
                                                            <td>{item.standardPackagingUnit}</td>
                                                            <td>{price}</td>
                                                            <td>{(item?.quantity[0]?.order * price)}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                state.orderDetail && state.orderDetail.length === 0 ?
                                                    <td colSpan="40" className="text-center"><div>No Data Found</div></td> : ''}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardWrapper>
    )

}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    rfqList: state.orderReducer.rfqList,
    currentOrder: state.orderReducer.currentOrder,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    getRfqList: (orderId, supplierId, type) => dispatch(getRfqList(orderId, supplierId, type)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetailDescription);
