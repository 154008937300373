import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetTopSupplierAction, GetTopCustomerAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { get, set } from "idb-keyval";

export function topSupplier(data, userType, from, to, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "topSupplier" }));
    let url = "";
    let bodyContent = {};
    url = Constant.apiURl + "/quotation/topSupplier";
    if (userType) {
      userType === "supplier" ? bodyContent['supplierId'] = data : userType === "customer" ? bodyContent['vesselId'] = data : bodyContent['customerId'] = data;
    }
    if (from && to) {
      bodyContent['from'] = from;
      bodyContent['to'] = to;
    }
    if (filterQuery && filterQuery.portId) {
      bodyContent['portId'] = filterQuery.portId;
    }
    if (filterQuery && filterQuery.itemCode) {
      bodyContent['itemCode'] = filterQuery.itemCode;
    }

    let myJson = await FETCH("POST", url, {
      ...bodyContent,
    });

    if (myJson && myJson.code === 201) {
      await set("topSuppliers", myJson);
      dispatch(GetTopSupplierAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "topSupplier",
          message: myJson.message,
        })
      );
    } else {
      const topSuppliers = await get("topSuppliers");
      if (topSuppliers) {
        dispatch(GetTopSupplierAction(topSuppliers.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "topSupplier",
            message: topSuppliers.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "topSupplier",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function topCustomer(data, userType, from, to, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "topCustomer" }));
    let url = "";
    let bodyContent = {};
    url = Constant.apiURl + "/quotation/topCustomer";
    if (userType) {
      userType === "supplier" ? bodyContent['supplierId'] = data : userType === "customer" ? bodyContent['vesselId'] = data : bodyContent['customerId'] = data;
    }
    if (from && to) {
      bodyContent['from'] = from;
      bodyContent['to'] = to;
    }
    if (filterQuery && filterQuery.supplierId && userType !== "supplier") {
      bodyContent['supplierId'] = filterQuery.supplierId;
    }
    if (filterQuery && filterQuery.itemCode) {
      bodyContent['itemCode'] = filterQuery.itemCode;
    }
    let myJson = await FETCH("POST", url, {
      ...bodyContent,
    });
    if (myJson && myJson.code === 201) {
      dispatch(GetTopCustomerAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "topCustomer",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "topCustomer",
          message: myJson.message,
        })
      );
    }
  };
}
