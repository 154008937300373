export const getOrder = "GET_ORDER";
export const AppendOrderList = "APPEND_ORDER_LIST";
export const getCart = "GET_CART";
export const getCartCsv = "GET_CART_CSV";
export const AppendCartList = "APPEND_CART_LIST";
export const getFleet = "GET_FLEET";
export const AppendFleetList = "APPEND_FLEET_LIST";

export function GetOrderAction(list, count) {
  return {
    type: getOrder,
    payload: { list, count },
  };
}
export function AppendOrderAction(list) {
  return {
    type: AppendOrderList,
    payload: { list },
  };
}
export function GetCartCsvAction(list, count) {
  return {
    type: getCartCsv,
    payload: { list, count },
  };
}
export function GetCartAction(list, count) {
  return {
    type: getCart,
    payload: { list, count },
  };
}

export function AppendCartAction(list) {
  return {
    type: AppendCartList,
    payload: { list },
  };
}

export function GetFleetAction(list, count) {
  return {
    type: getFleet,
    payload: { list, count },
  };
}
export function AppendFleetAction(list) {
  return {
    type: AppendFleetList,
    payload: { list },
  };
}
