import styled from "styled-components";

export const Wrapper = styled.div`
.dash-menubar{background:#4A4A4A;width:220px;min-width:220px;padding:15px;min-height:100vh;padding-right:7px;display: flex;flex-direction: column;}
.dash-logo-wrap img{max-height:56px}
.dash-menu-content .dash-menu-listing li{margin-bottom:10px}
.dash-menulinks,.dash-menu-item .btn.btn-link,.dash-menu-item .dropdown-item{font-weight:400;font-size:14px;color:#fff;text-decoration:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:0;white-space:pre-wrap}
.dash-menulinks:hover,.dash-menu-item .btn.btn-link:hover,.dash-menu-item .dropdown-item:hover,.dash-menu-item .dropdown-item:focus,.dash-menu-item .dropdown-item.active,.dash-menu-item .btn.btn-link:focus-within,.dash-menu-item .btn.btn-link:active{color:#85C33D;background:transparent}
.dash-menulinks img,.dash-menulinks i {margin-right:12px;width:22px;height:22px;line-height: normal;}
.dash-menulinks:hover img{-webkit-filter:invert(64%) sepia(52%) saturate(488%) hue-rotate(45deg) brightness(156%) contrast(115%);filter:invert(64%) sepia(52%) saturate(488%) hue-rotate(45deg) brightness(156%) contrast(115%)}
.dash-menu-item .btn.btn-link::after{position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);line-height: 0;}
.dash-menu-item .btn.btn-link.active::after {transform: translateY(-50%) rotate(180deg);}
.dash-menu-item .dropdown-menu {position:unset!important;-webkit-transform:translate(0,0)!important;-ms-transform:translate(0,0)!important;transform:translate(0,0)!important;float:none!important;padding:0;overflow:hidden;background-color:transparent;border:none;margin:0;padding-left:50px;margin-top:6px;}
.dash-menu-item .btn.btn-link{width:100%;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;position:relative;margin-bottom:0}
.dash-menu-item .dropdown-item{padding:0;margin-bottom:8px;font-size:13px}
.dash-menu-item .dropdown-item:last-child{margin-bottom:0}
.dash-menu-item .dropdown-item:hover,.dash-menu-item .dropdown-item:focus,.dash-menu-item .dropdown-item:active{background:transparent}
.dash-menu-content{margin-top:40px;max-height:calc(100vh - 130px);overflow:auto;padding-right:8px;flex: 1 1 auto;display: flex;}
.dash-menu-content ul{flex: 1 1 auto;display: flex;flex-direction: column;}
.ps{position:relative}
#sidebarListing .ps__rail-y{background-color:#ebebeb42;opacity:1;width:7px;border-radius:20px}
#sidebarListing .ps__thumb-y{background-color:#ebebeb6b;width:5px;right:1px}
.logout-link-item {margin-top: 50px;flex: 1 1 auto;display: flex;align-items: flex-end;}
.logout-link-item .dash-menulinks:hover,.logout-link-item .dash-menulinks:focus {color: #e6a935}
.logout-link-item .dash-menulinks:hover img,.logout-link-item .dash-menulinks:focus img {filter: invert(38%) sepia(62%) saturate(512%) hue-rotate(362deg) brightness(99%) contrast(91%);}
// Responsive Queries
@media only screen and (max-width:1600px) {
.dash-menulinks,.dash-menu-item .btn.btn-link {font-size:11px;line-height:normal}
.dash-menu-item .dropdown-item{font-size:10px;line-height:normal}
.dash-menulinks img,.dash-menulinks i{margin-right:6px;width:17px;height:17px}
.dash-logo-wrap img{max-height:70px}
.dash-menubar{width:190px;min-width:190px}
.dash-menu-item .dropdown-item{margin-bottom:10px}
.dash-menu-item .dropdown-menu{margin-top:10px;padding-left: 35px;}
}
@media only screen and (max-width:1280px) {
.dash-menubar{width:180px;min-width:180px}
}
@media only screen and (max-width:1025px) {
.dash-menubar{position:fixed;top:0;left:0;z-index:999;-webkit-transform:translateX(-100%);-ms-transform:translateX(-100%);transform:translateX(-100%);-webkit-transition:all .3s ease-in-out;-o-transition:all .3s ease-in-out;transition:all .3s ease-in-out}
.show{-webkit-transform:translateX(0);-ms-transform:translateX(0);transform:translateX(0);-webkit-transition:all .3s ease-in-out;-o-transition:all .3s ease-in-out;transition:all .3s ease-in-out}
}
`;