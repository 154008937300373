import React, { useEffect, useState } from "react";
import Routes from "./routes";
import Loader from "./Views/Common/Components/Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// import "../src/assets/js/jquery.scrolling-tabs.css";
// import "../src/assets/js/jquery.scrolling-tabs.js";
import "../src/assets/js/custom-style";
import "./App.css";
import { AlertBox } from './component/alert';

export default function App(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [message, setMessage] = useState({
    variant: '',
    text: ''
  });
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("load", function (params) {
      if (!isLoaded) {
        setIsLoaded(true);
      }
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("online", function () {
        console.log("You are online!");
        // setMessage("You are online!");
        setMessage({
          variant: 'success',
          text: 'You are online!'
        })
        setOpen(true);
        setTimeout(() => {
          setOpen(false)
        }, 8000)
      });
      window.addEventListener("offline", function () {
        console.log("Oh no, you lost your network connection.");
        // setMessage("Oh no, you lost your network connection.");
        setOpen(true);
        setMessage({
          variant: 'danger',
          text: 'Oh no, you lost your network connection.'
        })
        setTimeout(() => {
          setOpen(false)
        }, 8000)
      });
    }
  }, [window])

  return (
    <React.Fragment>
      {open && <AlertBox
        text={message.text}
        variant={message.variant}
        className="alert-box"
      />
      }
      {!isLoaded ? <Loader /> : ""}
      <Routes />
    </React.Fragment>
  );
}
