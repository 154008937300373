import * as Action from "../../../Views/Common/Actions/action";
import InitialState from "../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";

export default function CompanyReducer(state = InitialState.company, action) {
    switch (action.type) {
        case Action.getCompanies:
            let s = cloneDeep(state);
            s.list = action.payload.list;
            s.count = action.payload.count;
            return s;
        case Action.getCompanyDetail:
            let detailState = cloneDeep(state);
            detailState.detail = action.payload.detail;
            return detailState;
        case Action.getCompanyConfiguration:
            let configState = cloneDeep(state);
            configState.configuration = action.payload.detail?.configuration;
            return configState;
        default:
            return state;
    }
}