export const getOrderBasket = "GET_ORDER_BASKET";
export const updateIgnoreDepart = "UPDATE_IGNORE_DEPART";

export function basketOrdersAction(
  list,
  provisionBasketId,
  storeBasketId,
  provisionSupplier,
  storeSupplier,
  ignoreDepart,
  storeArrivalTime,
  provisionArrivalTime,
  proceedToSupplierComparison,
  remainingDepartments,
  vessels,
  provisionAdditionalBasketId,
  provisionAdditionalArrivalTime,
  storeAdditionalBasketId,
  storeAdditionalArrivalTime,
  provisionAdditionalSupplier,
  storeAdditionalSupplier
) {
  return {
    type: getOrderBasket,
    payload: {
      list,
      provisionBasketId,
      storeBasketId,
      provisionSupplier,
      storeSupplier,
      ignoreDepart,
      storeArrivalTime,
      provisionArrivalTime,
      proceedToSupplierComparison,
      remainingDepartments,
      vessels,
      provisionAdditionalBasketId,
      provisionAdditionalArrivalTime,
      storeAdditionalBasketId,
      storeAdditionalArrivalTime,
      provisionAdditionalSupplier,
      storeAdditionalSupplier
    },
  };
}

export function updateIgnoreDepartAction(data) {
  return {
    type: updateIgnoreDepart,
    payload: data,
  };
}
