import React from 'react';
import moment from "moment";
import { getVesselById } from '../../../../Common/ApiCalls/vesselList';

export function OrderOverView(props) {
    // const orderNeeded = props?.orderNeeded;
    const deptbudget = props?.deptBudget;
    const budget = props.vessel?.vesselType === "spot" ? props?.deptBudget : props?.orderNeeded;
    const remainingBudget = (budget - props.totalUsedPoints.total).toFixed(2);
    const usedPointInPercentage = (remainingBudget / budget) * 100;

    return (
        <div className=" wraper_cardbox ">
            <div className=" ordCatg-wrapper">
                <div className="card-bx-sty d-flex align-items-center flex-column flex-sm-row pb-1 pt-1">
                    <div
                        className="d-flex align-items-center flex-nowrap overflow-auto box-item-inn-wrap flex-fill mr-0 mb-0 py-0">
                        <div className="card-bx-sty box-item-inn">
                            <span className="text-nowrap semi-bold">Order Overview </span>
                            <span className="text-nowrap semi-bold">{props.department}: <span
                                className="stat-value-sty">{moment().format("DD-MM-YYYY")}</span></span>
                        </div>
                        {
                            !props.isAdditionalOrder &&
                            <div className="card-bx-sty box-item-inn">
                                <span className="text-nowrap  semi-bold">Remaining Budget : </span>
                                <span className="text-nowrap  semi-bold"><span
                                    className="stat-value-sty">{!isNaN(remainingBudget) ? remainingBudget : 0}</span> / <span
                                        className="stat-value-sty">{!isNaN(deptbudget) ? deptbudget.toFixed(2) : 0}</span>   =  <span
                                            className="stat-value-sty">{!isNaN(usedPointInPercentage) ? usedPointInPercentage.toFixed(2) : 0}</span> %</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}