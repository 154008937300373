export const getAllCountry = "GET_ALL_COUNTRY";
export const getAllDepartment = "GET_ALL_DEPARTMENT";
export const getCurrencyCode = "GET_CURRENCY_CODE";
export const getAllPort = "GET_ALL_PORT";
export const getTopSupplyHub = "GET_TOP_SUPPLY_HUB";
export const getTopSupplier = "GET_TOP_SUPPLIER";
export const getTopCustomer = "GET_TOP_CUSTOMER";
export const getAllCaptain = "GET_ALL_CAPTAIN";
export const getAllCook = "GET_ALL_COOK";
export const getAllRole = "GET_ALL_ROLE";
export const getAllVessel = "GET_ALL_VESSEL";
export const getVesselWiseStatList = "GET_VESSEL_WISE_STAT_LIST";
export const getVesselWiseStat = "GET_VESSEL_WISE_STAT";
export const getVesselKind = "GET_VESSEL_KIND";
export const getVesselById = "GET_VESSEL_BYID";
export const getVesselDropdown = "GET_VESSEL_DROPDOWN";
export const getCurrentUserVessel = "GET_CURRENT_USER_VESSEL";
export const getCutomer = "GET_CUSTOMER";
export const getCutomerEmail = "GET_CUSTOMER_EMAIL";
export const AppendCustomerList = "APPEND_CUSTOMER_LIST";
export const getCrewListing = "GET_CREW_LIST";
export const getRotationListing = "GET_ROTATION_LIST";
export const getOrderCount = "GET_ORDER_COUNT";
export const getTopVessel = "GET_TOP_VESSEL";
export const getCompanies = "GET_COMPANIES";
export const getCompanyDetail = "GET_COMPANY_DETAIL";
export const getCompanyConfiguration = "GET_COMPANY_CONFIGURATION";


export function GetOrderCountAction(count, userOrderId) {
  return {
    type: getOrderCount,
    payload: { count, userOrderId },
  };
}
export function GetAllCountryAction(list) {
  return {
    type: getAllCountry,
    payload: { list },
  };
}
export function GetAllDepartmentAction(list) {
  return {
    type: getAllDepartment,
    payload: { list },
  };
}
export function GetCurrencyCodeAction(list) {
  return {
    type: getCurrencyCode,
    payload: { list },
  };
}
export function GetAllPortAction(list) {
  return {
    type: getAllPort,
    payload: { list },
  };
}
export function GetTopSupplyHubAction(list) {
  return {
    type: getTopSupplyHub,
    payload: { list },
  };
}
export function GetTopSupplierAction(list) {
  return {
    type: getTopSupplier,
    payload: { list },
  };
}

export function GetTopCustomerAction(list) {
  return {
    type: getTopCustomer,
    payload: { list },
  };
}

export function GetAllCaptainAction(list) {
  return {
    type: getAllCaptain,
    payload: { list },
  };
}
export function GetAllCookAction(list) {
  return {
    type: getAllCook,
    payload: { list },
  };
}
export function GetAllRoleAction(list) {
  return {
    type: getAllRole,
    payload: { list },
  };
}
export function getAllVesselAction(list) {
  return {
    type: getAllVessel,
    payload: { list },
  };
}
export function getVesselDropdownAction(data) {
  return {
    type: getVesselDropdown,
    payload: { data },
  };
}
export function GetVesselByIdAction(data) {
  return {
    type: getVesselById,
    payload: { data },
  };
}
export function getCurrentUserVesselAction(list) {
  return {
    type: getCurrentUserVessel,
    payload: { list },
  };
}
export function getVesselKindAction(list) {
  return {
    type: getVesselKind,
    payload: { list },
  };
}
export function getVesselWiseStatListAction(list) {
  return {
    type: getVesselWiseStatList,
    payload: { list },
  };
}
export function getVesselWiseStatAction(list) {
  return {
    type: getVesselWiseStat,
    payload: { list },
  };
}
export function GetCustomerAction(list, count) {
  return {
    type: getCutomer,
    payload: { list, count },
  };
}
export function GetCustomerEmailAction(list, count) {
  return {
    type: getCutomerEmail,
    payload: { list, count },
  };
}

export function AppendCustomerAction(list) {
  return {
    type: AppendCustomerList,
    payload: { list },
  };
}
export function GetCrewListing(list) {
  return {
    type: getCrewListing,
    payload: { list },
  };
}
export function GetRotationListing(list) {
  return {
    type: getRotationListing,
    payload: { list },
  };
}
export function GetTopVesselAction(list) {
  return {
    type: getTopVessel,
    payload: { list },
  };
}

export function GetCompaniesAction(list, count) {
  return {
    type: getCompanies,
    payload: { list, count },
  };
}

export function GetCompanyDetailAction(detail) {
  return {
    type: getCompanyDetail,
    payload: { detail },
  };
}

export function GetCompanyConfigurationAction(detail) {
  return {
    type: getCompanyConfiguration,
    payload: { detail },
  };
}
