import { get, update } from 'idb-keyval';

const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const getHeaders = () => {
  const token = getCookie('token');
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
    headers['access-token'] = token;
  }
  return headers;
};

const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const getAllLocalStorageKeys = () => {
  /*
  Ref: https://stackoverflow.com/questions/17745292/how-to-retrieve-all-localstorage-items-without-knowing-the-keys-in-advance
  Author: Jason
  */
  let keys = [];
  for (let index = 0; index < localStorage.length; index++) {
    const key = localStorage.key(index);
    keys.push(key);
  }
  return keys;
};

const processAPIRequest = async (key) => {
  const body = localStorage.getItem(key);
  if (body) {
    try {
      const response = await fetch(key, {
        method: 'POST',
        body,
        headers: getHeaders(),
      });
      if (response.ok) {
        if (key.includes('/orderById')) {
          const resp = await response.json();
          const orderByIds = await get('orderByIds');
          const { orderId } = JSON.parse(body);
          const ret = { ...orderByIds, [orderId]: resp };
          update('orderByIds', (_) => ret);
        }
        // Remove the item from local storage after successfully sending
        localStorage.removeItem(key);
      } else {
        console.error(`Request with url '${key}' wasn't successful, error: ${response.statusText}`);
      }
    } catch (e) {
      console.error(`Caught Exception while calling API -> '${key}'. Error: ${e.message}`);
    }
  }
};

const sendQueuedRequests = async () => {
  const keys = getAllLocalStorageKeys();
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (key.startsWith('http')) {
      await processAPIRequest(key);
      await sleep(800);
    }
  }
};

export const register = () => {
  const url = `${process.env.PUBLIC_URL}/service-worker.js`;
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(url).then(() => console.log('Service worker registered successfully!'));
    window.addEventListener('online', (evt) => sendQueuedRequests());
  } else {
    console.log('Service worker isn"t ready!');
  }
};