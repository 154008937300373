import React, { Component } from "react";
import { SupplierWrapper } from "../supplierWrapper";
import {
  ApiClearAction,
  ClearApiByNameAction,
} from "../../../ApiCallStatus/Actions/action";
import {
  supplierListing,
  rateSupplier,
  getUserRate,
} from "../../ApiCalls/supplier";
import { connect } from "react-redux";
import { ProfileItem } from "./profileItem";
import { RatingModal } from "./model";
import $ from "jquery";

class Ranking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPortOption: null,
      selectedSortOption: null,
      portOptions: [
        { value: "singapore", label: "SingaPore" },
        { value: "china", label: "China" },
        { value: "russia", label: "Russia" },
      ],
      sortByOptions: [
        { value: "bestRate", label: "Best Rate" },
        { value: "fastest", label: "Fastes" },
        { value: "lowPrice", label: "Low Price" },
      ],
      filterQuery: `userId=${props.userId}&isRanked=true`,
      selectedSupplier: {},
    };
  }
  componentDidMount() {
    this.props.supplierListing(0, "supplier", "");
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.apiCallStatus.isStarted.indexOf("rateSupplier") !== -1 &&
      this.props.apiCallStatus.isStarted.indexOf("rateSupplier") === -1 &&
      this.props.apiCallStatus.isCompleted &&
      !this.props.apiCallStatus.isFailed
    ) {
      this.props.supplierListing(0, "supplier", this.state.filterQuery);
      $("#ratingModal").modal("hide");
    }
  }
  handlePortChange = (selectedPortOption) => {
    this.setState({ selectedPortOption });
  };
  handleSortChange = (selectedSortOption) => {
    this.setState({ selectedSortOption });
  };
  showRatingModal = (item) => {
    this.props.getUserRate(item, this.props.userId);
    this.setState(
      {
        selectedSupplier: item,
      },
      () => $("#ratingModal").modal("show")
    );
  };
  render() {
    return (
      <SupplierWrapper
        handleSortChange={this.handleSortChange}
        handlePortChange={this.handlePortChange}
        selectedPortOption={this.state.selectedPortOption}
        selectedSortOption={this.state.selectedSortOption}
        portOptions={this.state.portOptions}
        sortByOptions={this.state.sortByOptions}
        history={this.props.history}
        screenName="supplier-ranking"
      >
        {this.props.apiCallStatus.isStarted.indexOf("supplierListing") !==
        -1 ? (
          <div className="loader-img text-center w-100">
            <img
              style={{ width: "46px" }}
              src={require("../../../../assets/images/Spinner-1s-200px.gif")}
              alt=""
            />
          </div>
        ) : this.props.list.length === 0 &&
          this.props.apiCallStatus.isStarted.indexOf("supplierListing") ===
            -1 ? (
          <div className="noData-found">No Data Found</div>
        ) : (
          this.props.list.map((item, index) => {
            return (
              <ProfileItem
                {...item}
                key={"pItems-" + index}
                showRatingModal={this.showRatingModal}
              />
            );
          })
        )}
        <RatingModal
          rateSupplier={this.props.rateSupplier}
          customerId={this.props.userId}
          selectedSupplier={this.state.selectedSupplier}
          apiCallStatus={this.props.apiCallStatus}
          ClearApiByNameAction={this.props.ClearApiByNameAction}
        />
      </SupplierWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  list: state.supplierReducer.list,
  userId: state.authReducer.userId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  supplierListing: (offset, type, filterQuery) =>
    dispatch(supplierListing(offset, type, filterQuery)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  rateSupplier: (data) => dispatch(rateSupplier(data)),
  getUserRate: (supplierId, customerId) =>
    dispatch(getUserRate(supplierId, customerId)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Ranking);
