import React, { useState, useEffect } from "react";
import { getDashboardLink } from "../../../../Services/common";
import {
  quotationListing,
  sendToOffice,
  sendQuotation,
  compareQuotationList,
} from "../../Apicalls/quotations";
import { vesselListing } from "./../../../Vessel/ApiCalls/vessel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ApiClearAction,
  ClearApiByNameAction,
} from "../../../ApiCallStatus/Actions/action";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import moment from "moment";
import {
  capitalizeFirstLetter,
  getFormatedPricing,
} from "../../../../Services/common";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { CsvDownload } from "../../../Common/Components/CsvDownload";
import Swal from "sweetalert2";

const headers = [
  { label: "Vessel Name", key: "vesselName" },
  { label: "Customer Name", key: "customerName" },
  { label: "Type", key: "type" },
  { label: "Type of Order", key: "typeOfOrder" },
  { label: "Delivery Date", key: "nextDeliveryDate" },
  { label: "Delivery Place", key: "nextDeliveryPlace" },
  { label: "Nominated supplier", key: "supplierName" },
  { label: "Turnover", key: "rfqValue" },
  { label: "Status", key: "status" },
];
var csvLinkRef = React.createRef();
function InProgressQuotationList(props) {
  const [state, setState] = useState({
    isHitApi: false,
    qoutationList: [],
    customerVessels: [],
    totalCount: 0,
    activePage: 0,
    isDetail: false,
    isCsVDownload: false,
    captainId: "",
    csvData: [],
    selectedOrder: {},
  });
  const [collections, setCollectionsData] = useState([]);

  useEffect(() => {
    if (!state.isHitApi) {
      let captainId = props.subType === "captain" ? props.userId : "";
      let isDetail = props.userType === "supplier" ? false : true;
      if (props.subType === "customer") {
        props.vesselListing(0, 50);
      } else {
        props.quotationListing(state.activePage, 20, "rfq", isDetail);
      }
      let data = { ...state };
      data.isHitApi = true;
      data.captainId = captainId;
      data.isDetail = isDetail;
      setState(data);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getQuotation" ||
        props.apiCallStatus.apiCallFor === "appendQuotationList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      if (state.isCsVDownload) {
        csvLinkRef.current.link.click();
      }
      let csvData = [];
      for (let index = 0; index < props.quotationList.length; index++) {
        const element = props.quotationList[index];
        let csvObj = {
          vesselName:
            element.vesselId && element.vesselId.name
              ? element.vesselId.name
              : "",
          customerName: element.customerName,
          type:
            element.orderProvisionType[0].toUpperCase() +
            element.orderProvisionType.slice(1),
          nextDeliveryDate: moment(element.nextDeliveryDate).format(
            "DD.MM.YYYY"
          ),
          nextDeliveryPlace: element.nextDeliveryPlace,
          supplierName:
            element.supplierId && element.supplierId.name
              ? element.supplierId.name
              : "",
          rfqValue: element.rfqValue + " " + element.currency,
          status: element.status,
        };
        csvData.push(csvObj);
      }
      setState({
        ...state,
        qoutationList: props.quotationList,
        totalQuotation: props.totalQuotation,
        csvData,
        isCsVDownload: false,
        isHitApi: true,
      });
      props.ApiClearAction();
    }
    if (
      (props.apiCallStatus.apiCallFor === "vesselListing" ||
        props.apiCallStatus.apiCallFor === "appendVesselList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let customerVessels = [];
      if (props.subType === "customer" && props.vesselList.length > 0) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        props.quotationListing(0, 20, "rfq", customerVessels);
      }
      setState({
        ...state,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }

    if (
      props.apiCallStatus.apiCallFor === "sendToOffice" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      Swal.fire("Success!", "Sent to Office!", "success");
      props.quotationListing(state.activePage, 20, "rfq", props.userType !== "supplier");
    }

  }, [props, state.isHitApi]);

  const fetchMoreData = () => {
    let data = { ...state };
    data.activePage += 1;
    if (props.subType === "customer") {
      props.quotationListing(data.activePage, 20, "rfq", state.customerVessels);
    } else {
      props.quotationListing(
        data.activePage,
        20,
        "rfq",
        state.isDetail,
        state.captainId
      );
    }
    setState(data);
  };

  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
    {
      img: "Monitor-icon.svg",
      className: "dark-blue",
      name: "Dash<br />board",
      path: getDashboardLink(),
      isLogout: false,
    },
  ];

  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    if (props.subType === "customer") {
      props.quotationListing(
        0,
        state.totalQuotation,
        "rfq",
        state.customerVessels
      );
    } else {
      props.quotationListing(
        0,
        state.totalQuotation,
        "rfq",
        state.isDetail,
        state.captainId
      );
    }
    setState({
      ...state,
      isCsVDownload: true,
    });
  };

  const sendToOffice = (quotationId) => {
    Swal.fire({
      title: "Send to office",
      text: "Are you sure you want to send this quotation to Office?",
      icon: "question",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.sendToOffice(quotationId);
      }
    });
  };

  const isCaptain = props.subType === "captain";
  const isCustomer = props.userType === "customer";

  return (
    <DashboardWrapper
      title="RFQ overview (Current RFQs)"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="ordCatg-table-wrap mt-3 system-cat-filter">
        <div className="card-bx-sty">
          <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
              <CsvDownload
                data={state.csvData}
                csvLinkRef={csvLinkRef}
                filename={"Recent-RFQ.csv"}
                headers={headers}
                apiCallForCSVData={() => apiCallForCSVData()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Order Status Content */}
      <div className="row mt-lg-4 align-content-stretch newDash-contentStyle">
        <div className="col-12">
          <div className="content-box-wrap dash-content-bx">
            <div className="flex-fil text-left table-default-sty">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={state.qoutationList.length}
                  next={fetchMoreData}
                  height={"80vh"}
                  hasMore={
                    state.totalCount <= state.qoutationList.length
                      ? false
                      : true
                  }
                  loader={
                    <div
                      colSpan="12"
                      className="loadmore d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <img src={loader} alt="" />
                    </div>
                  }
                >
                  <table className="table mt-4 table-light1 bg-transparent rfq_overview">
                    <thead className="rounded-dark-head">
                      <tr>
                        <th>#</th>
                        {headers.map((item, inex) => {
                          return <th>{item.label}</th>;
                        })}
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody className="spacer">
                      {props.apiCallStatus.isStarted.indexOf("getQuotation") !==
                        -1 ? (
                        <tr>
                          <td colSpan="20">
                            <div className="loader-img text-center">
                              <img
                                style={{ width: "46px" }}
                                src={loader}
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                      ) : props.quotationList &&
                        props.quotationList.length > 0 ? (
                        props.quotationList.map((item, index) => {
                          // let status = props.userType === "supplier" ? item.status : item.orderDetail && item.orderDetail.orderStatus ? item.orderDetail.orderStatus : "inprogress";
                          const { status, typeOfOrder } = item;
                          const isAdditionalOrder = typeOfOrder?.toLowerCase()?.includes('additional');
                          const inProgressQuotation = status === 'inprogress';
                          const confirmedQuotation = status === 'confirmed';
                          const visibleToCustomer = isCustomer && confirmedQuotation && isAdditionalOrder;
                          const visibleToCaptain = isCaptain && confirmedQuotation;
                          const alreadySentToOffice = item.visibleToCustomer;
                          return (
                            <tr key={"ql11-" + index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.vesselId && item.vesselId.name
                                  ? item.vesselId.name
                                  : ""}
                              </td>
                              <td>{item.customerName}</td>
                              <td>
                                {capitalizeFirstLetter(item.orderProvisionType)}
                              </td>
                              <td>{typeOfOrder}</td>
                              <td>
                                {moment(item.nextDeliveryDate).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{item.nextDeliveryPlace}</td>
                              <td>
                                {item.supplierId && item.supplierId.name
                                  ? item.supplierId.name
                                  : ""}
                              </td>
                              <td>
                                {item.rfqValue
                                  ? getFormatedPricing(
                                    item.rfqValue,
                                    item.currency
                                  )
                                  : "0"}{" "}
                                {item.rfqValue ? item.currency : ""}
                              </td>
                              <td>
                                {inProgressQuotation ||
                                  status === "in-progress" ||
                                  status === "pending" ? (
                                  <>
                                    <span className="sta-indicator pend"></span>{" "}
                                    <span className="sta-text-wrap text-truncate"></span>
                                  </>
                                ) : confirmedQuotation ? (
                                  <>Quoted</>
                                ) : status === "pastorder" ? (
                                  <>
                                    <span className="sta-indicator past"></span>{" "}
                                    <span className="sta-text-wrap text-truncate"></span>
                                  </>
                                ) : (
                                  status
                                )}
                              </td>
                              <td className="text-center">
                                <div className="tb-buttons-wrap d-flex flex-column">
                                  <Link
                                    to={
                                      item.orderType === "order"
                                        ? "/confirm-order/" + item.orderId
                                        : confirmedQuotation
                                          ? "/prepare-quotation/" +
                                          `${item._id}?status=confirmed`
                                          : "/prepare-quotation/" + item._id
                                    }
                                    className={
                                      "btn tb-btn-rounded  " +
                                      (confirmedQuotation
                                        ? "green"
                                        : "orng")
                                    }
                                  >
                                    {confirmedQuotation
                                      ? "Quoted"
                                      : "View"}
                                  </Link>
                                  {
                                    confirmedQuotation && ((isCaptain && !isAdditionalOrder) || (isCustomer && isAdditionalOrder)) ? (
                                      <div className="mt-1">
                                        <Link
                                          to={`/supplier-comparison/${item.orderId}`}
                                          className="btn tb-btn-rounded orng"
                                        >
                                          Select Supplier
                                        </Link>
                                      </div>
                                    ) : ''
                                  }
                                  {
                                    isAdditionalOrder && visibleToCaptain && !alreadySentToOffice && <button className="btn tb-btn-rounded green mt-1" onClick={() => sendToOffice(item._id)}>Send to Office</button>
                                  }
                                </div>
                                {/* {!(props.userType === "supplier") &&
                                                                <div className="tb-buttons-wrap">
                                                                    {
                                                                        props.userType === 'customer' && status !== 'confirmed' ? '' :
                                                                            <Link to={"/compare-quotation/" + item._id} className={"btn tb-btn-rounded " + (status === "confirmed" ? "green" : "orng")}>{status === "confirmed" ? "View Quotations" : "Compare Quotations"}</Link>

                                                                    }
                                                                </div>} */}
                                {
                                  /*
                                  {!(props.userType === "supplier") && (
                                    <div className="tb-buttons-wrap">
                                      // <button type="button" className="btn btn-yellow" onClick={() => showOrderDetail(item.rfq)} >View Quotations</button>
                                      {props.userType === "customer" &&
                                        status !== "confirmed" ? (
                                        ""
                                      ) : (
                                        <Link
                                          to={
                                            "/supplier-comparison/" + item.orderId
                                          }
                                          className={
                                            "btn tb-btn-rounded " +
                                            (status === "confirmed"
                                              ? "green"
                                              : "orng")
                                          }
                                        >
                                          Select Supplier
                                        </Link>
                                      )}
                                    </div>
                                  )}
                                  */
                                }
                              </td>
                            </tr>
                          );
                        })
                      ) : props.quotationList &&
                        props.quotationList.length === 0 ? (
                        <tr>
                          <td colSpan="40" className="text-center">
                            <div>No Data Found</div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  quotationList: state.quotationReducer.quotationList,
  totalQuotation: state.quotationReducer.totalQuotation,
  quotationReducer: state.quotationReducer,
  vesselList: state.vesselReducer.list,
  userType: state.authReducer.userType,
  userId: state.authReducer.userId,
  subType: state.authReducer.subType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ApiClearAction: () => dispatch(ApiClearAction()),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  quotationListing: (offset, limit, type, isDetail, captainId) => dispatch(quotationListing(offset, limit, type, isDetail, captainId)),
  sendToOffice: (quotationId) => dispatch(sendToOffice(quotationId)),
  // sendQuotation: (data) => dispatch(sendQuotation(data)),
  // compareQuotationList: (quotationId, supplierId) => dispatch(compareQuotationList(quotationId, supplierId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InProgressQuotationList);
