import React, { useEffect, useState } from 'react';
import { CsvDownload } from '../../../../Common/Components/CsvDownload';
import CustomSelect from '../../../../Common/Components/CustomAutoComplete';

export function Filters(props) {
    // const [isLoaded, setIsLoaded] = useState(false);
    let url = window.location.pathname.split('/');
    let pathName = url[1];
    useEffect(() => {

    }, []);
    return (
        <div className=" wraper_cardbox ">
            <div className=" wraper_cardbox card-bx-sty fleet_vessel">
                <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                    {pathName === 'provision-catalogue' ?
                        <div className="fil-wrap-ctn d-flex align-items-center justify-content-center order_list ml-2">
                            <label className="text-nowrap mb-0">{props.orderKind !== "Inventory" ? "Ordered List" : "Inventory List"}
                                <div className="Slide-checkbx ml-2 justify-content-center" >
                                    <input type="checkbox" value="None" id="toggleOrderList" onChange={() => props.handleOrderedList(!props.showOrderedList)} checked={props.showOrderedList} />
                                    <label htmlFor="toggleOrderList"></label>
                                    <span className="indic-line"></span>
                                </div></label>
                        </div> : ""}
                    {!props.showOrderedList ?
                        <React.Fragment>
                            <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
                                {props.hideItemGroup ? "" :
                                    <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                                        <CustomSelect
                                            pagesList={props.itemGroupList}
                                            inputId="inputItemGroup"
                                            id="itemGroup"
                                            fetchMore={props.fetchMoreItemCode}
                                            pageListTotal={props.itemGroupList.length}
                                            value={props.itemGroup}
                                            inputValue={props.inputItemGroup}
                                            placeholder="Item Group"
                                            handleChange={props.handleChange}
                                            clickCustomeSelectHandler={props.clickCustomeSelectHandler}
                                        />
                                    </div>}
                                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                                    <CustomSelect
                                        pagesList={props.itemNoList}
                                        inputId="inputItemNo"
                                        id="itemNo"
                                        fetchMore={props.fetchMoreItemCode}
                                        pageListTotal={props.totalItemCode}
                                        value={props.itemNo}
                                        inputValue={props.inputItemNo}
                                        placeholder="Item No."
                                        handleChange={props.handleChange}
                                        clickCustomeSelectHandler={props.clickCustomeSelectHandler}
                                    />
                                </div>
                                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                                    <CustomSelect
                                        pagesList={props.descriptionList}
                                        inputId="inputDescription"
                                        id="description"
                                        fetchMore={props.fetchMoreDescription}
                                        pageListTotal={props.totalDescription}
                                        value={props.description}
                                        inputValue={props.inputDescription}
                                        placeholder="Description"
                                        handleChange={props.handleChange}
                                        clickCustomeSelectHandler={props.clickCustomeSelectHandler}
                                    />
                                </div>
                            </div>
                            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                                <button type="button" className=" btn btn-yellow  mr-1" onClick={() => props.clearFilters()} >Reset</button>
                                {pathName === 'provision-catalogue' && props.isInvenotry ? <button type="button" className=" btn btn-yellow  mr-1" onClick={() => props.downloadCatalogue()} >Download</button> : ""}
                                {pathName === 'provision-catalogue' ? '' :
                                    <CsvDownload data={props.data} csvLinkRef={props.csvLinkRef} filename={props.filename} headers={props.headers} apiCallForCSVData={props.apiCallForCSVData} />}
                            </div>
                        </React.Fragment> : ""}
                </div>
            </div>
        </div>
    );
}