import styled from "styled-components";
import EditIcon from "../../../assets/images/editpen-icon.svg";
import DltIcon from "../../../assets/images/delete-icon.svg";

export const Wrapper = styled.div`
.formBox{margin-top:0;border:1px solid #000;-webkit-box-shadow:0 4px 4px rgba(0,0,0,0.25);box-shadow:0 4px 4px rgba(0,0,0,0.25);border-radius:10px;padding:30px;height:100%;background:#EBEBEB}
.formBox h1{margin:0;padding:0;text-align:center;margin-bottom:50px;text-transform:uppercase;font-size:28px}
.formBox .form-group{position:relative;-webkit-box-sizing:border-box;box-sizing:border-box;margin-bottom:50px}
.formBox .form-group .inputText{position:absolute;font-size:24px;line-height:50px;-webkit-transition:.5s;-o-transition:.5s;transition:.5s;opacity:.5}
.formBox .form-group .form-control{position:relative;width:100%;height:40px;background-color:transparent;border:none;border-bottom:1px solid #979797;border-radius:0;padding-left:0;font-size:16px;outline:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;font-weight:500}
.formBox .form-group .form-control::-webkit-input-placeholder{color:#00000080;font-weight:500}
.formBox .form-group .form-control::-moz-placeholder{color:#00000080;font-weight:500}
.formBox .form-group .form-control:-ms-input-placeholder{color:#00000080;font-weight:500}
.formBox .form-group .form-control:-moz-placeholder{color:#00000080;font-weight:500}
.theme-table-wrap{background:#EBEBEB}
.uploadFile-wrap{width:40%;min-width:320px;border:1px dashed #b8b8b8;padding:20px;margin:0 auto;margin-bottom:30px;border-radius:10px}
.uploadFile-wrap .input-file-container{position:relative;width:225px;margin:0 auto}
.uploadFile-wrap .input-file-trigger{display:block;padding:10px 45px;background:#39D2B4;color:#fff;font-size:1em;-webkit-transition:all .4s;-o-transition:all .4s;transition:all .4s;cursor:pointer;text-align:center}
.uploadFile-wrap .input-file{position:absolute;top:0;left:0;width:225px;opacity:0;padding:14px 0;cursor:pointer}
.uploadFile-wrap .input-file:hover + .input-file-trigger,.uploadFile-wrap .input-file:focus + .input-file-trigger,.uploadFile-wrap .input-file-trigger:hover,.uploadFile-wrap .input-file-trigger:focus{background:#206F9E;color:#fff}
.uploadFile-wrap .file-return{margin:0}
.uploadFile-wrap .file-return:not(:empty){margin:1em 0}
.uploadFile-wrap .file-return{font-style:italic;font-size:13px;font-weight:600;color:#2e2e2e}
.uploadFile-wrap .file-return:not(:empty):before{content:"Selected file: ";font-style:normal;font-weight:400;font-size:14px}
.uploadFile-wrap h2,.form-opti-text{font-size:19px;margin-bottom:20px;color:#5e5e5e}

/* Table Style */
.table.theme-table-wrap thead th{vertical-align:bottom;border-bottom:2px solid #dee2e6;font-size:13px;font-weight:600;background: #f2f2f2;}
.table.theme-table-wrap tbody td{font-size:12px;font-weight:500;vertical-align: middle;}
.table.theme-table-wrap tbody tr:nth-child(even) td{background: #f2f2f2;}
.btn-edit-tab {background: url(${EditIcon}) no-repeat;background-size: 20px;width: 30px;height: 30px;opacity: 0.6;background-position: center;-webkit-transition: none !important;-o-transition: none !important;transition: none !important;}
.btn-dlt-tab {background: url(${DltIcon}) no-repeat;background-size: 20px;width: 30px;height: 30px;opacity: 0.6;background-position: center;-webkit-transition: none !important;-o-transition: none !important;transition: none !important;}
.btn-edit-tab:hover {opacity: 1;filter: invert(62%) sepia(68%) saturate(353%) hue-rotate(45deg) brightness(99%) contrast(91%);}
.btn-dlt-tab:hover {opacity: 1;-webkit-filter: invert(12%) sepia(81%) saturate(5841%) hue-rotate(11deg) brightness(89%) contrast(123%);filter: invert(12%) sepia(81%) saturate(5841%) hue-rotate(11deg) brightness(89%) contrast(123%);}

/* Ration Configuration Style */
.vessel-rate-wrap{width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:100%}
.vessel-rate-wrap .vess-rate-lis td{font-size:13px;color:#464646;border-top:none;padding:3px 12px}
.vessel-Ration-wrap .table.theme-table-wrap thead th:first-child{min-width:200px}
.vessel-Ration-wrap .table.theme-table-wrap thead th{white-space:pre}
.vessel-Ration-wrap .table.theme-table-wrap thead th{font-size:11px;min-width:100px;text-align:right;vertical-align:middle}
.vessel-Ration-wrap .table.theme-table-wrap tbody td{text-align:right}
.ves-admin-lis .form-group{width:300px;margin-bottom:20px!important}
.ves-admin-lis .form-group label{width:calc(100% - 82px);font-size:13px;margin-right:20px;text-align:right}
.ves-admin-lis .form-group .form-control{display:inline-block;font-size:13px;width:62px;min-width:62px;height:30px}

// Responsive Queries
@media (max-width: 1600px) {
    .uploadFile-wrap .input-file-trigger {padding: 7px 25px;font-size: 14px;}
    .uploadFile-wrap .input-file-container {width: 155px}
    .uploadFile-wrap .file-return,.uploadFile-wrap .file-return:not(:empty):before {font-size: 12px;}
    .table.theme-table-wrap thead th {font-size: 12px;}
    .table.theme-table-wrap tbody td {font-size: 11px;}
    .btn-dlt-tab,.btn-edit-tab {background-size: 16px;}
    .formBox .form-group .form-control {font-size: 13px;}
    .formBox .form-group {margin-bottom: 30px;}
    .pack-feature-wrap .form-control {height: 29px;font-size: 12px;line-height: normal;}
    .formBox h1 {font-size: 23px;margin-bottom: 35px;}
}
@media (max-width: 576px) {
    .formBox {padding: 20px;}
    .uploadFile-wrap {padding: 20px 10px;min-width: 230px;}
    .ves-admin-lis .form-group {width: 250px;}
}
`
