import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { DashboardWrapper } from "./dashboarWrapper";
import { connect } from "react-redux";
import { Constant } from "../../../Constants/constant";
import DatePicker from "react-datepicker";
import { quotationListing } from "../../Quotations/Apicalls/quotations";
import { cartListing, orderListing } from "../ApiCalls/orderListing";
import {
  topItemsListing,
  getOrderListing,
} from "./../../Orders/ApiCalls/provisionOrder";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { topCustomer } from "../../Common/ApiCalls/topSupplier";
import { vesselListing } from "./../../Vessel/ApiCalls/vessel";
import { topSupplyHub } from "../../Common/ApiCalls/topSupplyHub";
import {
  getVesselById,
  getVesselWiseStats,
  vesselWiseStatList,
} from "../../Common/ApiCalls/vesselList";
import { topVessel } from "../../Common/ApiCalls/topVessel";
import { topSupplier } from "../../Common/ApiCalls/topSupplier";
import {
  capitalizeFirstLetter,
  currencyFormatter,
  getFormatedPricing,
} from "../../../Services/common";
import "react-datepicker/dist/react-datepicker.css";
import "./dashboard-style.css";
import NewDashboard from "./NewDashboard";
import { VesselChartModal } from "../../Common/Components/vesselChartModal";

const OrderButtonWrapper = React.memo((props) => {
  const { userType } = props.userDetail;
  const isCustomer = userType === 'customer';
  return (
    isCustomer ?
      <div className="vessel_dash content-box-wrap dash-content-bx mb-2 mr-0 d-flex flex-column w-100">
        <>
          <div className="content-box-head ord-status-head">
            <h2 className="font-weight-bold">MESH* YOUR NEW ORDER:</h2>
          </div>
          <div className="dashOrder-buttons-wrap d-flex flex-column justify-content-center align-items-center flex-fill">
            {props.vesselId !== "" ? (
              <>
                <Link
                  to={`/create-provision-order/${props.vesselId}`}
                  className={`btn themeReguler-btn bg-dark-green`}
                >
                  PROVISION ORDER
                </Link>
                <Link
                  to={`/create-store-order/${props.vesselId}`}
                  className={`btn themeReguler-btn bg-dark-blue`}
                >
                  STORE ORDER
                </Link>
              </>
            ) : (
              <>
                <button className="btn themeReguler-btn bg-dark-green not-allowed">
                  PROVISION ORDER
                </button>
                <button className="btn themeReguler-btn bg-dark-blue not-allowed">
                  STORE ORDER
                </button>
              </>
            )}
          </div>
        </>
      </div> :
      <></>
  );
});

function ContractOffice(props) {
  const [state, setState] = useState({
    orderList: [],
    topItemsList: [],
    topSupplierList: [],
    topCustomerList: [],
    topSupplyHubList: [],
    topVesselList: [],
    qoutationList: [],
    vesselList: [],
    inventoryList: [],
    totalInventoryCount: [],
    activePage: 0,
    totalOrderCount: 0,
    totalTopItems: 0,
    vesselName: "",
    vesselPic: "",
    vesselId: "",
    customerVessels: [],
    vesselStatus: false,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    filterQuery: "",
    isFilter: false,
    isTopPort: false,
    isFleetOverview: false,
    isTopItem: false,
    isTopVessel: false,
    isTopCustomer: false,
    isTopSupplier: false,
    isHitApi: false,
    vesselWiseStatList: {},
    vesselWiseStats: [],
    showCatalogModal: "",
  });

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    let currentVesselId = query.get("vesselId");
    if (
      props.userDetail.isSubAdmin &&
      props.userDetail.userType === "user" &&
      props.userDetail.subType !== "subAdmin" &&
      props.userDetail.activeVesselId
    ) {
      currentVesselId = props.userDetail.activeVesselId;
    }
    if (
      currentVesselId !== null &&
      state.vesselId !== currentVesselId &&
      props.apiCallStatus.isStarted.indexOf("getVesselById") === -1 &&
      props.apiCallStatus.apiCallFor !== "getVesselById"
    ) {
      props.getVesselById(currentVesselId);
    }
    if (!state.isHitApi) {
      let data = { ...state };
      if (currentVesselId === null && !data.isFilter) {
        props.vesselListing(0, 5);
      }
      data.isHitApi = true;
      let from = moment(data.startDate).format("YYYY-MM-DD");
      let to = moment(data.endDate).format("YYYY-MM-DD");
      let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}`;
      let userType =
        props.userDetail.userType === "supplier"
          ? "supplier"
          : props.userDetail.subType === "captain"
            ? "captain"
            : props.userDetail.subType === "customer"
              ? "customer"
              : "";
      if (userType === "supplier") {
        cartQuery += `&supplierId=${props.userDetail.userId}`;
        props.cartListing(0, cartQuery);
      } else if (userType === "captain") {
        cartQuery += `&captainId=${props.userDetail.userId}`;
        props.cartListing(0, cartQuery);
      } else if (
        props.userDetail.userType === "user" &&
        (props.userDetail.subType === "user" ||
          props.userDetail.subType === "subAdmin")
      ) {
        props.cartListing(0, cartQuery);
      }
      if (
        props.userDetail.userType === "customer" ||
        props.userDetail.userType === "user"
      ) {
        setVesselWiseList();
      }
      if (
        props.userDetail.userType === "customer" ||
        props.userDetail.subType !== "customer"
      ) {
        props.topSupplyHub(props.userDetail.userId, userType, from, to);
        props.getOrderListing(0, 5, "?orderType=Inventory");
        props.topItemsListing(props.userDetail.userId, userType, from, to);
        props.topSupplier(props.userDetail.userId, userType, from, to);
        props.topVessel(props.userDetail.userId, userType, from, to);
        props.topCustomer(props.userDetail.userId, userType, from, to);
        props.quotationListing(0, 4, "rfq", true);
        props.quotationListing(0, 4, "order", true);
      }
      setState(data);
    }
    if (
      props.apiCallStatus.apiCallFor === "vesselWiseStatList" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      const firstVeselId = props.commonReducer.vesselWiseStatList[0]?._id;
      if (firstVeselId) {
        setVesselWiseStatList(firstVeselId);
        setState({
          ...state,
          vesselWiseStats: props.commonReducer.vesselWiseStatList,
          isHitApi: true,
        });
      }
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getVesselWiseStats" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        vesselWiseStatList: props.commonReducer.vesselWiseStats,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getCart" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        orderList: props.dashboardReducer.cartListing,
        totalOrderCount: props.dashboardReducer.totalCart,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topCustomer" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topCustomerList: props.commonReducer.topCustomerList,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getTopItems" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topItemsList: props.orderReducer.topItemsList,
        totalTopItems: props.orderReducer.totalTopItems,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topSupplyHub" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topSupplyHubList: props.commonReducer.topSupplyHubList,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topSupplier" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topSupplierList: props.commonReducer.topSupplierList,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topVessel" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topVesselList: props.commonReducer.topVesselList,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      currentVesselId === null &&
      (props.apiCallStatus.apiCallFor === "vesselListing" ||
        props.apiCallStatus.apiCallFor === "appendVesselList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let customerVessels = [];
      if (
        props.userDetail.subType === "customer" &&
        props.vesselList.length > 0
      ) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        let from = moment(state.startDate).format("YYYY-MM-DD");
        let to = moment(state.endDate).add(1, "days").format("YYYY-MM-DD");
        let newArray = JSON.stringify(customerVessels);
        let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}&vesselId=${newArray}`;
        props.cartListing(0, cartQuery);
        props.topSupplyHub(customerVessels, "customer", from, to);
        props.topSupplier(customerVessels, "customer", from, to);
        props.topItemsListing(customerVessels, "customer", from, to);
        props.getOrderListing(
          0,
          5,
          `?orderType=Inventory&vesselId=${newArray}`
        );
        props.topVessel(customerVessels, "customer", from, to);
        props.topCustomer(customerVessels, "customer", from, to);
        props.quotationListing(0, 4, "rfq", customerVessels);
      }
      let index = props.vesselList.findIndex((x) => x.active === true);
      if (index === -1 && props.vesselList.length > 0) {
        index = 0;
      }
      setState({
        ...state,
        vesselName:
          props.vesselList.length > 0 ? props.vesselList[index].name : "",
        vesselPic:
          props.vesselList.length > 0 ? props.vesselList[index].pic : "",
        vesselId:
          props.vesselList.length > 0 ? props.vesselList[index]._id : "",
        vesselStatus:
          props.vesselList.length > 0 ? props.vesselList[index].active : false,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getOrder" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let inventoryList = props.orderReducer.orderList;
      let totalInventoryCount = props.orderReducer.totalOrder;
      setState({
        ...state,
        inventoryList,
        totalInventoryCount,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getQuotation" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        qoutationList: props.quotationList.slice(0, 5),
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getVesselById" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      if (
        state.vesselId !== props.commonReducer.vesselById._id &&
        Object.keys(props.commonReducer.vesselById).length > 0
      ) {
        setState({
          ...state,
          vesselName: props.commonReducer.vesselById.name,
          vesselPic: props.commonReducer.vesselById.pic,
          vesselId: props.commonReducer.vesselById._id,
          vesselStatus: props.commonReducer.vesselById.active,
          isHitApi: true,
        });
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      }
    }
  }, [props, state.isHitApi]);

  const setVesselWiseList = async () => {
    await props.vesselWiseStatList();
  };
  const setVesselWiseStatList = async (id, orderProvisionType) => {
    await props.getVesselWiseStats(id, orderProvisionType);
  };
  const handleCatalogModalChange = (val) => {
    setState({
      ...state,
      showCatalogModal: val,
    });
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
      isHitApi: false,
      isFilter: true,
    });
  };
  const navbarOptions = [
    {
      img: "Cancel.svg",
      className: "red",
      name: "Logout",
      path: "/",
      isLogout: true,
    },
  ];
  const collapsHandle = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  return (
    <DashboardWrapper
      title={
        props.userDetail.isSubAdmin &&
          props.userDetail.userType === "user" &&
          props.userDetail.subType !== "sub-admin"
          ? `${props.userDetail.roleName} Dashboard`
          : props.userDetail.userType === "supplier"
            ? "Supplier Dashboard"
            : props.userDetail.userType === "customer"
              ? "Customer Dashboard"
              : "Admin Panel"
      }
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {(props.userDetail.userType === "user" ||
        props.userDetail.userType === "customer") && (
          <>
            <div className="d-flex  ord-vessl-wrap">
              <OrderButtonWrapper userDetail={props.userDetail} vesselId={props.userDetail.activeVesselId} />
            </div>
            <NewDashboard
              vesselWiseStats={props.commonReducer.vesselWiseStatList}
              vesselWiseStatList={props.commonReducer.vesselWiseStats}
              handleCatalogModalChange={(e) => handleCatalogModalChange(e)}
              setVesselWiseStatList={(e, orderProvisionType) =>
                setVesselWiseStatList(e, orderProvisionType)
              }
            />
          </>
        )}
      {props.userDetail.userType === "user" && (
        <>
          {/* <NewDashboard1 />
                <NewDashboard2 /> */}
        </>
      )}

      <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-center ">
        {/* Current Order Table */}
        <OrderStatusWrapper
          apiCallStatus={props.apiCallStatus}
          label="Current Orders"
          link={state.orderList.length === 0 ? "#" : "/orderOverview"}
        >
          {props.apiCallStatus.isStarted.indexOf("getCart") !== -1 ||
            props.apiCallStatus.isStarted.indexOf("getOrder") !== -1 ? (
            <tr>
              <td colSpan="20">
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          ) : props.quotationOrderList &&
            props.quotationOrderList.length === 0 ? (
            <tr>
              <td colSpan="40" className="text-center">
                <div>No Data Found</div>
              </td>
            </tr>
          ) : (
            props.quotationOrderList.map((item, index) => {
              let status =
                item.isSupplied && item.status === "confirmed"
                  ? "Supplied"
                  : item.status &&
                  item.status[0].toUpperCase() + item.status.slice(1);
              return (
                <tr key={"Orders-" + index}>
                  <td>
                    {item.vesselId && item.vesselId.name
                      ? item.vesselId.name
                      : ""}
                  </td>
                  <td>{item.customerName}</td>
                  <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                  <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                  <td>{item.nextDeliveryPlace}</td>
                  <td>
                    {item.supplierId && item.supplierId.name
                      ? item.supplierId.name
                      : ""}
                  </td>
                  <td>{currencyFormatter(item.rfqValue, item.currency)}</td>
                  <td>{status}</td>
                </tr>
              );
            })
          )}
        </OrderStatusWrapper>
        {/* Current RFQs Section */}
        <OrderStatusWrapper
          apiCallStatus={props.apiCallStatus}
          label="Current RFQ's"
          link={
            state.qoutationList.length === 0 ? "#" : "/inprogress-quotations"
          }
        >
          {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ? (
            <tr>
              <td colSpan="20">
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          ) : state.qoutationList && state.qoutationList.length === 0 ? (
            <tr>
              <td colSpan="40" className="text-center">
                <div>No Data Found</div>
              </td>
            </tr>
          ) : (
            state.qoutationList.map((item, index) => {
              return (
                <tr key={"RFQ-" + index}>
                  <td>{item?.vesselId?.name}</td>
                  <td>{item.customerName}</td>
                  <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                  <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                  <td>{item.nextDeliveryPlace}</td>
                  <td>
                    {item.supplierId && item.supplierId.name
                      ? item.supplierId.name
                      : ""}
                  </td>
                  <td>
                    <td>{currencyFormatter(item.rfqValue, item.currency)}</td>
                  </td>
                  <td>
                    {item.status === "inprogress" ||
                      item.status === "pending" ? (
                      <>
                        <span>Open</span>
                      </>
                    ) : (
                      <>
                        <span>Quoted</span>
                      </>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </OrderStatusWrapper>
      </div>

      <div className="mt-lg-2 d-flex contract-dash-wrap cont-ves-wrap">
        <div className="dashVesl-overview-wrap">
          <div className="content-box-wrap dash-content-bx">
            <div className="vessel-overview-wrap d-flex align-items-start">
              <div className="content-box-head d-flex align-items-center justify-content-center head-transparent w-100 mb-2 flex-wrap">
                {/* <h2 className="font-weight-bold dark-clr mr-3">OPERATING FIGURES</h2> */}
                {/* Date Range Picker */}
                <div className="date-range-picker d-flex align-items-center">
                  <span className="d-inline-block mr-1">
                    <DatePicker
                      selected={state.startDate}
                      onChange={(val) => handleChange("startDate", val)}
                      className="link-style"
                    />
                  </span>
                  to
                  <span className="d-inline-block ml-1">
                    <DatePicker
                      selected={state.endDate}
                      onChange={(val) => handleChange("endDate", val)}
                      className="link-style"
                    />
                  </span>
                </div>
                {/* <button type="button" className="btn btn-link d-flex align-items-center p-0 dark-clr">View complete list</button> */}
              </div>
            </div>
            {/* Top Customers Section */}
            {props.userDetail.userType !== "customer" ? (
              <CollapsWrapper>
                <div className="table-responsive">
                  <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                    <thead>
                      <tr>
                        <th
                          className="small-unit40"
                          onClick={() =>
                            collapsHandle("isTopCustomer", !state.isTopCustomer)
                          }
                        >
                          <span className="dropdown-icon d-inline-block">
                            <i
                              className={
                                state.isTopCustomer
                                  ? "fa fa-angle-down"
                                  : "fa fa-angle-right"
                              }
                            ></i>
                          </span>
                        </th>
                        {state.isTopCustomer === false ? (
                          <th className="text-center">Top Customer</th>
                        ) : (
                          <>
                            <th>Customer Name</th>
                            <th>Number of Supplies</th>
                            <th>Turnover P-MESH</th>
                            <th>Turnover C-MESH</th>
                            <th>Total Turnover</th>
                            <th>Vessels</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody
                      className={`custom-dropdown-menu ${state.isTopCustomer ? "show" : ""
                        }`}
                    >
                      {props.apiCallStatus.isStarted.indexOf("topCustomer") !==
                        -1 ? (
                        <td colSpan="20">
                          <div className="loader-img text-center">
                            <img
                              style={{ width: "46px" }}
                              src={require("../../../assets/images/Spinner-1s-200px.gif")}
                              alt=""
                            />
                          </div>
                        </td>
                      ) : state.topCustomerList &&
                        state.topCustomerList.length === 0 ? (
                        <td colSpan="40" className="text-center">
                          <div>No Data Found</div>
                        </td>
                      ) : (
                        state.topCustomerList &&
                        state.topCustomerList.length > 0 &&
                        state.topCustomerList.map((item, index) => {
                          if (index < 5)
                            return (
                              <tr key={"topCustomer-" + index}>
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.count}</td>
                                <td>
                                  {currencyFormatter(
                                    item?.pMeshVolume,
                                    item.currency
                                  )}
                                </td>
                                <td>
                                  {currencyFormatter(
                                    item?.cMeshVolume,
                                    item.currency
                                  )}
                                </td>

                                <td>
                                  {currencyFormatter(
                                    item?.totalVolume,
                                    item.currency
                                  )}
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/vessel-listing?fleetOverview=1&customerId=${item?._id}&customerEmail=${item?.email}`}
                                    className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            );
                        })
                      )}
                      {state.topCustomerList &&
                        state.topCustomerList.length > 0 ? (
                        <tr>
                          <td colSpan="11">
                            <div className="d-flex justify-content-end">
                              <Link
                                to="/customer-listing?topCustomer=1"
                                className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                              >
                                View all
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
              </CollapsWrapper>
            ) : (
              ""
            )}
            {/* Top Vessels Section */}
            <CollapsWrapper>
              <div className="table-responsive">
                <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                  <thead>
                    <tr>
                      <th
                        className="small-unit40"
                        onClick={() =>
                          collapsHandle("isTopVessel", !state.isTopVessel)
                        }
                      >
                        <span className="dropdown-icon d-inline-block">
                          <i
                            className={
                              state.isTopVessel
                                ? "fa fa-angle-down"
                                : "fa fa-angle-right"
                            }
                          ></i>
                        </span>
                      </th>
                      {state.isTopVessel === false ? (
                        <th className="text-center">Top Vessel</th>
                      ) : (
                        <>
                          <th>Vessel Name</th>
                          <th>Vessel Type</th>
                          {props.userDetail.userType !== "customer" && (
                            <th>Customer Name</th>
                          )}
                          <th>Number of Supplies</th>
                          <th>Turnover P-MESH</th>
                          <th>Turnover C-MESH</th>
                          <th>Total Turnover</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className={`custom-dropdown-menu ${state.isTopVessel ? "show" : ""
                      }`}
                  >
                    {props.apiCallStatus.isStarted.indexOf("topVessel") !==
                      -1 ? (
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    ) : state.topVesselList &&
                      state.topVesselList.length === 0 ? (
                      <td colSpan="40" className="text-center">
                        <div>No Data Found</div>
                      </td>
                    ) : (
                      state.topVesselList &&
                      state.topVesselList.length > 0 &&
                      state.topVesselList.map((item, index) => {
                        let storeBudget = "";

                        if (item.departments && item.departments.length > 0) {
                          let departments = item.departments;
                          storeBudget = departments
                            .map((s) => parseInt(s.budget))
                            .reduce(
                              (prev, next) => parseInt(prev) + parseInt(next)
                            );
                        }

                        if (index < 5)
                          return (
                            <tr key={"topVessel-" + index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.type}</td>
                              {props.userDetail.userType !== "customer" && (
                                <td>{item.customerName}</td>
                              )}
                              <td>{item.suppliedCount}</td>
                              <td>
                                {currencyFormatter(
                                  item.pMeshVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  item.cMeshVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  item.totalVolume,
                                  item.currency
                                )}
                              </td>
                            </tr>
                          );
                      })
                    )}
                    {state.topVesselList && state.topVesselList.length > 0 ? (
                      <tr>
                        <td colSpan="11">
                          <div className="d-flex justify-content-end">
                            <Link
                              to={
                                props.userDetail.userType === "customer"
                                  ? "/vessel-listing?topVessel=1"
                                  : "/vessel-listing?fleetOverview=1"
                              }
                              className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                            >
                              View all
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </CollapsWrapper>
            {/* Top Ports Section */}
            <CollapsWrapper>
              <div className="table-responsive">
                <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                  <thead>
                    <tr>
                      <th
                        className="small-unit40"
                        onClick={() =>
                          collapsHandle("isTopPort", !state.isTopPort)
                        }
                      >
                        <span className="dropdown-icon d-inline-block">
                          <i
                            className={
                              state.isTopPort
                                ? "fa fa-angle-down"
                                : "fa fa-angle-right"
                            }
                          ></i>
                        </span>
                      </th>
                      {state.isTopPort === false ? (
                        <th className="text-center">Top Port</th>
                      ) : (
                        <>
                          <th>Port Name</th>
                          <th>Country</th>
                          <th>Number of supplies</th>
                          <th>Turnover P-MESH</th>
                          <th>Turnover C-MESH</th>
                          <th>Total Turnover</th>
                          <th>Suppliers</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className={`custom-dropdown-menu ${state.isTopPort ? "show" : ""
                      }`}
                  >
                    {props.apiCallStatus.isStarted.indexOf("topSupplyHub") !==
                      -1 ? (
                      <tr>
                        <td colSpan="20">
                          <div className="loader-img text-center">
                            <img
                              style={{ width: "46px" }}
                              src={require("../../../assets/images/Spinner-1s-200px.gif")}
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    ) : state.topSupplyHubList &&
                      state.topSupplyHubList.length === 0 ? (
                      <tr>
                        <td colSpan="40" className="text-center">
                          <div>No Data Found</div>
                        </td>
                      </tr>
                    ) : (
                      state.topSupplyHubList &&
                      state.topSupplyHubList.length > 0 &&
                      state.topSupplyHubList.map((item, index) => {
                        if (index < 5)
                          return (
                            <tr key={"topPort-" + index}>
                              <td>{index + 1}</td>
                              <td>{item.port}</td>
                              <td>{item.countryName}</td>
                              <td>{item.orderCount}</td>
                              <td>
                                {currencyFormatter(
                                  item.pMeshVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  item.cMeshVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  item.totalVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                <Link
                                  to={`/supplier-listing?topSupplier=1&portId=${item._id}`}
                                  className="d-flex  p-0 fz-12 dark-pink-clr"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          );
                      })
                    )}
                    {state.topSupplyHubList &&
                      state.topSupplyHubList.length > 0 ? (
                      <tr>
                        <td colSpan="11">
                          <div className="d-flex justify-content-end">
                            <Link
                              to="/port-listing?topPort=1"
                              className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                            >
                              View all
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </CollapsWrapper>
            {/* Top Suppliers Section */}
            <CollapsWrapper>
              <div className="table-responsive">
                <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                  <thead>
                    <tr>
                      <th
                        className="small-unit40"
                        onClick={() =>
                          collapsHandle("isTopSupplier", !state.isTopSupplier)
                        }
                      >
                        <span className="dropdown-icon d-inline-block">
                          <i
                            className={
                              state.isTopSupplier
                                ? "fa fa-angle-down"
                                : "fa fa-angle-right"
                            }
                          ></i>
                        </span>
                      </th>
                      {state.isTopSupplier === false ? (
                        <th className="text-center">Top Suppliers</th>
                      ) : (
                        <>
                          <th>Supplier Name</th>
                          <th>Country</th>
                          <th>Number of Supplies</th>
                          <th>Turnover P-MESH</th>
                          <th>Turnover C-MESH</th>
                          <th>Total Turnover</th>
                          {props.userDetail.userType !== "customer" && (
                            <th>Vessels </th>
                          )}
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className={`custom-dropdown-menu ${state.isTopSupplier ? "show" : ""
                      }`}
                  >
                    {props.apiCallStatus.isStarted.indexOf("topSupplier") !==
                      -1 ? (
                      <tr>
                        <td colSpan="20">
                          <div className="loader-img text-center">
                            <img
                              style={{ width: "46px" }}
                              src={require("../../../assets/images/Spinner-1s-200px.gif")}
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    ) : state.topSupplierList &&
                      state.topSupplierList.length === 0 ? (
                      <tr>
                        <td colSpan="40" className="text-center">
                          <div>No Data Found</div>
                        </td>
                      </tr>
                    ) : (
                      state.topSupplierList &&
                      state.topSupplierList.length > 0 &&
                      state.topSupplierList.map((item, index) => {
                        if (index < 5)
                          return (
                            <tr key={"topSupliers-" + index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.countryName}</td>
                              <td>{item.totalOrder}</td>
                              <td>
                                {currencyFormatter(
                                  item.pMeshVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  item.cMeshVolume,
                                  item.currency
                                )}
                              </td>
                              <td>
                                {currencyFormatter(
                                  item.totalVolume,
                                  item.currency
                                )}
                              </td>

                              {props.userDetail.userType !== "customer" && (
                                <td>
                                  <Link
                                    to={`/vessel-listing?topVessel=1&supplierId=${item._id}&portId=${item.portId}`}
                                    className="d-flex  p-0 fz-12 dark-pink-clr"
                                  >
                                    View
                                  </Link>
                                </td>
                              )}
                            </tr>
                          );
                      })
                    )}
                    {state.topSupplierList &&
                      state.topSupplierList.length > 0 ? (
                      <tr>
                        <td colSpan="11">
                          <div className="d-flex justify-content-end">
                            <Link
                              to="/supplier-listing?topSupplier=1"
                              className="btn btn-link d-flex align-items-center p-0 dark-pink-clr fz-14"
                            >
                              View all
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </CollapsWrapper>
            {/* Top Items Collapse  */}
            <CollapsWrapper>
              <div className="table-responsive">
                <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                  <thead>
                    <tr>
                      <th
                        className="small-unit40"
                        onClick={() =>
                          collapsHandle("isTopItem", !state.isTopItem)
                        }
                      >
                        <span className="dropdown-icon d-inline-block">
                          <i
                            className={
                              state.isTopItem
                                ? "fa fa-angle-down"
                                : "fa fa-angle-right"
                            }
                          ></i>
                        </span>
                      </th>
                      {state.isTopItem === false ? (
                        <th className="text-center">Top Items</th>
                      ) : (
                        <>
                          <th>Item Description</th>
                          <th>Item group</th>
                          <th>IMPA</th>
                          <th>Unit</th>
                          <th>Number of Supplies</th>
                          <th>Quantity</th>
                          <th>Total Turnover</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody
                    className={`custom-dropdown-menu ${state.isTopItem ? "show" : ""
                      }`}
                  >
                    {props.apiCallStatus.isStarted.indexOf("getTopItems") !==
                      -1 ? (
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    ) : state.topItemsList &&
                      state.topItemsList.length === 0 ? (
                      <td colSpan="40" className="text-center">
                        No Data Found
                      </td>
                    ) : (
                      state.topItemsList.map((item, index) => {
                        if (index < 5)
                          return (
                            <tr key={"topItems-" + index}>
                              <td>{index + 1}</td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.description}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.category}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.alternativeCode}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.standardPackagingUnit}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.supplies}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.quantity}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {currencyFormatter(
                                    item.topItems.turnover,
                                    item.currency
                                  )}
                                </span>
                              </td>
                            </tr>
                          );
                      })
                    )}
                    <tr>
                      <td colSpan="11">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/catalogue-overview"
                            className="btn btn-link d-flex align-items-center p-0 fz-14 dark-pink-clr"
                          >
                            View all
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CollapsWrapper>
          </div>

          {/* Order Status Content */}
        </div>
      </div>
      <VesselChartModal
        vesselWiseStats={props.commonReducer.vesselWiseStatList}
        vesselWiseStatList={props.commonReducer.vesselWiseStats}
        showModal={state.showCatalogModal}
        handleModalChange={(val) => handleCatalogModalChange(val)}
      />
    </DashboardWrapper>
  );
}
const CollapsWrapper = React.memo((props) => {
  return (
    <div className="row dash-content-bx-columns dashborddemo">
      <div className="col-md-12">
        <div className="table-responsive">
          <div className="dash-chartInfo-table contr-figure-info dropdown">
            <div className="">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
});

const OrderStatusWrapper = React.memo((props) => {
  const [state, setState] = useState(false);
  return (
    <div className="d-flex flex-row dashVesl-overview-wrap vessel-order ">
      <div className="content-box-wrap dash-content-bx mt-2 w-100">
        <div className="status-wrap-head text-center content-box-head d-flex align-items-center flex-nowrap overflow-auto">
          <span className="dropdown-icon d-inline-block">
            <i
              onClick={() =>
                state === false ? setState(true) : setState(false)
              }
              className={
                state === true ? "fa fa-angle-down" : "fa fa-angle-right"
              }
            ></i>
          </span>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <h2>{props.label}</h2>
          </div>
        </div>
        {state && (
          <div className="d-flex dash-ord-content ordContent-table cust-turnover text-left">
            <div className="table-responsive">
              <table className="table mt-1 table-light1 theme-table-wrap2 short-table">
                <tbody>{props.children}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  dashboardReducer: state.dashboardReducer,
  orderReducer: state.orderReducer,
  commonReducer: state.commonReducer,
  supplierReducer: state.supplierReducer,
  quotationList: state.quotationReducer.quotationList,
  quotationOrderList: state.quotationReducer.quotationOrderList,
  vesselList: state.vesselReducer.list,
  userDetail: {
    userType: state.authReducer.userType,
    permission: state.authReducer.permission,
    isSubAdmin: state.authReducer.isSubAdmin,
    roleName: state.authReducer.roleName,
    userId: state.authReducer.userId,
    activeVesselId: state.authReducer.activeVesselId,
    subType: state.authReducer.subType,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  vesselWiseStatList: () => dispatch(vesselWiseStatList()),
  getVesselWiseStats: (id, orderProvisionType) =>
    dispatch(getVesselWiseStats(id, orderProvisionType)),
  cartListing: (offset, filterQuery) =>
    dispatch(cartListing(offset, filterQuery)),
  orderListing: (offset, filterQuery) =>
    dispatch(orderListing(offset, filterQuery)),
  getOrderListing: (offset, limit, filterQuery) =>
    dispatch(getOrderListing(offset, limit, filterQuery)),
  topSupplier: (userId, userType, from, to) =>
    dispatch(topSupplier(userId, userType, from, to)),
  topVessel: (userId, userType, from, to) =>
    dispatch(topVessel(userId, userType, from, to)),
  topCustomer: (userId, userType, from, to) =>
    dispatch(topCustomer(userId, userType, from, to)),
  topItemsListing: (userId, userType, from, to) =>
    dispatch(topItemsListing(userId, userType, from, to)),
  topSupplyHub: (userId, userType, from, to) =>
    dispatch(topSupplyHub(userId, userType, from, to)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  getVesselById: (vesselId) => dispatch(getVesselById(vesselId)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  quotationListing: (offset, limit, type, isDetail, captainId) =>
    dispatch(quotationListing(offset, limit, type, isDetail, captainId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContractOffice);
