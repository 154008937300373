import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetAllCountryAction, GetCurrencyCodeAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { get, set } from "idb-keyval";

export function getAllCountry(query) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllCountry" }));
        let url = Constant.apiURl + "/country";
        const partialCountries = query?.includes("countryCode");
        if (query && query !== "") {
            url += query;
        }
        const key = partialCountries ? "partialCountries" : "allCountries";
        const myJson = await FETCH("GET", url);
        if (myJson && myJson.code === 201) {
            await set(key, myJson);
            dispatch(GetAllCountryAction(myJson.data));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllCountry",
                    message: myJson.message,
                })
            );
        } else {
            const allCountries = await get(key);
            if (allCountries) {
                dispatch(GetAllCountryAction(allCountries.data));
                dispatch(
                    Actions.ApiFulfilledAction({
                        apiCallFor: "getAllCountry",
                        message: allCountries.message,
                    })
                );
            } else {
                dispatch(
                    Actions.ApiRejectedAction({
                        statusCode: allCountries.statusCode,
                        apiCallFor: "getAllCountry",
                        message: allCountries.message
                    })
                );
            }
        }
    };
}

export function getCurrencyCode() {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCurrencyCode" }));
        let myJson = await FETCH("GET", Constant.apiURl + "/filter/currency/code/0/200");
        if (myJson && myJson.code === 201) {
            dispatch(GetCurrencyCodeAction(myJson.data));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCurrencyCode",
                    message: myJson.message,
                }));
        }
        else {
            dispatch(Actions.ApiRejectedAction({ statusCode: myJson.statusCode, apiCallFor: "getCurrencyCode", message: myJson.message }));
        }
    };
}