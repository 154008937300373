import React from 'react';
import Sidebar  from './sidebar';
import { CommonWrapper } from './../Css/CommonWrapper';
import DemoHeader from '../../Common/Components/demoHeader';

const assignClass = window.location.pathname === "/contract-office" ? "dash-page-content contract-office-ctn" :  window.location.pathname === "/supplier-dashboard" ? "dash-page-content supplierDash-ctn-wrap" : "dash-page-content contrVessel-Dash-wrap";

export const Wrapper = ({ children }) => (
    <CommonWrapper>
        <div className="vesselOverview-cont-wrap form-content-ctn d-flex">
            <Sidebar />
            <div className={assignClass}>
                {/* <DemoHeader/> */}
                <div className="dash-pg-inn-wrap">
                    {children}
                </div>
            </div>
        </div>
    </CommonWrapper>
);