import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Wrapper, DashboardNavbar } from '../../Common/Components/CommonWrapper';
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';
import { MarketDashWrap } from './../Css/MarketStyleSheet';

function MarketDashboard(props) {
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Edit-icon.svg',
            className: "dark-green",
            name: "Create<br />Country",
            path: "/create-country",
            isLogout: false
        }
    ];
    return (
        <DashboardWrapper title="The Mesh * Market" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" >
            <MarketDashWrap>
                <div className="market-content-row d-flex mt-lg-5">
                    <div className="content-box-wrap stores-select-wrap dash-content-bx d-flex flex-column w-100">
                        <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
                            <h2 className="font-weight-bold text-center w-100">THE MESH*WORLD</h2>
                        </div>
                        {/* Order buttons wrap */}
                        <div className="dashOrder-buttons-wrap flex-fill">
                            <div className="row align-items-start">
                                <div className="col-sm-6 d-flex flex-column align-items-center">
                                    <button type="button" className="btn themeReguler-btn bg-dark-blue">STORES</button>
                                    <button type="button" className="btn themeReguler-btn bg-sky-blue">CREW BAG</button>
                                    <button type="button" className="btn themeReguler-btn bg-dark-brown">CERFTIFICATE MANAGEMENT</button>
                                    <button type="button" className="btn themeReguler-btn bg-dark-grey">ROPES & WIRES</button>
                                    <button type="button" className="btn themeReguler-btn bg-dark-yellow">MEDICINE</button>
                                </div>
                                <div className="col-sm-6 d-flex flex-column align-items-center">
                                    <button type="button" className="btn themeReguler-btn bg-dark-green">PROVISIONS</button>
                                    <button type="button" className="btn themeReguler-btn bg-dark-blue">SPARE PARTS</button>
                                    <button type="button" className="btn themeReguler-btn bg-dark-red">IHM- DOCUMENTATION</button>
                                    <button type="button" className="btn themeReguler-btn bg-olive">e-COOKING</button>
                                    <button type="button" className="btn themeReguler-btn bg-grey">COACHING</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-box-wrap dash-content-bx d-flex flex-column w-100">
                        <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
                            <h2 className="font-weight-bold text-center w-100">NEWSBOARD</h2>
                        </div>
                        {/* Order buttons wrap */}
                        <div className="dashOrder-buttons-wrap d-flex flex-fill market-inner-row">
                            <div className="mark-brand-tags-list d-flex align-content-start flex-wrap">
                                <a href="#" className="m-tags-items">
                                    <img src={require('../../../assets/images/market-img1.png')} className="img-fluid" alt="image" />
                                </a>
                                <a href="#" className="m-tags-items">
                                    <img src={require('../../../assets/images/market-img2.png')} className="img-fluid" alt="image" />
                                </a>
                                <a href="#" className="m-tags-items">
                                    <img src={require('../../../assets/images/market-img3.png')} className="img-fluid" alt="image" />
                                </a>
                                <a href="#" className="m-tags-items">
                                    <img src={require('../../../assets/images/market-img4.png')} className="img-fluid" alt="image" />
                                </a>
                                <a href="#" className="m-tags-items">
                                    <img src={require('../../../assets/images/market-img5.png')} className="img-fluid" alt="image" />
                                </a>
                                <a href="#" className="m-tags-items">
                                    <img src={require('../../../assets/images/market-img6.png')} className="img-fluid" alt="image" />
                                </a>
                            </div>
                            <div className="dashOrder-buttons-wrap flex-fill d-flex flex-column align-items-center">
                                <button type="button" className="btn themeReguler-btn bg-dark-yellow">EVENTS</button>
                                <button type="button" className="btn themeReguler-btn bg-dark-yellow">EXHIBITIONS</button>
                                <button type="button" className="btn themeReguler-btn bg-dark-yellow">WEBINARS</button>
                                <button type="button" className="btn themeReguler-btn bg-dark-yellow">EDUCATION</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="market-content-row chat-content-row d-flex">
                    <div className="content-box-wrap dash-content-bx d-flex flex-column w-100 mar-chat-box p-0 mr-3">
                        <div className="chat-img-wrap">
                            <img src={require('../../../assets/images/chat-box.png')} className="img-fluid" alt="image" />
                        </div>
                    </div>
                    <div className="content-box-head chat-search-loc-ctn">
                        <div className="content-box-wrap dash-content-bx stores-select-wrap chat-loc-wrap d-flex flex-column w-100 mr-0 mb-2">
                            <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
                                <h2 className="font-weight-bold text-center w-100">Contact Location Board</h2>
                            </div>
                            {/* Order buttons wrap */}
                            <div className="dashOrder-buttons-wrap flex-fill">
                                <form className="d-flex flex-fill flex-column h-100">
                                    <div className="form-group d-flex align-items-center justify-content-end mb-2">
                                        <label className="mr-2">Where:</label>
                                        <input type="text" className="form-control" value="Hamburg, Germany" />
                                    </div>
                                    <hr className="thin-hr mb-2 mt-0" />
                                    <div className="form-group d-flex align-items-center justify-content-end">
                                        <label className="mr-2">What:</label>
                                        <input type="text" className="form-control" value="Pump repair" />
                                    </div>
                                    <div className="d-flex justify-centent-center flex-fill align-items-end">
                                        <button type="button" className="btn themeReguler-btn bg-dark-green">SEARCH</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="content-box-wrap dash-content-bx stores-select-wrap chat-loc-wrap d-flex flex-column w-100 mr-0">
                            <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
                                <h2 className="font-weight-bold text-center w-100">Contact Proposals:</h2>
                            </div>
                            {/* Order buttons wrap */}
                            <div className="dashOrder-buttons-wrap contact-proposal flex-fill">
                                <form className="d-flex flex-fill flex-column h-100">
                                    <div className="form-group d-flex align-items-center justify-content-end mb-2">
                                        <input type="text" className="form-control" value="Köster Pumpen GmbH" />
                                        <button type="button" className="btn themeReguler-btn bg-dark-green">Contact</button>
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-end">
                                        <input type="text" className="form-control" value="AP-Pumpen" />
                                        <button type="button" className="btn themeReguler-btn bg-dark-green">Contact</button>
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-end">
                                        <input type="text" className="form-control" value="A-Z heko Pumpen GmbH" />
                                        <button type="button" className="btn themeReguler-btn bg-dark-green">Contact</button>
                                    </div>
                                    <div className="form-group d-flex align-items-center justify-content-end">
                                        <input type="text" className="form-control" value="Dudek Pumpen GmbH & Co.KG" />
                                        <button type="button" className="btn themeReguler-btn bg-dark-green">Contact</button>
                                    </div>
                                    <div className="d-flex justify-centent-center flex-fill align-items-end">
                                        <button type="button" className="btn themeReguler-btn bg-dark-yellow">Show More</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </MarketDashWrap>
        </DashboardWrapper>
    )
}
export default MarketDashboard;