import { get, set } from "idb-keyval";
import { Constant } from "../../../Constants/constant";
import { getCookie } from "../../../Services/cookies";
import { FETCH } from "../../../Services/fetch";
import * as Actions from "../../ApiCallStatus/Actions/action";
import {
  AppendQuotationAction,
  AppendQuotationOrderAction,
  GetQuotationAction,
  GetQuotationOrderAction,
  getQuotationDetailAction,
} from "../Actions/action";

export function quotationListing(offset, limit, type, isDetail) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendQuotationList" })
      );
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getQuotation" }));
      dispatch(GetQuotationAction([], 0));
    }
    let url =
      Constant.apiURl + "/quotation/" + offset + `/${limit}?type=${type}`;
    if (getCookie("userType") === "supplier") {
      url += `&supplierId=${getCookie("userId")}`;
    } else if (getCookie("userType") === "customer") {
      url += `&userId=${getCookie("userId")}`;
    } else if (getCookie("subType") === "captain") {
      url += `&userId=${getCookie("userId")}`;
    }
    if (isDetail === true) {
      url += "&isDetail=true";
    } else if (getCookie("subType") === "customer" && isDetail) {
      let arr = JSON.stringify(isDetail);
      url += `&isDetail=true&vesselId=${arr}`;
    }
    // MEANS THIS API IS BEING CALLED FROM DASHBOARD
    const DASHBOARD_ROUTES = ['contract-vessel', 'supplier-dashboard'];
    // const DASHBOARD_ROUTES = ['contract-vessel', 'supplier-dashboard', 'contract-office'];
    const callingFromDashboard = DASHBOARD_ROUTES.some((route) => window.location.pathname.includes(route));
    if (callingFromDashboard) {
      url += '&confirmed=false';
    }
    let myJson = await FETCH("GET", url);
    const key = type === "rfq" ? "myRfqs" : "myOrders";
    if (myJson && myJson.code === 201) {
      await set(key, myJson);
      if (offset !== 0) {
        if (type === "order") {
          dispatch(AppendQuotationOrderAction(myJson.data));
        } else {
          dispatch(AppendQuotationAction(myJson.data));
        }
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendQuotationList",
            message: "",
          })
        );
      } else {
        if (type === "order") {
          dispatch(GetQuotationOrderAction(myJson.data, myJson.count));
        } else {
          dispatch(GetQuotationAction(myJson.data, myJson.count));
        }
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getQuotation",
            message: myJson.message,
          })
        );
      }
    } else {
      const myJson = await get(key);
      if (myJson) {
        if (offset !== 0) {
          if (type === "order") {
            dispatch(AppendQuotationOrderAction(myJson.data));
          } else {
            dispatch(AppendQuotationAction(myJson.data));
          }
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "appendQuotationList",
              message: "",
            })
          );
        } else {
          if (type === "order") {
            dispatch(GetQuotationOrderAction(myJson.data, myJson.count));
          } else {
            dispatch(GetQuotationAction(myJson.data, myJson.count));
          }
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "getQuotation",
              message: myJson.message,
            })
          );
        }
      } else {
        if (offset !== 0) {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "appendCountryList",
              message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
            })
          );
        } else {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "getCountry",
              message: myJson.message,
            })
          );
        }
      }
    }
  };
}

export function getQuotationDetail(quotationId, supplierId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getQuotationDetail" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/quotation/items", {
      supplierId: supplierId,
      quotationId: quotationId,
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        getQuotationDetailAction(
          myJson.data,
          myJson.basketId,
          myJson.orderDetail,
          myJson.adminCatalogs,
        )
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getQuotationDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getQuotationDetail",
          message: myJson.message,
        })
      );
    }
  };
}

export function compareQuotationList(orderId) {
  return async (dispatch) => {
    dispatch(
      Actions.ApiRequestedAction({ apiCallFor: "compareQuotationList" })
    );
    let myJson = await FETCH("POST", Constant.apiURl + "/quotation/compare", {
      orderId,
    });
    if (myJson && myJson.code === 201) {
      let data = myJson.data ? myJson.data.sort(compareSupplier) : [];
      // let arr = [];
      let orderDetail = myJson.orderDetail;
      if (orderDetail.itemDetail && orderDetail.itemDetail.length > 0) {
        orderDetail.itemDetail = toObject(orderDetail.itemDetail);
      }
      // for (let index = 0; index < 3; index++) {
      //   arr.push(myJson.data[0])
      // }
      dispatch(getQuotationDetailAction(data, orderId, orderDetail, []));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "compareQuotationList",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "compareQuotationList",
          message: myJson.message,
        })
      );
    }
  };
}

export function sendQuotation(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "sendQuotation" }));
    let myJson = await FETCH("PUT", Constant.apiURl + "/quotation", data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "sendQuotation",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "sendQuotation",
          message: myJson.message,
        })
      );
    }
  };
}

export function saveQuotation(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "saveQuotation" }));
    const myJson = await FETCH("PUT", `${Constant.apiURl}/quotation/update/values`, data);
    if (myJson && myJson.code === 200) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "saveQuotation",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "saveQuotation",
          message: myJson.message,
        })
      );
    }
  };
}

function compareSupplier(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.supplier.title.toUpperCase();
  const nameB = b.supplier.title.toUpperCase();
  // const nameA = a.supplier.name.toUpperCase();
  // const nameB = b.supplier.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

function toObject(arr) {
  var rv = {};
  for (var i = 0; i < arr.length; ++i) {
    let itemCode = arr[i].itemCode;
    rv[itemCode] = arr[i];
  }
  return rv;
}

export function sendToOffice(quotationId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "sendToOffice" }));
    const myJson = await FETCH("PATCH", `${Constant.apiURl}/quotation/${quotationId}/send-to-office`);
    if (myJson && myJson.statusCode === 200) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "sendToOffice",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "sendToOffice",
          message: myJson.message,
        })
      );
    }
  };
}

export function changeStatus(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "changeStatus" }));
    const myJson = await FETCH("PATCH", `${Constant.apiURl}/quotation/set-status`, data);
    if (myJson && myJson.code === 200) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "changeStatus",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "changeStatus",
          message: myJson.message,
        })
      );
    }
  };
}