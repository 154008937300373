import { get, set } from "idb-keyval";
import { getAllLocalStorageKeys } from "../../../Services/common";
import { getCookie } from "../../../Services/cookies";
import { FETCH } from "../../../Services/fetch";
import * as Actions from "../../ApiCallStatus/Actions/action";
import {
  AddOrderAction,
  AppendFilterDetailAction,
  AppendOrderAction,
  AppendProvisionAction,
  GetFilterDetailAction,
  GetOrderAction,
  GetProvisionAction,
  GetTopItemsAction,
  getNextOrderQtyAction,
} from "../Actions/action";
import { Constant } from "./../../../Constants/constant";

const getDetailFromOrderSaveAPI = () => {
  let countryName = '';
  let totalBudgetPoints = 0.0;
  let orderProvisionType = '';
  try {
    const allLocalStroageKeys = getAllLocalStorageKeys();
    const reqKey = allLocalStroageKeys.find((key) => key.includes("orders/save"));
    if (reqKey) {
      const value = localStorage.getItem(reqKey);
      const { payloads } = JSON.parse(value);
      const lastIndex = payloads?.length - 1;
      const lastPayload = payloads[lastIndex];
      if (lastPayload && Object.keys(lastPayload).length > 0) {
        const { budgetPerDay, country, numberOfCrew, orderKind, orderPeriod } = lastPayload;
        const NO_OF_DAYS = orderKind === 'Fresh Top Up' ? orderPeriod?.freshProvisions?.regular : orderPeriod?.dryFrozen?.regular;
        totalBudgetPoints = budgetPerDay * NO_OF_DAYS * numberOfCrew?.regular;
        countryName = country?.name || '';
        orderProvisionType = lastPayload?.orderProvisionType;
      }
    }
  } catch (e) {
    console.error(`Something went wrong: ${e}`);
  }
  return {
    countryName,
    totalBudgetPoints,
    orderProvisionType
  };
};

const calculateBudgetPoints = async (orderDetails) => {
  const clone = [...orderDetails];

  let countryName = "";
  let totalBudgetPoints = 0.0;

  if (navigator.onLine) {
    const currentOrder = await get("currentOrder");
    ({ countryName, totalBudgetPoints } = currentOrder);
  } else {
    ({ countryName, totalBudgetPoints } = getDetailFromOrderSaveAPI());
  }

  const ret = clone.map((record) => {
    const { priceAvg, pricePerCountry } = record;
    let budgetPoints = 0.0;
    let price = 0.0;
    let filteredCountryArr = [...pricePerCountry];

    const countryNames = filteredCountryArr.map(({ name }) => name);
    const countryNameExists = countryNames.includes(countryName);

    if (countryNameExists) {
      filteredCountryArr = filteredCountryArr.find(({ name }) => name === countryName);
      price = filteredCountryArr?.price || 0.0;
      if (!price) price = priceAvg;
    } else {
      price = priceAvg;
    }

    try {
      budgetPoints = totalBudgetPoints > 0.0
        ? parseFloat(((price / totalBudgetPoints) * 10000))
        : 0.0;
    } catch (e) {
      console.error(`
        Something went wrong while calculating budget points: ${e}.
        filteredCountryArr: ${filteredCountryArr}.
        countryName: ${countryName}
      `);
      budgetPoints = 0.0;
    }

    return {
      ...record,
      budgetPoints,
    };
  });
  return ret;
};

const fetchValueFromQuery = (query, key) => {
  let val = "";

  if (query) {
    if (query.includes("&")) {
      const ret = query.split("&").filter((r) => r.includes(key));
      const cc = ret[0].replaceAll("?", "").split("=");
      val = Array.isArray(cc) ? decodeURIComponent(cc[1]) : "";
    } else {
      val = query.split(key)[1].replaceAll("=", "");
    }
  }

  return val;
};

const getCatalogsKey = (filterQuery) => {
  let keyPrefix = "";
  let key = "";
  const val = fetchValueFromQuery(filterQuery, "systemType");

  if (filterQuery) {
    if (val === "p") {
      keyPrefix = "adminProvision";
    } else if (val === "s") {
      keyPrefix = "adminStore";
    } else if (val === "provisionAdditional") {
      keyPrefix = "adminProvisionAdditional";
    } else if (val === "storeAdditional") {
      keyPrefix = "adminStoreAdditional";
    }
    key = `${keyPrefix}Catalogs`;
  }

  return key;
};

export function provisionListing(offset, limit, filterQuery) {
  return async (dispatch) => {
    let url = "";
    if (filterQuery !== "" && filterQuery !== undefined) {
      url =
        Constant.apiURl +
        "/catalouge/items/" +
        offset +
        "/" +
        limit +
        filterQuery;
    } else {
      url = Constant.apiURl + "/catalouge/items/" + offset + "/" + limit;
    }
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendProvisionList" })
      );
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getProvision" }));
      dispatch(GetProvisionAction([], 0));
    }

    const key = getCatalogsKey(filterQuery);
    const showingAdminCatalogs = filterQuery.includes("showAdminCatalogs=true");
    const itemGroupExists = filterQuery.includes("itemGroup=");
    const itemCodeExists = filterQuery.includes("itemCode=");
    const altCode2Exists = filterQuery.includes("alternativeCode2=");
    const descriptionExists = filterQuery.includes("description=");
    const durabilityExists = filterQuery.includes("durability=f");
    const orderIdExists = filterQuery.includes("orderId=");

    let myJson = null;

    if (showingAdminCatalogs) {
      myJson = await get(key);
    }

    if (!showingAdminCatalogs || !myJson) {
      myJson = await FETCH("GET", url);
      await set(key, myJson);
    }

    // let orderProvisionType = "";

    // if (navigator.onLine) {
    //   const currentOrder = await get("currentOrder");
    //   if (currentOrder) {
    //     orderProvisionType = currentOrder?.orderProvisionType;
    //   }
    // } else {
    //   // https://stackoverflow.com/questions/48714689/javascript-re-assign-let-variable-with-destructuring
    //   ({ orderProvisionType } = getDetailFromOrderSaveAPI());
    // }

    // const isProvisionOrder = orderProvisionType === "provision";

    let clone = [...myJson.data];

    if (itemGroupExists) {
      const itemGroup = fetchValueFromQuery(filterQuery, "itemGroup");
      const isProvisionOrder = window.location.pathname.includes("provision");
      clone = clone.filter(({ category, orderType }) => isProvisionOrder ? category === itemGroup : orderType === itemGroup);
      // clone = clone.filter(({ category, orderType }) => category === itemGroup || orderType === itemGroup);
    }
    if (itemCodeExists) {
      const itmCode = fetchValueFromQuery(filterQuery, "itemCode");
      clone = clone.filter(({ itemCode }) => itemCode.includes(itmCode));
    }
    if (altCode2Exists) {
      const altCode = fetchValueFromQuery(filterQuery, "alternativeCode2");
      clone = clone.filter(({ alternativeCode }) => alternativeCode?.toString()?.includes(altCode));
    }
    if (descriptionExists) {
      const desc = fetchValueFromQuery(filterQuery, "description");
      clone = clone.filter(({ description }) => description === desc || description?.toLowerCase()?.includes(desc));
    }
    if (durabilityExists) {
      const FRESH_PROVISIONS = ['BEVERAGES', 'DAIRY PRODUCTS'];
      clone = clone.filter(({ category }) => FRESH_PROVISIONS.includes(category));
    }
    if (orderIdExists) {
      clone = await calculateBudgetPoints(clone);
    }

    const startIndex = offset > 0 ? offset * limit : offset;
    clone = clone.slice(startIndex, startIndex + limit);

    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendProvisionAction(clone));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendProvisionList",
            message: "",
          })
        );
      } else {
        dispatch(GetProvisionAction(clone, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getProvision",
            message: myJson.message,
          })
        );
      }
    } else {
      if (myJson) {
        if (offset !== 0) {
          dispatch(AppendProvisionAction(clone));
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "appendProvisionList",
              message: "",
            })
          );
        } else {
          dispatch(GetProvisionAction(clone, myJson.count));
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "getProvision",
              message: myJson.message,
            })
          );
        }
      } else {
        if (offset !== 0) {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "appendProvisionList",
              message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
            })
          );
        } else {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "getProvision",
              message: myJson.message,
            })
          );
        }
      }
    }
  };
}

export function topItemsListing(
  data,
  userType,
  from,
  to,
  itemCode,
  description
) {
  return async (dispatch) => {
    let url = "";
    let bodyContent = {};
    url = Constant.apiURl + "/quotation/topItems";
    if (userType) {
      userType === "supplier"
        ? (bodyContent["supplierId"] = data)
        : userType === "customer"
          ? (bodyContent["vesselId"] = data)
          : (bodyContent["customerId"] = data);
    }
    if (itemCode) {
      bodyContent["itemCode"] = itemCode;
    }
    if (description) {
      bodyContent["description"] = description;
    }
    if (from && to) {
      bodyContent["from"] = from;
      bodyContent["to"] = to;
    }
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getTopItems" }));
    let myJson = await FETCH("POST", url, {
      ...bodyContent,
    });
    if (myJson && myJson.code === 201) {
      await set("topItems", myJson);
      dispatch(GetTopItemsAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getTopItems",
          message: myJson.message,
        })
      );
    } else {
      const topItems = await get("topItems");
      if (topItems) {
        dispatch(GetTopItemsAction(topItems.data, topItems.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getTopItems",
            message: topItems.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getTopItems",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function getFilterDetail(offset, filterQuery) {
  return async (dispatch) => {
    let url = "";
    if (filterQuery !== "" && filterQuery !== undefined) {
      url =
        Constant.apiURl + "/catalouge/items/" + offset + "/200" + filterQuery;
    } else {
      url = Constant.apiURl + "/catalouge/items/" + offset + "/200";
    }
    if (offset !== 0) {
      dispatch(
        Actions.ApiRequestedAction({ apiCallFor: "appendFilterDetail" })
      );
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getFilterDetail" }));
      dispatch(GetFilterDetailAction([], [], 0));
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendFilterDetailAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendFilterDetail",
            message: "",
          })
        );
      } else {
        dispatch(GetFilterDetailAction(myJson.data, myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getFilterDetail",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendFilterDetail",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getFilterDetail",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function saveOrder(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "saveOrder" }));
    let userType = getCookie("userType");
    let subType = getCookie("subType");
    if (userType === "customer" && subType === "customer") {
      data["userType"] = "customer";
    }
    const url = Constant.apiURl + "/orders/save";
    const myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 201) {
      if (data?.orderProvisionType === 'provision') {
        const { budgetPerDay, country, numberOfCrew, orderPeriod, orderKind } = data;
        /*
        orderPeriod: {
          "dryFrozen": {
              "regular": 40,
              "expectedDelay": 5
          },
          "freshProvisions": {
              "regular": 30,
              "expectedDelay": 5
          }
        }
       */
        const NO_OF_DAYS = orderKind === 'Fresh Top Up' ? orderPeriod?.freshProvisions?.regular : orderPeriod?.dryFrozen?.regular;
        const totalBudgetPoints = budgetPerDay * numberOfCrew?.regular * NO_OF_DAYS;
        await set("currentOrder", {
          countryName: country?.name,
          totalBudgetPoints,
        });
      }
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "saveOrder",
          message: myJson.data._id,
        })
      );
    } else {
      if (!navigator.onLine) {
        const allLocalStorageKeys = getAllLocalStorageKeys();
        const reqKey = allLocalStorageKeys.find((key) => key.includes("orders/save"));
        if (reqKey) {
          const existingOrders = localStorage.getItem(reqKey);
          const { payloads } = JSON.parse(existingOrders);
          const updatedPayloads = [...payloads, data];
          localStorage.setItem(url, JSON.stringify({
            payloads: updatedPayloads
          }));
        } else {
          const payloads = [data];
          localStorage.setItem(url, JSON.stringify({
            payloads
          }));
        }
        const orderId = data?.orderId;
        if (!!orderId) {
          const urll = `${Constant.apiURl}/orders/orderById`;
          localStorage.setItem(urll, JSON.stringify({ orderId }));
        }
      }
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "saveOrder",
          message: myJson.message,
        })
      );
    }
  };
}

export function getOrderListing(offset, limit, filterQuery) {
  return async (dispatch) => {
    let url = "";
    if (getCookie("userType") === "customer") {
      if (filterQuery !== "" && filterQuery !== undefined) {
        url = Constant.apiURl + "/orders/" + offset + "/" + limit + filterQuery;
      } else {
        url =
          Constant.apiURl +
          "/orders/" +
          offset +
          "/" +
          limit +
          `?userId=${getCookie("userId")}`;
      }
    } else {
      url = Constant.apiURl + "/orders/" + offset + "/" + limit + filterQuery;
    }

    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendOrderList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrder" }));
      dispatch(GetOrderAction([], 0));
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendOrderAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendOrderList",
            message: "",
          })
        );
      } else {
        dispatch(GetOrderAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendOrderList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function getOrderDetail(orderId) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/orderById";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrderDetail" }));
    let myJson = await FETCH("POST", url, { orderId });

    if (!myJson.code) {
      const orderByIds = await get("orderByIds");
      if (orderByIds) {
        myJson = orderByIds[orderId];
      }
    }

    if (myJson && myJson.code === 201) {
      let obj = {};
      if (myJson.data) {
        let orderDetail = {};
        myJson.data.orderDetails &&
          myJson.data.orderDetails.forEach((element) => {
            orderDetail[element.itemCode] = element;
          });
        obj = {
          orderNeeded: myJson?.data?.orderNeeded,
          imoNum: myJson.data.imoNumber,
          _id: myJson.data._id,
          vessel: myJson.data.vesselId,
          captain: myJson.data.captainId,
          place: myJson.data.placeOfDelivery,
          country: {
            name: myJson.data.countryName
              ? myJson.data.countryName
              : myJson.data.countryLabel,
            value: myJson.data.countryId,
            countryCode: "DE",
            label: myJson.data.countryName
              ? myJson.data.countryName
              : myJson.data.countryLabel,
          },
          orderDetail: orderDetail,
          orderDetails: myJson.data.orderDetails,
          agentDetail: myJson.data.agentDetails,
          eta: myJson.data.arrivalTime,
          etd: myJson.data.departureTime,
          budgetPerDay: myJson.data.budgetPerDay,
          currency: myJson.data.currency,
          numberOfCrew: {
            regular: myJson.data.regularCrew,
            additional: myJson.data.additionalCrew,
          },
          vesselRate: myJson.data.budgetPerDay,
          userId: myJson.data.customerId,
          supplierId: myJson.data.supplierId,
          isOrder: myJson.data.isOrder,
          orderProvisionType: myJson.data.orderProvisionType,
          remainingBudget: myJson.data.remainingBudget,
        };
        if (myJson.data.orderProvisionType === "store") {
          obj.department = myJson.data.department;
          obj.refNo = myJson.data.refNo ? myJson.data.refNo : "";
          obj.rank = myJson.data.rank;
          obj.orderPeriod = {
            regular: myJson.data.dryOrderDays,
            expectedDelay: myJson.data.dryAddtionalOrderDays,
          };
          obj.numberOfCrew = {
            regular: 0,
            additional: 0,
          };
          obj.deptBudget = myJson.data.deptBudget;
          obj.orderBy = myJson.data.orderBy;
        } else {
          obj.orderKind = myJson.data.orderType;
          obj.categories = myJson.data.categories ? myJson.data.categories : [];
          obj.cook = myJson.data.cookId;
          obj.numberOfCrew = {
            regular: myJson.data.regularCrew,
            additional: myJson.data.additionalCrew,
          };
          obj.orderPeriod = {
            dryFrozen: {
              regular: myJson.data.dryOrderDays,
              expectedDelay: myJson.data.dryAddtionalOrderDays,
            },
            freshProvisions: {
              regular: myJson.data.freshOrderDays,
              expectedDelay: myJson.data.freshAddtionalOrderDays,
            },
          };
        }
      }
      dispatch(AddOrderAction(obj));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getOrderDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getOrderDetail",
          message: myJson.message,
        })
      );
    }
  };
}

export function updateOrder(data) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/updateById";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateOrder" }));
    const myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateOrder",
          message: myJson.data._id,
        })
      );
    } else {
      if (!navigator.onLine) {
        const allLocalStroageKeys = getAllLocalStorageKeys();
        const reqKey = allLocalStroageKeys.find((key) => key.includes("orders/save"));
        if (reqKey) {
          const value = localStorage.getItem(reqKey);
          const { payloads } = JSON.parse(value);
          if (payloads?.length > 0) {
            const lastIndex = payloads.length - 1;
            const lastPayload = payloads[lastIndex];
            const newValue = {
              ...lastPayload,
              orderDetails: data,
            };
            payloads[lastIndex] = newValue;
            localStorage.setItem(reqKey, JSON.stringify({
              payloads,
            }));
          }
        }
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "updateOrder",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function updateOrderType(data) {
  return async (dispatch) => {
    let url = Constant.apiURl + "/orders/updateType";
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateOrderType" }));
    let myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateOrderType",
          message: myJson.data._id,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateOrderType",
          message: myJson.message,
        })
      );
    }
  };
}

export function getNextOrderQty(vesselId, captainId) {
  return async (dispatch) => {
    const url = `${Constant.apiURl}/orders/find/next/order/qty?vesselId=${vesselId}&captainId=${captainId}`;
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getNextOrderQty" }));
    const myJson = await FETCH("GET", url);
    if (myJson.code === 200) {
      dispatch(getNextOrderQtyAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getNextOrderQty",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getNextOrderQty",
          message: myJson.message,
        })
      );
    }
  };
}