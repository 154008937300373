import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  basketOrders,
  ignoreDepartmentCart,
  sendAdditionalOrder,
  updateCartETA,
  deleteOrder,
} from "../ApiCalls/index";
import moment from "moment";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { capitalizeFirstLetter } from "../../../Services/common";
import Swal from "sweetalert2";
import Select from "react-select";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { getAllCountry } from "../../Common/ApiCalls/countryList";
import { getAllPort } from "../../Common/ApiCalls/portList";
import { getVesselById } from "../../Common/ApiCalls/vesselList";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";
// import { SupplierComparisonListing } from "../../Supplier/ApiCalls/supplier";

const getSelectStyle = (maxHeight) => {
  return {
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: maxHeight,
    }),
  };
};
const navbarOptions = [
  {
    img: "Cancel.svg",
    className: "red",
    name: "Logout",
    path: "/",
    isLogout: true,
  },
  // {
  //     img: 'Dashboard-icon.svg',
  //     className: "dark-green",
  //     name: "upgrade",
  //     path: "/UpgradeClient",
  //     isLogout: false
  // }
];

function OrderBasket(props) {
  const childRef = useRef();
  const [state, setState] = useState({
    provisionOrder: [],
    storeOrder: [],
    provisionAdditionalOrders: [],
    storeAdditionalOrders: [],
    additionalOrderList: [],
    storeNext: false,
    provisionNext: false,
    sendAdditionalOrder: false,
    country: "",
    countryOption: [],
    selectedCountryOption: null,
    port: "",
    portOption: [],
    count: 0,
    type: "",
    selectedPortOption: null,
    isHitApi: false,
    provisionSupplier: {},
    storeSupplier: {},
    currentVessel: "",
  });

  useEffect(() => {
    const isShowCustomerOrders = props.userDetail.userType === "customer";
    if (!state.isHitApi) {
      props.basketOrders(props.userDetail.userId, isShowCustomerOrders);
      props.getAllCountry();
      props.getAllPort();
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
    }
    if (
      props.apiCallStatus.apiCallFor === "basketOrders" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const vesselId = urlParams.get("vesselId");
      let provisionNext = false;
      props.getVesselById(
        vesselId ? vesselId : props.userDetail.activeVesselId
      );
      let provisionOrder = [];
      let provisionAdditionalOrders = [];
      let storeAdditionalOrders = [];
      let confirmedProvisionOrder = -1;

      if (props.orderBasketReducer.list.length > 0) {
        provisionOrder = props.orderBasketReducer.list.filter(
          (x) =>
            x?.orderProvisionType === "provision" &&
            x?.orderType?.toString().toLowerCase() !== "additional"
        );
      }

      props.orderBasketReducer.list.filter((order) => {
        if (order.department.toLowerCase() === "additional") {
          if (order.orderProvisionType === "provision") {
            provisionAdditionalOrders.push(order);
          } else {
            storeAdditionalOrders.push(order);
          }
        }
      });

      // if (isShowCustomerOrders && props.orderBasketReducer.list.length > 0) {
      //   props.orderBasketReducer.vessels.map(({ value, label }) => {
      //     const provisionAdditional = [];
      //     const storeAdditional = [];
      //     props.orderBasketReducer.list.filter((x) => {
      //       if (x?.department?.toString().toLowerCase() === "additional" && value === x.vesselId) {
      //         if (x.orderProvisionType === "provision") {
      //           provisionAdditional.push(x);
      //         } else {
      //           storeAdditional.push(x);
      //         }
      //       }
      //     });
      //     provisionAdditionalOrders.push({ label, data: provisionAdditional });
      //     storeAdditionalOrders.push({ label, data: storeAdditional });
      //   });

      //   // additionalOrderList = abc;
      // } else {
      //   props.orderBasketReducer.list.filter((order) => {
      //     if (order.department.toLowerCase() === "additional") {
      //       if (order.orderProvisionType === "provision") {
      //         provisionAdditionalOrders.push(order);
      //       } else {
      //         storeAdditionalOrders.push(order);
      //       }
      //     }
      //   });
      // }

      if (provisionOrder.length === 0) {
        provisionOrder.push({
          orderType: "Main Order",
          createdAt: "",
          placeOfDelivery: "",
          totalPrice: "",
          currency: "",
          status: "",
          rank: "",
          budget: "",
          isAddedToBasket: false,
          supplierId: "",
        });
      } else {
        confirmedProvisionOrder = provisionOrder.findIndex(
          (x) => x.status === "confirmed" || x.isAddedToBasket === false
        );
        if (confirmedProvisionOrder !== -1) {
          provisionNext = false;
        } else {
          provisionNext = true;
        }
      }
      // console.log(additionalOrderList, "additionalOrderList:::");
      setState({
        ...state,
        provisionOrder,
        provisionNext,
        // additionalOrderList,
        provisionAdditionalOrders,
        storeAdditionalOrders,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      // console.log("props.orderBasketReducer", props.orderBasketReducer);
    }
    if (
      props.apiCallStatus.apiCallFor === "getAllCountry" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArr = [];
      let arr = props.countryList;
      for (let i = 0; i < arr.length; i++) {
        newArr.push({
          value: arr[i]._id,
          label: arr[i].name,
          countryCode: arr[i].countryCode,
          className: "dropdown-custom-style",
        });
      }
      setState({
        ...state,
        countryOption: newArr,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getAllPort" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArr = [];
      let arr = props.portList;
      for (let i = 0; i < arr.length; i++) {
        newArr.push({
          value: arr[i]._id,
          label: arr[i].port,
          className: "dropdown-custom-style",
        });
      }
      setState({
        ...state,
        portOption: newArr,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getVesselById" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let storeOrder = [];
      let storeNext = false;
      let storeOrderCount = 0;

      if (
        props.commonReducer.vesselById.departments.length > 0 &&
        props.orderBasketReducer.list.length > 0
      ) {
        if (props.orderBasketReducer.list.length > 0) {
          storeOrder = props.orderBasketReducer.list.filter(
            (x) => x.orderProvisionType === "store"
          );
        }
        if (storeOrder.length === 0) {
          storeOrder.push({
            orderType: "Main Order",
            createdAt: "",
            placeOfDelivery: "",
            totalPrice: "",
            currency: "",
            status: "",
            rank: "",
            budget: "",
            isAddedToBasket: false,
            supplierId: "",
          });
        }
        // props.commonReducer.vesselById.departments.map((item, index) => {
        //     let currentindex = props.orderBasketReducer.list.findIndex(x => x.department === item.department);
        //     if (currentindex !== -1) {
        //         let obj = {
        //             department: item.department,
        //             isAddedToBasket: props.orderBasketReducer.list[currentindex].isAddedToBasket,
        //             createdAt: props.orderBasketReducer.list[currentindex].createdAt,
        //             arrivalTime: props.orderBasketReducer.list[currentindex].arrivalTime,
        //             placeOfDelivery: props.orderBasketReducer.list[currentindex].placeOfDelivery,
        //             totalPrice: props.orderBasketReducer.list[currentindex].totalPrice,
        //             currency: props.orderBasketReducer.list[currentindex].currency,
        //             status: props.orderBasketReducer.list[currentindex].status,
        //             rank: props.orderBasketReducer.list[currentindex].rank,
        //             supplierId: props.orderBasketReducer.list[currentindex].supplierId,
        //             budget: item.budget,
        //             isAddedToBasket: props.orderBasketReducer.list[currentindex].isAddedToBasket,
        //             _id: props.orderBasketReducer.list[currentindex]._id,
        //         }
        //         storeOrder.push(obj)
        //         storeOrderCount++;

        //     } else {
        //         let obj = {
        //             department: item.department,
        //             createdAt: '',
        //             arrivalTime: '',
        //             placeOfDelivery: '',
        //             totalPrice: '',
        //             currency: '',
        //             status: '',
        //             supplierId: '',
        //             budget: item.budget,
        //             rank: item.userType,
        //             isAddedToBasket: item.isAddedToBasket
        //         }
        //         storeOrder.push(obj)
        //     }
        // })
      } else if (
        props.commonReducer.vesselById.departments.length > 0 &&
        props.orderBasketReducer.list.length === 0
      ) {
        props.commonReducer.vesselById.departments.map((item) => {
          let obj = {
            department: item.department,
            createdAt: "",
            arrivalTime: "",
            placeOfDelivery: "",
            totalPrice: "",
            currency: "",
            status: "",
            supplierId: "",
            budget: item.budget,
            rank: item.userType,
            isAddedToBasket: item.isAddedToBasket,
          };
          storeOrder.push(obj);
        });
      }
      if (storeOrderCount === 0) {
        storeNext = false;
      } else {
        let confirmedStoreOrder = -1;
        confirmedStoreOrder = storeOrder.findIndex(
          (x) => x.status === "confirmed" || x.isAddedToBasket === false
        );
        storeNext = confirmedStoreOrder === -1;
      }

      storeOrder = storeOrder.filter(
        (x) => x?.department?.toString().toLowerCase() !== "additional"
      );

      setState({
        ...state,
        storeOrder,
        storeNext,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }

    if (
      props.apiCallStatus.apiCallFor === "getAllCountry" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArr = [];
      let arr = props.countryList;
      for (let i = 0; i < arr.length; i++) {
        newArr.push({
          value: arr[i]._id,
          label: arr[i].name,
          countryCode: arr[i].countryCode,
          className: "dropdown-custom-style",
        });
      }
      setState({
        ...state,
        countryOption: newArr,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }

    if (
      state.sendAdditionalOrder &&
      props.apiCallStatus.apiCallFor === "sendAdditionalOrder" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      Swal.fire("Success", props.apiCallStatus.message, "success");
    }

    if (
      props.apiCallStatus.apiCallFor === "updateCartETA" &&
      props.apiCallStatus.isCompleted
    ) {
      const failed = props.apiCallStatus.isFailed;
      if (!failed || (failed && !navigator.onLine)) {
        childRef.current.HideModal();
        Swal.fire({
          title: `Do you want to proceed with following ${state.count} orders ?`,
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Procced",
        }).then((result) => {
          if (result.isConfirmed) {
            const basketIdMapping = {
              provision: props.orderBasketReducer.provisionBasketId,
              provisionAdditional: props.orderBasketReducer.provisionAdditionalBasketId,
              store: props.orderBasketReducer.storeBasketId,
              storeAdditional: props.orderBasketReducer.storeAdditionalBasketId,
            };
            const basketId = basketIdMapping[state.type];
            props.history.push(`/supplier-comparison/${basketId}`);
          }
        });
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      }
    }

    if (
      props.apiCallStatus.apiCallFor === "deleteOrder" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      Swal.fire("Deleted!", "Order has been deleted!", "success");
      props.basketOrders(props.userDetail.userId, isShowCustomerOrders);
    }

  }, [props, state.isHitApi]);

  const handleAdditionalOrder = (orderIds) => {
    props.sendAdditionalOrder(orderIds);
    setState({
      ...state,
      sendAdditionalOrder: true,
    });
  };

  const handleSelectChange = (e, name) => {
    let data = { ...state };
    if (name === "port") {
      data["selectedPortOption"] = e;
    } else if (name === "country") {
      data["selectedCountryOption"] = e;
      data["port"] = "";
      data["portOption"] = [];
      data["selectedPortOption"] = null;
      let query = "countryCode=" + e.countryCode;
      props.getAllPort(query);
    }
    data[name] = e.value;
    data["errorMessage"] = "";
    data["isError"] = false;
    setState(data);
  };

  const handleSubmit = (
    type,
    count,
    eta,
    basketId,
    agentDetail,
    countryName,
    countryId,
    portId,
    portName,
    currentVessel
  ) => {
    const data = { ...state };

    const selectedPort = data?.selectedPortOption?.label;
    const provisionPortsSame = data?.provisionOrder.every((order) => order?.placeOfDelivery?.label === selectedPort);
    const provisionAdditionalPortsSame = data?.provisionAdditionalOrders.every((order) => order?.placeOfDelivery?.label === selectedPort);
    const storePortsSame = data?.storeOrder.every((order) => order?.placeOfDelivery?.label === selectedPort);
    const storeAdditionalPortsSame = data?.storeAdditionalOrders.every((order) => order?.placeOfDelivery?.label === selectedPort);

    const validPort =
      (type === 'provision' && provisionPortsSame) ||
      (type === 'provisionAdditional' && provisionAdditionalPortsSame) ||
      (type === 'store' && storePortsSame) ||
      (type === 'storeAdditional' && storeAdditionalPortsSame);

    if (!validPort) {
      Swal.fire(
        "Error",
        "In order to proceed, please make sure all the ports are same.",
        "error"
      );
    } else {
      props.updateCartETA({
        basketId,
        eta,
        agentDetail,
        countryName,
        countryId,
        portId,
        portName,
      });

      setState({
        ...state,
        type,
        count,
        currentVessel,
      });
    }
  };

  const openModal = () => {
    let data = { ...state };
    data["port"] = "";
    data["selectedPortOption"] = null;
    data["country"] = "";
    data["selectedCountryOption"] = null;
    data["sendAdditionalOrder"] = false;
    setState(data);
  };

  const deleteOrder = (orderId) => {
    Swal.fire({
      title: "Are you sure you want to delete this order?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ed020a",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteOrder(orderId);
      }
    });
  };

  return (
    <DashboardWrapper
      title={"Order Basket"}
      navbarOptions={navbarOptions}
      isShowLogo={true}
      logoUrl="supplier-logo.png"
      logoType="Supplier"
      className="mesh-board-wrap d-flex align-items-end oder-align"
    >
      {/* Order Status Content */}
      <div className="  mt-lg-5 align-content-stretch newDash-contentStyle dash-rfq-status-info  orderbasket-align-table ">
        <OrderStatusWrapper
          label="Order Basket"
          isSubAdmin={props.userDetail.isSubAdmin}
        ></OrderStatusWrapper>
      </div>

      {
        <>
          <div className="mt-3 d-flex contract-dash-wrap">
            <OrderTypeWrapper
              deleteOrder={deleteOrder}
              label="Provision"
              isAllowSubmit={!props.orderBasketReducer?.proceedToSupplierComparison?.provision}
              ignoreDepartmentCart={props.ignoreDepartmentCart}
              ref={childRef}
              {...state}
              provisionArrivalTime={
                props.orderBasketReducer.provisionArrivalTime
              }
              basketId={props.orderBasketReducer.provisionBasketId}
              supplier={props.orderBasketReducer.provisionSupplier}
              orderList={state.provisionOrder}
              openModal={openModal}
              handleSubmit={handleSubmit}
              vesselId={props.userDetail.activeVesselId}
              handleSelectChange={handleSelectChange}
              apiCallStatus={props.apiCallStatus}
              isProvisionOrder={true}
              isStoreOrder={false}
              buttonCaption={"Next"}
              userDetail={props.userDetail}
              departments={[]}
              isSubAdmin={props.userDetail.isSubAdmin}
              type="provision"
            />
          </div>
          <div className="mt-3 d-flex contract-dash-wrap">
            <OrderTypeWrapper
              deleteOrder={deleteOrder}
              label="Provision Additional"
              isAllowSubmit={!props.orderBasketReducer?.proceedToSupplierComparison?.provisionAdditional}
              ignoreDepartmentCart={props.ignoreDepartmentCart}
              ref={childRef}
              {...state}
              provisionArrivalTime={
                props.orderBasketReducer.provisionArrivalTime
              }
              basketId={props.orderBasketReducer.provisionBasketId}
              supplier={props.orderBasketReducer.provisionSupplier}
              orderList={state.provisionAdditionalOrders}
              openModal={openModal}
              handleSubmit={handleSubmit}
              vesselId={props.userDetail.activeVesselId}
              handleSelectChange={handleSelectChange}
              apiCallStatus={props.apiCallStatus}
              isProvisionOrder={true}
              isStoreOrder={false}
              buttonCaption={"Next"}
              userDetail={props.userDetail}
              departments={[]}
              isSubAdmin={props.userDetail.isSubAdmin}
              type="provisionAdditional"
            />
          </div>
          <div className="mt-5 d-flex contract-dash-wrap">
            <OrderTypeWrapper
              deleteOrder={deleteOrder}
              label="Store"
              isAllowSubmit={!props.orderBasketReducer?.proceedToSupplierComparison?.store}
              ignoreDepartList={props.orderBasketReducer.ignoreDepart}
              ignoreDepartmentCart={props.ignoreDepartmentCart}
              ref={childRef}
              {...state}
              storeArrivalTime={props.orderBasketReducer.storeArrivalTime}
              basketId={props.orderBasketReducer.storeBasketId}
              supplier={props.orderBasketReducer.storeSupplier}
              orderList={state.storeOrder}
              openModal={openModal}
              handleSubmit={handleSubmit}
              handleSelectChange={handleSelectChange}
              vesselId={props.userDetail.activeVesselId}
              buttonCaption={"Next"}
              isStoreOrder={true}
              isProvisionOrder={false}
              userDetail={props.userDetail}
              departments={props.orderBasketReducer?.remainingDepartments}
              apiCallStatus={props.apiCallStatus}
              isSubAdmin={props.userDetail.isSubAdmin}
              isNext={true}
              type="store"
            />
          </div>
          <div className="mt-3 d-flex contract-dash-wrap">
            <OrderTypeWrapper
              deleteOrder={deleteOrder}
              label="Store Additional"
              isAllowSubmit={!props.orderBasketReducer?.proceedToSupplierComparison?.storeAdditional}
              ignoreDepartmentCart={props.ignoreDepartmentCart}
              ref={childRef}
              {...state}
              provisionArrivalTime={
                props.orderBasketReducer.provisionArrivalTime
              }
              basketId={props.orderBasketReducer.provisionBasketId}
              supplier={props.orderBasketReducer.provisionSupplier}
              orderList={state.storeAdditionalOrders}
              openModal={openModal}
              handleSubmit={handleSubmit}
              vesselId={props.userDetail.activeVesselId}
              handleSelectChange={handleSelectChange}
              apiCallStatus={props.apiCallStatus}
              isProvisionOrder={true}
              isStoreOrder={false}
              buttonCaption={"Next"}
              userDetail={props.userDetail}
              departments={[]}
              isSubAdmin={props.userDetail.isSubAdmin}
              type="storeAdditional"
            />
          </div>
        </>
      }

      {/* {props.userDetail.userType === "customer" ? (
        state.additionalOrderList.map((additionalOrder) => {
          return (
            <div className="mt-5 d-flex contract-dash-wrap">
              <OrderTypeWrapper
                label={additionalOrder.label}
                isAllowSubmit={
                  props.apiCallStatus.isStarted.length === 0 &&
                    !props.orderBasketReducer?.proceedToSupplierComparison
                      ?.additional
                    ? true
                    : false
                }
                ignoreDepartList={props.orderBasketReducer.ignoreDepart}
                ignoreDepartmentCart={props.ignoreDepartmentCart}
                ref={childRef}
                {...state}
                storeArrivalTime={props.orderBasketReducer.storeArrivalTime}
                basketId={props.orderBasketReducer.storeBasketId}
                supplier={props.orderBasketReducer.storeSupplier}
                orderList={additionalOrder.data}
                openModal={openModal}
                handleSubmit={handleSubmit}
                buttonCaption={
                  props?.userDetail?.userType !== "customer"
                    ? "Send To User"
                    : "Next"
                }
                isProvisionOrder={false}
                isStoreOrder={false}
                isAdditionalOrder={true}
                sendAdditionalOrder={handleAdditionalOrder}
                handleSelectChange={handleSelectChange}
                vesselId={props.userDetail.activeVesselId}
                userDetail={props.userDetail}
                departments={[]}
                vessels={props.orderBasketReducer.vessels}
                apiCallStatus={props.apiCallStatus}
                isSubAdmin={props.userDetail.isSubAdmin}
                isNext={false}
              />
            </div>
          );
        })
      ) : (
        <div className="mt-5 d-flex contract-dash-wrap">
          <OrderTypeWrapper
            label="Additional"
            isAllowSubmit={
              props.apiCallStatus.isStarted.length === 0 &&
                !props.orderBasketReducer?.proceedToSupplierComparison?.additional
                ? true
                : false
            }
            ignoreDepartList={props.orderBasketReducer.ignoreDepart}
            ignoreDepartmentCart={props.ignoreDepartmentCart}
            ref={childRef}
            {...state}
            storeArrivalTime={props.orderBasketReducer.storeArrivalTime}
            basketId={props.orderBasketReducer.storeBasketId}
            supplier={props.orderBasketReducer.storeSupplier}
            orderList={state.additionalOrderList}
            openModal={openModal}
            handleSubmit={handleSubmit}
            buttonCaption={
              props?.userDetail?.userType !== "customer"
                ? "Send To User"
                : "Next"
            }
            isProvisionOrder={false}
            isStoreOrder={false}
            isAdditionalOrder={true}
            sendAdditionalOrder={handleAdditionalOrder}
            handleSelectChange={handleSelectChange}
            vesselId={props.userDetail.activeVesselId}
            userDetail={props.userDetail}
            departments={[]}
            vessels={props.orderBasketReducer.vessels}
            apiCallStatus={props.apiCallStatus}
            isSubAdmin={props.userDetail.isSubAdmin}
            isNext={false}
          />
        </div>
      )} */}
    </DashboardWrapper>
  );
}

function OrderStatusWrapper(props) {
  return (
    <div className="content-box-wrap dash-content-bx">
      <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
        <h2>{props.label}</h2>
        <h2>
          <span className="sta-indicator pend"></span>
          <span className="sta-text-wrap text-truncate">Ready</span>
        </h2>
        <h2>
          <span className="sta-indicator past"></span>
          <span className="sta-text-wrap text-truncate">Open</span>
        </h2>
        <h2>
          <span className="sta-indicator appr"></span>
          <span className="sta-text-wrap text-truncate">Sent</span>
        </h2>
      </div>
    </div>
  );
}

const OrderTypeWrapper = forwardRef((props, ref) => {
  let storeCount = 0;
  let provisionCount = 0;
  const [checkedDepartments, setCheckedDepartments] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [eta, setEta] = useState(new Date(moment().add(1, "days")));
  const [agent, setAgent] = useState("");
  const [supplier, setSupplier] = useState({
    id: "",
    name: "",
  });
  useEffect(() => {
    if (
      Object.size(props.supplier) !== 0 &&
      supplier.id !== props.supplier.id
    ) {
      setSupplier(props.supplier);
    }
  });
  const handleCheckbox = (e) => {
    const id = e.target.id;
    const departments = checkedDepartments;
    const index = departments.indexOf(id);
    if (index !== -1) {
      departments.splice(index, 1);
    } else {
      departments.push(id);
    }
    setCheckedDepartments(departments);
  };

  const handleVesselChange = (e) => {
    const { id } = e.target;
    const vesselIndex = vessels.findIndex(({ _, value }) => id === value);
    if (vesselIndex !== -1) {
      vessels.splice(vesselIndex, 1);
    } else {
      vessels.push(id);
    }
    setVessels(vessels);
  };

  const onOpenModal = () => {
    let isValid = true;
    if (!props.isProvisionOrder) {
      for (let index = 0; index < props.orderList.length; index++) {
        const element = props.orderList[index];
        if (
          props.ignoreDepartList === undefined ||
          (props.ignoreDepartList.indexOf(element.department) === -1 &&
            element.status === "")
        ) {
          isValid = false;
        }
      }
    }
    if (isValid) {
      props.openModal();
      setOpen(true);
    } else {
      Swal.fire(
        "Error",
        "You can only jump to the next step, when all departments have the status “ready”",
        "error"
      );
    }
  };

  const onCloseModal = () => {
    setOpen(false);
    setAgent("");
    setEta(new Date(moment().add(1, "days")));
  };

  useImperativeHandle(ref, () => ({
    HideModal() {
      setOpen(false);
    },
  }));

  const handleSubmit = () => {
    if (validateForm()) {
      const {
        basketId, handleSubmit, isProvisionOrder, label, selectedCountryOption, selectedPortOption, type
      } = props;

      if (selectedCountryOption && selectedPortOption) {
        // let type = props.label === 'Additional' ? "additional" : props.isProvisionOrder ? 'provision' : 'store';
        // let type = props.isAdditionalOrder
        //   ? "additional"
        //   : props.isProvisionOrder
        //     ? "provision"
        //     : props.isStoreOrder
        //       ? "store"
        //       : "";
        // console.log('type12345', type)

        const count = isProvisionOrder ? provisionCount : storeCount;
        const countryName = selectedCountryOption.label;
        const countryId = selectedCountryOption.value;
        const portId = selectedPortOption.value;
        const portname = selectedPortOption.label;

        handleSubmit(
          type,
          count,
          eta,
          basketId,
          agent,
          countryName,
          countryId,
          portId,
          portname,
          label
        );
      }
    }
  };

  const validateForm = () => {
    let msg = "";
    if (agent === "") {
      msg = "Please enter the agent detail";
    } else if (props.selectedCountryOption === null) {
      msg = "Please select the country";
    } else if (props.selectedPortOption === null) {
      msg = "Please select the port";
    } else if (checkedDepartments.length !== props.departments.length) {
      msg = "Please select all checkbox first";
    }
    setMsg(msg);
    return msg === "";
  };

  const ignoreDepartmentCart = (department, status) => {
    if (props.basketId !== "") {
      props.ignoreDepartmentCart({
        cartId: props.basketId,
        department: department,
        status: status,
      });
    } else {
      Swal.fire("Error", "Atleast, one order is required.", "error");
    }
  };

  const sendAdditionalOrderToUser = () => {
    const orderIds = props.orderList?.map(({ _id }) => _id) || [];
    props.sendAdditionalOrder(orderIds);
  };

  const isCaptain = props.userDetail?.subType === 'captain';
  const isCustomer = props.userDetail?.userType === 'customer';
  const isProvision = ['provision', 'provisionAdditional'].includes(props.type);

  return (
    <>
      <div className="orderbasket-align-table content-box-wrap dash-content-bx w-100 ">
        <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
          {/* <div className="flex-fill"></div> */}
          <h2>{props.label}</h2>
        </div>
        <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left ">
          <div className="table-responsive">
            <table className="table mt-4 table-light1 ">
              <thead>
                <tr>
                  {props.isAdditionalOrder && (
                    <td className="class1-col1 semi-bold">Ship</td>
                  )}
                  <td className="class1-col1 semi-bold ">Department</td>
                  <td className="class2-col2 semi-bold ">Order Created</td>
                  <td className="class2-col2 semi-bold ">Delivery Date</td>
                  <td className="class3-col3 semi-bold ">Place of delivery</td>
                  <td className="class4-col4 semi-bold ">Order Value</td>
                  <td className="class5-col5 semi-bold ">Order Status</td>
                  {supplier.name !== "" ? (
                    <td className="class5-col5 text-center semi-bold ">
                      Supplier Name
                    </td>
                  ) : (
                    ""
                  )}
                  <td className="class6-col6 semi-bold ">Responsible</td>
                  <td className="class7-col7 semi-bold text-center">Actions</td>
                </tr>
              </thead>
              <tbody>
                {props.apiCallStatus.isStarted.indexOf("getVesselById") !==
                  -1 ||
                  props.apiCallStatus.isStarted.indexOf("basketOrders") !== -1 ? (
                  <tr>
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img
                          style={{ width: "46px" }}
                          src={require("../../../assets/images/Spinner-1s-200px.gif")}
                          alt=""
                        />
                      </div>
                    </td>
                  </tr>
                ) : props.orderList && props.orderList.length === 0 ? (
                  <tr>
                    <td colSpan="20" className="text-center">
                      <div>No Data Found</div>
                    </td>
                  </tr>
                ) : (
                  props.orderList.map((item, index) => {
                    let { vesselId } = item;
                    if (!vesselId) {
                      vesselId = props.userDetail?.activeVesselId;
                    }
                    let link = isProvision
                      ? `/create-provision-order/${vesselId}`
                      : `/create-store-order/${vesselId}`;
                    link = item.department
                      ? (link += `?orderKind=${item.department}&deptBudget=${item.budget}`)
                      : link;
                    link =
                      !props.isProvisionOrder && item.rank
                        ? (link += `&rank=${item.rank}`)
                        : link;
                    if (
                      !props.isProvisionOrder &&
                      item.placeOfDelivery &&
                      item.placeOfDelivery.label
                    ) {
                      storeCount++;
                    } else if (
                      props.isProvisionOrder &&
                      item.placeOfDelivery &&
                      item.placeOfDelivery.label
                    ) {
                      provisionCount++;
                    }
                    let status =
                      !props.isProvisionOrder &&
                        props.ignoreDepartList !== undefined &&
                        props.ignoreDepartList.length !== 0 &&
                        props.ignoreDepartList.indexOf(item.department) !== -1
                        ? "inprogress"
                        : item.status;
                    let arrivalTime = props.isProvisionOrder
                      ? props.provisionArrivalTime
                      : props.storeArrivalTime;
                    return (
                      <tr key={"provision2-" + index}>
                        {props.isAdditionalOrder && (
                          <td className="class1-col1 semi-bold">
                            {item?.vesselName || ""}
                          </td>
                        )}
                        <td className="class1-col1">
                          {props.isProvisionOrder
                            ? item.orderType
                            : item.department}
                        </td>
                        <td className="class2-col2">
                          {item.createdAt
                            ? moment(item.createdAt).format("YYYY-MM-DD")
                            : ""}
                        </td>
                        <td className="class2-col2">
                          {status && item.arrivalTime
                            ? moment(item.arrivalTime).format("YYYY-MM-DD")
                            : ""}
                        </td>
                        <td className="class3-col3">
                          {item.placeOfDelivery && item.placeOfDelivery.label
                            ? item.placeOfDelivery.label
                            : ""}
                        </td>
                        <td className="class4-col4">
                          {item.totalPrice ? item.totalPrice.toFixed(2) : 0}{" "}
                          {item.currency ? item.currency : ""}
                        </td>
                        <td className="class4-col4 ">
                          {status === "inprogress" ? (
                            <>
                              <span className="sta-indicator pend"></span>
                              <span className="sta-text-wrap text-truncate"></span>
                              <span className="dropdown show">
                                <a
                                  className=" dropdown-toggle"
                                  href="#"
                                  role="button"
                                  id="dropdownMenuLink"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                ></a>
                                <span
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() =>
                                      ignoreDepartmentCart(
                                        item.department,
                                        "remove"
                                      )
                                    }
                                  >
                                    Open
                                  </a>
                                </span>
                              </span>{" "}
                              {capitalizeFirstLetter(status)}
                            </>
                          ) : status === "confirmed" ? (
                            <>
                              <span className="sta-indicator appr"></span>{" "}
                              <span className="sta-text-wrap text-truncate">
                                {" "}
                                {capitalizeFirstLetter(status)}
                              </span>
                            </>
                          ) : status === "" ? (
                            <>
                              <span className="sta-indicator past"></span>
                              <span className="sta-text-wrap text-truncate"></span>
                              {!props.isProvisionOrder ? (
                                <span className="dropdown show">
                                  <a
                                    className=" dropdown-toggle"
                                    href="#"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  ></a>
                                  <span
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      onClick={() =>
                                        ignoreDepartmentCart(
                                          item.department,
                                          "ready"
                                        )
                                      }
                                    >
                                      Ready
                                    </a>
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            item.status
                          )}
                        </td>
                        {supplier.name !== "" ? (
                          <td className="class4-col4">{supplier.name}</td>
                        ) : (
                          ""
                        )}
                        <td className="class5-col5 text-left">
                          {item.rank
                            ? capitalizeFirstLetter(item.rank)
                            : props.isProvisionOrder
                              ? "Cook"
                              : ""}
                        </td>
                        {/* <td>{item.supplier}</td> */}
                        <td className="class6-col6">
                          <Link
                            to={
                              item.status === ""
                                ? link
                                : item.status === "confirmed"
                                  ? `/order-detail-description/${props.basketId}/${supplier.id}`
                                  : item.status !== "" &&
                                    item.status !== "confirmed" &&
                                    isProvision
                                    ? `/create-provision-order/${vesselId}?orderId=${item._id}`
                                    : `/create-store-order/${vesselId}?orderId=${item._id}`
                            }
                            // className="btn tb-btn-new-rounded dark-blue"
                            className={`btn ${item.status === "" ? "" : 'btn-edit-tab'}`}
                          >
                            {item.status === "" && "Create Order"}
                          </Link>
                          {
                            item.status === "inprogress" && (isCaptain || isCustomer) &&
                            <button
                              type="button"
                              onClick={() => { props.deleteOrder(item._id); }}
                              className="btn btn-dlt-tab">
                            </button>
                          }
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div className="form-sub-btn-wrap mt-4 text-right mb-2">
            {!props.isProvisionOrder &&
              props.apiCallStatus.isStarted.indexOf("ignoreDepartmentCart") !==
              -1 ? (
              <div className="loader-img text-center">
                <img
                  style={{ width: "46px" }}
                  src={require("../../../assets/images/Spinner-1s-200px.gif")}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            {/* <img className="img-fluid" src={require('../../../assets/images/circle_outline_with_exclamation.svg')} alt="icon" /> */}
            {props.label !== "Additional" &&
              (props.isStoreOrder ||
                props.isProvisionOrder ||
                props.provisionNext) ? (
              <button
                disabled={props.isAllowSubmit}
                type="button"
                onClick={onOpenModal}
                className="btn themeReguler-btn bg-green max-content px-4"
              >
                {props.buttonCaption}
              </button>
            ) : props?.userDetail?.userType !== "customer" ? (
              <button
                disabled={props.isAllowSubmit}
                type="button"
                onClick={sendAdditionalOrderToUser}
                className="btn themeReguler-btn bg-green max-content px-4"
              >
                {props.buttonCaption}
              </button>
            ) : (
              <button
                disabled={props.isAllowSubmit}
                type="button"
                onClick={onOpenModal}
                className="btn themeReguler-btn bg-green max-content px-4"
              >
                {props.buttonCaption}
              </button>
            )}
            {/*{!props.isProvisionOrder && props.storeNext ?
                        <button disabled={!props.isAllowSubmit} type="button" onClick={onOpenModal}
                                className="btn themeReguler-btn bg-green max-content px-4">{props.buttonCaption}</button> : ''}*/}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="formBox dropdown-custom-style pt-2">
          <h1 className="btn-sty-title flex-fill p-2 mb-3 fz-16 ">
            Select Country, Port &amp; ETA
          </h1>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group d-flex align-items-center">
                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Select Country"
                    value={props.selectedCountryOption}
                    onChange={(e) => {
                      props.handleSelectChange(e, "country");
                    }}
                    options={props.countryOption}
                    styles={getSelectStyle("180px")}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6 mt-1">
              <div className="datePicker form-control field-bx-sty p-0 fa-icon w-70">
                <DatePicker
                  selected={eta}
                  minDate={new Date(moment().add(1, "days"))}
                  onChange={(val) => setEta(val)}
                />
              </div>
            </div>
          </div>
          <div className="row  justify-content-start align-items-center mb-3 pb-3 agent_detail">
            <div className="col-sm-6">
              <div className="form-group d-flex align-items-center">
                <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                  <Select
                    classNamePrefix="react-select"
                    placeholder="Select Port"
                    value={props.selectedPortOption}
                    onChange={(e) => {
                      props.handleSelectChange(e, "port");
                    }}
                    options={props.portOption}
                    styles={getSelectStyle("147px")}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group d-flex align-items-center">
                <input
                  type="text"
                  value={agent}
                  onChange={(e) => setAgent(e.target.value)}
                  className="form-control "
                  placeholder="Enter Agent's Detail"
                />
              </div>
            </div>
          </div>

          {props.departments?.length > 0 && (
            <>
              <h6>
                Please check the box if you want to proceed
                <br />
                without the following departments
              </h6>

              <div className="row justify-content-start align-items-center mb-3 pb-3 agent_detail">
                {props.departments.map((department) => {
                  return (
                    <div className="col-sm-6">
                      <div className="form-check">
                        <input
                          onChange={(e) => handleCheckbox(e)}
                          type="checkbox"
                          className="form-check-input"
                          name={department}
                          id={department}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={department}
                        >
                          {" "}
                          {department}
                        </label>
                        <br />
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {props.orderBasketReducer?.vessels?.length > 0 &&
            props.isAdditionalOrder && (
              <>
                {props.orderBasketReducer?.vessels.map(({ label, value }) => {
                  return (
                    <div className="col-sm-6">
                      <div className="form-check">
                        <input
                          onChange={handleVesselChange}
                          type="checkbox"
                          className="form-check-input"
                          name={value}
                          id={value}
                        />
                        <label className="form-check-label" htmlFor={value}>
                          {label}
                        </label>
                        <br />
                      </div>
                    </div>
                  );
                })}
              </>
            )}

          {props.apiCallStatus.isStarted.indexOf("updateCartETA") !== -1 ? (
            <div className="loader-img text-center">
              <img
                style={{ width: "46px" }}
                src={require("../../../assets/images/Spinner-1s-200px.gif")}
                alt=""
              />
            </div>
          ) : (
            ""
          )}
          {msg !== "" ? <div className="alert alert-danger">{msg}</div> : ""}

          <div className="d-flex align-items-end flex-column flex-sm-row devider">
            <div className="form-btn d-flex justify-content-end flex-fill pt-4 mb-2">
              <button
                disabled={
                  props.label === "store" &&
                    checkedDepartments.length === props.departments.length
                    ? true
                    : false
                }
                type="submit"
                onClick={handleSubmit}
                className="btn btn themeReguler-btn bg-sky-blue"
              >
                {props.isEdit
                  ? "Update"
                  : props?.userDetail?.userType !== "customer"
                    ? "Send"
                    : "Select Supplier"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  orderBasketReducer: state.orderBasketReducer,
  countryList: state.commonReducer.countryList,
  commonReducer: state.commonReducer,
  portList: state.commonReducer.portList,
  userDetail: {
    userType: state.authReducer.userType,
    permission: state.authReducer.permission,
    isSubAdmin: state.authReducer.isSubAdmin,
    subType: state.authReducer.subType,
    userId: state.authReducer.userId,
    activeVesselId: state.authReducer.activeVesselId,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getVesselById: (Id) => dispatch(getVesselById(Id)),
  basketOrders: (id, showCustomerOrders) => dispatch(basketOrders(id, showCustomerOrders)),
  sendAdditionalOrder: (data) => dispatch(sendAdditionalOrder(data)),
  updateCartETA: (data) => dispatch(updateCartETA(data)),
  getAllCountry: () => dispatch(getAllCountry()),
  getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
  ignoreDepartmentCart: (data) => dispatch(ignoreDepartmentCart(data)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  deleteOrder: (orderId) => dispatch(deleteOrder(orderId)),
  // supplierComparisonListing: (data, type) => dispatch(SupplierComparisonListing(data, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderBasket);
