import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { AllRoutes } from "./Constants/routesConfig";
import { getCookie, setCookie } from "./Services/cookies";

const createBrowserHistory = require("history").createBrowserHistory;
const history = createBrowserHistory();
export default () => (
  <Router history={history}>
    <Switch>
      {AllRoutes.map((item, index) => {
        const { component } = item;
        if (item.isPrivate) {
          return <PrivateRoute key={"Allroutes-" + index} {...item} />;
        } else {
          return (
            <Route
              exact
              key={"Allroutes2-" + index}
              path={item.path}
              name={item.name}
              component={component}
            />
          );
        }
      })}
    </Switch>
  </Router>
);

const PrivateRoute = ({
  component,
  name,
  roles,
  ...rest
}) => {
  let isAuthenticated = getCookie("token");
  let loginUrl = "/login";
  const isAdmin = getCookie("email") === "admin@mesh-online.com";
  let index = -1;
  index = roles !== undefined ? roles.indexOf(getCookie("userType")) : "";
  if (index === -1) {
    loginUrl = "/not-found";
  }
  if (getCookie("isTokenExpire") === "true") {
    setCookie("isTokenExpire", "");
    loginUrl += "?msg=expired";
  }
  const Auth = component;
  return (
    <Route
      {...rest}
      name={name}
      render={(props) =>
        !isAdmin && (isAuthenticated === "" || index === -1) ? (
          <Redirect to={loginUrl} />
        ) : (
          <Auth {...props} />
        )
      }
    />
  );
};
