import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import {
  GetItemCodeAction,
  AppendItemCodeAction,
  GetDescriptionAction,
  AppendDescriptionAction,
  GetItemGroupAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { get, set } from 'idb-keyval';

const fetchValueFromQuery = (query, key) => {
  let val = "";

  if (query) {
    if (query.includes("&")) {
      const ret = query.split("&").filter((r) => r.includes(key));
      const cc = ret[0].replaceAll("?", "").split("=");
      val = Array.isArray(cc) ? decodeURIComponent(cc[1]) : "";
    } else {
      val = query.split(key)[1].replaceAll("=", "");
    }
  }

  return val;
};

const getPrefixKey = (query) => {
  const val = fetchValueFromQuery(query, "availability");
  let keyPrefix = '';
  if (val === "p") {
    keyPrefix = "adminProvision";
  } else if (val === "s") {
    keyPrefix = "adminStore";
  } else if (val === "provisionAdditional") {
    keyPrefix = "adminProvisionAdditional";
  } else if (val === "storeAdditional") {
    keyPrefix = "adminStoreAdditional";
  }
  return keyPrefix;
};

const getItemCodesKey = (query) => {
  const keyPrefix = getPrefixKey(query);
  const key = `${keyPrefix}ItemCodes`;
  return key;
};

const getDescriptionKey = (query) => {
  const keyPrefix = getPrefixKey(query);
  const key = `${keyPrefix}Description`;
  return key;
};

const getCategoriesKey = (query) => {
  const keyPrefix = getPrefixKey(query);
  const key = `${keyPrefix}Categories`;
  return key;
};

export function getItemCode(offset, limit, query) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendItemCode" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getItemCode" }));
      dispatch(GetItemCodeAction([], 0));
    }
    let url = `${Constant.apiURl}/catalouge/filter/code/${offset}/${limit}`;
    if (query && query !== "") {
      url += query;
    }

    const key = getItemCodesKey(query);
    const showingAdminCatalogs = query.includes("showAdminCatalogs=true");
    let myJson = null;
    console.log({ query });

    if (showingAdminCatalogs) {
      myJson = await get(key);
    }

    if (!showingAdminCatalogs || !myJson) {
      myJson = await FETCH("GET", url);
      await set(key, myJson);
    }

    let clone = [...myJson.data];

    const itemGroupExists = query.includes("itemGroup=");
    const itemCodeExists = query.includes("itemCode=");
    const altCode2Exists = query.includes("alternativeCode2=");
    const durabilityExists = query.includes("durability=f");

    if (itemGroupExists) {
      const itemGroup = fetchValueFromQuery(query, "itemGroup");
      const isProvisionOrder = window.location.pathname.includes("provision");
      clone = clone.filter(({ category, orderType }) => isProvisionOrder ? category === itemGroup : orderType === itemGroup);
    }
    if (itemCodeExists) {
      const itmCode = fetchValueFromQuery(query, "itemCode");
      clone = clone.filter(({ itemCode }) => itemCode.includes(itmCode));
    }
    if (altCode2Exists) {
      const altCode2 = fetchValueFromQuery(query, "alternativeCode2");
      // `alternativeCode2` might be null in some records, so better use `alternativeCode` and convert it to string to compare.
      clone = clone.filter(({ alternativeCode }) => alternativeCode.toString().includes(altCode2));
    }
    if (durabilityExists) {
      clone = clone.filter(({ durability }) => durability === 'FRESH PROVISIONS');
    }

    const startIndex = offset > 0 ? offset * limit : offset;
    clone = clone.slice(startIndex, startIndex + limit);
    const length = (itemGroupExists || altCode2Exists || itemCodeExists) ? clone.length : myJson.catalougeCount;

    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendItemCodeAction(clone));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendItemCode",
            message: "",
          })
        );
      } else {
        dispatch(GetItemCodeAction(clone, length));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getItemCode",
            message: myJson.message,
          })
        );
      }
    } else {
      // const myJson = await get(key);
      if (myJson) {
        if (offset !== 0) {
          dispatch(AppendItemCodeAction(clone));
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "appendItemCode",
              message: "",
            })
          );
        } else {
          dispatch(GetItemCodeAction(clone, length));
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "getItemCode",
              message: myJson.message,
            })
          );
        }
      } else {
        if (offset !== 0) {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "appendItemCode",
              message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
            })
          );
        } else {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "getItemCode",
              message: myJson.message,
            })
          );
        }
      }
    }
  };
}

export function getDescription(offset, limit, query) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendDescription" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getDescription" }));
      dispatch(GetDescriptionAction([], 0));
    }
    let url = `${Constant.apiURl}/catalouge/filter/description/${offset}/${limit}`;
    if (query && query !== "") {
      url += query;
    }

    const key = getDescriptionKey(query);
    const showingAdminCatalogs = query.includes("showAdminCatalogs=true");
    let myJson = null;

    if (showingAdminCatalogs) {
      myJson = await get(key);
    }

    if (!showingAdminCatalogs || !myJson) {
      myJson = await FETCH("GET", url);
      await set(key, myJson);
    }

    let clone = [...myJson.data];

    const itemGroupExists = query.includes("itemGroup=");
    const itemCodeExists = query.includes("itemCode=");
    const altCode2Exists = query.includes("alternativeCode2=");
    const descriptionExists = query.includes("description=");
    const durabilityExists = query.includes("durability=f");

    if (itemGroupExists) {
      const itemGroup = fetchValueFromQuery(query, "itemGroup");
      const isProvisionOrder = window.location.pathname.includes("provision");
      clone = clone.filter(({ category, orderType }) => isProvisionOrder ? category === itemGroup : orderType === itemGroup);
    }
    if (itemCodeExists) {
      const itmCode = fetchValueFromQuery(query, "itemCode");
      clone = clone.filter(({ itemCode }) => itemCode.includes(itmCode));
    }
    if (altCode2Exists) {
      const altCode2 = fetchValueFromQuery(query, "alternativeCode2");
      // `alternativeCode2` might be null in some records, so better use `alternativeCode` and convert it to string to compare.
      clone = clone.filter(({ alternativeCode }) => alternativeCode.toString().includes(altCode2));
    }
    if (descriptionExists) {
      const desc = fetchValueFromQuery(query, "description");
      clone = clone.filter(({ description }) => description === desc || description?.toLowerCase()?.includes(desc));
    }
    if (durabilityExists) {
      clone = clone.filter(({ durability }) => durability === 'FRESH PROVISIONS');
    }

    const startIndex = offset > 0 ? offset * limit : offset;
    clone = clone.slice(startIndex, startIndex + limit);
    const length = (itemGroupExists || itemCodeExists || altCode2Exists) ? clone.length : myJson.catalougeCount;

    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendDescriptionAction(clone));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendDescription",
            message: "",
          })
        );
      } else {
        dispatch(GetDescriptionAction(clone, length));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getDescription",
            message: myJson.message,
          })
        );
      }
    } else {
      if (myJson) {
        if (offset !== 0) {
          dispatch(AppendDescriptionAction(clone));
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "appendDescription",
              message: "",
            })
          );
        } else {
          dispatch(GetDescriptionAction(clone, length));
          dispatch(
            Actions.ApiFulfilledAction({
              apiCallFor: "getDescription",
              message: myJson.message,
            })
          );
        }
      } else {
        if (offset !== 0) {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "appendDescription",
              message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
            })
          );
        } else {
          dispatch(
            Actions.ApiRejectedAction({
              statusCode: myJson.statusCode,
              apiCallFor: "getDescription",
              message: myJson.message,
            })
          );
        }
      }
    }
  };
}

export function getItemGroup(query) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getItemGroup" }));
    let url = Constant.apiURl + "/catalouge/filter/category";
    if (query && query !== "") {
      url = url + query;
    }

    const key = getCategoriesKey(query);
    const showingAdminCatalogs = query.includes("showAdminCatalogs=true");
    let myJson = null;

    if (showingAdminCatalogs) {
      myJson = await get(key);
    }

    if (!showingAdminCatalogs || !myJson) {
      myJson = await FETCH("GET", url);
      await set(key, myJson);
    }

    const durabilityExists = query.includes("durability=f");

    let clone = [...myJson.data];

    if (durabilityExists) {
      const FRESH_PROVISIONS = ['BEVERAGES', 'DAIRY PRODUCTS'];
      clone = clone.filter((record) => FRESH_PROVISIONS.includes(record));
    }

    if (myJson && myJson.code === 201) {
      dispatch(GetItemGroupAction(clone));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getItemGroup",
          message: myJson.message,
        })
      );
    } else {
      // const myJson = await get(key);
      if (myJson) {
        dispatch(GetItemGroupAction(clone));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getItemGroup",
            message: myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getItemGroup",
            message: myJson.message,
          })
        );
      }
    }
  };
}
