import React, { useEffect, useState } from 'react';
import { createDepartment } from "./../ApiCalls/department";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { DepartmentForm } from './departmentForm';
import Swal from 'sweetalert2'
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import { getRoutePathBykey } from '../../../Constants/routesConfig';
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';
const departmentInitialState = {
    name: '',
    // budgetPrice: '',
}
function CreateDepartment(props) {
    const [state, setState] = useState(departmentInitialState);
    useEffect(() => {
        if (!isAllowPermission(window.location.pathname, props.roles)) {
            props.history.push('/not-allowed')
        } else {
            if ((props.apiCallStatus.apiCallFor === "createDepartment") &&
                props.apiCallStatus.isCompleted === true &&
                props.apiCallStatus.isFailed === false) {
                setState(departmentInitialState)
                props.ApiClearAction();
                Swal.fire("Success", `The department has been created successfully.`, "success")
                if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('departmentList'), props.roles)) {
                    props.history.push(getDashboardLink())
                } else {
                    props.history.push('/department-listing')
                }
            }
        }
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let departmentDetail = { ...state }
        departmentDetail[name] = value;
        setState(departmentDetail)
    }
    const createHandler = () => {
        let obj = {
            name: state.name,
            // budgetPrice: state.budgetPrice
        }
        props.createDepartment(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/department-listing",
            isLogout: false,
        }
    ];
    return (
        <DashboardWrapper title="Create Department" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="formBox mt-4">
                <DepartmentForm {...state} history={props.history} handleFormChange={handleFormChange} handleSubmit={createHandler} apiCallStatus={props.apiCallStatus} apiName="createDepartment" />
            </div>
        </DashboardWrapper>
    )
}


const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    createDepartment: (data) => dispatch(createDepartment(data)),
    ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateDepartment);
