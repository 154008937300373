import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { countryListing, deleteCountry } from "../ApiCalls/country";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import loader from '../../../assets/images/Spinner-1s-200px.gif';
import InfiniteScroll from "react-infinite-scroll-component";
import { isAllowPermission } from '../../../Services/common';
import Swal from 'sweetalert2'
import { getRoutePathBykey } from "../../../Constants/routesConfig";
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';
function CountryList(props) {
    const [state, setState] = useState({
        countryList: [],
        activePage: 0,
        totalCount: 0,
        isHitApi: false,
    });
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.countryListing(0);
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getCountry" || props.apiCallStatus.apiCallFor === "appendCountryList") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            setState({
                ...state,
                countryList: props.countryReducer.list,
                totalCount: props.countryReducer.count,
                isHitApi: true
            })
        }
        if (props.apiCallStatus.apiCallFor === "deleteCountry" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            Swal.fire("Deleted!", "Country has been deleted!", "success");
            props.countryListing(0);
        }

    }, [props]);
    const fetchMoreData = () => {
        let { activePage } = state;
        activePage += 1;
        setState({
            ...state,
            activePage
        })
        props.countryListing(activePage)
    };
    const removeRecord = (id) => {
        if (!isAllowPermission('/delete-country', props.roles)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "You don't have permission of this feature"
            })
        } else {
            Swal.fire({
                title: "Delete Country",
                text: "Are you sure you want to delete this Country?",
                icon: "warning",
                buttons: true,
                // showCancelButton: true,
                dangerMode: true,
            })
                .then(result => {
                    if (result.isConfirmed) {
                        props.deleteCountry(id)
                    }
                });
        }
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        },
        {
            img: 'Edit-icon.svg',
            className: "dark-green",
            name: "Create<br />Country",
            path: "/create-country",
            isLogout: false
        }
    ];
    return (
        <DashboardWrapper title="Countries" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <InfiniteScroll
                dataLength={state.countryList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={state.totalCount <= state.countryList.length ? false : true}
                loader={<div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div>}
            >
                <div className="table-responsive mt-4">
                    <table className="table theme-table-wrap with-action-btns">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Country Name</th>
                                <th scope="col">Country Code</th>
                                {props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editCountry'), props.roles) && !isAllowPermission('/delete-country', props.roles) ? "" : <th scope="col">Action</th>}

                            </tr>
                        </thead>
                        <tbody>
                            {props.apiCallStatus.isStarted.indexOf("getCountry") !== -1 ?
                                <tr><td colSpan="20">
                                    <div className="loader-img text-center">
                                        <img style={{ width: "46px" }} src={loader} alt='' />
                                    </div>
                                </td></tr>
                                : state.countryList && state.countryList.length > 0 ? state.countryList.map((item, index) => {
                                    let isDisableEdit = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission(getRoutePathBykey('editCountry'), props.roles) ? true : false;
                                    let isDisableDel = props.isSubAdmin && props.roles.length > 0 && !isAllowPermission('/delete-country', props.roles) ? true : false;
                                    return <tr key={"countryList" + index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.countryCode}</td>
                                        {isDisableDel && isDisableEdit ? "" : <td>
                                            {isDisableEdit ? "" : <Link to={"/edit-country/" + item._id} className="btn btn-edit-tab mr-2"></Link>}
                                            {isDisableDel ? "" : <button type="button" onClick={() => removeRecord(item._id)} className="btn btn-dlt-tab"></button>}
                                        </td>}
                                    </tr>

                                }) : state.countryList && state.countryList.length === 0 ?
                                        <tr><td colSpan="40" className="text-center">No Data Found</td></tr> : ''}
                        </tbody>
                    </table>
                </div>
            </InfiniteScroll>
        </DashboardWrapper>
    )
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    isSubAdmin: state.authReducer.isSubAdmin,
    countryReducer: state.countryReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    countryListing: (offset) => dispatch(countryListing(offset)),
    deleteCountry: (countryId) => dispatch(deleteCountry(countryId))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountryList);
