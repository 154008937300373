import $ from 'jquery';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { Constant, inventory as InventoryConstant } from '../../../../../Constants/constant';
import { Sum, getCountryPrice, getDashboardLink, getFormatedPricing } from '../../../../../Services/common';
import { ApiClearAction } from "../../../../ApiCallStatus/Actions/action";
import { getVesselById } from "../../../../Common/ApiCalls/vesselList";
import DashboardNavbar from '../../../../Common/Components/DashboardNavbar';
import { Wrapper } from '../../../Css/previsionCatalouge';
import { AddOrderAction } from './../../../Actions/action';
import { getFilterDetail, getNextOrderQty, getOrderDetail, provisionListing, updateOrder } from './../../../ApiCalls/provisionOrder';
import { getDescription, getItemCode, getItemGroup } from './../../../ApiCalls/searchProvisionOrder';
import { CatalougeTable } from './catalougeTable';
import { Filters } from './filters';
import { NutritionDetail } from './models';
import { OrderOverView } from './orderOverview';
// var Config = require('../config.json');

const emoji1 = require("../../../../../assets/images/mesh-popup-img.png");
const emoji2 = require("../../../../../assets/images/mesh-popup-img2.png");

const calculateTotalUsedPoint = (data, numOfCrew, numOfDays) => {
    let keys = Object.keys(data);
    let total = 0;
    let energy = 0;
    let protein = 0;
    let fat = 0;
    let carbohydrates = 0;
    let sugar = 0;
    let dietaryFibres = 0;
    for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        let orKg = data[element].orKg;
        let order = data[element].order ? parseFloat(data[element].order) : 0;
        let diffInkg = order * 10 * orKg;
        let proteinVal = parseFloat(data[element].protein);
        if (data[element] && data[element].usedPoints !== '' && data[element].usedPoints != 'Infinity') {
            total += data[element].usedPoints;
        }
        if (data[element] && data[element].energy !== 0) {
            energy += parseFloat(data[element].energy) * diffInkg;
        }
        if (data[element] && proteinVal !== 0) {
            protein += proteinVal * diffInkg;
        }
        if (data[element] && data[element].fat !== 0) {
            fat += parseFloat(data[element].fat) * diffInkg;
        }
        if (data[element] && data[element].carbohydrates !== 0) {
            carbohydrates += parseFloat(data[element].carbohydrates) * diffInkg;
        }
        if (data[element] && data[element].sugar !== 0) {
            sugar += parseFloat(data[element].sugar) * diffInkg;
        }
        if (data[element] && data[element].dietaryFibres !== 0) {
            dietaryFibres += parseFloat(data[element].dietaryFibres) * diffInkg;
        }
    }
    if (energy > 0) {
        energy = ((energy / numOfDays) / numOfCrew).toFixed(0);
    }
    if (protein > 0) {
        protein = ((protein / numOfDays) / numOfCrew).toFixed(0);
    }
    if (fat > 0) {
        fat = ((fat / numOfDays) / numOfCrew).toFixed(0);
    }
    if (carbohydrates > 0) {
        carbohydrates = ((carbohydrates / numOfDays) / numOfCrew).toFixed(0);
    }
    if (sugar > 0) {
        sugar = ((sugar / numOfDays) / numOfCrew).toFixed(0);
    }
    if (dietaryFibres > 0) {
        dietaryFibres = ((dietaryFibres / numOfDays) / numOfCrew).toFixed(0);
    }
    let percentage = (total / 10000) * 100;
    // console.log('------- end ---------');
    return {
        total: parseFloat(total.toFixed(2)),
        percentage: parseFloat(percentage.toFixed(2)),
        energy,
        protein,
        fat,
        carbohydrates,
        sugar,
        dietaryFibres
    };
};
let filterInputTimeout = null;

class ProvisionCatalogue extends Component {
    constructor(props) {
        super(props);
        let durability = this.props.currentOrder.orderKind === "Main Order" ? "d" : "f";
        this.state = {
            selectedOption: null,
            catalougeList: [],
            filterCatalouge: [],
            currentOrder: {},
            isOrder: true,
            orderDetail: {},
            numOfCrew: 0,
            numOfDays: 0,
            itemGroupList: [],
            cloneItemGroupList: [],
            itemNoList: [
                { label: "1101", value: "1101" },
                { label: "1102", value: "1102" },
                { label: "1103", value: "1103" },
                { label: "1104", value: "1104" },
                { label: "1105", value: "1105" },
                { label: "1106", value: "1106" },
                { label: "1107", value: "1107" },
                { label: "1108", value: "1108" },
                { label: "1109", value: "1109" }
            ],
            filters: {
                itemGroup: "",
                inputItemGroup: "",
                itemNo: "",
                inputItemNo: "",
                inputDescription: "",
                description: "",
                isApplied: false
            },
            descriptionList: [],
            totalDescription: 0,
            showOrderDetailModel: false,
            orderNeeded: 0,
            totalOrder: 0,
            totalOrderPoints: 10000,
            totalOrderBudget: 0,
            filterQuery: '?systemType=p&durability=' + (durability),
            totalCount: 0,
            totalItemCode: 0,
            activePage: 0,
            itemCodePage: 0,
            descriptionPage: 0,
            selectedItem: {},
            durability: durability,
            isError: false,
            vesselCategory: [],
            isSaveAndClose: false,
            captainId: "",
            showOrderedList: false,
            isLimitPointExceed: false,
            isSpotVessel: false,
            userHasBeenWarned: false,
            nextOrderQtyMapping: {},
        };
    };

    componentDidMount() {
        if (this.props.currentOrder && this.props.currentOrder.numberOfCrew && this.props.currentOrder.orderPeriod) {
            this.calculateAndUpdateOrderDetail();
        } else {
            this.props.getOrderDetail(this.props.match.params.orderId);
            // this.props.history.push('/create-provision-order/' + this.props.match.params.order)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (Object.keys(this.props.currentOrder).length !== 0 && this.state.isOrder) {
            this.calculateAndUpdateOrderDetail();
        }

        if (((prevProps.apiCallStatus.isStarted.indexOf('getProvision') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getProvision') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendProvisionList') !== -1 &&
                this.props.apiCallStatus.isStarted.indexOf('appendProvisionList') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            const isSpotVessel = this.props.vesselDetail.vesselType === "spot";
            this.setState({
                ...this.state,
                catalougeList: this.props.orderReducer.provisionList,
                filterCatalouge: this.props.orderReducer.provisionList,
                totalCount: this.props.orderReducer.totalProvision,
                isSpotVessel,
            });
            if (this.props.apiCallStatus.apiCallFor === "getProvision") {
                this.disableScroll();
            }
            setTimeout(() => {
                $("input[type=number]").on("focus", function () {
                    $(this).on("keydown", function (event) {
                        if (event.keyCode === 38 || event.keyCode === 40 || event.key === "-") {
                            event.preventDefault();
                        }
                    });
                });
            }, 500);
        }

        if (((prevProps.apiCallStatus.isStarted.indexOf('getItemCode') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getItemCode') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendItemCode') !== -1 &&
                this.props.apiCallStatus.isStarted.indexOf('appendItemCode') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = this.props.orderReducer.itemCodeList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].itemCode, label: arr[i].itemCode });
            }
            this.setState({
                ...this.state,
                itemNoList: newArr,
                totalItemCode: this.props.orderReducer.totalItemCode,
            });
        }

        if (((prevProps.apiCallStatus.isStarted.indexOf('getDescription') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getDescription') === -1) || (prevProps.apiCallStatus.isStarted.indexOf('appendDescription') !== -1 &&
                this.props.apiCallStatus.isStarted.indexOf('appendDescription') === -1)) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = this.props.orderReducer.descriptionList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i].description, label: arr[i].description });
            }
            this.setState({
                ...this.state,
                descriptionList: newArr,
                totalDescription: this.props.orderReducer.totalDescription,
            });
        }

        if ((prevProps.apiCallStatus.isStarted.indexOf('getItemGroup') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('getItemGroup') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = this.props.orderReducer.itemGroupList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i], label: arr[i] });
            }
            this.setState({
                ...this.state,
                cloneItemGroupList: newArr,
                itemGroupList: newArr,
            });
        }

        if (
            (prevProps.apiCallStatus.isStarted.indexOf('updateOrder') !== -1 && this.props.apiCallStatus.isStarted.indexOf('updateOrder') === -1) &&
            this.props.apiCallStatus.isCompleted
        ) {
            if (!this.props.apiCallStatus.isFailed) {
                let id = this.props.apiCallStatus.message;
                this.props.AddOrderAction({});
                if (this.state.isSaveAndClose) {
                    this.props.history.push(getDashboardLink());
                } else {
                    let vesselId = this.props.currentOrder.vessel;
                    let url = getDashboardLink();
                    let isInvenotry = this.props.currentOrder.orderKind === InventoryConstant;
                    if (this.props.isSubAdmin && this.props.userType === 'user' && this.props.subType === 'captain') {
                        url = `/order-basket?vesselId=${vesselId}`;
                        if (isInvenotry) {
                            url = `/inventory-list`;
                        }
                    }
                    let msg = 'Your order has been added into the basket successfully.';
                    if (isInvenotry) {
                        msg = 'Your inventory has been updated successfully.';
                    } else if (this.props.userType === "user" && !this.props.isSubAdmin) {
                        msg = 'Your order was added to the order basket.';
                    }
                    Swal.fire({
                        title: msg,
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.props.history.push(url);
                        }
                    });
                    this.props.ApiClearAction();
                }
            } else {
                if (!navigator.onLine) {
                    this.props.history.push(getDashboardLink());
                    this.props.ApiClearAction();
                    Swal.fire("Success", "Order will be added once you get the Internet Connection back!", "success");
                } else {
                    Swal.fire("error", "Something went wrong!", "error");
                }
            }
        }

        if (
            (
                prevProps.apiCallStatus.isStarted.indexOf('getNextOrderQty') !== -1 &&
                this.props.apiCallStatus.isStarted.indexOf('getNextOrderQty') === -1
            ) && this.props.apiCallStatus.isCompleted
        ) {
            this.setState({
                ...this.state,
                nextOrderQtyMapping: this.props.orderReducer?.nextOrderQty || {},
            });
        }

        /*if ((prevProps.apiCallStatus.isStarted.indexOf('updateOrder') !== -1 &&
            this.props.apiCallStatus.isStarted.indexOf('updateOrder') === -1) && this.props.apiCallStatus.isCompleted && !this.props.apiCallStatus.isFailed) {
            let id = this.props.apiCallStatus.message;
            this.props.AddOrderAction({});
            if (this.state.isSaveAndClose) {
                this.props.history.push(getDashboardLink());
            } else {
                let vesselId = this.props.currentOrder.vessel;
                let url = getDashboardLink();
                let isInvenotry = this.props.currentOrder.orderKind === InventoryConstant ? true : false;
                if (this.props.isSubAdmin && this.props.userType === 'user' && this.props.subType === 'captain') {
                    url = `/order-basket?vesselId=${vesselId}`;
                    if (isInvenotry) {
                        url = `/inventory-list`;
                    }
                }
                let msg = 'Your order has been added into the basket successfully.';
                if (isInvenotry) {
                    msg = 'Your inventory has been updated successfully.';
                } else if (this.props.userType === "user" && !this.props.isSubAdmin) {
                    msg = 'Your order was added to the order basket.';
                }
                Swal.fire({
                    title: msg,
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    allowOutsideClick: false,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push(url);
                    }
                });
                this.props.ApiClearAction();
            }
        }*/
    }

    calculateAndUpdateOrderDetail = () => {
        const { currentOrder } = this.props;
        const vesselId = currentOrder?.vessel;
        const captainId = currentOrder?.captain;
        this.props.getNextOrderQty(vesselId, captainId);

        // let categoryType = this.props.currentOrder.orderKind === "Main Order" ? 'main' : 'fresh';
        let vesselCategory = this.props.currentOrder.categories.filter(function (cat) {
            return cat;
        });
        const orderKind = this.props.currentOrder?.orderKind || "";
        const isAdditional = orderKind?.toLowerCase() === "additional";
        const durability = orderKind === "Main Order" || isAdditional ? "d" : "f";
        const availability = isAdditional ? 'provisionAdditional' : 'p';
        let filterQuery = `?systemType=${availability}&showAdminCatalogs=true&durability=${durability}&orderId=${this.props.match.params.orderId}`;
        let query = `?availability=${availability}&showAdminCatalogs=true&durability=${durability}`;
        if (this.props.currentOrder.orderKind === InventoryConstant) {
            filterQuery = `?systemType=${availability}`;
            query = `?availability=${availability}`;
        }
        let numOfCrew = Sum(this.props.currentOrder.numberOfCrew.regular, this.props.currentOrder.numberOfCrew.additional);
        let numOfDays = 0;
        if (durability === "d") {
            numOfDays = Sum(this.props.currentOrder.orderPeriod.dryFrozen.regular, this.props.currentOrder.orderPeriod.dryFrozen.expectedDelay);
        } else {
            numOfDays = Sum(this.props.currentOrder.orderPeriod.freshProvisions.regular, this.props.currentOrder.orderPeriod.freshProvisions.expectedDelay);
        }
        let orderNeeded = 0;
        let totalOrder = 0;
        let vesselPriceRate = parseFloat(this.props.currentOrder.vesselRate);
        let totalOrderBudget = vesselPriceRate * numOfCrew * numOfDays;
        let orderDetail = {};
        for (let index = 0; index < vesselCategory.length; index++) {
            if (this.props.currentOrder.orderKind === "Fresh Top Up") {
                if (vesselCategory[index].category === "DAIRY PRODUCTS" || vesselCategory[index].category === "FRUITS & VEGETABLES") {
                    orderNeeded += (parseFloat(vesselCategory[index].qtyPerDay) / 1000 * numOfCrew * numOfDays);
                }
            } else {
                let isFreshOrder = false;
                let freshNumOfDays = 0;
                if (vesselCategory[index].category === "DAIRY PRODUCTS" || vesselCategory[index].category === "FRUITS & VEGETABLES") {
                    isFreshOrder = true;
                    freshNumOfDays = Sum(this.props.currentOrder.orderPeriod.freshProvisions.regular, this.props.currentOrder.orderPeriod.freshProvisions.expectedDelay);
                }
                if (freshNumOfDays) {
                    isFreshOrder = false;
                    orderNeeded += parseFloat(vesselCategory[index].qtyPerDay) / 1000 * numOfCrew * freshNumOfDays;
                } else {
                    orderNeeded += parseFloat(vesselCategory[index].qtyPerDay) / 1000 * numOfCrew * numOfDays;

                }
            }
        }
        // const { currentOrder } = this.props;
        // let captainId = currentOrder.captain;
        if (currentOrder.orderDetail) {
            for (let key in currentOrder.orderDetail) {
                const rec = currentOrder.orderDetail[key];
                const { orKg, price } = getCountryPrice(
                    rec?.pricePerCountry,
                    currentOrder?.country?.name,
                    rec?.priceAvg,
                    rec?.standardPackagingUnit,
                    rec?.baseQuantity,
                );
                let inventory = parseFloat(rec.inventory) * orKg;
                let order = parseFloat(rec.order) * orKg;
                // let inventory = parseFloat(rec.inventory);
                // let order = parseFloat(rec.order);
                let count = 0;
                if (!isNaN(inventory)) {
                    count += inventory;
                    totalOrder += inventory;
                }
                if (!isNaN(order)) {
                    count += order;
                    totalOrder += order;
                }
                const budgetPoints = ((parseFloat(price) / totalOrderBudget) * this.state.totalOrderPoints).toFixed(2);
                orderDetail[key] = rec;
                orderDetail[key].budgetPoints = budgetPoints;
                orderDetail[key].usedPoints = parseFloat((budgetPoints * count).toFixed(2));
                orderDetail[key].orKg = orKg;
            }
        }
        calculateTotalUsedPoint(orderDetail, numOfCrew, numOfDays);
        this.setState({
            orderDetail: orderDetail,
            isOrder: false,
            filterQuery,
            durability,
            vesselCategory,
            numOfCrew,
            numOfDays,
            totalOrder,
            totalOrderBudget: totalOrderBudget,
            currentOrder: this.props.currentOrder,
            orderNeeded: orderNeeded.toFixed(2),
            captainId
        });

        this.props.provisionListing(0, 200, filterQuery);
        this.props.getItemCode(0, 20, query);
        this.props.getDescription(0, 20, query);
        this.props.getItemGroup(query);

        if (Object.size(this.props.vesselDetail) === 0) {
            this.props.getVesselById(this.props.currentOrder.vessel);
        }

        let title = "";
        const body = `
            <p>The order form provides an overview of your consumption, spent budget and nutritional values.</p>
            <p>You can search for items by item group or item description (also partly). </p>
            <p>You may save the order at any time and continue later. </p>
            <p>To finalize, please add your order to the order basket and proceed from there.</p>
            <h6>THANK YOU!</h6>
        `;

        if (orderKind !== InventoryConstant) {
            if (orderKind === "Main Order") {
                title = "Welcome to your provision order";
            } else if (orderKind === "Fresh Top Up") {
                title = "Welcome to fresh provision order";
            } else {
                title = "Welcome to your additional provision order";
            }
            Swal.fire(title, body, "success");
        }
    };
    handleFilterChange = (id, val) => {
        let filters = { ...this.state.filters };
        let obj = {};
        filters[id] = val;
        obj.filters = filters;
        let th = this;
        const orderKind = this.props.currentOrder?.orderKind || "";
        const isAdditional = orderKind.toLowerCase() === 'additional';
        const availability = isAdditional ? 'provisionAdditional' : 'p';
        let query = `?availability=${availability}&showAdminCatalogs=true&durability=${this.state.durability}`;
        if (this.props.currentOrder.orderKind === InventoryConstant) {
            query = `?availability=${availability}`;
        }
        if (filters.itemGroup) {
            query += `&itemGroup=${filters.itemGroup}`;
        }
        clearTimeout(filterInputTimeout);
        if (id === 'inputItemNo') {
            obj.itemCodePage = 0;
            // query = "?availability=p&durability=" + this.state.durability;
            if (val !== '') {
                query = query + '&itemCode=' + val;
            }
        } else if (id === 'inputDescription') {
            obj.descriptionPage = 0;
            // query = "?availability=p&durability=" + this.state.durability;
            if (val !== '') {
                query = query + '&description=' + val;
            }
        } else if (id === "inputItemGroup") {
            let arr = this.state.cloneItemGroupList.filter((item) => {
                let trimVal = val.toLowerCase();
                let selectedVal = item.value.toLowerCase();
                return selectedVal.indexOf(trimVal) !== -1;
            });

            obj.itemGroupList = arr;
        }
        // Make a new timeout set to go off in 1000ms (1 second)
        filterInputTimeout = setTimeout(function () {
            if (id === 'inputItemNo') {
                th.props.getItemCode(0, 20, query);
            } else if (id === 'inputDescription') {
                th.props.getDescription(0, 20, query);
            }
        }, 500);
        this.setState(obj);
    };
    clickCustomeSelectHandler = (id, val) => {
        let filters = { ...this.state.filters };
        let obj = {};
        filters[id] = val;
        if (id === 'itemNo') {
            filters.inputItemNo = val;
            obj.itemCodePage = 0;
            // let query = "?availability=p&durability=" + this.state.durability;
            // if (val !== '') {
            // 	query = query + '&itemCode=' + val;
            // }
        } else if (id === 'description') {
            filters.inputDescription = val;
            obj.descriptionPage = 0;
            // let query = "?availability=p&durability=" + this.state.durability;
            // if (val !== '') {
            // 	query = query + '&description=' + val;
            // }
        } else if (id === "itemGroup") {
            filters.inputItemGroup = val;
            filters.itemNo = "";
            filters.description = "";
            filters.inputItemNo = "";
            filters.inputDescription = "";
        }
        obj.filters = filters;
        this.setState(obj, () => this.applyFilters());
    };
    clearFilters = () => {
        const orderKind = this.props.currentOrder?.orderKind || "";
        const isAdditional = orderKind.toLowerCase() === 'additional';
        const availability = isAdditional ? 'provisionAdditional' : 'p';
        let filterQuery = `?systemType=${availability}&showAdminCatalogs=true&durability=${this.state.durability}&orderId=${this.props.match.params.orderId}`;
        let query = `?availability=${availability}&showAdminCatalogs=true&durability=${this.state.durability}`;
        if (this.props.currentOrder.orderKind === InventoryConstant) {
            filterQuery = `?systemType=${this.state.durability}`;
            query = `?availability=${this.state.durability}`;
        }
        this.setState({
            filters: {
                itemGroup: "",
                itemNo: "",
                inputItemGroup: "",
                inputItemNo: "",
                inputDescription: "",
                description: "",
                isApplied: false
            },
            itemGroupList: this.state.cloneItemGroupList,
            itemCodePage: 0,
            descriptionPage: 0,
            filterQuery
        }, () => {
            this.props.provisionListing(0, 200, filterQuery);
            this.props.getItemCode(0, 20, query);
            this.props.getDescription(0, 20, query);
            this.props.getItemGroup(query);
        });
    };
    applyFilters = () => {
        let val1 = '';
        let val2 = '';
        let val3 = '';
        let activePage = 0;
        if (this.state.filters.itemGroup !== '') {
            val1 = "&itemGroup=" + encodeURIComponent(this.state.filters.itemGroup);
        }
        if (this.state.filters.itemNo !== '') {
            val2 = "&itemCode=" + this.state.filters.itemNo;
        }
        if (this.state.filters.description !== '') {
            val3 = "&description=" + encodeURIComponent(this.state.filters.description);
        }
        const orderKind = this.props.currentOrder?.orderKind || "";
        const isAdditional = orderKind.toLowerCase() === 'additional';
        const availability = isAdditional ? 'provisionAdditional' : 'p';
        let filterQuery = `?systemType=${availability}&showAdminCatalogs=true&durability=${this.state.durability}&orderId=${this.props.match.params.orderId}`;
        if (this.props.currentOrder.orderKind === InventoryConstant) {
            filterQuery = `?systemType=${availability}`;
        }
        if (val1 !== '') {
            filterQuery += val1;
        }
        if (val2 !== '') {
            filterQuery += val2;
        }
        if (val3 !== '') {
            filterQuery += val3;
        }
        this.setState({
            filterQuery,
            activePage,
            itemCodePage: 0,
            descriptionPage: 0,
            isApplied: true
        }, () => {
            this.props.provisionListing(activePage, 200, filterQuery);
            const availQ = filterQuery?.replace("systemType=", "availability=");
            this.props.getItemCode(activePage, 200, availQ);
            this.props.getDescription(activePage, 200, availQ);
        });
    };

    validateOrderDetails = (category) => {
        const { currentOrder, vesselDetail } = this.props;
        const { vesselCategory, numOfCrew, totalOrderPoints, orderDetail } = this.state;

        let catIndex = vesselCategory.findIndex(x => x.category === category);
        let needed = 0;
        let catName = "";
        let numOfDays = 0;

        if (category === "DAIRY PRODUCTS" || category === "FRUITS & VEGETABLES") {
            numOfDays = Sum(
                currentOrder.orderPeriod.freshProvisions.regular,
                currentOrder.orderPeriod.freshProvisions.expectedDelay
            );
        } else {
            numOfDays = Sum(
                currentOrder.orderPeriod.dryFrozen.regular,
                currentOrder.orderPeriod.dryFrozen.expectedDelay
            );
        }

        if (catIndex !== -1) {
            catName = vesselCategory[catIndex].category;
            needed = parseFloat(vesselCategory[catIndex].qtyPerDay) / 1000 * numOfCrew * numOfDays;
        } else {
            catIndex = vesselCategory.findIndex(x => x.category === "MEAT");
            if (catIndex !== -1) {
                needed = parseFloat(vesselCategory[catIndex].qtyPerDay) / 1000 * numOfCrew * numOfDays;
                catName = vesselCategory[catIndex].category;
            }
        }

        const isSpiceAndHerbsCategory = catName.toLowerCase() === "spices & herbs";

        let totalOrder = 0.0;

        const orderkeys = Object.keys(orderDetail);

        for (let index = 0; index < orderkeys.length; index++) {
            const element = orderkeys[index];
            const rec = orderDetail[element];
            if (rec) {
                const { inventory, order, orKg } = rec;
                const orderVal = parseFloat(order) * orKg;
                const inventoryVal = parseFloat(inventory) * orKg;
                if (!isNaN(orderVal)) {
                    totalOrder += orderVal;
                }
                if (!isNaN(inventoryVal)) {
                    totalOrder += inventoryVal;
                }
            }
        }

        const {
            rateLimitFixed,
            rateTolerance,
            maxQuantityLimitFixed,
            categoyLimitFixed,
            maxCategoryTolerance,
            minimumFixed,
        } = vesselDetail;

        const validationsImposed = rateLimitFixed || maxQuantityLimitFixed || categoyLimitFixed || minimumFixed;

        const orderKind = currentOrder?.orderKind || "";
        const isAdditionalOrder = orderKind.toLowerCase() === 'additional';

        let rateAllowance = totalOrderPoints + ((totalOrderPoints / 100) * rateTolerance);
        let catAllowance = needed + ((needed / 100) * maxCategoryTolerance);

        let isError = false;
        let title = "";
        let message = "";

        const { total } = calculateTotalUsedPoint(orderDetail, numOfCrew, numOfDays);
        const calculateOrderedProduct = this.calculateOrderedProduct(category, orderDetail);

        const rateLimitViolation = rateLimitFixed && total > rateAllowance;
        const maxQuantityLimitViolation = maxQuantityLimitFixed && calculateOrderedProduct > totalOrder;
        const categoryLimitViolation = categoyLimitFixed && calculateOrderedProduct > catAllowance;

        // NO VALIDATIONS ON ADDITIONAL ORDER.
        if (validationsImposed && !isAdditionalOrder) {
            if (rateLimitViolation || maxQuantityLimitViolation || categoryLimitViolation) {
                if (rateLimitViolation) {
                    title = "Budget points exceeded";
                    message = "Please adjust your budget points before sending the order.";
                    isError = true;
                }

                if (maxQuantityLimitViolation && !isSpiceAndHerbsCategory) {
                    title = "Overall Quantity limit violation";
                    message = `Overall Quantity exceeded its allowance, which is ${getFormatedPricing(totalOrder, "USD")} KGs.`;
                    isError = true;
                }

                if (categoryLimitViolation && !isSpiceAndHerbsCategory) {
                    title = "Item group quantity exceeded";
                    message = `Please adjust the quantity of ${catName} before sending the order.`;
                    isError = true;
                }
            }
        }
        return { isError, title, message };
    };

    handleTableChange = (itemNo, id, value, budgetPoints, category, nutritions, item) => {
        let orderDetail = cloneDeep(this.state.orderDetail);
        let catIndex = this.state.vesselCategory.findIndex(x => x.category === category);
        let needed = 0;
        let catName = "";
        let numOfDays = 0;
        if (category === "DAIRY PRODUCTS" || category === "FRUITS & VEGETABLES") {
            numOfDays = Sum(this.props.currentOrder.orderPeriod.freshProvisions.regular, this.props.currentOrder.orderPeriod.freshProvisions.expectedDelay);
        } else {
            numOfDays = Sum(this.props.currentOrder.orderPeriod.dryFrozen.regular, this.props.currentOrder.orderPeriod.dryFrozen.expectedDelay);
        }
        if (catIndex !== -1) {
            catName = this.state.vesselCategory[catIndex].category;
            needed = parseFloat(this.state.vesselCategory[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * numOfDays;
        } else {
            catIndex = this.state.vesselCategory.findIndex(x => x.category === "MEAT");
            if (catIndex !== -1) {
                needed = parseFloat(this.state.vesselCategory[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * numOfDays;
                catName = this.state.vesselCategory[catIndex].category;
            }
        }
        const isSpiceAndHerbsCategory = catName.toLowerCase() === "spices & herbs";
        let isShowError = true;
        let isLimitPointExceed = false;
        let obj = orderDetail[itemNo];
        let totalOrder = 0;
        if (obj) {
            if (id === 'order' && obj.inventory === '' && !this.state.isSpotVessel) {
                Swal.fire("Error", "Please fill the inventory column before filling in the desired order quantities", "error");
            } else {
                if (parseInt(obj[id]) > parseInt(value)) {
                    isShowError = false;
                }
                obj[id] = value;
                obj.budgetPoints = budgetPoints;
                obj.category = category;
                obj.energy = nutritions.energy !== 0 ? nutritions.energy : 0;
                obj.protein = nutritions.protein !== 0 ? nutritions.protein : 0;
                obj.fat = nutritions.fat !== 0 ? nutritions.fat : 0;
                obj.carbohydrates = nutritions.carbohydrates !== 0 ? nutritions.carbohydrates : 0;
                obj.sugar = nutritions.sugar !== 0 ? nutritions.sugar : 0;
                obj.dietaryFibres = nutritions.dietaryFibres !== 0 ? nutritions.dietaryFibres : 0;
                obj.description = nutritions.description;
                obj.explanation = nutritions.explanation;
                obj.standardPackagingUnit = nutritions.standardPackagingUnit;
                obj.pricePerBaseUnit = nutritions.pricePerBaseUnit;
                obj.orderUsedPoints = nutritions.orderUsedPoints;
                obj.category = nutritions.category;
                obj.itemCode = nutritions.itemCode;
            }
        } else {
            delete item._id;
            delete item.createdAt;
            delete item.updatedAt;
            obj = { ...item, inventory: '', order: '', budgetPoints: '', usedPoints: '' };

            if (id === 'order' && !this.state.isSpotVessel) {
                Swal.fire("Error", "Please fill the inventory column before filling in the desired order quantities", "error");
            } else {
                if (parseInt(obj[id]) > parseInt(value)) {
                    isShowError = false;
                }
                obj[id] = value;
                obj.budgetPoints = budgetPoints;
                obj.category = category;
                obj.energy = nutritions.energy !== 0 ? nutritions.energy : 0;
                obj.protein = nutritions.protein !== 0 ? nutritions.protein : 0;
                obj.fat = nutritions.fat !== 0 ? nutritions.fat : 0;
                obj.carbohydrates = nutritions.carbohydrates !== 0 ? nutritions.carbohydrates : 0;
                obj.sugar = nutritions.sugar !== 0 ? nutritions.sugar : 0;
                obj.dietaryFibres = nutritions.dietaryFibres !== 0 ? nutritions.dietaryFibres : 0;
                obj.pricePerBaseUnit = nutritions.pricePerBaseUnit;
                obj.orderUsedPoints = nutritions.orderUsedPoints;
            }
        }
        const { pricePerCountry, standardPackagingUnit, baseQuantity, priceAvg } = obj;
        const country = this.state.currentOrder?.country?.name;
        const { orKg } = getCountryPrice(pricePerCountry, country, priceAvg, standardPackagingUnit, baseQuantity);
        let inventory = parseFloat(obj.inventory) * orKg;
        let order = parseFloat(obj.order) * orKg;
        let count = 0;
        if (!isNaN(inventory)) {
            count += inventory;
        }
        if (!isNaN(order)) {
            count += order;
        }
        let filterCatalouge = this.state.filterCatalouge;
        let totalCount = this.state.totalCount;
        obj.usedPoints = parseFloat((budgetPoints * count).toFixed(2));
        if (!isNaN(order) || !isNaN(inventory)) {
            orderDetail[itemNo] = obj;
        } else if (isNaN(order) && isNaN(inventory)) {
            delete orderDetail[itemNo];
            if (this.state.showOrderedList) {
                filterCatalouge = Object.values(orderDetail);
                totalCount = filterCatalouge.length;
            }
        }

        const { orderNeeded, totalOrderPoints, numOfCrew } = this.state;
        const { vesselDetail, currentOrder } = this.props;

        const calculateOrderedProduct = this.calculateOrderedProduct(category, orderDetail);
        const { total, percentage } = calculateTotalUsedPoint(orderDetail, numOfCrew, numOfDays);
        console.log({ total, percentage });
        console.log({ calculateOrderedProduct });

        const {
            rateLimitFixed,
            rateTolerance,
            maxQuantityLimitFixed,
            maxQuantityTolerance,
            categoyLimitFixed,
            maxCategoryTolerance,
            minimumFixed,
            sufficientProvisionsMinimum,
        } = vesselDetail;

        const validationsImposed = rateLimitFixed || maxQuantityLimitFixed || categoyLimitFixed || minimumFixed;
        console.log({ validationsImposed });
        // console.log({ vesselDetail });

        const orderKind = currentOrder?.orderKind || "";
        const isAdditionalOrder = orderKind.toLowerCase() === 'additional';

        const orderkeys = Object.keys(orderDetail);

        for (let index = 0; index < orderkeys.length; index++) {
            const element = orderkeys[index];
            const rec = orderDetail[element];
            if (rec) {
                const { inventory, order, orKg } = rec;
                const orderVal = parseFloat(order) * orKg;
                const inventoryVal = parseFloat(inventory) * orKg;
                if (!isNaN(orderVal)) {
                    totalOrder += orderVal;
                }
                if (!isNaN(inventoryVal)) {
                    totalOrder += inventoryVal;
                }
            }
        }

        console.log({ totalOrderPoints });
        let rateAllowance = totalOrderPoints + ((totalOrderPoints / 100) * rateTolerance);
        console.log({ rateAllowance });
        console.log({ orderNeeded });
        let quantityAllowance = needed + ((needed / 100) * maxQuantityTolerance);
        console.log({ quantityAllowance });
        console.log({ needed });
        let catAllowance = needed + ((needed / 100) * maxCategoryTolerance);
        console.log({ catAllowance });
        console.log({ totalOrder });
        const sufficientQtyAllowance = ((Number(orderNeeded) / 100) * sufficientProvisionsMinimum);
        console.log({ sufficientQtyAllowance });
        // EACH ITEM GROUP SHOULD NOT BE HAVING LESS THAN `sufficientProvisionsMinimum` DEFINED IN VESSEL CONFIG.
        const minimumFixedRequired = (needed * sufficientProvisionsMinimum) / 100;
        console.log("-----------------------------");
        console.log({ rateLimitFixed, total, rateAllowance });
        console.log({ maxQuantityLimitFixed, calculateOrderedProduct, totalOrder });
        console.log({ categoyLimitFixed, calculateOrderedProduct, catAllowance });
        console.log({ minimumFixed, calculateOrderedProduct, minimumFixedRequired });

        let errorMessage = "";
        let isError = false;

        const rateLimitViolation = rateLimitFixed && total > rateAllowance;
        const maxQuantityLimitViolation = maxQuantityLimitFixed && calculateOrderedProduct > totalOrder;
        const categoryLimitViolation = categoyLimitFixed && calculateOrderedProduct > catAllowance;
        // const minimumFixedViolation = minimumFixed && calculateOrderedProduct < minimumFixedRequired;

        // NO VALIDATIONS ON ADDITIONAL ORDER.
        if (validationsImposed && !isAdditionalOrder) {
            if (rateLimitViolation || maxQuantityLimitViolation || categoryLimitViolation) {
                let title = '';
                let message = '';

                if (rateLimitViolation) {
                    title = "Budget points exceeded";
                    message = "Please adjust your budget points before sending the order.";
                    isError = true;
                }

                if (maxQuantityLimitViolation && !isSpiceAndHerbsCategory) {
                    title = "Overall Quantity limit violation";
                    message = `Overall Quantity exceeded its allowance, which is ${getFormatedPricing(totalOrder, "USD")} KGs.`;
                    isError = true;
                }

                if (categoryLimitViolation && !isSpiceAndHerbsCategory) {
                    title = "Item group quantity exceeded";
                    message = `Please adjust the quantity of ${catName} before sending the order.`;
                    isError = true;
                }

                // else {
                //     title = "Item group quantity exceeded";
                //     message = `Please adjust the quantity of ${catName} before sending the order.`;
                // }

                // else if (minimumFixedViolation) {
                //     title = "Minimum Fixed limit violation";
                //     message = `${catName} doesn't have sufficient amount of ordered Quantity. It should not have less than ${getFormatedPricing(minimumFixedRequired, "USD")} KGs.`;
                // }

                errorMessage = `
                    <div id="warningModel">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div style="display: flex; justify-content: space-between;align-items: center;">
                                        <div style="margin-right: 20px;">
                                            <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
                                        </div>
                                        <div className="content-wrap ml-4">
                                            <h4>${title}</h4>
                                            <p>${message}</p>
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
                                        <div style="margin-right: 20px;">
                                            <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
                                        </div>
                                        <div className="content-wrap ml-4">
                                            <p style="font-style: italic;font-size: 17px;">Click <strong>"View Order Details"</strong> or filter by <strong>"Ordered List"</strong> to find the items with the highest quantity</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                `;
            }
        }

        if (isError) {
            Swal.fire({
                className: "warning-new-model",
                customClass: {
                    container: "container-class",
                },
                html: errorMessage,
                showCloseButton: true,
            });
        }

        this.setState({
            totalOrder,
            orderDetail,
            filterCatalouge,
            totalCount,
            isError,
            isLimitPointExceed
        });

        // if ((orderKind === InventoryConstant) || (rateLimitFixed || percentage <= 100) || (!rateLimitFixed && rateAllowance <= total)) {
        //     let isError = false;

        //     if (isShowError && orderKind !== InventoryConstant && !isAdditionalOrder) {
        //         if ((!categoyLimitFixed && needed < calculateOrderedProduct) || (categoyLimitFixed && catAllowance < calculateOrderedProduct)) {
        //             Swal.fire({
        //                 className: "warning-new-model",
        //                 customClass: {
        //                     container: 'container-class',
        //                 },
        //                 html:
        //                     `<div id="warningModel">
        // 				  <div className="modal-dialog">

        // 					  <div className="modal-content">
        // 						  <div className="modal-body">
        // 							  <div style="display: flex; justify-content: space-between;align-items: center;">
        // 								  <div style="margin-right: 20px;">
        // 									  <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
        // 								  </div>
        // 								  <div className="content-wrap ml-4">
        // 									  <h2>Item group quantity exceeded</h2>
        // 									  <p>Please adjust the quantity of ${catName} before sending the order</p>
        // 								  </div>
        // 							  </div>
        // 							  <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
        // 								  <div style="margin-right: 20px;">
        // 									  <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
        // 								  </div>
        // 								  <div className="content-wrap ml-4">
        // 									  <p style="font-style: italic;font-size: 17px;">Click <strong>"View Order Details"</strong> or filter by <strong>"Ordered List"</strong> to find the items with the highest quantity</p>

        // 								  </div>
        // 							  </div>
        // 						  </div>
        // 					  </div>

        // 				  </div>
        // 			  </div>`,
        //                 showCloseButton: true,
        //             });
        //             if (!categoyLimitFixed) {
        //                 Swal.fire({
        //                     className: "warning-new-model",
        //                     customClass: {
        //                         container: 'container-class',
        //                     },
        //                     html:
        //                         `<div id="warningModel">
        // 					  <div className="modal-dialog">

        // 						  <div className="modal-content">
        // 							  <div className="modal-body">
        // 								  <div style="display: flex; justify-content: space-between;align-items: center;">
        // 									  <div style="margin-right: 20px;">
        // 										  <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
        // 									  </div>
        // 									  <div className="content-wrap ml-4">
        // 										  <h2>Item group quantity exceeded</h2>
        // 										  <p>Please adjust the quantity of ${catName} before sending the order</p>
        // 									  </div>
        // 								  </div>
        // 								  <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
        // 									  <div style="margin-right: 20px;">
        // 										  <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
        // 									  </div>
        // 									  <div className="content-wrap ml-4">
        // 										  <p style="font-style: italic;font-size: 17px;">Click <strong>"View Order Details"</strong> or filter by <strong>"Ordered List"</strong> to find the items with the highest quantity</p>

        // 									  </div>
        // 								  </div>
        // 							  </div>
        // 						  </div>

        // 					  </div>
        // 				  </div>`,
        //                     showCloseButton: true,
        //                 });
        //             }
        //             isError = true;
        //         } else if (maxQuantityLimitFixed && quantityAllowance <= totalOrder && !isAdditionalOrder) {
        //             Swal.fire({
        //                 className: "warning-new-model",
        //                 customClass: {
        //                     container: 'container-class',
        //                 },
        //                 html:
        //                     `<div id="warningModel">
        // 				  <div className="modal-dialog">

        // 					  <div className="modal-content">
        // 						  <div className="modal-body">
        // 							  <div style="display: flex; justify-content: space-between;align-items: center;">
        // 								  <div style="margin-right: 20px;">
        // 									  <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
        // 								  </div>
        // 								  <div className="content-wrap ml-4">
        // 									  <h2>Maximum quantity exceeded</h2>
        // 									  <p>Please adjust your total quantity before sending the order</p>
        // 								  </div>
        // 							  </div>
        // 							  <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
        // 								  <div style="margin-right: 20px;">
        // 									  <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
        // 								  </div>
        // 								  <div className="content-wrap ml-4">
        // 									  <p style="font-style: italic;font-size: 17px;">Click <strong>"View Order Details"</strong> or filter by <strong>"Ordered List"</strong> to find the items with the highest quantity</p>

        // 								  </div>
        // 							  </div>
        // 						  </div>
        // 					  </div>

        // 				  </div>
        // 			  </div>`,
        //                 showCloseButton: true,
        //             });
        //             isError = true;
        //         }
        //     }

        //     if (Number(total) > rateAllowance && orderKind.toLowerCase() !== "additional") {
        //         Swal.fire({
        //             className: "warning-new-model",
        //             customClass: {
        //                 container: 'container-class',
        //             },
        //             html:
        //                 `<div id="warningModel">
        // 			  <div className="modal-dialog">

        // 				  <div className="modal-content">
        // 					  <div className="modal-body">
        // 						  <div style="display: flex; justify-content: space-between;align-items: center;">
        // 							  <div style="margin-right: 20px;">
        // 								  <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
        // 							  </div>
        // 							  <div className="content-wrap ml-4">
        // 								  <h2>Budget points exceeded</h2>
        // 								  <p>Please adjust your budget points before sending the order</p>
        // 							  </div>
        // 						  </div>
        // 						  <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
        // 							  <div style="margin-right: 20px;">
        // 								  <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
        // 							  </div>
        // 							  <div className="content-wrap ml-4">
        // 								  <p style="font-style: italic;font-size: 17px;">Filter by <strong>"Order List"</strong> and check the <strong>Used Points</strong> to find your cost triggers</p>

        // 							  </div>
        // 						  </div>
        // 					  </div>
        // 				  </div>

        // 			  </div>
        // 		  </div>`,
        //             showCloseButton: true,
        //         });
        //         isError = true;
        //         isLimitPointExceed = true;

        //     }
        // } else {
        //     if (isShowError) {
        //         let msg = `Please adjust the quantity of ${catName} before sending the order`;
        //         if (!rateLimitFixed && rateTolerance !== 0) {
        //             msg = `Your order is exceeding the budget. Please adjust the quantity of ${catName} before sending the order`;
        //         }
        //         Swal.fire("Error", msg, "error");
        //     }
        // }
    };

    fetchMoreData = () => {
        let { activePage } = this.state;
        activePage += 1;
        this.setState({
            activePage
        }, () => {
            this.props.provisionListing(activePage, 200, this.state.filterQuery);
        });
    };
    fetchMoreItemCode = () => {
        let { filters, itemCodePage } = this.state;
        itemCodePage += 1;
        const orderKind = this.props.currentOrder?.orderKind || "";
        const isAdditional = orderKind.toLowerCase() === 'additional';
        const availability = isAdditional ? 'provisionAdditional' : 'p';
        let query = `?showAdminCatalogs=true&availability=${availability}&durability=${this.state.durability}`;
        if (this.props.currentOrder.orderKind === InventoryConstant) {
            query = `?availability=${availability}`;
        }
        if (filters.itemGroup !== '') {
            query += '&itemGroup=' + filters.itemGroup;
        }
        if (filters.itemNo !== '') {
            query += '&itemCode=' + filters.itemNo;
        }
        this.setState({
            itemCodePage
        }, () => {
            this.props.getItemCode(itemCodePage, 20, query);
        });
    };
    fetchMoreDescription = () => {
        let { descriptionPage, filters } = this.state;
        descriptionPage += 1;
        const orderKind = this.props.currentOrder?.orderKind || "";
        const isAdditional = orderKind.toLowerCase() === 'additional';
        const availability = isAdditional ? 'provisionAdditional' : 'p';
        let query = `?showAdminCatalogs=true&availability=${availability}&durability=${this.state.durability}`;
        if (this.props.currentOrder.orderKind === InventoryConstant) {
            query = `?availability=${availability}`;
        }
        if (filters.itemGroup !== '') {
            query += '&itemGroup=' + filters.itemGroup;
        }
        if (filters.inputDescription !== '') {
            query += '&description=' + filters.inputDescription;
        }
        this.setState({
            descriptionPage
        }, () => {
            this.props.getDescription(descriptionPage, 20, query);
        });
    };
    CatogeryDetails = (minOrderLimit) => {
        let { vesselCategory, numOfCrew, numOfDays, orderDetail } = this.state;
        let provisionOrderType = this.props.currentOrder.orderKind;
        let isBlocked = false;
        if (provisionOrderType === 'Fresh Top Up' || provisionOrderType === 'Main Order') {
            for (let i = 0; i < vesselCategory.length; i++) {
                let item = vesselCategory[i];
                const categoryName = item.category;
                if (provisionOrderType === 'Fresh Top Up') {
                    if (categoryName === "DAIRY PRODUCTS" || categoryName === 'FRUITS & VEGETABLES') {
                        let needed = (parseFloat(item.qtyPerDay) / 1000 * numOfCrew * numOfDays).toFixed(2);
                        let ordered = this.calculateOrderedProduct(categoryName, orderDetail);
                        let minOrderNeeded = (needed / 100) * minOrderLimit;
                        if (ordered < minOrderNeeded) {
                            isBlocked = true;
                            break;
                        }
                    }
                } else if (provisionOrderType === 'Main Order') {
                    if (categoryName !== "SPICES & HERBS") {
                        if (categoryName === "DAIRY PRODUCTS" || categoryName === 'FRUITS & VEGETABLES') {
                            numOfDays = Sum(this.props.currentOrder.orderPeriod.freshProvisions.regular, this.props.currentOrder.orderPeriod.freshProvisions.expectedDelay);
                        }
                        let needed = (parseFloat(item.qtyPerDay) / 1000 * numOfCrew * numOfDays).toFixed(2);
                        let ordered = this.calculateOrderedProduct(categoryName, orderDetail);
                        let minOrderNeeded = (needed / 100) * minOrderLimit;
                        if (ordered < minOrderNeeded) {
                            isBlocked = true;
                            break;
                        }
                    }
                }
            }
        }
        return isBlocked;
    };

    updateOrder = (e, buttonText) => {
        if (this.props.apiCallStatus.isStarted.indexOf("updateOrder") === -1) {
            let { minimumFixed, sufficientProvisionsMinimum } = this.props.vesselDetail;
            if (Object.size(this.props.vesselDetail) > 0) {
                let needed = this.state.orderNeeded;
                if (minimumFixed) {
                    needed = (this.state.orderNeeded / 100) * sufficientProvisionsMinimum;
                }
                let isDisabled = false;
                const { isSaveAndClose } = this.state;
                if (!isSaveAndClose) {
                    isDisabled = this.CatogeryDetails(sufficientProvisionsMinimum);
                }
                if (this.state.isLimitPointExceed) {
                    if (isSaveAndClose) {
                        this.setState({
                            isSaveAndClose: false
                        });
                    }
                    Swal.fire({
                        className: "warning-new-model",
                        customClass: {
                            container: 'container-class',
                        },
                        html: `
                            <div id="warningModel">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div style="display: flex; justify-content: space-between;align-items: center;">
                                                <div style="margin-right: 20px;">
                                                    <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
                                                </div>
                                                <div className="content-wrap ml-4">
                                                    <h2>Budget points exceeded</h2>
                                                    <p>Please adjust budget points before sending the order</p>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
                                                <div style="margin-right: 20px;">
                                                    <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
                                                </div>
                                                <div className="content-wrap ml-4">
                                                <p style="font-style: italic;font-size: 17px;">Filter by <strong>"Order List"</strong> and check the <strong>Used Points</strong> to find your cost triggers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `,
                        showCloseButton: true,
                    });
                } else if (minimumFixed && isDisabled) {
                    if (isSaveAndClose) {
                        this.setState({
                            isSaveAndClose: false
                        });
                    }
                    Swal.fire({
                        className: "warning-new-model",
                        customClass: {
                            container: 'container-class',
                        },
                        html: `
                            <div id="warningModel">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div style="display: flex; justify-content: space-between;align-items: center;">
                                                <div style="margin-right: 20px;">
                                                    <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
                                                </div>
                                                <div className="content-wrap ml-4">
                                                    <h2>We ensure your sufficient diet!</h2>
                                                    <p>Please increase the quantity of all the ITEM GROUPS to minimum ${minimumFixed ? sufficientProvisionsMinimum + "%." : ""}</p>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
                                                <div style="margin-right: 20px;">
                                                    <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
                                                </div>
                                                <div className="content-wrap ml-4">
                                                    <p style="font-style: italic;font-size: 17px;">Click <strong>"View Order Details"</strong> to find the category that needs to be fulfilled.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `,
                        showCloseButton: true,
                    });
                } else {
                    // let {
                    //     rateLimitFixed,
                    //     rateTolerance,
                    //     maxQuantityLimitFixed,
                    //     maxQuantityTolerance,
                    //     categoyLimitFixed,
                    //     maxCategoryTolerance
                    // } = this.props.vesselDetail;

                    const isInventory = this.props.currentOrder.orderKind === InventoryConstant;
                    const isAdditional = this.props.currentOrder.orderKind?.toString()?.toLowerCase === "additional";

                    let data = {};
                    let orderDetail = [];
                    let itemCodeList = [];
                    let itemPriceAvailable = 0;
                    let itemPriceReq = 0;
                    let pointsUsed = 0;
                    let totalBudget = 0;
                    let totalPricePerBaseUnit = 0;
                    let totalPrice = 0;
                    // let currentCategory = [];
                    // let isQtyWrong = false;
                    let keys = Object.keys(this.state.orderDetail);

                    const countryName = this.props.currentOrder?.country?.name;

                    for (let index = 0; index < keys.length; index++) {
                        const element = keys[index];
                        // let numOfDays = 0;
                        // if (this.state.orderDetail[element].category === "DAIRY PRODUCTS" || this.state.orderDetail[element].category === "FRUITS & VEGETABLES") {
                        //     numOfDays = Sum(this.props.currentOrder.orderPeriod.freshProvisions.regular, this.props.currentOrder.orderPeriod.freshProvisions.expectedDelay);
                        // } else {
                        //     numOfDays = Sum(this.props.currentOrder.orderPeriod.dryFrozen.regular, this.props.currentOrder.orderPeriod.dryFrozen.expectedDelay);
                        // }

                        // let currentCatIndex = currentCategory.findIndex(x => x === this.state.orderDetail[element].category);

                        // if (currentCatIndex === -1) {
                        //     let calculateOrderedProduct = this.calculateOrderedProduct(this.state.orderDetail[element].category, this.state.orderDetail);
                        //     let catIndex = this.state.vesselCategory.findIndex(x => x.category === this.state.orderDetail[element].category);
                        //     let needed = 0;
                        //     if (catIndex !== -1) {
                        //         needed = parseFloat(this.state.vesselCategory[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * numOfDays;
                        //     } else {
                        //         catIndex = this.state.vesselCategory.findIndex(x => x.category === "MEAT");
                        //         if (catIndex !== -1) {
                        //             needed = parseFloat(this.state.vesselCategory[catIndex].qtyPerDay) / 1000 * this.state.numOfCrew * numOfDays;
                        //         }
                        //     }
                        //     currentCategory.push(this.state.orderDetail[element].category);
                        //     let catAllowance = needed + ((needed / 100) * maxCategoryTolerance);
                        //     if ((!categoyLimitFixed && needed < calculateOrderedProduct) || (categoyLimitFixed && catAllowance <= calculateOrderedProduct)) {
                        //         isQtyWrong = true;
                        //     }
                        // }

                        if (this.state.orderDetail[element].pricePerBaseUnit > 0) {
                            itemPriceAvailable += 1;
                        } else {
                            itemPriceReq += 1;
                        }

                        if (this.state.orderDetail[element].usedPoints !== 0) {
                            pointsUsed += this.state.orderDetail[element].usedPoints;
                        }

                        if (this.state.orderDetail[element].budgetPoints !== 0) {
                            totalBudget += parseFloat(this.state.orderDetail[element].budgetPoints);
                        }

                        if (this.state.orderDetail[element].pricePerBaseUnit !== 0) {
                            totalPricePerBaseUnit += this.state.orderDetail[element].pricePerBaseUnit;
                        }

                        let finalPrice = 0.0;
                        const record = this.state.orderDetail[element];
                        const { pricePerCountry, order, priceAvg } = record;
                        const pricesExist = Array.isArray(pricePerCountry) && pricePerCountry.length !== 0;
                        if (pricesExist) {
                            const countryWisePrice = pricePerCountry.find(({ name }) => name === countryName);
                            finalPrice = countryWisePrice?.price || priceAvg;
                        } else {
                            finalPrice = priceAvg;
                        }
                        totalPrice += (finalPrice * order);

                        itemCodeList.push(element);
                        orderDetail.push({ ...this.state.orderDetail[element] });

                        // if ((isInvenotry && this.state.orderDetail[element].inventory !== "") || (this.state.orderDetail[element].inventory !== "" && this.state.orderDetail[element].order !== "")) {
                        //     if (this.state.orderDetail[element].category === category && this.state.orderDetail[element].order !== '') {
                        //     	sum += parseFloat(orderDetail[element].order) * orderDetail[element].orKg
                        //     }
                        //     itemCodeList.push(element);
                        // }
                    }

                    const allInventoryAndOrdersAreFilled = orderDetail.every(({ inventory, order }) => !!inventory && !!order);

                    if (!allInventoryAndOrdersAreFilled) {
                        const itemCodes = [...orderDetail]
                            .filter(({ inventory, order }) => (!!inventory && !order) || (!inventory && !!order))
                            .map(({ description }) => description)
                            .join("<br/>");
                        /*
                        Ref: https://stackoverflow.com/questions/67327505/change-icon-sweetalert-with-image
                        Author: Limon Monte
                        SVG Icon Taken from: https://iconbuddy.app
                        */
                        Swal.fire({
                            title: "Dear Captain,",
                            html: `Please fill in the order cell for all fields with an inventory: <br/> ${itemCodes}`,
                            iconHtml: `
                                <svg width="100" height="80" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#FFD983" d="M29 11.06c0 6.439-5 7.439-5 13.44c0 3.098-3.123 3.359-5.5 3.359c-2.053 0-6.586-.779-6.586-3.361C11.914 18.5 7 17.5 7 11.06C7 5.029 12.285.14 18.083.14C23.883.14 29 5.029 29 11.06z"/>
                                    <path fill="#CCD6DD" d="M22.167 32.5c0 .828-2.234 2.5-4.167 2.5c-1.933 0-4.167-1.672-4.167-2.5c0-.828 2.233-.5 4.167-.5c1.933 0 4.167-.328 4.167.5z"/>
                                    <path fill="#FFCC4D" d="M22.707 10.293a.999.999 0 0 0-1.414 0L18 13.586l-3.293-3.293a.999.999 0 1 0-1.414 1.414L17 15.414V26a1 1 0 1 0 2 0V15.414l3.707-3.707a.999.999 0 0 0 0-1.414z"/>
                                    <path fill="#99AAB5" d="M24 31a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-6h12v6z"/>
                                    <path fill="#CCD6DD" d="M11.999 32a1 1 0 0 1-.163-1.986l12-2a.994.994 0 0 1 1.15.822a.999.999 0 0 1-.822 1.15l-12 2a.927.927 0 0 1-.165.014zm0-4a1 1 0 0 1-.163-1.986l12-2a.995.995 0 0 1 1.15.822a.999.999 0 0 1-.822 1.15l-12 2a.927.927 0 0 1-.165.014z"/>
                                </svg>
                            `,
                            customClass: {
                                icon: 'no-border'
                            }
                        });
                        return;
                    }

                    data.orderId = this.props.match.params.orderId;
                    data.itemCodeList = itemCodeList;
                    data.orderDetail = orderDetail;
                    data.orderItems = orderDetail.length;
                    data.itemPriceReq = itemPriceReq;
                    data.itemPriceAvailable = itemPriceAvailable;
                    data.totalBudget = totalBudget;
                    data.pointsUsed = pointsUsed;
                    data.orderNeeded = this.state.orderNeeded;
                    data.orderRequested = this.state.totalOrder;
                    data.captain = this.state.captainId;
                    data.totalPricePerBaseUnit = totalPricePerBaseUnit;
                    data.totalPrice = totalPrice;
                    data.redirectionPath = "/provision-catalogue/";

                    /*
                    when user modifies the order, isAddedToBasket would be false. That means,
                    captain will have to again edit the order and add the order to basket.
                    This is done purposefully to prevent the user to manipulate order and still proceed to supplier comparison.
                    */
                    if (isSaveAndClose) {
                        data.isAddedToBasket = false;
                    } else if (!!buttonText) {
                        data.isAddedToBasket = true;
                    }

                    if (orderDetail.length > 0) {
                        let errorExists = false;

                        if (!!buttonText) {
                            const errorCategMapping = {};
                            const vals = Object.values(this.state.orderDetail);
                            const categories = [...new Set(vals.map(({ category }) => category))];
                            categories.forEach((category) => {
                                const ret = this.validateOrderDetails(category);
                                errorCategMapping[category] = ret;
                            });

                            errorExists = Object.values(errorCategMapping).some(({ isError }) => isError);
                        }

                        const { isError, userHasBeenWarned } = this.state;

                        if (!isAdditional && (isError || errorExists)) {
                            if (isSaveAndClose) {
                                this.setState({
                                    isSaveAndClose: false,
                                });
                            } else {
                                // User is adding order to basket
                                Swal.fire({
                                    className: "warning-new-model",
                                    customClass: {
                                        container: "container-class",
                                    },
                                    html:
                                        `<div id="warningModel">
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <div style="display: flex; justify-content: space-between;align-items: center;">
                                                            <div style="margin-right: 20px;">
                                                                <img src="${emoji1}" alt="Smiley face" height="60" width="60"/>
                                                            </div>
                                                            <div className="content-wrap ml-4">
                                                                <h2>Item group quantity exceeded</h2>
                                                                <p>Please adjust your total quantity before sending the order</p>
                                                            </div>
                                                        </div>
                                                        <div style="display: flex; justify-content: space-between;margin: 50px 0px 0 30px;">
                                                            <div style="margin-right: 20px;">
                                                                <img src="${emoji2}" alt="Smiley face" height="60" width="60"/>
                                                            </div>
                                                            <div className="content-wrap ml-4">
                                                                <p style="font-style: italic;font-size: 17px;">Click <strong>"View Order Details"</strong> or filter by <strong>"Ordered List"</strong> to find the items with the highest quantity</p>          
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    `,
                                    showCloseButton: true,
                                });
                            }
                        } else {
                            this.props.updateOrder(data);
                        }
                    } else {
                        if (isSaveAndClose) {
                            this.setState({
                                isSaveAndClose: false
                            });
                        }
                        Swal.fire("Error", `Please select some items before sending ${isInventory ? "inventory" : "order"}.`, "error");
                    }
                }
            } else {
                Swal.fire("Error", `Vessel not found`, "error");
            }
        }
    };
    calculateOrderedProduct = (category, orderDetail) => {
        let sum = 0;
        let keys = Object.keys(orderDetail);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            const rec = orderDetail[element];
            const { inventory, order, orKg } = rec;
            if (rec.category === category) {
                if (!!order) {
                    sum += parseFloat(order) * orKg;
                }
                if (!!inventory) {
                    sum += parseFloat(inventory) * orKg;
                }
            }
        }
        return sum;
    };
    showItemDetailModel = (selectedItem) => {
        this.setState({
            selectedItem
        }, () => $("#NutritaionModal").modal('show'));
    };
    disableScroll = () => {
        $('.ordCatg-InnTable').on('focus', 'input[type=number]', function (e) {
            $(this).on('wheel.disableScroll', function (e) {
                e.preventDefault();
            });
        });
    };
    goBack = () => {
        let url = getDashboardLink();
        if (this.props.currentOrder && this.props.currentOrder.vessel) {
            url = '/create-provision-order/' + this.props.currentOrder.vessel + "?orderId=" + this.props.match.params.orderId;
        }
        let data = { ...this.props.currentOrder };
        data.orderDetail = this.state.orderDetail;
        if (data.orderKind !== InventoryConstant) {
            data.country.label = data.country.name;
        }
        this.props.AddOrderAction(data);
        this.props.history.push(url);
    };
    closeOrder = () => {
        this.props.AddOrderAction({});
        this.props.history.push(getDashboardLink());
    };
    saveAndClose = (e) => {
        this.setState({
            isSaveAndClose: true,
        }, () => this.updateOrder());
    };
    downloadCatalogue = () => {
        window.open(Constant.mediaUrl + "Catalogue/inventory.xlsx");
    };
    getNavbarOptions = (point) => {
        let arr = [
            {
                img: 'left-arrow.svg',
                className: "orange",
                name: "Go back",
                path: '/create-provision-order/',
                isLogout: false,
                isCustom: true,
                onClick: this.goBack
            },

        ];
        if (this.props.currentOrder.orderKind !== InventoryConstant && this.props.subType !== 'subAdmin') {
            arr.push({
                img: 'Monitor-icon.svg',
                className: "dark-blue",
                name: "Save<br />Order",
                path: '',
                isLogout: false,
                isCustom: true,
                onClick: this.saveAndClose
            });
        }
        arr.push({
            img: 'Message-icon.svg',
            className: `green ${this.props.apiCallStatus.isStarted.indexOf("updateOrder") !== -1 ? "disabled" : ""}`,
            name: `Add ${this.props.currentOrder && this.props.currentOrder.orderKind === InventoryConstant ? "" : "to Order Basket"}`,
            path: "/checkout",
            isLogout: false,
            isCustom: true,
            // disabled: point < 30,
            onClick: this.updateOrder
        });
        return arr;
    };
    handleOrderedList = (showOrderedList) => {
        let filterCatalouge = [];
        let totalCount = 0;
        if (showOrderedList) {
            filterCatalouge = Object.values(this.state.orderDetail);
            totalCount = filterCatalouge.length;
        } else {
            filterCatalouge = this.props.orderReducer.provisionList;
            totalCount = this.props.orderReducer.totalProvision;
        }
        this.setState({
            filterCatalouge,
            totalCount,
            showOrderedList
        });
    };

    render() {
        const totalUsedPoints = calculateTotalUsedPoint(this.state.orderDetail, this.state.numOfCrew, this.state.numOfDays);
        let isInvenotry = this.props.currentOrder && this.props.currentOrder.orderKind === InventoryConstant;
        const totalRemainingPoints = (10000 - totalUsedPoints.total).toFixed(2);
        const usedRemainingPoints = ((10000 - (10000 - totalUsedPoints.total))).toFixed(2);
        const point = (totalRemainingPoints / 10000) * 100;
        return (
            <Wrapper>
                <div className="pagebox pageWrapper">
                    <div className="pageContainer mb-0">
                        {/* <DemoHeader /> */}
                        <div className="order-dlts-wrapper">
                            <DashboardNavbar
                                title={`${this.props.currentOrder && this.props.currentOrder.orderKind ? isInvenotry ? "INVENTORY DETAILS" : this.props.currentOrder.orderKind === "Additional" ? "ADDITIONAL ORDER DETAILS" : "PROVISION ORDER DETAILS" : ''}`}
                                navbarOptions={this.getNavbarOptions(point)} isShowLogo={true} logoUrl="logo.png"
                                logoType="Order"
                                className="pageHeader d-flex align-items-center flex-column flex-lg-row" />
                        </div>
                        {/* Order Overview */}
                        {this.props.currentOrder && this.props.currentOrder.orderKind !== InventoryConstant ?
                            <OrderOverView
                                orderPeriod={this.props.currentOrder.orderPeriod}
                                provisionOrderType={this.props.currentOrder.orderKind}
                                category={this.state.vesselCategory} numOfCrew={this.state.numOfCrew}
                                numOfDays={this.state.numOfDays} orderDetail={this.state.orderDetail}
                                orderNeeded={this.state.orderNeeded} totalOrder={this.state.totalOrder}
                                totalUsedPoints={totalUsedPoints}
                                totalRemainingPoints={totalRemainingPoints}
                                usedRemainingPoints={usedRemainingPoints}
                                point={point}
                                nextOrderQtyMapping={this.state.nextOrderQtyMapping}
                                totalOrderPoints={this.state.totalOrderPoints} />
                            : ""}
                        {/* Provision Catalogue Table */}
                        <div className="ordCatg-table-wrap mt-1">
                            <Filters {...this.state.filters} downloadCatalogue={this.downloadCatalogue}
                                orderKind={this.props.currentOrder.orderKind}
                                handleOrderedList={this.handleOrderedList}
                                showOrderedList={this.state.showOrderedList} isInvenotry={isInvenotry}
                                fetchMoreItemCode={this.fetchMoreItemCode}
                                fetchMoreDescription={this.fetchMoreDescription}
                                totalItemCode={this.state.totalItemCode}
                                totalDescription={this.state.totalDescription}
                                itemGroupList={this.state.itemGroupList} itemNoList={this.state.itemNoList}
                                descriptionList={this.state.descriptionList} handleChange={this.handleFilterChange}
                                clickCustomeSelectHandler={this.clickCustomeSelectHandler}
                                clearFilters={this.clearFilters} applyFilters={this.applyFilters}
                                getFilterDetail={this.props.getFilterDetail} />
                            {/* Table */}
                            <CatalougeTable isInvenotry={isInvenotry} data={this.state.filterCatalouge}
                                fetchMoreData={this.fetchMoreData} totalCount={this.state.totalCount}
                                handleTableChange={this.handleTableChange}
                                orderDetail={this.state.orderDetail}
                                totalOrderBudget={this.state.totalOrderBudget}
                                totalOrderPoints={this.state.totalOrderPoints}
                                apiCallStatus={this.props.apiCallStatus}
                                country={this.props.currentOrder && this.props.currentOrder.country ? this.props.currentOrder.country.name : ''}
                                showItemDetailModel={this.showItemDetailModel} />
                        </div>
                    </div>
                    {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
                    {/* Order Details Modal */}
                    {/* <OrderDetailModal category={this.state.vesselCategory} totalUsedPoints={totalUsedPoints} numOfDays={this.state.numOfDays} numOfCrew={this.state.numOfCrew} orderDetail={this.state.orderDetail} /> */}
                    <NutritionDetail selectedItem={this.state.selectedItem} />
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    vesselDetail: state.commonReducer.vesselById,
    orderReducer: state.orderReducer,
    currentOrder: state.orderReducer.currentOrder,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType,
    isSubAdmin: state.authReducer.isSubAdmin,
    subType: state.authReducer.subType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    provisionListing: (offset, limit, filterQuery) => dispatch(provisionListing(offset, limit, filterQuery)),
    getItemCode: (offset, limit, query) => dispatch(getItemCode(offset, limit, query)),
    getDescription: (offset, limit, query) => dispatch(getDescription(offset, limit, query)),
    getItemGroup: (query) => dispatch(getItemGroup(query)),
    getFilterDetail: (offset, filterQuery) => dispatch(getFilterDetail(offset, filterQuery)),
    updateOrder: (data) => dispatch(updateOrder(data)),
    AddOrderAction: (data) => dispatch(AddOrderAction(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    getOrderDetail: (orderId) => dispatch(getOrderDetail(orderId)),
    getVesselById: (id) => dispatch(getVesselById(id)),
    getNextOrderQty: (vesselId, captainId) => dispatch(getNextOrderQty(vesselId, captainId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProvisionCatalogue);
