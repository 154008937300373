// var env = process.env.REACT_APP_ENV;//'production'

// export const env = "local";
// export const env = "demo";
export const env = "spot";
// export const env = "wilhelmsen";
// export const env = "vroon";
// export const env = "development";
// export const env = "production";

const LOCAL_CONSTANTS = {
  apiURl: "http://localhost:4000/v1",
  mediaUrl: "http://localhost:3000/media/",
  socketUrl: "http://localhost:5000",
};

const DEMO_CONSTANTS = {
  apiURl: "https://mesh-demo.online/v1",
  mediaUrl: "https://mesh-demo.online/media/",
  socketUrl: "https://mesh-demo.online",
};

const SPOT_CONSTANTS = {
  apiURl: "https://mesh-order.com/v1",
  mediaUrl: "https://mesh-order.com/media/",
  socketUrl: "https://mesh-order.com",
};

// const WILHELMSEN_CONSTANTS = {
//   apiURl: "https://wilhelmsen-order.com/v1",
//   mediaUrl: "https://wilhelmsen-order.com/media/",
//   socketUrl: "https://wilhelmsen-order.com",
// };

const WILHELMSEN_CONSTANTS = {
  apiURl: "https://mesh-wsm-order.com/v1",
  mediaUrl: "https://mesh-wsm-order.com/media/",
  socketUrl: "https://mesh-wsm-order.com",
};

const VROON_CONSTANTS = {
  apiURl: "https://vroon-order.com/v1",
  mediaUrl: "https://vroon-order.com/media/",
  socketUrl: "https://vroon-order.com",
};

const DEV_CONSTANTS = {
  apiURl: "http://188.34.159.29:4000/v1",
  mediaUrl: "http://188.34.159.29:4000/media/",
  socketUrl: "http://188.34.159.29:4000",
};

const PROD_CONSTANTS = {
  apiURl: "https://mesh-online.app/v1",
  mediaUrl: "https://mesh-online.app/media/",
  socketUrl: "https://mesh-online.app",
};

export const ConstantMapping = {
  local: LOCAL_CONSTANTS,
  demo: DEMO_CONSTANTS,
  spot: SPOT_CONSTANTS,
  wilhelmsen: WILHELMSEN_CONSTANTS,
  vroon: VROON_CONSTANTS,
  development: DEV_CONSTANTS,
  production: PROD_CONSTANTS,
};

export const Constant = ConstantMapping[env];

export const reminderNameList = [
  {
    label: "First Order in order basket",
    value: "First Order in order basket",
  },
  {
    label: "Order basket complete",
    value: "Order basket complete",
  },
  {
    label: "Quotation by suppliers required",
    value: "Quotation by suppliers required",
  },
  {
    label: "Captain sends order / Supplier receives order",
    value: "Captain sends order / Supplier receives order",
  },
  {
    label: "Upcoming delivery",
    value: "Upcoming delivery",
  },
  {
    label: "Order delivery",
    value: "Order delivery",
  },
];
export const inventory = "Inventory";
