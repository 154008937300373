import React, { useEffect, useState } from 'react';
import { getDepartmentDetail, updateDepartment } from "./../ApiCalls/department";
import { ApiClearAction } from "../../ApiCallStatus/Actions/action";
import { connect } from 'react-redux';
import { DepartmentForm } from './departmentForm';
import { isAllowPermission } from '../../../Services/common';
import Swal from 'sweetalert2'
import { DashboardWrapper } from '../../Dashboard/Components/dashboarWrapper';
const departmentInitialState = {
    name: '',
    // budgetPrice: '',
}
function EditDepartment(props) {
    const [state, setState] = useState(departmentInitialState);
    const [isHitOnLoadApi, setIsHitOnLoadApi] = useState(false);
    useEffect(() => {
        if (!isHitOnLoadApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed')
            } else {
                props.getDepartmentDetail(props.match.params.id)
                setIsHitOnLoadApi(true)
            }
        }
        if ((props.apiCallStatus.apiCallFor === "getDepartmentDetail") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(props.departmentReducer.detail)
            props.ApiClearAction();
        }
        if ((props.apiCallStatus.apiCallFor === "updateDepartment") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            setState(departmentInitialState)
            props.ApiClearAction();
            Swal.fire("Success", `The department has been updated successfully.`, "success")
            props.history.push('/department-listing')

        }
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let departmentDetail = { ...state }
        departmentDetail[name] = value;
        setState(departmentDetail)
    }
    const updateHandler = () => {
        let obj = {
            name: state.name,
            // budgetPrice: state.budgetPrice,
            departmentId: props.match.params.id
        }
        props.updateDepartment(obj)
    }
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "go back",
            path: "/department-listing",
            isLogout: false,
        }
    ];
    return (
        <DashboardWrapper title="Edit Department" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end">
            <div className="formBox">
                <DepartmentForm {...state} history={props.history} handleFormChange={handleFormChange} handleSubmit={updateHandler} apiCallStatus={props.apiCallStatus} isEdit={true} apiName="updateDepartment" />
            </div>
        </DashboardWrapper>
    )
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    roles: state.authReducer.roles,
    departmentReducer: state.departmentReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateDepartment: (data) => dispatch(updateDepartment(data)),
    getDepartmentDetail: (id) => dispatch(getDepartmentDetail(id)),
    ApiClearAction: () => dispatch(ApiClearAction())
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDepartment);
