import * as Action from "../Actions/action";
import InitialState from "../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function DashboardReducer(
  state = InitialState.dashboard,
  action
) {
  switch (action.type) {
    case Action.getOrder:
      let s = cloneDeep(state);
      s.orderListing = action.payload.list;
      s.totalOrder = action.payload.count;
      return s;
    case Action.AppendOrderList:
      var s1 = cloneDeep(state);
      var arr = s1.orderListing.slice();
      s1.orderListing = arr.concat(action.payload.list);
      return s1;
    case Action.getFleet:
      let s2 = cloneDeep(state);
      s2.fleetListing = action.payload.list;
      s2.totalFleet = action.payload.count;
      return s2;
    case Action.AppendFleetList:
      var s3 = cloneDeep(state);
      var arr = s3.fleetListing.slice();
      s3.fleetListing = arr.concat(action.payload.list);
      return s3;
      case Action.getCart:
        let s4 = cloneDeep(state);
        s4.cartListing = action.payload.list;
        s4.totalCart = action.payload.count;
        return s4;
      case Action.AppendCartList:
        var s5 = cloneDeep(state);
        var arr = s5.cartListing.slice();
        s5.cartListing = arr.concat(action.payload.list);
        return s5;
        case Action.getCartCsv:
        let s6 = cloneDeep(state);
        s6.cartCsvListing = action.payload.list;
        return s6;
    default:
      return state;
  }
}
