import styled from "styled-components";

export const Wrapper = styled.div`
.dropdown{position:relative;display:block}
.dropdown button.filter-button{background:0 0;border:none;outline:0;font-size:15px;color:#000;font-weight:600;text-align:left;padding:0;position:relative}
.dropdown-menu.filter-drop{overflow:auto;width:100%;margin-top:0;padding:5px;height:auto;margin-top:10px!important;background:#250a72;color:#fff;font-size:12px;top:100% !important;left:0 !important;transform:translate3d(0, 0, 0) !important;}
// . fixscrolfilter .dropdown-menu.filter-drop{overflow:auto;width:100%;margin-top:10px;padding:5px;height:unset !important;margin-top:10px!important;background:#250a72;color:#fff;font-size:12px}
.dropdown-menu.filter-drop{overflow:auto;width:100%;margin-top:10px;padding:5px;margin-top:10px!important;background:#250a72;color:#fff;font-size:12px}
// .dropdown-menu.show-left{left:0;right:auto}
.open>.dropdown-menu{-webkit-transition:all .4s ease-in-out;-o-transition:all .4s ease-in-out;transition:all .4s ease-in-out;opacity:1;-webkit-transform:translateY(0);-ms-transform:translateY(0);transform:translateY(0);visibility:visible}
.dropdown.open .dropdown-menu{display:block}
.input-tag.custom-select{position:relative}
.input-tag{display:block;margin:10px 0 5px}
.custom-select{position:relative;cursor:pointer}
.starting-page-box{display:block;max-height:100%;overflow-y:auto;overflow-x:auto !important;padding:0}
// .starting-page-box{display:block;max-height:100%;overflow-y:auto;overflow-x:hidden;padding:0;height:250px;}
.starting-page-box{display:block;overflow-y:auto;overflow-x:auto !important;padding:0;height:250px !important;}
.starting-page-box .filter-list-items li{padding:6px 12px}
// .starting-page-box .filter-list-items li{margin-bottom: 5px 12px !important;}
.starting-page-box .filter-list-items li:hover{background-color:#F41D37}
.pagin-select-menu .dropdown-toggle{font-size:12px;color:#6F7276;padding:4px 15px;font-weight:600;text-align:left;position:relative;outline:none!important;-webkit-box-shadow:none!important;box-shadow:none!important}
.starting-page-box ul{margin:0;padding:0}
.starting-page-box ul.loadmore{min-height:100%;max-height:100%;height:100%}
.dropdown-toggle::after{display:inline-block;content:"";border:5px solid transparent;border-top-color:#6F7276;position:absolute;top:11px;right:10px;border-bottom-width:0}
.filter-search-wrap input{width:100%;border-radius:3px;border:1px solid #FF9800;-webkit-box-shadow:none!important;box-shadow:none!important;outline:none!important;margin-bottom:20px;padding:4px 10px;background:#fff;font-size:12px;font-weight:500;color:#6F7276}
.pagin-select-menu .loadmore{text-align:center}
.pagin-select-menu .loadmore img{width:50px;margin:0 auto}
`