import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { currencyFormatter } from "../../Services/common";

function SingleColumnChart({ data }) {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: " ",
        data: data,
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default SingleColumnChart;
