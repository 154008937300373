import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { DashboardWrapper } from "./dashboarWrapper";
import { connect } from "react-redux";
// import { Constant } from "../../../Constants/constant";
import DatePicker from "react-datepicker";
import { quotationListing } from "../../Quotations/Apicalls/quotations";
import { cartListing, orderListing } from "../ApiCalls/orderListing";
import { topItemsListing, provisionListing } from "./../../Orders/ApiCalls/provisionOrder";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { topSupplyHub } from "./../../Common/ApiCalls/topSupplyHub";
import { topSupplier } from "./../../Common/ApiCalls/topSupplier";
import { getVesselById } from "./../../Common/ApiCalls/vesselList";
import { vesselListing } from "./../../Vessel/ApiCalls/vessel";
import { getDescription, getItemCode, getItemGroup } from '../../Orders/ApiCalls/searchProvisionOrder';
import { capitalizeFirstLetter, currencyFormatter } from "../../../Services/common";
import { getAllPort } from "../../Common/ApiCalls/portList";
import { getAllCountry } from "../../Common/ApiCalls/countryList";
import { SupplierComparisonListing } from "../../Supplier/ApiCalls/supplier";
import { basketOrders } from "../../OrderBasket/ApiCalls/index";

import "react-datepicker/dist/react-datepicker.css";
import "./dashboard-style.css";

function ContractVessel(props) {
  const [state, setState] = useState({
    orderList: [],
    topItemsList: [],
    topSupplierList: [],
    topSupplyHubList: [],
    qoutationList: [],
    vesselList: [],
    activePage: 0,
    totalOrderCount: 0,
    totalTopItems: 0,
    vesselName: "",
    isCartList: false,
    vesselPic: "",
    vesselId: "",
    customerVessels: [],
    vesselStatus: false,
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    filterQuery: "",
    isFilter: false,
    isTopPort: false,
    isTopItem: false,
    isTopSupplier: false,
    isHitApi: false,
  });
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    let currentVesselId = query.get("vesselId");
    if (
      props.userDetail.isSubAdmin &&
      props.userDetail.userType === "user" &&
      props.userDetail.subType !== "subAdmin" &&
      props.userDetail.activeVesselId
    ) {
      currentVesselId = props.userDetail.activeVesselId;
    }
    if (
      currentVesselId !== null &&
      state.vesselId !== currentVesselId &&
      props.apiCallStatus.isStarted.indexOf("getVesselById") === -1 &&
      props.apiCallStatus.apiCallFor !== "getVesselById"
    ) {
      props.getVesselById(currentVesselId);
    }
    if (!state.isHitApi) {
      const isShowCustomerOrders = props.userDetail.userType === "customer";
      props.basketOrders(props.userDetail.userId, isShowCustomerOrders);
      props.getAllCountry();
      props.getAllPort();
      let data = { ...state };
      if (currentVesselId === null && !data.isFilter) {
        props.vesselListing(0, 50);
      }
      data.isHitApi = true;
      let from = moment(data.startDate).format("YYYY-MM-DD");
      let to = moment(data.endDate).add(1, "days").format("YYYY-MM-DD");
      let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}`;
      let userType =
        props.userDetail.userType === "supplier"
          ? "supplier"
          : props.userDetail.subType === "captain"
            ? "captain"
            : props.userDetail.subType === "customer"
              ? "customer"
              : "";
      if (userType === "supplier") {
        cartQuery += `&supplierId=${props.userDetail.userId}`;
        props.cartListing(0, cartQuery);
      } else if (userType === "captain") {
        cartQuery += `&captainId=${props.userDetail.userId}`;
        props.cartListing(0, cartQuery);
      } else if (
        props.userDetail.userType === "user" &&
        (props.userDetail.subType === "user" ||
          props.userDetail.subType === "subAdmin")
      ) {
        props.cartListing(0, cartQuery);
      }
      if (props.userDetail.subType !== "customer") {
        props.topSupplyHub(props.userDetail.userId, userType, from, to);
        props.topItemsListing(props.userDetail.userId, userType, from, to);
        props.topSupplier(props.userDetail.userId, userType, from, to);
        props.quotationListing(0, 4, "rfq", true);
        props.quotationListing(0, 4, "order", true);

        // For Provision orders
        props.provisionListing(0, 0, "?showAdminCatalogs=true&systemType=p");
        props.getItemCode(0, 0, "?showAdminCatalogs=true&availability=p");
        props.getDescription(0, 0, "?showAdminCatalogs=true&availability=p");
        props.getItemGroup("?showAdminCatalogs=true&availability=p");

        // For Provision additional orders
        props.provisionListing(0, 0, "?showAdminCatalogs=true&systemType=provisionAdditional");
        props.getItemCode(0, 0, "?showAdminCatalogs=true&availability=provisionAdditional");
        props.getDescription(0, 0, "?showAdminCatalogs=true&availability=provisionAdditional");
        props.getItemGroup("?showAdminCatalogs=true&availability=provisionAdditional");

        // For Store orders
        props.provisionListing(0, 0, "?showAdminCatalogs=true&systemType=s");
        props.getItemCode(0, 0, "?showAdminCatalogs=true&availability=s");
        props.getDescription(0, 0, "?showAdminCatalogs=true&availability=s");
        props.getItemGroup("?showAdminCatalogs=true&availability=s");

        // For Store additional orders
        props.provisionListing(0, 0, "?showAdminCatalogs=true&systemType=storeAdditional");
        props.getItemCode(0, 0, "?showAdminCatalogs=true&availability=storeAdditional");
        props.getDescription(0, 0, "?showAdminCatalogs=true&availability=storeAdditional");
        props.getItemGroup("?showAdminCatalogs=true&availability=storeAdditional");

        props.getAllPort();
      }
      setState(data);
    }

    if (
      props.apiCallStatus.apiCallFor === "basketOrders" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      const {
        provisionBasketId, provisionAdditionalBasketId, storeBasketId, storeAdditionalBasketId
      } = props.orderBasketReducer;

      if (provisionBasketId) {
        props.supplierComparisonListing({ isProvision: true, basketId: provisionBasketId }, "");
      }
      if (storeBasketId) {
        props.supplierComparisonListing({ isStore: true, basketId: storeBasketId }, "");
      }
      if (provisionAdditionalBasketId) {
        props.supplierComparisonListing({ isProvisionAdditional: true, basketId: provisionAdditionalBasketId }, "");
      }
      if (storeAdditionalBasketId) {
        props.supplierComparisonListing({ isStoreAdditional: true, basketId: storeAdditionalBasketId }, "");
      }
    }

    if (
      props.apiCallStatus.apiCallFor === "getCart" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        orderList: props.dashboardReducer.cartListing,
        totalOrderCount: props.dashboardReducer.totalCart,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getTopItems" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topItemsList: props.orderReducer.topItemsList,
        totalTopItems: props.orderReducer.totalTopItems,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topSupplyHub" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topSupplyHubList: props.commonReducer.topSupplyHubList,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "topSupplier" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        topSupplierList: props.commonReducer.topSupplierList,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      currentVesselId === null &&
      (props.apiCallStatus.apiCallFor === "vesselListing" ||
        props.apiCallStatus.apiCallFor === "appendVesselList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let customerVessels = [];
      if (
        props.userDetail.subType === "customer" &&
        props.vesselList.length > 0
      ) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        let from = moment(state.startDate).format("YYYY-MM-DD");
        let to = moment(state.endDate).add(1, "days").format("YYYY-MM-DD");
        let newArray = JSON.stringify(customerVessels);
        let cartQuery = `?from=${from}&to=${to}&offset=${0}&limit=${5}&vesselId=${newArray}`;
        props.cartListing(0, cartQuery);
        props.topSupplyHub(customerVessels, "customer", from, to);
        props.topSupplier(customerVessels, "customer", from, to);
        props.topItemsListing(customerVessels, "customer", from, to);
        props.quotationListing(0, 4, "rfq", true);
      }
      let index = props.vesselList.findIndex((x) => x.active === true);
      if (index === -1 && props.vesselList.length > 0) {
        index = 0;
      }
      setState({
        ...state,
        vesselName:
          props.vesselList.length > 0 ? props.vesselList[index].name : "",
        vesselPic:
          props.vesselList.length > 0 ? props.vesselList[index].pic : "",
        vesselId:
          props.vesselList.length > 0 ? props.vesselList[index]._id : "",
        vesselStatus:
          props.vesselList.length > 0 ? props.vesselList[index].active : false,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getQuotation" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        qoutationList: props.quotationList.slice(0, 5),
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getVesselById" &&
      props.apiCallStatus.isCompleted === true &&
      !props.apiCallStatus.isFailed
    ) {
      if (
        state.vesselId !== props.commonReducer.vesselById._id &&
        Object.keys(props.commonReducer.vesselById).length > 0
      ) {
        setState({
          ...state,
          vesselName: props.commonReducer.vesselById.name,
          vesselPic: props.commonReducer.vesselById.pic,
          vesselId: props.commonReducer.vesselById._id,
          vesselStatus: props.commonReducer.vesselById.active,
          isHitApi: true,
        });
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
      }
    }
  }, [props, state.isHitApi]);
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
      isHitApi: false,
      isFilter: true,
    });
  };
  const navbarOptions = [
    {
      img: "Cancel.svg",
      className: "red",
      name: "Logout",
      path: "/",
      isLogout: true,
    },
    // {
    //     img: 'Shopping-cart.svg',
    //     className: "dark-blue " + (state.vesselId === '' ? 'not-allowed' : ''),
    //     name: "New<br />order",
    //     path: "/create-provision-order/" + state.vesselId,
    //     isLogout: false,
    //     isCustom: state.vesselId === '' ? true : false,
    //     onClick: () => { }
    // },
    // {
    //     img: 'Shopping-cart.svg',
    //     className: "green " + (state.vesselId === '' ? 'not-allowed' : ''),
    //     name: "Provisions<br />order",
    //     path: "/provision-catalogue/" + state.vesselId,
    //     isLogout: false,
    //     isCustom: state.vesselId === '' ? true : false,
    //     onClick: () => { }
    // }
  ];
  const collapsHandle = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };
  return (
    <DashboardWrapper
      title={
        props.userDetail.isSubAdmin &&
          props.userDetail.userType === "user" &&
          props.userDetail.subType !== "sub-admin"
          ? `${props.userDetail.roleName} Dashboard`
          : props.userDetail.userType === "supplier"
            ? "Supplier Dashboard"
            : props.userDetail.userType === "customer"
              ? "Customer Dashboard"
              : "Admin Panel"
      }
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {/* Body Content */}
      {/* <div className="mt-lg-5 d-flex contract-dash-wrap cont-ves-wrap"> */}
      <div className="d-flex contract-dash-wrap cont-ves-wrap">
        {props.userDetail.subType === "customer" ? (
          ""
        ) : (
          <div className="dashNew-ord-wrap d-flex flex-column mr-lg-1 mr-0 mb overWrap-ctrl ">
            {/* M/V Containership */}
            <div className="vessel_dash content-box-wrap dash-content-bx mb-2 d-flex flex-column w-100 customer-logo-wrap ">
              {props.apiCallStatus.isStarted.indexOf("vesselListing") !== -1 ||
                props.apiCallStatus.isStarted.indexOf("getVesselById") !== -1 ? (
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              ) : (
                <>
                  <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
                    <h2 className="font-weight-bold">
                      {state.vesselStatus && state.vesselName !== ""
                        ? state.vesselName
                        : "Create Vessel"}
                    </h2>
                  </div>
                  {/* <div className="mv-contai-img-wrap flex-fill text-center">
                    {state.vesselStatus ? (
                      state.vesselPic !== "" ? (
                        <img
                          className="img-fluid"
                          src={Constant.mediaUrl + state.vesselPic}
                          alt="icon"
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={require("../../../assets/images/no-image-found.png")}
                          alt="icon"
                        />
                      )
                    ) : props.userDetail.userType === "customer" ? (
                      <span className="text-center">
                        <div className="d-flex justify-content-end mt-2">
                          For vessel, please contact admin
                        </div>
                      </span>
                    ) : (
                      <Link
                        to="/create-vessel"
                        type="button"
                        className="btn themeReguler-btn bg-dark-green"
                      >
                        Create New Vessel
                      </Link>
                    )}
                  </div> */}
                </>
              )}
            </div>
            {props.userDetail.userType !== "customer" && (
              <div className="d-flex  ord-vessl-wrap">
                <OrderButtonWrapper {...state} userDetail={props.userDetail} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex-fill dash-ord-content ordContent-table cust-turnover text-left mb-3">
        <OrderStatusWrapper
          apiCallStatus={props.apiCallStatus}
          label="Current Orders"
          link={state.orderList.length === 0 ? "#" : "/orderOverview"}
        >
          {props.apiCallStatus.isStarted.indexOf("getCart") !== -1 ||
            props.apiCallStatus.isStarted.indexOf("getOrder") !== -1 ? (
            <tr>
              <td colSpan="20">
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          ) : props.quotationOrderList &&
            props.quotationOrderList.length === 0 ? (
            <tr>
              <td colSpan="40" className="text-center">
                <div>No Data Found</div>
              </td>
            </tr>
          ) : (
            props.quotationOrderList.map((item, index) => {
              let status =
                item.isSupplied && item.status === "confirmed"
                  ? "Supplied"
                  : item.status &&
                  item.status[0].toUpperCase() + item.status.slice(1);
              return (
                <tr key={"status-" + index}>
                  <td>
                    {item.vesselId && item.vesselId.name
                      ? item.vesselId.name
                      : ""}
                  </td>
                  <td>{item.customerName}</td>
                  <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                  <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                  <td>{item.nextDeliveryPlace}</td>
                  <td>
                    {item.supplierId && item.supplierId.name
                      ? item.supplierId.name
                      : ""}
                  </td>
                  <td>{currencyFormatter(item.rfqValue, item.currency)}</td>
                  <td>{status}</td>
                </tr>
              );
            })
          )}
        </OrderStatusWrapper>
        <OrderStatusWrapper
          apiCallStatus={props.apiCallStatus}
          label="Current RFQ's"
          link={
            state.qoutationList.length === 0 ? "#" : "/inprogress-quotations"
          }
        >
          {props.apiCallStatus.isStarted.indexOf("getQuotation") !== -1 ? (
            <tr>
              <td colSpan="20">
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          ) : state.qoutationList && state.qoutationList.length === 0 ? (
            <tr>
              <td colSpan="40" className="text-center">
                <div>No Data Found</div>
              </td>
            </tr>
          ) : (
            state.qoutationList.map((item, index) => {
              return (
                <tr key={"quotation-" + index}>
                  <td>
                    {item.vesselId && item.vesselId.name
                      ? item.vesselId.name
                      : ""}
                  </td>
                  <td>{item.customerName}</td>
                  <td>{capitalizeFirstLetter(item.orderProvisionType)}</td>
                  <td>{moment(item.nextDeliveryDate).format("DD.MM.YYYY")}</td>
                  <td>{item.nextDeliveryPlace}</td>
                  <td>
                    {item.supplierId && item.supplierId.name
                      ? item.supplierId.name
                      : ""}
                  </td>
                  <td>{currencyFormatter(item.rfqValue, item.currency)}</td>
                  <td>
                    {item.status === "inprogress" ||
                      item.status === "pending" ? (
                      <>
                        <span>Open</span>
                      </>
                    ) : (
                      <>
                        <span>Quoted</span>
                      </>
                    )}
                  </td>
                </tr>
              );
            })
          )}
        </OrderStatusWrapper>
      </div>
      <div className="wrapper-rightside dashVesl-overview-wrap">
        <div className="vessel_dash content-box-wrap dash-content-bx">
          <div className="vessel-overview-wrap d-flex align-items-start">
            <div className="content-box-head d-flex align-items-center justify-content-center head-transparent w-100 mb-1 flex-wrap">
              {/* Date Range Picker */}
              <div className="date-range-picker d-flex align-items-center">
                <span className="d-inline-block mr-1">
                  <DatePicker
                    selected={state.startDate}
                    onChange={(val) => handleChange("startDate", val)}
                    className="link-style"
                  />
                </span>
                to
                <span className="d-inline-block ml-1">
                  <DatePicker
                    selected={state.endDate}
                    onChange={(val) => handleChange("endDate", val)}
                    className="link-style"
                  />
                </span>
              </div>
            </div>
          </div>
          {props.userDetail.subType === "customer" && (
            <CollapsWrapper>
              <div className="table-responsive">
                <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                  <thead>
                    <tr>
                      <th
                        className="small-unit40 "
                        onClick={() =>
                          collapsHandle(
                            "isFleetOverview",
                            !state.isFleetOverview
                          )
                        }
                      >
                        <span className="dropdown-icon d-inline-block cur-poi">
                          <i
                            className={
                              state.isFleetOverview
                                ? "fa fa-angle-down"
                                : "fa fa-angle-right"
                            }
                          ></i>
                        </span>
                      </th>
                      <th>Fleet Overview</th>
                      <th>Vessel Name</th>
                      <th>Type</th>
                      <th>Provision rate</th>
                      <th>Store rate</th>
                      <th>Next Supply Date</th>
                      <th>Delivery place</th>
                    </tr>
                  </thead>
                  <tbody
                    className={`custom-dropdown-menu ${state.isFleetOverview ? "show" : "hide"
                      }`}
                  >
                    {props.apiCallStatus.isStarted.indexOf("vesselListing") !==
                      -1 ? (
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    ) : props.vesselList && props.vesselList.length === 0 ? (
                      <td colSpan="40" className="text-center">
                        <div>No Data Found</div>
                      </td>
                    ) : (
                      props.vesselList &&
                      props.vesselList.length > 0 &&
                      props.vesselList.map((item, index) => {
                        let storeBudget = "";
                        if (item.departments && item.departments.length > 0) {
                          let departments = item.departments;
                          storeBudget = departments
                            .map((s) => parseInt(s.budget))
                            .reduce(
                              (prev, next) => parseInt(prev) + parseInt(next)
                            );
                        }
                        if (index < 5)
                          return (
                            <tr key={"vesselList-" + index}>
                              <td>&nbsp;</td>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>
                                {item.kindOfVessel && item.kindOfVessel.label
                                  ? item.kindOfVessel.label
                                  : ""}
                              </td>
                              <td>{item.rate}</td>
                              <td>{storeBudget}</td>
                              <td>
                                {item.arrivalTime
                                  ? moment(item.arrivalTime).format(
                                    "DD-MM-YYYY"
                                  )
                                  : ""}
                              </td>
                              <td>{item.placeOfDelivery}</td>
                            </tr>
                          );
                      })
                    )}
                    {props.vesselList && props.vesselList.length > 0 ? (
                      <tr>
                        <td colSpan="11">
                          <div className="d-flex justify-content-end">
                            <Link
                              to="/vessel-listing?fleetOverview=1"
                              className="btn btn-link d-flex align-items-center p-0 fz-12 dark-pink-clr"
                            >
                              View
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </CollapsWrapper>
          )}
          <CollapsWrapper>
            <div className="table-responsive dash-chartInfo-table">
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopPort", !state.isTopPort)
                      }
                    >
                      <span className="dropdown-icon d-inline-block cur-poi">
                        <i
                          className={
                            state.isTopPort
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopPort === false ? (
                      <th className="text-center">Top Port</th>
                    ) : (
                      <>
                        <th>Port Name</th>
                        <th>Country</th>
                        <th>Number of supplies</th>
                        <th>Turnover P-MESH</th>
                        <th>Turnover C-MESH</th>
                        <th>Total Turnover</th>
                        <th>Suppliers</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${state.isTopPort ? "show" : ""
                    }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("topSupplyHub") !==
                    -1 ? (
                    <tr>
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    </tr>
                  ) : state.topSupplyHubList &&
                    state.topSupplyHubList.length === 0 ? (
                    <tr>
                      <td colSpan="40" className="text-center">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  ) : (
                    state.topSupplyHubList &&
                    state.topSupplyHubList.length > 0 &&
                    state.topSupplyHubList.map((item, index) => {
                      if (index < 5)
                        return (
                          <tr key={"suppList-" + index}>
                            <td>{index + 1}</td>
                            <td>{item.port}</td>
                            <td>{item.countryName}</td>
                            <td>{item.orderCount}</td>
                            <td>
                              {currencyFormatter(
                                item.pMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.cMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.totalVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/supplier-listing?topSupplier=1&portId=${item._id}`}
                                className="d-flex  p-0 fz-12 dark-pink-clr"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                    })
                  )}
                  {state.topSupplyHubList &&
                    state.topSupplyHubList.length > 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/port-listing?topPort=1"
                            className="btn btn-link d-flex align-items-center p-0 fz-12 dark-pink-clr"
                          >
                            View all
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </CollapsWrapper>
          <CollapsWrapper>
            <div className="table-responsive dash-chartInfo-table">
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopSupplier", !state.isTopSupplier)
                      }
                    >
                      <span className="dropdown-icon d-inline-block cur-poi">
                        <i
                          className={
                            state.isTopSupplier
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopSupplier === false ? (
                      <th className="text-center">Top Suppliers</th>
                    ) : (
                      <>
                        <th>Top Suppliers</th>
                        <th>Country</th>
                        <th>Number of Supplies</th>
                        <th>Turnover P-MESH</th>
                        <th>Turnover C-MESH</th>
                        <th>Total Turnover</th>
                        <th>Vessels</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${state.isTopSupplier ? "show" : ""
                    }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("topSupplier") !==
                    -1 ? (
                    <tr>
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    </tr>
                  ) : state.topSupplierList &&
                    state.topSupplierList.length === 0 ? (
                    <tr>
                      <td colSpan="40" className="text-center">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  ) : (
                    state.topSupplierList &&
                    state.topSupplierList.length > 0 &&
                    state.topSupplierList.map((item, index) => {
                      if (index < 5)
                        return (
                          <tr key={"suppList2-" + index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.countryName}</td>
                            <td>{item.totalOrder}</td>
                            <td>
                              {currencyFormatter(
                                item.pMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.cMeshVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              {currencyFormatter(
                                item.totalVolume,
                                item.currency
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/port-listing?topPort=1&supplierId=${item._id}`}
                                className="d-flex  p-0 fz-12 dark-pink-clr"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                    })
                  )}
                  {state.topSupplierList && state.topSupplierList.length > 0 ? (
                    <tr>
                      <td colSpan="11">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/supplier-listing?topSupplier=1"
                            className="btn btn-link d-flex align-items-center p-0 dark-pink-clr fz-12"
                          >
                            View all
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </CollapsWrapper>
          <CollapsWrapper>
            <div className="table-responsive dash-chartInfo-table">
              <table className="table theme-table-wrap2 short-table th-bd-none table-in-dash ">
                <thead>
                  <tr>
                    <th
                      className="small-unit40"
                      onClick={() =>
                        collapsHandle("isTopItem", !state.isTopItem)
                      }
                    >
                      <span className="dropdown-icon d-inline-block cur-poi">
                        <i
                          className={
                            state.isTopItem
                              ? "fa fa-angle-down"
                              : "fa fa-angle-right"
                          }
                        ></i>
                      </span>
                    </th>
                    {state.isTopItem === false ? (
                      <th className="text-center">Top Items</th>
                    ) : (
                      <>
                        <th>Item Description</th>
                        <th>Item group</th>
                        <th>IMPA</th>
                        <th>Unit</th>
                        <th>Number of Supplies</th>
                        <th>Quantity</th>
                        <th>Total Turnover</th>
                      </>
                    )}
                    {
                      // BASICALLY, SHOW THIS ONLY TO ADMIN.
                      props.userDetail.userType === "user" &&
                      props.userDetail.subType === "user" && (
                        <>
                          <th>Number of Supplies</th>
                          <th>Top Vessels</th>
                          <th>Top Customer</th>
                          <th>Top Supplier</th>
                          <th>Top Ports</th>
                          <th>Total Turnover</th>
                        </>
                      )
                    }
                  </tr>
                </thead>
                <tbody
                  className={`custom-dropdown-menu ${state.isTopItem ? "show" : ""
                    }`}
                >
                  {props.apiCallStatus.isStarted.indexOf("getTopItems") !==
                    -1 ? (
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img
                          style={{ width: "46px" }}
                          src={require("../../../assets/images/Spinner-1s-200px.gif")}
                          alt=""
                        />
                      </div>
                    </td>
                  ) : state.topItemsList && state.topItemsList.length === 0 ? (
                    <td colSpan="40" className="text-center">
                      No Data Found
                    </td>
                  ) : (
                    state.topItemsList
                      .sort(
                        (a, b) =>
                          a.topItems.alternativeCode -
                          b.topItems.alternativeCode
                      )
                      .map((item, index) => {
                        if (index < 5)
                          return (
                            <tr key={"topItems2-" + index}>
                              <td>{index + 1}</td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.description}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.category}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.alternativeCode}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.standardPackagingUnit}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.supplies}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {item.topItems.quantity}
                                </span>
                              </td>
                              <td>
                                <span className="td-wrap-sty">
                                  {currencyFormatter(
                                    item.topItems.turnover,
                                    item.currency
                                  )}
                                </span>
                              </td>
                              {props.userDetail.userType === "user" &&
                                props.userDetail.subType === "user" && (
                                  <>
                                    <td>
                                      <span className="td-wrap-sty">
                                        {item.supplies}
                                      </span>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/vessel-listing?topVessel=1&itemCode=${item.topItems.itemCode}`}
                                        className="d-flex  p-0 fz-12 dark-pink-clr"
                                      >
                                        View
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/customer-listing?topCustomer=1&itemCode=${item.topItems.itemCode}`}
                                        className="d-flex  p-0 fz-12 dark-pink-clr"
                                      >
                                        View
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/supplier-listing?topSupplier=1&itemCode=${item.topItems.itemCode}`}
                                        className="d-flex  p-0 fz-12 dark-pink-clr"
                                      >
                                        View
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/port-listing?topPort=1&itemCode=${item.topItems.itemCode}`}
                                        className="d-flex  p-0 fz-12 dark-pink-clr"
                                      >
                                        View
                                      </Link>
                                    </td>
                                    <td>
                                      <span className="td-wrap-sty">
                                        {Number(
                                          item.topItems.pricePerBaseUnit.toFixed(
                                            2
                                          )
                                        ) * Number(item.supplies)}
                                      </span>
                                    </td>
                                  </>
                                )}
                            </tr>
                          );
                      })
                  )}
                  <tr>
                    <td colSpan="13">
                      <div className="d-flex justify-content-end">
                        <Link
                          to="/catalogue-overview"
                          className="btn btn-link d-flex align-items-center p-0 fz-12 dark-pink-clr"
                        >
                          View all
                        </Link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CollapsWrapper>
        </div>
        {/* Order Status Content */}
      </div>
    </DashboardWrapper>
  );
}
const CollapsWrapper = React.memo((props) => {
  return (
    <div className="row dash-content-bx-columns dashborddemo">
      <div className="col-md-12">
        <div className="table-responsive">
          <div className=" contr-figure-info dropdown contract-dash-wrap mt-2">
            <div className="">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
});
function OrderStatusWrapper(props) {
  const [state, setState] = useState(false);
  return (
    <div className="d-flex vessel_dash dashVesl-overview-wrap vessel-order ">
      <div className="content-box-wrap dash-content-bx  mt-1 w-100">
        <div className="status-wrap-head content-box-head d-flex align-items-center flex-nowrap overflow-auto">
          <span className="dropdown-icon d-inline-block">
            <i
              onClick={() =>
                state === false ? setState(true) : setState(false)
              }
              className={
                state === true ? "fa fa-angle-down" : "fa fa-angle-right"
              }
            ></i>
          </span>
          <div className="flex-fill justify-content-start ">
            <h2>{props.label}</h2>
          </div>
        </div>
        {state && (
          <div className="d-flex dash-ord-content ordContent-table cust-turnover text-left">
            <div className="table-responsive">
              <table className="table  table-light1 theme-table-wrap2 short-table">
                <tbody>{props.children}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
const OrderButtonWrapper = React.memo((props) => {
  let isProvisionDisable =
    (props.userDetail.isSubAdmin &&
      props.userDetail.subType !== "sub-admin" &&
      props.userDetail.permission &&
      props.userDetail.permission.provisionOrder &&
      props.userDetail.permission.provisionOrder.length === 0) ||
    props.userDetail.userType === "customer";
  let isStoreDisable =
    (props.userDetail.isSubAdmin &&
      props.userDetail.subType !== "sub-admin" &&
      props.userDetail.permission &&
      props.userDetail.permission.department &&
      props.userDetail.permission.department.length === 0) ||
    props.userDetail.userType === "customer";
  return (
    <div className="vessel_dash content-box-wrap dash-content-bx mb-2 mr-0 d-flex flex-column w-100">
      <>
        <div className="content-box-head ord-status-head d-flex align-items-center justify-content-between">
          <h2 className="font-weight-bold">MESH* YOUR NEW ORDER:</h2>
        </div>
        {/* Order buttons wrap */}
        <div className="dashOrder-buttons-wrap d-flex flex-column justify-content-center align-items-center flex-fill">
          {props.vesselStatus && props.vesselId !== "" ? (
            <>
              <Link
                to={
                  isProvisionDisable
                    ? "#"
                    : "/create-provision-order/" + props.vesselId
                }
                className={`btn themeReguler-btn bg-dark-green ${isProvisionDisable ? "not-allowed btn-disable" : ""
                  }`}
              >
                PROVISION ORDER
              </Link>
              <Link
                to={
                  isStoreDisable ? "#" : "/create-store-order/" + props.vesselId
                }
                className={`btn themeReguler-btn bg-dark-blue ${isStoreDisable ? "not-allowed btn-disable" : ""
                  }`}
              >
                STORE ORDER
              </Link>
            </>
          ) : (
            <>
              <button className="btn themeReguler-btn bg-dark-green not-allowed">
                PROVISION ORDER
              </button>
              <button className="btn themeReguler-btn bg-dark-blue not-allowed">
                STORE ORDER
              </button>
            </>
          )}
        </div>
      </>
    </div>
  );
});

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  dashboardReducer: state.dashboardReducer,
  orderReducer: state.orderReducer,
  commonReducer: state.commonReducer,
  supplierReducer: state.supplierReducer,
  orderBasketReducer: state.orderBasketReducer,
  quotationList: state.quotationReducer.quotationList,
  quotationOrderList: state.quotationReducer.quotationOrderList,
  vesselList: state.vesselReducer.list,
  userDetail: {
    userType: state.authReducer.userType,
    permission: state.authReducer.permission,
    isSubAdmin: state.authReducer.isSubAdmin,
    roleName: state.authReducer.roleName,
    userId: state.authReducer.userId,
    activeVesselId: state.authReducer.activeVesselId,
    subType: state.authReducer.subType,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  cartListing: (offset, filterQuery) => dispatch(cartListing(offset, filterQuery)),
  orderListing: (offset, filterQuery) => dispatch(orderListing(offset, filterQuery)),
  topSupplier: (userId, userType, from, to) => dispatch(topSupplier(userId, userType, from, to)),
  topItemsListing: (userId, userType, from, to) => dispatch(topItemsListing(userId, userType, from, to)),
  topSupplyHub: (userId, userType, from, to) => dispatch(topSupplyHub(userId, userType, from, to)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  getVesselById: (vesselId) => dispatch(getVesselById(vesselId)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  quotationListing: (offset, limit, type, isDetail, captainId) => dispatch(quotationListing(offset, limit, type, isDetail, captainId)),
  provisionListing: (offset, limit, filterQuery) => dispatch(provisionListing(offset, limit, filterQuery)),
  getItemCode: (offset, limit, query) => dispatch(getItemCode(offset, limit, query)),
  getDescription: (offset, limit, query) => dispatch(getDescription(offset, limit, query)),
  getItemGroup: (query) => dispatch(getItemGroup(query)),
  getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
  getAllCountry: () => dispatch(getAllCountry()),
  basketOrders: (id, showCustomerOrders) => dispatch(basketOrders(id, showCustomerOrders)),
  supplierComparisonListing: (data, type) => dispatch(SupplierComparisonListing(data, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractVessel);
