import React from "react";
import loader from "../../../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCountryPrice } from "../../../../../Services/common";
export function CatalougeTable(props) {
  const getMoreData = () => {
    props.fetchMoreData();
  };
  return (
    <div className="ordCatg-InnTable">
      <InfiniteScroll
        dataLength={props.data.length}
        next={getMoreData}
        height={"80vh"}
        hasMore={props.totalCount <= props.data.length ? false : true}
        loader={
          props.apiCallStatus.isStarted.indexOf("appendProvisionList") !==
            -1 ? (
            <div
              colSpan="40"
              className="loadmore d-flex align-items-center justify-content-center"
            >
              {" "}
              <img src={loader} alt="" />
            </div>
          ) : (
            ""
          )
        }
      >
        <table className="table fixed-head">
          <thead>
            <TableHead isInvenotry={props.isInvenotry} />
          </thead>
          <tbody>
            {props.apiCallStatus.isStarted.indexOf("getProvision") !== -1 ? (
              <tr>
                <td colSpan="40" className="text-center">
                  <div className="loader-img text-center w-100">
                    <img
                      style={{ width: "46px" }}
                      src={require("../../../../../assets/images/Spinner-1s-200px.gif")}
                      alt=""
                    />
                  </div>
                </td>
              </tr>
            ) : props.data.length === 0 ? (
              <tr>
                <td colSpan="40" className="text-center">
                  <div className="white-clr noData-found mb-2">
                    No Data Found
                  </div>
                </td>
              </tr>
            ) : (
              props.data.map((item, index) => {
                const { orKg } = getCountryPrice(
                  item.pricePerCountry,
                  props.country,
                  item?.priceAvg,
                  item?.standardPackagingUnit,
                  item?.baseQuantity,
                );
                return (
                  <TableRow
                    {...item}
                    isInvenotry={props.isInvenotry}
                    data={{ ...item, orKg }}
                    key={"props.Data-" + index}
                    handleTableChange={props.handleTableChange}
                    inventory={
                      props.orderDetail[item.itemCode]
                        ? props.orderDetail[item.itemCode].inventory
                        : ""
                    }
                    order={
                      props.orderDetail[item.itemCode]
                        ? props.orderDetail[item.itemCode].order
                        : ""
                    }
                    budgetPoints={parseFloat(item?.budgetPoints).toFixed(2)}
                    orKg={orKg}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}

function TableHead(props) {
  return (
    <tr>
      <th>Item group</th>
      <th className="text-center">Item No.</th>
      <th>Description</th>
      <th>Explanation</th>
      {/* <th>Remarks</th> */}
      <th className="small-unit90">
        <span className="bg-rounded white text-center w-100 d-inline-block">
          Inventory
        </span>
      </th>
      {!props.isInvenotry ? (
        <th className="small-unit90">
          <span className="bg-rounded white text-center">Order</span>
        </th>
      ) : (
        ""
      )}
      <th className="small-unit110 text-center">STD. unit</th>
      {!props.isInvenotry ? (
        <th className="small-unit110 text-center">BGT. points</th>
      ) : (
        ""
      )}
      <th className="small-unit110 text-center">Packaging Size</th>
      {!props.isInvenotry ? (
        <th className="small-unit110 text-center">Used points</th>
      ) : (
        ""
      )}
      {/* <th><span className="bg-rounded white bg-dark-yellow white-clr text-center">Nutrition</span></th> */}
    </tr>
  );
}

function TableRow(props) {
  // let orKg = props.baseQuantityAvg;
  const { budgetPoints } = props;
  let inventory = props.inventory ? parseFloat(props.inventory) : 0;
  let order = props.order ? parseFloat(props.order) : 0;
  // let diffInKg = order * 10 * props.orKg;
  let protein = parseFloat(props.protein);
  const orderCalc = budgetPoints * order;
  const inventoryCalc = budgetPoints * inventory;
  const totalUsedPoints = inventoryCalc + orderCalc;
  const usedPoints = isNaN(totalUsedPoints) ? 0 : totalUsedPoints;
  return (
    <tr>
      <td>
        <span className="td-wrap-sty text-left">{props.category}</span>
      </td>
      <td>
        <span className="td-wrap-sty">{props.itemCode}</span>
      </td>
      <td>
        <span className="td-wrap-sty text-left">{props.description}</span>
      </td>
      <td>
        <span className="td-wrap-sty text-left">{props?.explanation || ''}</span>
      </td>
      {/* <td><span className="td-wrap-sty text-left">{props.remark}</span></td> */}
      <td>
        <input
          className="whi-round text-center"
          type="number"
          min="0"
          onChange={(e) =>
            props.handleTableChange(
              props.itemCode,
              "inventory",
              e.target.value,
              props.budgetPoints,
              props.category,
              {
                energy: props.energy,
                itemCode: props.itemCode,
                category: props.category,
                orderUsedPoints: props.orderUsedPoints,
                pricePerBaseUnit: props.price,
                category: props.category,
                standardPackagingUnit: props.standardPackagingUnit,
                description: props.description,
                explanation: props.explanation,
                protein: protein,
                carbohydrates: props.carbohydrates,
                fat: props.fat,
                sugar: props.sugar,
                dietaryFibres: props.dietaryFibres,
              },
              props.data
            )
          }
          value={props.inventory}
        />
      </td>
      {!props.isInvenotry ? (
        <td>
          <input
            className="whi-round text-center"
            min="0"
            type="number"
            onChange={(e) =>
              props.handleTableChange(
                props.itemCode,
                "order",
                e.target.value,
                props.budgetPoints,
                props.category,
                {
                  energy: props.energy,
                  itemCode: props.itemCode,
                  category: props.category,
                  orderUsedPoints: props.orderUsedPoints,
                  pricePerBaseUnit: props.price,
                  category: props.category,
                  standardPackagingUnit: props.standardPackagingUnit,
                  description: props.description,
                  explanation: props.explanation,
                  protein: protein,
                  carbohydrates: props.carbohydrates,
                  fat: props.fat,
                  sugar: props.sugar,
                  dietaryFibres: props.dietaryFibres,
                },
                props.data
              )
            }
            value={props.order}
          />
        </td>
      ) : (
        ""
      )}
      <td className="small-unit50">
        <span className="td-wrap-sty">{props.standardPackagingUnit}</span>
      </td>
      {!props.isInvenotry ? (
        <td className="small-unit50">
          <span className="td-wrap-sty text-right">{props.budgetPoints}</span>
        </td>
      ) : (
        ""
      )}
      <td className="small-unit50">
        <span className="td-wrap-sty text-right">{props.orKg}</span>
      </td>
      {!props.isInvenotry ? (
        <td className="small-unit70">
          <span className="td-wrap-sty text-right">
            {usedPoints.toFixed(2)}
          </span>
        </td>
      ) : (
        ""
      )}
      {/* <td><span><button type="button" className="btn tb-btn-rounded orng w-100" onClick={() => props.showItemDetailModel({ energy: (diffInKg * props.energy), protein: (diffInKg * protein), carbohydrates: (diffInKg * props.carbohydrates), fat: (diffInKg * props.fat), sugar: (diffInKg * props.sugar), dietaryFibres: (diffInKg * props.dietaryFibres) })}>Details</button></span></td> */}
    </tr>
  );
}
