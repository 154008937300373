import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { capitalizeFirstLetter, getDashboardLink, getFormatedPricing, quotationStatusMapping } from "../../../../Services/common";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import {
  ApiClearAction,
  ClearApiByNameAction,
} from "../../../ApiCallStatus/Actions/action";
import { CsvDownload } from "../../../Common/Components/CsvDownload";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { quotationListing } from "../../../Quotations/Apicalls/quotations";
import {
  approveDoucment,
  getCustomerOrderDetail,
  getDocumentsList,
  uploadDocuments,
} from "../../ApiCalls/supplier";
import { vesselListing } from "./../../../Vessel/ApiCalls/vessel";
import { CustomerDetailModal, UploadDocModal } from "./model";

const headers = [
  { label: "Vessel Name", key: "vesselName" },
  { label: "Customer Name", key: "customerName" },
  { label: "Type", key: "type" },
  { label: "Type of Order", key: "typeOfOrder" },
  { label: "Delivery Date", key: "nextDeliveryDate" },
  { label: "Delivery Place", key: "nextDeliveryPlace" },
  { label: "Nominated supplier", key: "supplierName" },
  { label: "Turnover", key: "rfqValue" },
  { label: "Status", key: "status" },
];
var csvLinkRef = React.createRef();
function InProgressOrderList(props) {
  const [state, setState] = useState({
    isHitApi: false,
    quotationOrderList: [],
    totalQuotation: 0,
    isCsVDownload: false,
    csvData: [],
    customerVessels: [],
    activePage: 0,
    limit: 20,
    selectedOrder: "",
    query: "?status=inprogress",
    supplierId: "",
    quotationId: "",
  });
  useEffect(() => {
    if (!state.isHitApi) {
      if (props.subType === "customer") {
        props.vesselListing(0, 50);
      } else {
        props.quotationListing(0, 20, "order");
      }
      let data = { ...state };
      data.isHitApi = true;
      setState(data);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getQuotation" ||
        props.apiCallStatus.apiCallFor === "appendQuotationList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      if (state.isCsVDownload) {
        csvLinkRef.current.link.click();
      }
      let csvData = [];
      for (let index = 0; index < props.quotationOrderList.length; index++) {
        const element = props.quotationOrderList[index];
        let status =
          element.isSupplied && element.status === "confirmed"
            ? "Supplied"
            : element.status &&
            element.status[0].toUpperCase() + element.status.slice(1);
        let csvObj = {
          vesselName:
            element.vesselId && element.vesselId.name
              ? element.vesselId.name
              : "",
          customerName: element.customerName,
          type:
            element.orderProvisionType[0].toUpperCase() +
            element.orderProvisionType.slice(1),
          nextDeliveryDate: moment(element.nextDeliveryDate).format(
            "DD.MM.YYYY"
          ),
          nextDeliveryPlace: element.nextDeliveryPlace,
          supplierName:
            element.supplierId && element.supplierId.name
              ? element.supplierId.name
              : "",
          rfqValue: element.rfqValue + " " + element.currency,
          status: status,
        };
        csvData.push(csvObj);
      }
      setState({
        ...state,
        quotationOrderList: props.quotationOrderList,
        totalQuotation: props.totalQuotation,
        csvData,
        isCsVDownload: false,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      (props.apiCallStatus.apiCallFor === "vesselListing" ||
        props.apiCallStatus.apiCallFor === "appendVesselList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let customerVessels = [];
      if (props.subType === "customer" && props.vesselList.length > 0) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        props.quotationListing(0, 20, "order", customerVessels);
      }
      setState({
        ...state,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "uploadDocuments" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        selectedOrder: "",
      });
      $("#deliveryNote").val(null);
      $("#invoice").val(null);
      $("#uploadDocumentModal").modal("hide");
      Swal.fire(
        "Success",
        "Your documents has been uploaded successfully. Please wait for the approval.",
        "success"
      );
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    } else if (
      props.apiCallStatus.apiCallFor === "approveDoucment" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        selectedOrder: "",
        activePage: 0,
      });
      Swal.fire(
        "Success",
        "Documents has been approved successfully.",
        "success"
      );
      $("#uploadDocumentModal").modal("hide");
      if (props.userType === "customer") {
        props.quotationListing(0, 20, "order", state.customerVessels);
      } else {
        props.quotationListing(0, 20, "order");
      }
    }
  });
  const fetchMoreData = () => {
    let data = { ...state };
    data.activePage += 20;
    if (props.userType === "customer") {
      props.quotationListing(
        data.activePage,
        20,
        "order",
        state.customerVessels
      );
    } else {
      props.quotationListing(data.activePage, 20, "order");
    }
    setState(data);
  };
  const showCustomerDetailModal = (orderId, userId) => {
    $("#customerDetailModal").modal("show");
    props.getCustomerOrderDetail(orderId, userId);
  };
  const showDocDettailModal = (orderId, supplierId, quotationId) => {
    setState({
      ...state,
      selectedOrder: orderId,
      supplierId: supplierId,
      quotationId: quotationId,
    });
    $("#uploadDocumentModal").modal("show");
    props.getDocumentsList(orderId);
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
    {
      img: "Monitor-icon.svg",
      className: "dark-blue",
      name: "Dash<br />board",
      path: getDashboardLink(),
      isLogout: false,
    },
  ];
  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    if (props.userType === "customer") {
      props.quotationListing(
        0,
        state.totalQuotation,
        "order",
        state.customerVessels
      );
    } else {
      props.quotationListing(0, state.totalQuotation, "order");
    }

    setState({
      ...state,
      isCsVDownload: true,
    });
  };

  const isSupplier = props.userType === "supplier";
  const isCustomer = props.userType === "customer";
  const isAdmin = props.userType === "user" && props.subType === "user";

  return (
    <DashboardWrapper
      title="Orders"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="ordCatg-table-wrap mt-3 system-cat-filter">
        <div className="card-bx-sty">
          <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
              <CsvDownload
                data={state.csvData}
                csvLinkRef={csvLinkRef}
                filename={"Current Orders.csv"}
                headers={headers}
                apiCallForCSVData={() => apiCallForCSVData()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Order Status Content */}
      <div className="row mt-lg-4 align-content-stretch newDash-contentStyle">
        <div className="col-12">
          <div className="content-box-wrap dash-content-bx">
            <div className="flex-fil text-left table-default-sty">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={state.quotationOrderList.length}
                  next={fetchMoreData}
                  height={"80vh"}
                  hasMore={
                    state.totalQuotation <= state.quotationOrderList.length
                      ? false
                      : true
                  }
                  loader={
                    <div
                      colSpan="12"
                      className="loadmore d-flex align-items-center justify-content-center"
                    >
                      {" "}
                      <img src={loader} alt="" />
                    </div>
                  }
                >
                  <table className="table mt-4 table-light1 bg-transparent with-action-btns">
                    <thead className="rounded-dark-head">
                      <tr>
                        <th>Nr.</th>
                        {headers.map((item, inex) => {
                          return <th>{item.label}</th>;
                        })}
                        <th>Actions&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="spacer">
                      {props.apiCallStatus.isStarted.indexOf("getQuotation") !==
                        -1 ? (
                        <tr>
                          <td colSpan="20">
                            <div className="loader-img text-center">
                              <img
                                style={{ width: "46px" }}
                                src={loader}
                                alt=""
                              />
                            </div>
                          </td>
                        </tr>
                      ) : state.quotationOrderList &&
                        state.quotationOrderList.length > 0 ? (
                        state.quotationOrderList.map((item, index) => {
                          // let status =
                          //   item.isSupplied && item.status === "confirmed"
                          //     ? "Supplied"
                          //     : item.status &&
                          //     item.status[0].toUpperCase() +
                          //     item.status.slice(1);

                          let supplierId =
                            item.supplierId && item.supplierId._id
                              ? item.supplierId._id
                              : item.supplierId
                                ? item.supplierId
                                : "";

                          const { _id, documentsUploaded, orderId, orderProvisionType, status } = item;

                          const isConfirmed = status === 'confirmed';
                          const isPendingForApproval = status === 'pending_approval';
                          const isSupplied = status === 'supplied';
                          const viewOrderDetailLink = `/order-detail/${_id}?orderId=${orderId}&orderType=${orderProvisionType}&supplierId=${supplierId}`;

                          return (
                            <tr key={"qOL-" + index}>
                              <td>{index + 1}</td>
                              <td>
                                {item.vesselId && item.vesselId.name
                                  ? item.vesselId.name
                                  : ""}
                              </td>
                              <td>{item.customerName}</td>
                              <td>
                                {capitalizeFirstLetter(item.orderProvisionType)}
                              </td>
                              <td>{item.typeOfOrder}</td>
                              <td>
                                {moment(item.nextDeliveryDate).format(
                                  "DD.MM.YYYY"
                                )}
                              </td>
                              <td>{item.nextDeliveryPlace}</td>
                              <td>
                                {item.supplierId && item.supplierId.name
                                  ? item.supplierId.name
                                  : ""}
                              </td>
                              <td>
                                {item.rfqValue
                                  ? getFormatedPricing(
                                    item.rfqValue,
                                    item.currency
                                  )
                                  : "0"}{" "}
                                {item.rfqValue ? item.currency : ""}
                              </td>
                              <td>
                                {status === "inprogress" ||
                                  status === "pending" ? (
                                  <>
                                    <span className="sta-indicator pend"></span>{" "}
                                    <span className="sta-text-wrap text-truncate"></span>
                                  </>
                                ) : status === "confirmed" ||
                                  status === "supplied" ? (
                                  <>
                                    <span className="sta-indicator appr"></span>{" "}
                                    <span className="sta-text-wrap text-truncate"></span>
                                  </>
                                ) : status === "pastorder" ? (
                                  <>
                                    <span className="sta-indicator past"></span>{" "}
                                    <span className="sta-text-wrap text-truncate"></span>
                                  </>
                                ) : (
                                  <span>{quotationStatusMapping[status]}</span>
                                )}
                              </td>
                              <td className="text-right">
                                <div className="tb-buttons-wrap">
                                  {documentsUploaded && (isSupplier || isAdmin) && (isConfirmed || isPendingForApproval) ? (
                                    <Link to={`/supply-confirmation/${_id}`} className={"btn tb-btn-rounded mr-2 orng"}>Add Supply</Link>
                                  ) : (
                                    ""
                                  )}
                                  {isSupplier
                                    ? ""
                                    : // <button type="button" onClick={() => showCustomerDetailModal(item.orderId, item.userId)} className="btn tb-btn-rounded orng mr-2">View Customer Detail</button>
                                    isCustomer
                                      ? ""
                                      : //  <Link to={"/supplier-profile/" + supplierId} className="btn tb-btn-rounded orng mr-2">View Supplier Detail</Link>
                                      ""}
                                  {/* <button type="button" className="btn tb-btn-rounded red mr-2">Cancel</button> */}
                                  <Link
                                    to={viewOrderDetailLink}
                                    className={
                                      "btn tb-btn-rounded mr-2 " +
                                      (isConfirmed ? "green " : "orng ")
                                    }
                                  >
                                    {isSupplier ? "View Order" : isSupplied ? "Supplied" : "View"}
                                  </Link>
                                  {isConfirmed ? (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        showDocDettailModal(
                                          orderId,
                                          item.supplierId._id,
                                          _id
                                        )
                                      }
                                      className="btn tb-btn-rounded orng mr-2"
                                    >
                                      {(isSupplier || isAdmin) && !documentsUploaded ? "Upload Document" : "View Document"}
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : state.quotationOrderList &&
                        state.quotationOrderList.length === 0 ? (
                        <tr>
                          <td colSpan="40" className="text-center">
                            <div>No Data Found</div>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.userType === "supplier" ? (
        <CustomerDetailModal customerDetail={props.customerDetail} />
      ) : (
        ""
      )}

      <UploadDocModal
        userType={props.userType}
        history={props.history}
        isSubAdmin={props.isSubAdmin}
        approveDoucment={props.approveDoucment}
        uploadDocuments={props.uploadDocuments}
        documentList={props.documentList}
        apiCallStatus={props.apiCallStatus}
        orderId={state.selectedOrder}
        supplierId={state.supplierId}
        quotationId={state.quotationId}
        userId={props.userId}
      />
      {/* <h3 className="mash-info-text">*meshing = connect your vessel(s) to our worlwide network of suppliers & knowledge</h3> */}
    </DashboardWrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  quotationOrderList: state.quotationReducer.quotationOrderList,
  totalQuotation: state.quotationReducer.totalQuotation,
  userType: state.authReducer.userType,
  subType: state.authReducer.subType,
  vesselList: state.vesselReducer.list,
  isSubAdmin: state.authReducer.isSubAdmin,
  userId: state.authReducer.userId,
  documentList: state.orderReducer.documentList,
  customerDetail: state.orderReducer.customerDetail,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ApiClearAction: () => dispatch(ApiClearAction()),
  getDocumentsList: (userId) => dispatch(getDocumentsList(userId)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  approveDoucment: (data) => dispatch(approveDoucment(data)),
  uploadDocuments: (data) => dispatch(uploadDocuments(data)),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  quotationListing: (offset, limit, type, isDetail) =>
    dispatch(quotationListing(offset, limit, type, isDetail)),
  getCustomerOrderDetail: (orderId, userId) =>
    dispatch(getCustomerOrderDetail(orderId, userId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InProgressOrderList);
