import React, { useEffect, useState } from "react";
import "./dashboard-style.css";
import { topItemsListing } from "./../../Orders/ApiCalls/provisionOrder";
import {
  getItemCode,
  getItemGroup,
  getDescription,
} from "./../../Orders/ApiCalls/searchProvisionOrder";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { Link } from "react-router-dom";
import { Filters } from "../../Orders/Components/Catalogue/ProvisionCatalogue/filters";
import { vesselListing } from "./../../Vessel/ApiCalls/vessel";
import cloneDeep from "lodash.clonedeep";
import { DashboardWrapper } from "./dashboarWrapper";
import { currencyFormatter } from "../../../Services/common";
let filterInputTimeout = null;
const headers = [
  { label: "Nr.", key: "Nr." },
  { label: "Item Description", key: "description" },
  { label: "Item group", key: "category" },
  { label: "IMPA", key: "alternativeCode" },
  { label: "Unit", key: "standardPackagingUnit" },
  { label: "No. of Supplies", key: "supplies" },
  { label: "Quantity", key: "quantity" },
  { label: "Total Turnover", key: "turnover" },
];
var csvLinkRef = React.createRef();
function CatalogueOverview(props) {
  const [state, setState] = useState({
    topItemsList: [],
    itemGroupList: [],
    cloneItemGroupList: [],
    customerVessels: [],
    itemNoList: [
      { label: "1101", value: "1101" },
      { label: "1102", value: "1102" },
      { label: "1103", value: "1103" },
      { label: "1104", value: "1104" },
      { label: "1105", value: "1105" },
      { label: "1106", value: "1106" },
      { label: "1107", value: "1107" },
      { label: "1108", value: "1108" },
      { label: "1109", value: "1109" },
    ],
    filters: {
      itemGroup: "",
      inputItemGroup: "",
      itemNo: "",
      inputItemNo: "",
      inputDescription: "",
      description: "",
      isApplied: false,
    },
    descriptionList: [],
    csvData: [],
    userType:
      props.userDetail.userType === "supplier"
        ? "supplier"
        : props.userDetail.subType === "captain"
          ? "captain"
          : props.userDetail.subType === "customer"
            ? "customer"
            : "",
    totalItemCode: 0,
    totalDescription: 0,
    filterQuery: "",
    activePage: 0,
    itemCodePage: 0,
    descriptionPage: 0,
    totalTopItems: 0,
    isHitApi: false,
    isSelectAndStateUpdate: false,
  });

  useEffect(() => {
    if (!state.isHitApi) {
      let filterQuery = "";
      if (state.userType !== "customer") {
        props.topItemsListing(props.userDetail.userId, state.userType);
      } else {
        props.vesselListing(0, 50);
      }
      props.getItemCode(0, 20, "");
      props.getDescription(0, 20, "");
      props.getItemGroup("");
      let data = { ...state };
      data.isHitApi = true;
      data.filterQuery = filterQuery;
      setState(data);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getTopItems" ||
        props.apiCallStatus.apiCallFor === "appendTopItemsList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let data = { ...state };
      let newArray = [];
      let Data = [...props.orderReducer.topItemsList];
      for (let index = 0; index < Data.length; index++) {
        let obj = {
          category: Data[index].topItems.category,
          itemCode: Data[index].topItems.itemCode,
          description: Data[index].topItems.description,
          alternativeCode: Data[index].topItems.alternativeCode,
          standardPackagingUnit: Data[index].topItems.standardPackagingUnit,
          pricePerBaseUnit:
            Data[index].topItems.pricePerBaseUnit + " " + Data[index].currency,
        };
        newArray.push(obj);
      }
      data.topItemsList = props.orderReducer.topItemsList;
      data.totalTopItems = props.orderReducer.totalTopItems;
      data.csvData = newArray;
      data.isHitApi = true;
      setState(data);
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getItemCode" ||
        props.apiCallStatus.apiCallFor === "appendItemCode") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArr = [];
      let arr = props.orderReducer.itemCodeList;
      for (let i = 0; i < arr.length; i++) {
        newArr.push({ value: arr[i].itemCode, label: arr[i].itemCode });
      }
      let data = { ...state };
      data.itemNoList = newArr;
      data.totalItemCode = props.orderReducer.totalItemCode;
      data.isHitApi = true;
      setState(data);
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getDescription" ||
        props.apiCallStatus.apiCallFor === "appendDescription") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArr = [];
      let arr = props.orderReducer.descriptionList;
      for (let i = 0; i < arr.length; i++) {
        newArr.push({ value: arr[i].description, label: arr[i].description });
      }
      let data = { ...state };
      data.descriptionList = newArr;
      data.totalDescription = props.orderReducer.totalDescription;
      data.isHitApi = true;
      setState(data);
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "getItemGroup" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let newArr = [];
      let arr = props.orderReducer.itemGroupList;
      for (let i = 0; i < arr.length; i++) {
        newArr.push({ value: arr[i], label: arr[i] });
      }
      let data = { ...state };
      data.cloneItemGroupList = newArr;
      data.itemGroupList = newArr;
      data.isHitApi = true;
      setState(data);
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "vesselListing" ||
      (props.apiCallStatus.apiCallFor === "appendVesselList" &&
        props.apiCallStatus.isCompleted &&
        !props.apiCallStatus.isFailed)
    ) {
      let customerVessels = [];
      if (state.userType === "customer" && props.vesselList.length > 0) {
        for (let index = 0; index < props.vesselList.length; index++) {
          const id = props.vesselList[index]._id;
          customerVessels.push(id);
        }
        props.topItemsListing(customerVessels, state.userType);
      }
      setState({
        ...state,
        customerVessels,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (state.isSelectAndStateUpdate) {
      applyFilters();
      setState({
        ...state,
        isSelectAndStateUpdate: false,
      });
    }
  }, [props, state.isSelectAndStateUpdate]);
  const fetchMoreItemCode = () => {
    let data = { ...state };
    let { itemCodePage } = { ...data };
    itemCodePage += 1;
    let query = "";
    if (state.filters.itemNo !== "") {
      query = "?itemCode=" + state.filters.itemNo;
    }
    data.itemCodePage = itemCodePage;
    setState(data);
    props.getItemCode(itemCodePage, 20, query);
  };
  const fetchMoreDescription = () => {
    let data = { ...state };
    let { descriptionPage } = { ...data };
    descriptionPage += 1;
    let query = "";
    if (state.filters.description !== "") {
      query = "?description=" + state.filters.description;
    }
    data.descriptionPage = descriptionPage;
    setState(data);
    props.getDescription(descriptionPage, 20, query);
  };
  const handleFilterChange = (id, val) => {
    let data = cloneDeep(state);
    let filters = { ...data.filters };
    let obj = {};
    filters[id] = val;
    obj.filters = filters;
    let query = "";
    clearTimeout(filterInputTimeout);
    if (id === "inputItemNo") {
      data.itemCodePage = 0;
      if (val !== "") {
        query = "?itemCode=" + val;
      }
    } else if (id === "inputDescription") {
      data.descriptionPage = 0;
      if (val !== "") {
        query = "?description=" + val;
      }
    } else if (id === "inputItemGroup") {
      let arr = state.cloneItemGroupList.filter((item) => {
        let trimVal = val.toLowerCase();
        let selectedVal = item.value.toLowerCase();
        return selectedVal.indexOf(trimVal) !== -1;
      });
      data.itemGroupList = arr;
    }
    // Make a new timeout set to go off in 1000ms (1 second)
    filterInputTimeout = setTimeout(function () {
      if (id === "inputItemNo") {
        props.getItemCode(0, 20, query);
      } else if (id === "inputDescription") {
        props.getDescription(0, 20, query);
      }
    }, 500);
    data.filters = filters;
    setState(data);
  };
  const clickCustomeSelectHandler = (id, val) => {
    let data = cloneDeep(state);
    let filters = { ...data.filters };
    filters[id] = val;
    if (id === "itemNo") {
      filters.inputItemNo = val;
      data.itemCodePage = 0;
    } else if (id === "description") {
      filters.inputDescription = val;
      data.descriptionPage = 0;
    } else if (id === "itemGroup") {
      filters.inputItemGroup = val;
    }
    data.filters = filters;
    data.isSelectAndStateUpdate = true;
    setState(data);
  };
  const applyFilters = () => {
    let itemGroup = "";
    let itemCode = "";
    let description = "";
    let activePage = 0;
    let data = cloneDeep(state);

    if (data.filters.itemGroup !== "") {
      itemGroup = data.filters.itemGroup;
    }
    if (data.filters.itemNo !== "") {
      itemCode = data.filters.itemNo;
    }
    if (data.filters.description !== "") {
      description = data.filters.description;
    }
    // let filterQuery = '';
    // if (props.userDetail.userType === 'customer') {
    //     filterQuery = `?userId=${props.userDetail.userId}`
    // } else if (props.userDetail.userType === 'supplier') {
    //     filterQuery = `?supplierId=${props.userDetail.userId}`
    // }
    // if (val1 !== '') {
    //     filterQuery += filterQuery !== '' ? `&${val1}` : `?${val1}`;
    // }
    // if (val2 !== '') {
    //     filterQuery += filterQuery !== '' ? `&${val2}` : `?${val2}`;
    // }
    // if (val3 !== '') {
    //     filterQuery += filterQuery !== '' ? `&${val3}` : `?${val3}`;
    // }
    // data.filterQuery = filterQuery;
    data.activePage = activePage;
    data.itemCodePage = 0;
    data.descriptionPage = 0;
    data.isApplied = true;
    setState(data);
    if (state.userType !== "customer") {
      props.topItemsListing(
        props.userDetail.userId,
        state.userType,
        "",
        "",
        itemCode,
        description
      );
    } else {
      props.topItemsListing(
        state.customerVessels,
        state.userType,
        "",
        "",
        itemCode,
        description
      );
    }
  };
  const clearFilters = () => {
    let query = "";
    let data = { ...state };
    data.filters = {
      itemGroup: "",
      itemNo: "",
      inputItemGroup: "",
      inputItemNo: "",
      inputDescription: "",
      description: "",
      isApplied: false,
    };
    data.itemGroupList = state.cloneItemGroupList;
    data.itemCodePage = 0;
    data.descriptionPage = 0;
    setState(data);
    if (state.userType !== "customer") {
      props.topItemsListing(props.userDetail.userId, state.userType);
    } else {
      props.topItemsListing(state.customerVessels, state.userType);
    }
    props.getItemCode(0, 20, query);
    props.getDescription(0, 20, query);
    props.getItemGroup(query);
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
  ];
  const apiCallForCSVData = () => {
    /**
     * Requesting csv files data
     */
    csvLinkRef.current.link.click();
  };
  return (
    <DashboardWrapper
      title="Item Overview"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="ordCatg-table-wrap mt-3 system-cat-filter">
        <Filters
          data={[]}
          csvLinkRef={csvLinkRef}
          filename={"Top-Items.csv"}
          headers={headers}
          apiCallForCSVData={apiCallForCSVData}
          hideItemGroup={true}
          {...state.filters}
          fetchMoreItemCode={fetchMoreItemCode}
          fetchMoreDescription={fetchMoreDescription}
          totalItemCode={[]}
          totalDescription={[]}
          itemGroupList={[]}
          itemNoList={[]}
          descriptionList={[]}
          handleChange={handleFilterChange}
          clickCustomeSelectHandler={clickCustomeSelectHandler}
          clearFilters={clearFilters}
          applyFilters={applyFilters}
          getFilterDetail={[]}
        />
        {/* <InfiniteScroll
                    dataLength={state.topItemsList.length}
                    next={fetchMoreData}
                    height={"80vh"}
                    hasMore={state.totalTopItems <= state.topItemsList.length ? false : true}
                    loader={props.apiCallStatus.isStarted.indexOf("getProvision") === -1 ? <div colSpan="12" className="loadmore d-flex align-items-center justify-content-center" > <img src={loader} alt='' /></div> : ""}
                > */}
        <table className="table theme-table-wrap2 short-table sticky-th-border mt-4">
          <thead>
            <tr>
              {headers.map((item, inex) => {
                return <th>{item.label}</th>;
              })}

              {/* {props.userDetail.userType === "supplier" && (
                <>
                  <th>Top Ports</th>
                  <th>Total Turnover</th>
                  <th>Top Supplier</th>
                </>
              )} */}
            </tr>
          </thead>
          <tbody>
            {props.apiCallStatus.isStarted.indexOf("getTopItems") !== -1 ? (
              <td colSpan="20">
                <div className="loader-img text-center">
                  <img
                    style={{ width: "46px" }}
                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                    alt=""
                  />
                </div>
              </td>
            ) : state.topItemsList && state.topItemsList.length === 0 ? (
              <td colSpan="40" className="text-center">
                No Data Found
              </td>
            ) : (
              state.topItemsList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <span className="td-wrap-sty">
                        {item.topItems.description}
                      </span>
                    </td>
                    <td>
                      <span className="td-wrap-sty">
                        {item.topItems.category}
                      </span>
                    </td>
                    <td>
                      <span className="td-wrap-sty">
                        {item.topItems.alternativeCode}
                      </span>
                    </td>
                    <td>
                      <span className="td-wrap-sty">
                        {item.topItems.standardPackagingUnit}
                      </span>
                    </td>
                    <td>
                      <span className="td-wrap-sty">{item.supplies}</span>
                    </td>
                    <td>
                      <span className="td-wrap-sty">
                        {currencyFormatter(item.topItems.quantity, "", false)}
                      </span>
                    </td>
                    <td>
                      <span className="td-wrap-sty">
                        {currencyFormatter(
                          item.topItems.turnover,
                          item.topItems.currency
                        )}
                      </span>
                    </td>
                    {/* {props.userDetail.userType === "supplier" && (
                      <>
                        <td>
                          <Link
                            to={`/supplier-listing?topSupplier=1`}
                            className="d-flex  p-0 fz-12 dark-pink-clr"
                          >
                            View{" "}
                          </Link>
                        </td>

                        <td>
                          <Link
                            to={`/port-listing?topPort=1`}
                            className="d-flex  p-0 fz-12 dark-pink-clr"
                          >
                            View
                          </Link>
                        </td>
                        <td>
                          <span className="td-wrap-sty">
                            {Number(item.topItems.pricePerBaseUnit.toFixed(2)) *
                              Number(item.supplies)}
                          </span>
                        </td>
                      </>
                    )} */}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {/* </InfiniteScroll> */}
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  orderReducer: state.orderReducer,
  vesselList: state.vesselReducer.list,
  userDetail: {
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
    userId: state.authReducer.userId,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  topItemsListing: (userId, userType, from, to, itemCode, description) =>
    dispatch(
      topItemsListing(userId, userType, from, to, itemCode, description)
    ),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  vesselListing: (offset, limit) => dispatch(vesselListing(offset, limit)),
  getItemCode: (offset, limit, query) => dispatch(getItemCode(offset, limit, query)),
  getDescription: (offset, limit, query) => dispatch(getDescription(offset, limit, query)),
  getItemGroup: (query) => dispatch(getItemGroup(query)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CatalogueOverview);
