import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import {
  GetVesselAction,
  AppendVessalAction,
  GetVesselDetailAction,
  QuestionaireAction,
  GetVesselOffsetLimitAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { getCookie } from "../../../Services/cookies";
export function createVessel(data, type) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "createVessel" }));
    let myJson = "";
    if (type === "questionaire") {
      myJson = await FETCH("POST", Constant.apiURl + "/vessel", data);
    } else {
      const formData = new FormData();
      formData.append("userId", data.userId);
      // formData.append("alternativeName", data.alternativeName);
      formData.append("name", data.name);
      formData.append("imo", data.imo);
      formData.append("email", data.email);
      // formData.append("build", data.build);
      formData.append("availableService", data.availableService);
      formData.append("meshStartDate", data.meshStartDate);
      // formData.append("tdw", data.tdw);
      formData.append("kindOfVessel", JSON.stringify(data.kindOfVessel));
      // formData.append("gear", data.gear);
      formData.append("currency", data.currency);
      formData.append("vesselType", data.vesselType);
      if (data.userEmail && data.userEmail !== "") {
        formData.append("userEmail", data.userEmail);
      }
      // formData.append("cook", JSON.stringify(data.cook));
      // formData.append("captain", JSON.stringify(data.captain));
      if (data.pic.length > 0) {
        formData.append("file", data.pic[0]);
      }
      myJson = await FETCH("POST", Constant.apiURl + "/vessel", formData, true);
    }

    if (myJson && myJson.code === 201) {
      dispatch(QuestionaireAction(myJson.data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "createVessel",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "createVessel",
          message: myJson.message,
        })
      );
    }
  };
}
export function vesselListing(offset, limit, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendVesselList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "vesselListing" }));
      dispatch(GetVesselAction([], 0));
    }
    let url = "";
    if (getCookie("userType") === "customer") {
      url = Constant.apiURl + "/vessel/filter/" + offset + `/${limit}`;
      if (filterQuery) {
        url += filterQuery;
        url += `userId=${getCookie("userId")}`;
      } else {
        url += `?userId=${getCookie("userId")}`;
      }
    } else {
      url = Constant.apiURl + "/vessel/filter/" + offset + `/${limit}`;
      if (filterQuery) {
        url += filterQuery;
      }
    }

    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendVessalAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendVesselList",
            message: "",
          })
        );
      } else {
        dispatch(GetVesselAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "vesselListing",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendVesselList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "vesselListing",
            message: myJson.message,
          })
        );
      }
    }
  };
}
export function getVesselDetail(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getVesselDetail" }));
    let myJson = await FETCH("GET", Constant.apiURl + "/vessel/" + id);
    if (myJson && myJson.code === 201) {
      let data = { ...myJson.data };
      data.user = {
        label:
          myJson.data.User && myJson.data.User[0]
            ? myJson.data.User[0].email
            : "",
        value:
          myJson.data.User && myJson.data.User[0]
            ? myJson.data.User[0]._id
            : "",
      };
      data.cook =
        myJson.data.cook && myJson.data.cook.length > 0 ? myJson.data.cook : [];
      data.captain =
        myJson.data.captain && myJson.data.captain.length > 0
          ? myJson.data.captain
          : [];
      dispatch(GetVesselDetailAction(data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getVesselDetail",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getVesselDetail",
          message: myJson.message,
        })
      );
    }
  };
}
export function editVessel(id, data, type) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "editVessel" }));
    let myJson = "";
    if (type === "questionaire") {
      // data.userId = getCookie("userId");
      myJson = await FETCH("PUT", Constant.apiURl + "/vessel/" + id, data);
    } else {
      var formData = new FormData();
      formData.append("imo", data.imo);
      formData.append("userId", data.userId);
      // formData.append("alternativeName", data.alternativeName);
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("availableService", data.availableService);
      formData.append("meshStartDate", data.meshStartDate);
      // formData.append("build", data.build);
      // formData.append("tdw", data.tdw);
      formData.append("kindOfVessel", JSON.stringify(data.kindOfVessel));
      // formData.append("gear", data.gear);
      formData.append("currency", data.currency);
      formData.append("vesselType", data.vesselType);
      if (data.userEmail && data.userEmail !== "") {
        formData.append("userEmail", data.userEmail);
      }
      // formData.append("cook", JSON.stringify(data.cook));
      // formData.append("captain", JSON.stringify(data.captain));
      // if (data.pic.length > 0) {
      //   formData.append("file", data.pic[0]);
      // }
      myJson = await FETCH(
        "PUT",
        Constant.apiURl + "/vessel/" + id,
        formData,
        true
      );
    }
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "editVessel",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "editVessel",
          message: myJson.message,
        })
      );
    }
  };
}
export function deleteVessel(id) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteVessel" }));
    let myJson = await FETCH("DELETE", Constant.apiURl + "/vessel/" + id, {
      userId: getCookie("userId"),
      vesselCount: getCookie("vesselCount"),
    });
    if (myJson && myJson.code === 201) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deleteVessel",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deleteVessel",
          message: myJson.message,
        })
      );
    }
  };
}

export function vesselListingOffsetLimit(offset, limit, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendVesselList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "vesselListing" }));
      dispatch(GetVesselOffsetLimitAction([], 0));
    }
    let url = "";
    if (getCookie("userType") === "customer") {
      url = `${Constant.apiURl}/vessel/${offset}/${limit}`;
      const userId = getCookie("userId");
      if (filterQuery) {
        url += filterQuery;
        url += `userId=${userId}`;
      } else {
        url += `?userId=${userId}`;
      }
    } else {
      url = `${Constant.apiURl}/vessel/${offset}/${limit}`;
      if (filterQuery) {
        url += filterQuery;
      }
    }

    const myJson = await FETCH("GET", url);

    if (myJson && myJson.code === 200) {
      if (offset !== 0) {
        dispatch(AppendVessalAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendVesselList",
            message: "",
          })
        );
      } else {
        dispatch(GetVesselOffsetLimitAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "vesselListing",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendVesselList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "vesselListing",
            message: myJson.message,
          })
        );
      }
    }
  };
}