import React, { useState } from "react";
import { FETCH } from "../../../Services/fetch";
import { Constant } from "./../../../Constants/constant";
import Swal from "sweetalert2";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";

const UploadPorts = (props) => {
    const [state, setState] = useState({
        selectedFile: null,
        isLoading: false,
        navbarOptions: [
            {
                img: "left-arrow.svg",
                className: "orange",
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack(),
            },
        ],
    });

    const onChangeHandler = (event) => {
        if (event.target !== undefined) {
            setState({
                ...state,
                selectedFile: event.target.files[0],
            });
        }
    };

    const uploadExcelFile = async (event) => {
        event.preventDefault();
        const { selectedFile } = state;
        if (selectedFile) {
            const filename = selectedFile.name;
            const allowedFileTypes = ["xlsx", "xls"];
            const [extension] = filename.split(".").slice(-1);
            if (!extension || !allowedFileTypes.includes(extension)) {
                Swal.fire("Error!", "Invalid File type.", "error");
                return;
            }
            setState({
                ...state,
                isLoading: true,
            });
            const formData = new FormData();
            formData.append("file", selectedFile);
            const url = `${Constant.apiURl}/port/upload`;
            const myJson = await FETCH("POST", url, formData, true);
            let success = false;
            if (myJson && myJson.code === 201) {
                const msg = "Port(s) have been uploaded successfully!";
                Swal.fire("Success", msg, "success");
                success = true;
            } else {
                Swal.fire("Error!", myJson.message, "error");
            }
            document.getElementById("uploadPorts").value = null;
            setState({
                ...state,
                selectedFile: null,
                isLoading: false,
            });
            if (success) {
                setTimeout(() => {
                    props.history.push("/port-listing");
                }, 500);
            }
        }
    };

    return (
        <DashboardWrapper
            title="Upload Ports"
            navbarOptions={state.navbarOptions}
            className="mesh-board-wrap d-flex align-items-end"
        >
            <div className="formBox mt-4">
                <form className="needs-validation">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group form-input-container">
                                <input
                                    type="file"
                                    name="file"
                                    id="uploadPorts"
                                    accept=".xlsx, .xls"
                                    className="form-control"
                                    onChange={onChangeHandler}
                                />
                                <label
                                    htmlFor="uploadPorts"
                                    className="d-flex align-items-center"
                                >
                                    <span className="text-truncate">
                                        {state.selectedFile
                                            ? state.selectedFile.name
                                            : "Upload File"}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        {state.isLoading ? (
                            <div className="loader-img text-center">
                                <img
                                    style={{ width: "46px" }}
                                    src={require("../../../assets/images/Spinner-1s-200px.gif")}
                                    alt=""
                                />
                            </div>
                        ) : (
                            ""
                        )}

                        <div className="form-upload-btn d-flex flex-fill justify-content-end mt-3 mt-md-0">
                            <button
                                type="submit"
                                className="btn btn themeReguler-btn bg-sky-blue mr-0"
                                onClick={uploadExcelFile}
                                disabled={state.isLoading}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </DashboardWrapper>
    );
};

export default UploadPorts;
