import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./dashboard-style.css";
import { orderListing } from "../ApiCalls/orderListing";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import {
  ApiClearAction,
  ClearApiByNameAction,
} from "../../ApiCallStatus/Actions/action";
import { getCookie } from "../../../Services/cookies";
import { capitalizeFirstLetter } from "../../../Services/common";
import Swal from "sweetalert2";
import { DashboardWrapper } from "./dashboarWrapper";
function VesselOverview(props) {
  const [state, setState] = useState({
    orderList: [],
    startDate: null,
    endDate: null,
    filterQuery: "",
    activePage: 0,
    totalCount: 0,
    isHitApi: false,
  });
  const query = new URLSearchParams(props.location.search);
  const orderType = query.get("orderType");
  useEffect(() => {
    if (!state.isHitApi) {
      let data = { ...state };
      let filterQuery = "";
      if (orderType === "completed" && getCookie("userType") === "user") {
        filterQuery = "?orderType=completed";
      }
      if (getCookie("userType") === "user") {
        props.orderListing(0, filterQuery);
      } else {
        let userType =
          getCookie("userType") === "customer" ? "userId" : "supplierId";
        filterQuery = `?${userType}=${getCookie("userId")}`;
        props.orderListing(0, filterQuery);
      }
      data.isHitApi = true;
      data.filterQuery = filterQuery;
      setState(data);
    }
    if (
      (props.apiCallStatus.apiCallFor === "getOrder" ||
        props.apiCallStatus.apiCallFor === "appendOrderList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        orderList: props.dashboardReducer.orderListing,
        totalCount: props.dashboardReducer.totalOrder,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
  }, [props, state.isHitApi]);
  // const applyFilters = () => {
  //     setState({
  //         ...state,
  //         isHitApi: false
  //     })
  // }
  // const clearFilters = () => {
  //     setState({
  //         ...state,
  //         startDate: new Date(new Date().getTime() - (60 * 60 * 24 * 30 * 1000)),
  //         endDate: new Date(),
  //         isHitApi: false
  //     })
  // }
  const fetchMoreData = () => {
    let { activePage, filterQuery } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    props.orderListing(activePage, filterQuery);
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
  ];
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
    });
  };
  const applyFilters = () => {
    if (state.startDate !== null && state.endDate !== null) {
      let data = { ...state };
      let from = moment(data.startDate).format("YYYY-MM-DD");
      let to = moment(data.endDate).add(1, "days").format("YYYY-MM-DD");
      let filterQuery = `?from=${from}&to=${to}`;
      if (orderType === "completed" && getCookie("userType") === "user") {
        filterQuery += "&orderType=completed";
      }
      if (getCookie("userType") === "user") {
        props.orderListing(0, filterQuery);
      } else {
        let userType =
          getCookie("userType") === "customer" ? "userId" : "supplierId";
        filterQuery += `&${userType}=${getCookie("userId")}`;
        props.orderListing(0, filterQuery);
      }
      data.isHitApi = true;
      data.filterQuery = filterQuery;
      data.activePage = 0;
      setState(data);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select both date.",
      });
    }
  };
  const clearFilters = () => {
    let data = { ...state };
    let filterQuery = "";
    if (orderType === "completed" && getCookie("userType") === "user") {
      filterQuery += "?orderType=completed";
    }
    if (getCookie("userType") === "user") {
      props.orderListing(0, filterQuery);
    } else {
      let userType =
        getCookie("userType") === "customer" ? "userId" : "supplierId";
      filterQuery += `?${userType}=${getCookie("userId")}`;
      props.orderListing(0, filterQuery);
    }
    data.isHitApi = true;
    data.filterQuery = filterQuery;
    data.startDate = null;
    data.endDate = null;
    data.activePage = 0;
    setState(data);
  };
  return (
    <DashboardWrapper
      title="Orders"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      {/* Filter  Area*/}
      <div className="ordCatg-table-wrap mt-3 system-cat-filter">
        <div className="card-bx-sty">
          <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
            <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0">
              <div className="custom-select-menu mr-2 p-0">
                <div className="date-range-picker d-flex align-items-center">
                  <span className="d-inline-block ml-0">
                    <DatePicker
                      selected={state.startDate}
                      onChange={(val) => handleChange("startDate", val)}
                      className="link-style"
                    />
                  </span>
                  to
                  <span className="d-inline-block mr-0">
                    <DatePicker
                      selected={state.endDate}
                      maxDate={new Date()}
                      disabled={state.startDate === null ? true : false}
                      minDate={
                        state.startDate !== null
                          ? new Date(moment(state.startDate).add(1, "days"))
                          : new Date(moment().add(1, "days"))
                      }
                      onChange={(val) => handleChange("endDate", val)}
                      className="link-style"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
              <button
                type="button"
                className="btn btn-yellow"
                onClick={() => applyFilters()}
              >
                Search
              </button>
              <button
                type="button"
                className="btn btn-yellow ml-1"
                onClick={() => clearFilters()}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-lg-5 d-flex flex-column flex-lg-row">
        <div className="dashVesl-overview-wrap">
          <div className=" dash-content-bx">
            <div className="catalouge-details-wrap p-0">
              <InfiniteScroll
                dataLength={state.orderList.length}
                next={fetchMoreData}
                height={"80vh"}
                hasMore={
                  state.totalCount <= state.orderList.length ? false : true
                }
                loader={
                  <div
                    colSpan="12"
                    className="loadmore d-flex align-items-center justify-content-center"
                  >
                    {" "}
                    <img src={loader} alt="" />
                  </div>
                }
              >
                <table className="table theme-table-wrap2 short-table sticky-th-border">
                  <thead>
                    <tr>
                      <th>Order Id</th>
                      <th>Order Type</th>
                      <th>Status</th>
                      <th>Price</th>
                      <th>Currency</th>
                      <th>Date</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.apiCallStatus.isStarted.indexOf("getOrder") !==
                    -1 ? (
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img
                            style={{ width: "46px" }}
                            src={require("../../../assets/images/Spinner-1s-200px.gif")}
                            alt=""
                          />
                        </div>
                      </td>
                    ) : state.orderList && state.orderList.length > 0 ? (
                      state.orderList.map((item, index) => {
                        return (
                          <tr key={"ol3-" + index}>
                            <td>{item._id}</td>
                            <td>
                              {capitalizeFirstLetter(item.orderProvisionType)}
                            </td>
                            <td>
                              {item.status === "inprogress" ? (
                                <>
                                  <span className="sta-indicator pend"></span>{" "}
                                  <span className="sta-text-wrap text-truncate"></span>
                                </>
                              ) : item.status === "confirmed" ? (
                                <>
                                  <span className="sta-indicator appr"></span>{" "}
                                  <span className="sta-text-wrap text-truncate"></span>
                                </>
                              ) : item.status === "pastorder" ? (
                                <>
                                  <span className="sta-indicator past"></span>{" "}
                                  <span className="sta-text-wrap text-truncate"></span>
                                </>
                              ) : (
                                item.status
                              )}{" "}
                              {capitalizeFirstLetter(item.status)}
                            </td>
                            {/* <td>{item.orderRequested}</td> */}
                            <td>
                              {item.totalPrice
                                ? parseFloat(item.totalPrice.toFixed(2))
                                : "0"}
                            </td>
                            <td>{item.currency}</td>
                            <td>
                              {moment(item.createdAt).format("Do MMM YYYY")}
                            </td>
                            <td>
                              <Link
                                className="viewDetailLink d-block w-100 align-self-lg-end text-left text-sm mb-2"
                                to={`/order-detail-description/${item._id}/${item.supplierId}`}
                              >
                                View Details
                              </Link>
                            </td>
                          </tr>
                        );
                      })
                    ) : state.orderList && state.orderList.length === 0 ? (
                      <td colSpan="40" className="text-center">
                        <div>No Data Found</div>
                      </td>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  dashboardReducer: state.dashboardReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  orderListing: (offset, filterQuery) =>
    dispatch(orderListing(offset, filterQuery)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
});
export default connect(mapStateToProps, mapDispatchToProps)(VesselOverview);
