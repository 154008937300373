import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { CsvDownload } from "../../../Common/Components/CsvDownload";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { getRfqList } from "../../../Orders/ApiCalls/supplier";
import { sendQuotation } from "../../../Quotations/Apicalls/quotations";

const headers = [
  { label: "ItemCode", key: "itemCode" },
  { label: "IMPA", key: "alternativeCode" },
  { label: "Description", key: "description" },
  { label: "Explanation", key: "explanation" },
  { label: "Department", key: "department" },
  { label: "Availability", key: "availability" },
  { label: "Quantity", key: "order" },
  { label: "Unit", key: "standardPackagingUnit" },
  { label: "Price Per Unit", key: "price" },
  { label: "Total Price", key: "totalPrice" },
  { label: "Remarks", key: "remark" },
];
var csvLinkRef = React.createRef();
function OrderDetail(props) {
  const [state, setState] = useState({
    isHitApi: false,
    price: 0,
    orderDetail: {},
    orderId: "",
    orderType: "",
    quotationDetailList: [],
    csvData: [],
    toBeSupplied: false,
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [TotalNetPrice, setTotalNetPrice] = useState(0);
  useEffect(() => {
    // ReactTooltip.rebuild()
    if (!state.isHitApi) {
      const urlParams = new URLSearchParams(window.location.search);
      const orderId = urlParams.get("orderId");
      const orderType = urlParams.get("orderType");
      const supplierId = urlParams.get("supplierId");
      const toBeSupplied = urlParams.get("toBeSupplied");
      const quotationId = props.match.params.id;
      props.getRfqList(orderId, supplierId, quotationId, "order");
      let data = { ...state };
      data.isHitApi = true;
      data.orderId = orderId;
      data.orderType = orderType;
      data.toBeSupplied = toBeSupplied;
      setState(data);
    }
    if (
      props.apiCallStatus.apiCallFor === "getRfqList" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let quotationDetailList = props.rfqList;
      // let quotationDetailList = []
      // if (state.orderType === "store") {
      //     quotationDetailList = props.rfqList.reduce((r, a) => {
      //         r[a.department] = [...r[a.department] || [], a];
      //         return r;
      //     }, {});
      // } else {
      //     quotationDetailList = props.rfqList;
      // }
      setState({
        ...state,
        quotationDetailList,
        isHitApi: true,
      });
      props.ApiClearAction();
      getTotalPrice(state.orderType, quotationDetailList);
    }
    if (
      props.apiCallStatus.apiCallFor === "sendQuotation" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      // Swal.fire('Success', "Order send succesfully", 'success');
      Swal.fire(
        "Success",
        "Your order confirmation has been uploaded successfully",
        "success"
      );
      props.history.push("/open-orders");
      props.ApiClearAction();
    }
    if (
      props.apiCallStatus.apiCallFor === "sendQuotation" &&
      props.apiCallStatus.isCompleted &&
      props.apiCallStatus.isFailed
    ) {
      Swal.fire(
        "Error",
        props.apiCallStatus.message &&
          typeof props.apiCallStatus.message === "string"
          ? props.apiCallStatus.message
          : "Error in saving quotation",
        "error"
      );
      props.ApiClearAction();
    }
  });

  const sendQuotation = () => {
    if (
      Object.size(state.quotationDetailList) > 0 ||
      state.quotationDetailList.length > 0
    ) {
      props.sendQuotation({
        items: state.quotationDetailList,
        quotationId: props.match.params.id,
        orderId: state.orderId,
        vesselName: props.orderDetail.vesselName,
        placeOfDelivery: props.orderDetail.placeOfDelivery.label,
        arrivalTime: moment(props.orderDetail.arrivalTime).format("YYYY-MM-DD"),
        supplierName: props.orderDetail.supplier.name,
        totalPrice,
      });
    }
  };
  const getTotalPrice = (type, data) => {
    let totalPrice = 0;
    let totalnetprices = 0;
    data.map((item) => {
      let order = item?.quantity?.[0]?.order || 0;
      let price = item?.qtdPrice || 0;

      // let meshPriceunit = 100 * price / 97.5
      // let newTotalPrice = order * meshPriceunit
      let newTotalPrice = order * price;

      // totalPrice = totalPrice + Number((parseFloat(order * price * 100 / 97.5)).toFixed(2))
      // totalnetprices = totalnetprices + Number((parseFloat(price)).toFixed(2))
      totalPrice = totalPrice + price;
      totalnetprices = totalnetprices + newTotalPrice;
    });
    // if (type === "store") {
    //     if (Object.size(data) > 0) {
    //         Object.keys(data).map((key, i) => {
    //             return data[key].map((item, index) => {
    //                 // let price = item.pricePerCountry && item.pricePerCountry.baseQuantity ? item.pricePerCountry.baseQuantity : 0;
    //                 let price = item?.qtdPricePackagingUnit || 0;
    //                 // let order = item.quantity.length > 0 && item.quantity[0] && item.quantity[0].order ? item.quantity[0].order : 0;
    //                 let order = item?.order || 0;
    //                 totalPrice = totalPrice + Number((parseFloat(order * price)).toFixed(2))
    //             })
    //         })
    //     }
    // } else {
    //     data.map((item, index) => {
    //         // let price = item?.quantity?.length > 0 && item?.quantity[0] && item?.pricePerCountry?.price ? item?.pricePerCountry?.price : 0;
    //         let price = item?.qtdPricePackagingUnit || 0;
    //         let order = item?.quantity?.length > 0 && item?.quantity[0] && item?.quantity[0]?.order ? item?.quantity[0].order : 0;
    //         totalPrice = totalPrice + Number((parseFloat(order * price)).toFixed(2))
    //     })
    // }
    setTotalPrice(totalPrice);
    setTotalNetPrice(totalnetprices);
  };
  const getNavbarOptions = () => {
    let options = [
      {
        img: "left-arrow.svg",
        className: "orange",
        name: "Go back",
        path: "/",
        isLogout: false,
        isCustom: true,
        onClick: () => props.history.goBack(),
      },
    ];
    if (props.userType === "supplier" && props.orderStatus !== "confirmed") {
      let classType = "btn-disable";
      if (
        Object.size(state.quotationDetailList) > 0 ||
        state.quotationDetailList.length > 0
      ) {
        classType = "";
      }
      options.push({
        img: "Edit-icon.svg",
        className: "dark-green " + classType,
        name: "Confirm<br />Order",
        path: `/`,
        isLogout: false,
        isCustom: true,
        onClick: () => sendQuotation(),
      });
    }
    return options;
  };
  const handleTableChange = (value, name, index, key) => {
    let quotationDetailList = cloneDeep(state.quotationDetailList);
    if (key) {
      let newArry = quotationDetailList;

      if (name === "remark") {
        if (newArry[index]["quantity"][0]) {
          newArry[index]["quantity"][0][name] = value;
        } else {
          let quantity = {
            remark: value,
            pricePerCountry: {
              price: 0,
            },
          };
          newArry[index]["quantity"].push(quantity);
        }
      } else if (name === "order") {
        newArry[index]["quantity"][0][name] = value;
      } else if (name === "baseQuantity") {
        newArry[index][name] = value;
        newArry[index]["baseQuantityAvg"] = value;
      } else {
        newArry[index][name] = value;
      }
      quotationDetailList = newArry;
    } else {
      if (name === "remark") {
        if (quotationDetailList[index]["quantity"][0]) {
          quotationDetailList[index]["quantity"][0][name] = value;
          quotationDetailList[index][name] = value;
        } else {
          let quantity = {
            remark: value,
            pricePerCountry: {
              price: 0,
            },
          };
          quotationDetailList[index]["quantity"].push(quantity);
        }
      } else if (name === "baseQuantity") {
        quotationDetailList[index][name] = value;
        quotationDetailList[index]["baseQuantityAvg"] = value;
        quotationDetailList[index]["baseQuantity"] = value;
      } else if (name === "order") {
        quotationDetailList[index]["quantity"][0][name] = value;
        quotationDetailList[index][name] = value;
      } else {
        quotationDetailList[index][name] = value;
      }
    }
    setState({
      ...state,
      quotationDetailList,
    });
    getTotalPrice(key ? "store" : "provision", quotationDetailList);
  };
  const apiCallForCSVData = () => {
    let csvData = [];
    // if (state.orderType === "store") {
    //   if (Object.size(state.quotationDetailList) > 0) {
    //     Object.keys(state.quotationDetailList).map((key, i) => {
    //       return state.quotationDetailList[key].map((item, index) => {
    //         let price =
    //           item?.quantity[0] && item?.pricePerCountry?.price
    //             ? item?.pricePerCountry?.price
    //             : 0;
    //         let order =
    //           item?.quantity[0] && item?.pricePerCountry?.baseQuantity
    //             ? item?.pricePerCountry?.baseQuantity
    //             : 0;
    //         let remark =
    //           item?.quantity[0] && item?.quantity[0]?.remark
    //             ? item?.quantity[0]?.remark
    //             : "";
    //         let csvObj = {
    //           itemCode: item.itemCode,
    //           alternativeCode: item.alternativeCode,
    //           description: item.description,
    //           department: item.department,
    //           availability: item.availability,
    //           order: order,
    //           standardPackagingUnit: item.standardPackagingUnit,
    //           price: price !== 0 ? parseFloat(price).toFixed(2) : 0,
    //           totalPrice: parseFloat(order * price).toFixed(2),
    //           remark: remark,
    //         };
    //         csvData.push(csvObj);
    //       });
    //     });
    //   }
    // } else {
    //   state.quotationDetailList.map((item, index) => {
    //     let price =
    //       item?.quantity[0] && item?.pricePerCountry?.price
    //         ? item?.pricePerCountry?.price
    //         : 0;
    //     let order =
    //       item?.quantity[0] && item?.pricePerCountry?.baseQuantity
    //         ? item?.pricePerCountry?.baseQuantity
    //         : 0;
    //     let remark =
    //       item?.quantity[0] && item?.quantity[0]?.remark
    //         ? item?.quantity[0]?.remark
    //         : "";
    //     let csvObj = {
    //       itemCode: item.itemCode,
    //       alternativeCode: item.alternativeCode,
    //       description: item.description,
    //       department: item.department,
    //       availability: item.availability,
    //       order: order,
    //       standardPackagingUnit: item.standardPackagingUnit,
    //       price: price !== 0 ? parseFloat(price).toFixed(2) : 0,
    //       totalPrice: parseFloat(order * price).toFixed(2),
    //       remark: remark,
    //     };
    //     csvData.push(csvObj);
    //   });
    // }
    state.quotationDetailList.map((item, index) => {
      const { qtdPrice, order, remark } = item;

      let csvObj = {
        itemCode: item.itemCode,
        alternativeCode: item.alternativeCode,
        description: item.description,
        explanation: item.explanation,
        department: item.department,
        availability: item.availability,
        order,
        standardPackagingUnit: item.standardPackagingUnit,
        price: qtdPrice ? parseFloat(qtdPrice).toFixed(2) : 0,
        totalPrice: parseFloat(order * qtdPrice).toFixed(2),
        remark,
      };
      csvData.push(csvObj);
    });
    if (csvData.length > 0) {
      setState({
        ...state,
        csvData,
      });
    }
    setTimeout(() => {
      csvLinkRef.current.link.click();
    }, 1000);
  };
  return (
    <React.Fragment>
      <DashboardWrapper
        title="Order Detail"
        navbarOptions={getNavbarOptions()}
        className="mesh-board-wrap d-flex align-items-end"
      >
        <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
          {/* RFQ Detials Section */}
          <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap d-flex align-items-start next-oder mb-2">
            <div className="rfq-durations rfq-details-items  descriptrfq">
              <h2 className="mt-2 font-weight-bold">Vessel Name</h2>
              <div className="inner-content text-left">
                <h4 className="mt-0 font-weight-bold">
                  Del. Place:{" "}
                  <span className="font-weight-bold mt-1 d-inline-block"></span>
                </h4>
                <h4 className="mt-0 font-weight-bold">
                  Del. date:{" "}
                  <span className="font-weight-bold mt-1 d-inline-block"></span>
                </h4>
                <h4 className="mt-0 font-weight-bold">
                  Agent Detail:{" "}
                  <span className="font-weight-bold mt-1 d-inline-block"></span>
                </h4>
              </div>
            </div>
            {Object.size(props.orderDetail) > 0 ? (
              <div className="rfq-rating rfq-details-items">
                <h2 className="mt-2">
                  {props.orderDetail ? props.orderDetail.vesselName : ""}
                </h2>
                <h2 className="mt-2">
                  {props.orderDetail && props.orderDetail.placeOfDelivery
                    ? props.orderDetail.placeOfDelivery.label
                    : ""}
                </h2>
                <h2 className="mt-2">
                  {props.orderDetail
                    ? moment(props.orderDetail.arrivalTime).format("YYYY-MM-DD")
                    : ""}
                </h2>
                <h2 className="mt-2">
                  {props.orderDetail ? props.orderDetail.agentDetail : ""}
                </h2>
              </div>
            ) : (
              ""
            )}
            <div className="rfq-durations rfq-details-items  descriptrfq ml-5">
              <div className="inner-content text-left">
                <h4 className="mt-0 font-weight-bold"></h4>
                <h4 className="mt-0 font-weight-bold">Supplier Name: </h4>
                <h4 className="mt-0 font-weight-bold">Supplier Email: </h4>
                <h4 className="mt-0 font-weight-bold">Supplier Website: </h4>
                {/* <h4 className="mt-0 font-weight-bold">Net Price: </h4> */}
                <h4 className="mt-0 font-weight-bold">
                  Grand Total:
                  <p className="text-left order-detail-p">
                    (include mesh price)
                  </p>
                </h4>
              </div>
            </div>
            {props.orderDetail && props.orderDetail.supplier ? (
              <div className="rfq-rating rfq-details-items">
                <h2 className="mt-2"></h2>
                <h2 className="mt-2">{props.orderDetail.supplier.name}</h2>
                <h2 className="mt-2">{props.orderDetail.supplier.email}</h2>
                <h2 className="mt-2">
                  {props.orderDetail.supplier.websiteUrl}
                </h2>
                {/* <h2 className="mt-2">{parseFloat(totalPrice).toFixed(2)} {props.orderDetail && props.orderDetail.vesselCurrency ? props.orderDetail.vesselCurrency : ''}</h2> */}
                <h2 className="mt-2">
                  {TotalNetPrice.toFixed(2)}{" "}
                  {props.orderDetail && props.orderDetail.vesselCurrency
                    ? props.orderDetail.vesselCurrency
                    : ""}
                </h2>
                {/* <h2 className="mt-2">{totalPrice.toFixed(2)} {props.orderDetail && props.orderDetail.vesselCurrency ? props.orderDetail.vesselCurrency : ''}</h2> */}
              </div>
            ) : (
              ""
            )}
            <div className="contact-info rfq-details-items flex-fill d-flex flex-column align-items-center mt-2">
              {/* <div> */}
              {/* {props.orderDetail && props.orderDetail.supplier ?
                                <ul className="list-inline p-0 m-0">
                                    <li><span className="font-weight-bold">Supplier Name:</span> {props.orderDetail.supplier.name}</li>
                                    <li><span className="font-weight-bold">Supplier Email:</span>{props.orderDetail.supplier.email}</li>
                                    <li><span className="font-weight-bold">Supplier Website:</span>{props.orderDetail.supplier.websiteUrl}</li>
                                </ul> : ""} */}
              {/* </div> */}
            </div>
          </div>
          {/* RFQ Detials Section End */}
          <div className="ordCatg-table-wrap my-3 system-cat-filter">
            <div className="card-bx-sty">
              <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                  <CsvDownload
                    data={state.csvData}
                    csvLinkRef={csvLinkRef}
                    filename={"orderDetail.csv"}
                    headers={headers}
                    apiCallForCSVData={() => apiCallForCSVData()}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Table */}
          <div className="ordCatg-InnTable store-catgTable">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-left  text-nowrap">Sr. No.</th>
                    <th className="text-left  text-nowrap">ItemCode</th>
                    <th className="text-left  text-nowrap">IMPA</th>
                    {/* {state.orderType === "store" ? <th className="text-left  text-nowrap">Department</th> : ''} */}
                    <th className="text-left  text-nowrap">Department</th>
                    <th className="text-left  text-nowrap">Description</th>
                    <th className="text-left  text-nowrap">Explanation</th>
                    <th className="text-left  text-nowrap">Availability</th>
                    <th className="text-left  text-nowrap">Quantity</th>
                    <th className="text-left  text-nowrap">Unit</th>
                    <th className="text-left  text-nowrap">Packaging Size</th>
                    <th className="text-left  text-nowrap">Price Per Unit</th>

                    {/* <th className="text-left  text-nowrap">Mesh Price</th> */}

                    <th className="text-left  text-nowrap">Total Price</th>

                    <th className="text-left  text-nowrap">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {props.apiCallStatus.isStarted.indexOf("getRfqList") !==
                    -1 ? (
                    <tr>
                      <td colSpan="20">
                        <div className="loader-img text-center">
                          <img style={{ width: "46px" }} src={loader} alt="" />
                        </div>
                      </td>
                    </tr>
                  ) : state.quotationDetailList &&
                    state.quotationDetailList.length !== 0 ? (
                    <ShowOrderDetails
                      data={state.quotationDetailList.sort(
                        (a, b) => a.alternativeCode - b.alternativeCode
                      )}
                      userType={props.userType}
                      orderStatus={props.orderStatus}
                      orderDetail={props.orderDetail}
                      handleTableChange={handleTableChange}
                      toBeSupplied={state.toBeSupplied}
                    />
                  ) : state.quotationDetailList &&
                    state.quotationDetailList.length === 0 ? (
                    <tr>
                      <td colSpan="40" className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </React.Fragment>
  );
}

const ShowOrderDetails = ({
  data,
  userType,
  orderStatus,
  orderDetail,
  handleTableChange,
  toBeSupplied,
}) => {
  return (
    <>
      {React.Children.toArray(
        data.map((item, index) => {
          const order = item?.quantity?.[0]?.order || 0;
          const baseQuantity = item?.baseQuantity || 0;
          const pricePerUnit = item?.qtdPrice || "0.00";
          const standardPackagingUnit = item?.standardPackagingUnit || "";
          const remark = item?.remark || "";
          const department = item?.department || "";
          // const meshPriceunit = pricePerUnit * 100 / 97.5 || 0.00;
          // let meshPriceunit = 100 * pricePerUnit / 97.5
          let newTotalPrice = order * pricePerUnit;
          return (
            <tr>
              <td>
                <span className="text-center td-wrap-sty">{index + 1}</span>
              </td>
              <td>
                <span className="text-center td-wrap-sty">{item.itemCode}</span>
              </td>
              <td>
                <span className="td-wrap-sty text-center">
                  {item.alternativeCode}
                </span>
              </td>
              <td>
                <span className="text-left td-wrap-sty">{department}</span>
              </td>
              <td>
                <span className="text-left td-wrap-sty">
                  {item.description}
                </span>
              </td>
              <td>
                <span className="text-left td-wrap-sty mw-15">
                  {item.explanation}
                </span>
              </td>
              <td>
                <span className="text-left td-wrap-sty">
                  {item.availability}
                </span>
              </td>
              <td>
                {userType === "supplier" && orderStatus !== "confirmed" ? (
                  <input
                    className="whi-round text-right"
                    type="number"
                    value={order}
                    id={`order${index}`}
                    onChange={(e) =>
                      handleTableChange(e.target.value, "order", index, null)
                    }
                  />
                ) : (
                  <span className="text-center td-wrap-sty">{order}</span>
                )}
              </td>
              <td>
                <span className="td-wrap-sty text-center">
                  {standardPackagingUnit}
                </span>
              </td>

              {/* <td>
                {userType === "customer" ? (
                  <span className="text-center td-wrap-sty">
                    {baseQuantity}
                  </span>
                ) : (
                  <input
                    className="whi-round text-right"
                    type="number"
                    value={baseQuantity}
                    id={`baseQuantity${index}`}
                    onChange={(e) =>
                      handleTableChange(
                        e.target.value,
                        "baseQuantity",
                        index,
                        null
                      )
                    }
                  />
                )}
              </td> */}

              {/*
              The above code puts the packaging size field editable to non-customer users. But supplier also should not be able to edit it.
              */}
              <td>
                <span className="text-center td-wrap-sty">
                  {baseQuantity}
                </span>
              </td>

              <td>
                <span className="td-wrap-sty text-right">
                  {parseFloat(pricePerUnit).toFixed(2)}{" "}
                  {orderDetail && orderDetail.vesselCurrency
                    ? orderDetail.vesselCurrency
                    : ""}
                </span>
              </td>

              {/* <td><span className="text-left td-wrap-sty">{parseFloat(meshPriceunit).toFixed(2)}</span></td> */}

              <td>
                <span className="td-wrap-sty text-right">
                  {parseFloat(newTotalPrice).toFixed(2)}{" "}
                  {orderDetail && orderDetail.vesselCurrency
                    ? orderDetail.vesselCurrency
                    : ""}
                </span>
              </td>

              <td>
                {userType === "supplier" && ((orderStatus !== "confirmed") || (orderStatus === "confirmed" && toBeSupplied)) ? (
                  <input
                    className="whi-round text-right"
                    type="text"
                    value={remark}
                    id={`remark${index}`}
                    onChange={(e) =>
                      handleTableChange(e.target.value, "remark", index, null)
                    }
                  />
                ) : (
                  <span
                    data-tip={remark}
                    data-effect="float"
                    className="td-wrap-sty text-right"
                  >
                    <ReactTooltip />
                    {remark ? remark : "\u00A0"}
                  </span>
                )}
              </td>
            </tr>
          );
        })
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  quotationReducer: state.quotationReducer,
  rfqList: state.orderReducer.rfqList,
  orderStatus: state.orderReducer.orderStatus,
  orderDetail: state.orderReducer.orderDetail,
  userId: state.authReducer.userId,
  userType: state.authReducer.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendQuotation: (data) => dispatch(sendQuotation(data)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  getRfqList: (orderId, supplierId, quotationId, type) =>
    dispatch(getRfqList(orderId, supplierId, quotationId, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
