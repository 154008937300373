import { getCookie } from "../Services/cookies";
var roles = getCookie("roles");
roles = roles !== "" ? JSON.parse(roles) : [];
let permission = getCookie("permission");
permission = permission !== "" ? JSON.parse(permission) : {};
var StoreState = {
  auth: {
    activeVesselId: getCookie("activeVesselId"),
    isLogin: getCookie("token") === "" ? false : true,
    token: getCookie("token"),
    isVerified: getCookie("isVerified") === "true" ? true : false,
    isShipManager: getCookie("isShipManager") === "true" ? true : false,
    userType: getCookie("userType"),
    userId: getCookie("userId"),
    logo: getCookie("logo"),
    isFirstTimeLogin: getCookie("isFirstTimeLogin"),
    roles,
    permission,
    isSubAdmin: getCookie("isSubAdmin") === "true" ? true : false,
    roleName: getCookie("roleName"),
    subType: getCookie("subType"),
  },
  apiCall: {
    apiCallFor: "",
    statusCode: "",
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: [],
  },
  dashboard: {
    orderListing: [],
    totalOrder: 0,
    cartListing: [],
    cartCsvListing: [],
    totalCart: 0,
    fleetListing: [],
    totalFleet: 0,
  },
  common: {
    countryList: [],
    crewList: [],
    rotationList: [],
    currencyCodeList: [],
    topSupplierList: [],
    toCustomerList: [],
    topVesselList: [],
    topSupplyHubList: [],
    portList: [],
    departmentList: [],
    captainList: [],
    cookList: [],
    roleList: [],
    vesselList: [],
    vesselDropdown: [],
    vesselKindList: [],
    vesselWiseStats: [],
    vesselWiseStatList: [],
    customerList: [],
    customerEamils: [],
    totalEmails: 0,
    totalCustomer: 20,
    vesselById: {},
    currentUserVessel: [],
    orderCount: 0,
    userOrderId: ''
  },
  port: {
    list: [],
    count: 0,
    detail: {},
  },
  reminder: {
    list: [],
    count: 0,
    detail: {},
  },
  orderBasket: {
    list: [],
    provisionBasketId: "",
    storeBasketId: "",
    provisionSupplier: {},
    storeSupplier: {},
    ignoreDepart: [],
    storeArrivalTime: "",
    provisionArrivalTime: "",
    provisionAdditionalBasketId: "",
    provisionAdditionalArrivalTime: "",
    provisionAdditionalSupplier: {},
    storeAdditionalBasketId: "",
    storeAdditionalArrivalTime: "",
    storeAdditionalSupplier: {},
  },
  role: {
    list: [],
    count: 0,
    detail: {},
    subAdminList: [],
    allRoles: [],
    subAdminCount: 0,
    subAdminDetail: {},
  },
  department: {
    list: [],
    count: 0,
    detail: {},
  },
  country: {
    list: [],
    count: 0,
    detail: {},
  },
  vessel: {
    list: [],
    detail: {},
    questionaire: {},
    count: 0,
    vesselTypesDropdown: [],
  },
  captain: {
    list: [],
    count: 0,
    detail: {},
  },
  supplier: {
    list: [],
    detail: {},
    comparison: {},
    count: 0,
  },
  upload: {
    documentList: [],
    catalogueList: [],
    detail: {},
    totalCatalogue: 0,
  },
  package: {
    list: [],
    categoryList: [],
    count: 0,
    detail: {},
  },
  user: {
    list: [],
    count: 0,
    detail: {},
    roleAssignmentDetail: {},
  },
  order: {
    provisionList: [],
    orderStatus: "",
    orderDetail: {},
    totalProvision: 0,
    topItemsList: [],
    totalTopItems: 0,
    orderList: [],
    totalOrder: 0,
    currentOrder: {},
    categoryList: [],
    categoryCodeList: [],
    totalCategoryCode: 0,
    itemCodeList: [],
    descriptionList: [],
    totalDescription: 0,
    itemGroupList: [],
    totalItemCode: 0,
    supplierSelectionList: [],
    port: {},
    customerDetail: {},
    rfqList: [],
    documentList: [],
    nextOrderQty: {},
  },
  quotation: {
    quotationList: [],
    quotationOrderList: [],
    quotationDetailList: [],
    basketId: "",
    totalQuotation: 0,
    orderDetail: {},
    adminCatalogs: [],
  },
  socketDetail: {
    notificationsList: [],
    unReadMsgCount: 0,
    totalCount: 0,
  },
  company: {
    list: [],
    count: 0,
    detail: {},
    configuration: {
      logo: '',
      mainBackgroundImage: '',
      sidebarBackgroundColor: '',
    },
  },
};
export default StoreState;
