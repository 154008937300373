import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getPageTitle } from "../../../Services/common";
import { getCompanyByDomain } from "../../Common/ApiCalls/company";
import { Wrapper } from '../Css/authStyle.jsx';

const AuthWrapper = (props) => {
    const [domainFetched, setDomainFetched] = useState(false);
    const [logo, setLogo] = useState('');
    const [bgImage, setBgImage] = useState('');

    useEffect(() => {
        document.title = getPageTitle();

        if (!domainFetched) {
            props.getCompanyByDomain(window.location.host);
            setDomainFetched(true);
        }

        const { configuration } = props.companyReducer || {};

        if (configuration && Object.keys(configuration).length > 0) {
            const { logo, mainBackgroundImage } = configuration;
            setLogo(logo);
            setBgImage(mainBackgroundImage);
        }
    }, [domainFetched, props.companyReducer]);

    return (
        <Wrapper style={
            bgImage ? { background: `url(${bgImage})`, backgroundSize: 'cover' } : {}
        }>
            <div className="authBlockHolder d-flex h-100">
                {props.children}
            </div>
            <div className="authLogoholder">
                <img className="img-fluid" src={logo || require('../../../assets/images/logo.png')} alt="Logo" />
            </div>
        </Wrapper>
    );
};

const mapStateToProps = (state, ownProps) => ({
    companyReducer: state.companyReducer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    getCompanyByDomain: (domain) => dispatch(getCompanyByDomain(domain)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthWrapper);
