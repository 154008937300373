import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CsvDownload } from './CsvDownload';

const defaultState = {
    password: "",
    confirmPassword: "",
    message: "",
    messageType: "",
    messageFor: "",
    csvData: [],
}
var csvLinkPMeshRef = React.createRef();
var csvLinkCMeshRef = React.createRef();
var csvLinkAMeshRef = React.createRef();
const headersP = [
    { label: 'Alternative Code', key: 'alternativeCode' },
    { label: 'Rank', key: 'rank' },
    { label: 'Item Code2', key: 'itemCode2' },
    { label: 'Category', key: 'category' },
    { label: 'Durability', key: 'durability' },
    { label: 'Sub Category 1', key: 'subCategory' },
    { label: 'Sub Category 2', key: 'subCategory' },
    { label: 'Description', key: 'description' },
    { label: 'Explanation', key: 'explanation' },
    { label: 'Price Per Packaging Unit', key: 'pricePerPackagingUnit' },
    { label: 'Standard Packaging Unit', key: 'standardPackagingUnit' },
    { label: 'Base Quantity', key: 'baseQuantity' },
    { label: 'Base Unit', key: 'baseUnit' },
    { label: 'Price Per Base Unit', key: 'pricePerBaseUnit' },
    { label: 'Country Code', key: 'countryCode' },
    { label: 'Price AVG.', key: 'priceAvg' },
    { label: 'Packaging Unit AVG.', key: 'pacakageUnitAvg' },
    { label: 'Base Quantity AVG.', key: 'baseQuantityAvg' },
];
const headers = [
    { label: 'Item Code', key: 'itemCode' },
    { label: 'IMPA Code', key: 'alternativeCode' },
    { label: 'Category', key: 'category' },
    { label: 'Durability', key: 'durability' },
    { label: 'Sub Category 1', key: 'subCategory' },
    { label: 'Sub Category 2', key: 'subCategory' },
    { label: 'Description', key: 'description' },
    { label: 'Explanation', key: 'explanation' },
    { label: 'QTD Price/ Packaging Unit', key: 'qtdPricePackagingUnit' },
    { label: 'Packaging Unit', key: 'standardPackagingUnit' },
    { label: 'Packaging Unit Standard', key: 'standardPackagingUnit' },
    { label: 'Base Unit', key: 'baseUnit' },
    { label: 'Picture', key: '-' },
    { label: 'Price Per Base Unit', key: 'pricePerBaseUnit' },
    { label: 'Price Type', key: 'priceType' },
    { label: 'Remark (MAX 50 Digits)', key: 'remark' },
];

export function CatalogListModal(props) {
    const [state, setState] = useState(defaultState);
    const handleModalChange = () => {
        setState(defaultState);
        props.handleModalChange('', '');

    }
    const apiCallForCSVData = async (type) => {
        const catalogs = await props.getCatalogList(props.selectedUser, type)
        setState({
            ...state,
            csvData: catalogs.data,
        })
        if (type === 'P-MESH') {
            csvLinkPMeshRef.current.link.click()
        } else if (type === 'C-MESH') {
            csvLinkCMeshRef.current.link.click()
        } else {
            csvLinkAMeshRef.current.link.click()
        }
        props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    const isDisabled = props.apiCallStatus.apiCallFor === "catalogListing" && !props.apiCallStatus.isCompleted
    return (
        <Modal size="lg" show={props.showModal === "catalogList"} className="modal-centered" onHide={() => handleModalChange()}>
            <Modal.Header closeButton>
                <h5 className="welcome-inn-content m-0 p-0 text-left">Catalog Availability</h5>
            </Modal.Header>
            <Modal.Body>
                <div className='availability'>
                    {props.catalogs?.pMeshCount > 0 &&
                        <div className={isDisabled ? "disableCsvBtn ml-2" : "ml-2"}>
                            <CsvDownload btnName="P-MESH" data={state.csvData} csvLinkRef={csvLinkPMeshRef} filename={"CatalogP-Mesh.csv"} headers={headersP} apiCallForCSVData={() => apiCallForCSVData('P-MESH')} />
                        </div>
                    }
                    {props.catalogs?.cMeshCount > 0 &&
                        <div className={isDisabled ? "disableCsvBtn ml-2" : "ml-2"}>
                            <CsvDownload btnName="C-MESH" data={state.csvData} csvLinkRef={csvLinkCMeshRef} filename={"CatalogC-Mesh.csv"} headers={headers} apiCallForCSVData={() => apiCallForCSVData('C-MESH')} />
                        </div>
                    }
                    {props.catalogs?.aMeshCount > 0 &&
                        <div className={isDisabled ? "disableCsvBtn ml-2" : "ml-2"}>
                            <CsvDownload btnName="A-MESH" data={state.csvData} csvLinkRef={csvLinkAMeshRef} filename={"CatalogA-Mesh.csv"} headers={headers} apiCallForCSVData={() => apiCallForCSVData('A-MESH')} />
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}
