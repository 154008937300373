import { del, get, set } from 'idb-keyval';
import { Constant } from "../../../Constants/constant";
import { FETCH } from "../../../Services/fetch";
import * as Actions from "../../ApiCallStatus/Actions/action";
import { GetCompaniesAction, GetCompanyConfigurationAction, GetCompanyDetailAction } from "../Actions/action";

async function removeCompanyConfigFromStorage() {
    await del('companyConfig');
}

export function getCompanies(filterQuery) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getAllCompanies" }));
        let url = `/companies`;
        if (filterQuery) {
            url += filterQuery;
        }
        const myJson = await FETCH("GET", `${Constant.apiURl}${url}`);
        if (myJson && myJson.code === 200) {
            dispatch(GetCompaniesAction(myJson.data, myJson.count));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getAllCompanies",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getAllCompanies",
                    message: myJson.message,
                })
            );
        }
    };
}

export function deleteCompany(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteCompany" }));
        const myJson = await FETCH("DELETE", Constant.apiURl + `/companies/${id}`);
        if (myJson && myJson.code === 204) {
            await removeCompanyConfigFromStorage();
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "deleteCompany",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "deleteCompany",
                    message: myJson.message,
                })
            );
        }
    };
}

export function createCompany(data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "createCompany" }));
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            // If it's an object.
            if (["country", "port", "configuration"].includes(key)) {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        });
        const myJson = await FETCH("POST", Constant.apiURl + "/companies", formData, true);
        if (myJson && myJson.code === 201) {
            await removeCompanyConfigFromStorage();
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "createCompany",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "createCompany",
                    message:
                        myJson.message && typeof myJson.message === "string"
                            ? myJson.message
                            : "",
                })
            );
        }
    };
}

export function updateCompany(id, data) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCompany" }));
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            // If it's an object.
            if (["country", "port", "configuration"].includes(key)) {
                formData.append(key, JSON.stringify(value));
            } else {
                formData.append(key, value);
            }
        });
        const myJson = await FETCH("PUT", Constant.apiURl + `/companies/${id}`, formData, true);
        if (myJson && myJson.code === 200) {
            await removeCompanyConfigFromStorage();
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "updateCompany",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "updateCompany",
                    message:
                        myJson.message && typeof myJson.message === "string"
                            ? myJson.message
                            : "",
                })
            );
        }
    };
}

export function getCompanyDetail(id) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCompanyDetail" }));
        const myJson = await FETCH("GET", Constant.apiURl + `/companies/${id}`);
        if (myJson && myJson.code === 200) {
            dispatch(
                GetCompanyDetailAction(myJson.data)
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCompanyDetail",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getCompanyDetail",
                    message:
                        myJson.message && typeof myJson.message === "string"
                            ? myJson.message
                            : "",
                })
            );
        }
    };
}

export function getCompanyByDomain(domain) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "getCompanyByDomain" }));
        const key = "companyConfig";
        let myJson = await get(key);

        if (!myJson) {
            myJson = await FETCH("GET", `${Constant.apiURl}/companies/by/domain/${domain}`);
            await set(key, myJson);
        }

        if (myJson && myJson.code === 200) {
            dispatch(
                GetCompanyConfigurationAction(myJson.data)
            );
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "getCompanyByDomain",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "getCompanyByDomain",
                    message:
                        myJson.message && typeof myJson.message === "string"
                            ? myJson.message
                            : "",
                })
            );
        }
    };
}
