import React, { useEffect, useState } from 'react';
import { Wrapper } from '../../Common/Components/CommonWrapper';
import { createUser, userListing, roleAssignment } from "../ApiCalls/user";
import { ApiClearAction, ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { getAllCountry } from './../../Common/ApiCalls/countryList';
import { getAllPort } from './../../Common/ApiCalls/portList';
import { getAllVessel } from './../../Common/ApiCalls/vesselList';
import { getAllRoles } from './../../Common/ApiCalls/getAllRoles';
import { connect } from 'react-redux';
import { getDashboardLink, isAllowPermission } from '../../../Services/common';
import { getRoutePathBykey } from '../../../Constants/routesConfig';
import DashboardNavbar from './../../Common/Components/DashboardNavbar';
import { UserForm } from './userForm';
import Swal from 'sweetalert2';
const userInitialState = {
    name: '',
    type: '',
    typeOption: [
        { label: 'Captain', value: 'captain' },
        { label: 'Chief Mate(1st Officer)', value: 'chiefMate' },
        { label: 'Chief Engineer', value: 'chiefEngineer' },
        { label: 'Electrician', value: 'electrician' },
        { label: 'Cook', value: 'cook' },
        { label: 'Controller', value: 'controller' },
        { label: 'Super intendent', value: 'superIntendent' },
        { label: 'Purchase manager', value: 'purchaseManager' },
        { label: 'Purchaser', value: 'purchaser' },
        { label: 'Management customer', value: 'managementCustomer' },
    ],
    selectedTypeOption: null,
    port: '',
    portOption: [],
    selectedPortOption: null,
    role: '',
    roleOption: [],
    selectedRoleOption: null,
    orderType: '',
    selectedOrderTypeOption: null,
    orderTypeOption: [
        { label: 'provision', value: 'provision' },
        { label: 'store', value: 'store' },
    ],
    country: '',
    countryOption: [],
    selectedCountryOption: null,
    vessel: '',
    vesselOption: [],
    selectedVesselOption: null,
    departmentOption: [],
    selectedDepOption: null,
    speciality: '',
    specialityList: [],
    email: '',
    websiteUrl: '',
    phone: '',
    password: '',
    confirmPassword: '',
    isError: false,
    errorMessage: '',
    isHitApi: false
};
function CreateUser(props) {
    const [state, setState] = useState(userInitialState);
    useEffect(() => {
        if (!state.isHitApi) {
            if (!isAllowPermission(window.location.pathname, props.roles)) {
                props.history.push('/not-allowed');
            } else {
                props.getAllCountry();
                props.getAllVessel();
                props.getAllRoles();
                let data = { ...state };
                data.isHitApi = true;
                setState(data);
            }
        }
        if (props.apiCallStatus.apiCallFor === "roleAssignment" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let data = props.userReducer.roleAssignmentDetail;
            if (!data.isShow) {
                Swal.fire("Error", data.message, "error");
                setState({
                    ...state,
                    selectedDepOption: null,
                    role: '',
                    selectedRoleOption: null,
                });
            }

            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllCountry" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.countryList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].name, countryCode: arr[i].countryCode });
            }
            setState({
                ...state,
                countryOption: newArr,
                isHitApi: true
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllPort" &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            let newArr = [];
            let arr = props.portList;
            for (let i = 0; i < arr.length; i++) {
                newArr.push({ value: arr[i]._id, label: arr[i].port });
            }
            setState({
                ...state,
                portOption: newArr,
                isHitApi: true
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllVessel" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            for (let index = 0; index < props.vesselList.length; index++) {
                let vesselElem = props.vesselList[index];
                let departments = [];
                if (vesselElem["departments"]) {
                    vesselElem["departments"].forEach(element => {
                        departments.push({ ...element, label: element.department, value: element.id });
                    });
                }
                newArr.push({ label: vesselElem.name, value: vesselElem._id, departments });
            }
            setState({
                ...state,
                vesselOption: newArr,
                isHitApi: true
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if (props.apiCallStatus.apiCallFor === "getAllRoles" &&
            props.apiCallStatus.isCompleted === true && !props.apiCallStatus.isFailed) {
            let newArr = [];
            for (let index = 0; index < props.roleList.length; index++) {
                let val = props.roleList[index];
                newArr.push({ label: val.roleName, value: val._id, roles: val.roles, permission: val.permission });
            }
            setState({
                ...state,
                roleOption: newArr,
                isHitApi: true
            });
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
        }
        if ((props.apiCallStatus.apiCallFor === "getUser") &&
            props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            if (props.userReducer.list.length > 0) {
                Swal.fire("Error", "This Vessel is already assign", "error");
            } else {
                const { name, country, port, type, role, vessel, email, phone, websiteUrl, specialityList, password, } = state;
                let obj = {
                    subType: type,
                    type: "user",
                    name,
                    countryId: country,
                    portId: port,
                    vesselId: vessel,
                    email,
                    phone,
                    roleId: role,
                    websiteUrl,
                    speciality: specialityList,
                    password
                };
                props.createUser(obj);
            }

        }
        if ((props.apiCallStatus.apiCallFor === "createUser") &&
            props.apiCallStatus.isCompleted === true &&
            props.apiCallStatus.isFailed === false) {
            let msg = `The ${state.name} has been created successfully.`;
            Swal.fire("Success", msg, "success");
            setState(userInitialState);
            props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
            if (props.isSubAdmin && !isAllowPermission(getRoutePathBykey('userList'), props.roles)) {
                props.history.push(getDashboardLink());
            } else {
                props.history.push('/user-listing');
            }
        }
    });
    const handleFormChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = { ...state };
        data[name] = value;
        data['errorMessage'] = '';
        data['isError'] = false;
        setState(data);

    };
    const addItem = () => {
        const specialityList = [...state.specialityList];
        if (state.speciality !== '') {
            specialityList.push(state.speciality);
            setState({
                ...state,
                specialityList,
                speciality: ''
            });
        } else {
            setState({
                ...state,
                isError: true,
                errorMessage: 'Please Add Speciality',
            });
        }
    };
    const removeItem = (val) => {
        let specialityList = [...state.specialityList];
        let newArray = specialityList.filter(x => x !== val);
        setState({
            ...state,
            specialityList: newArray
        });
    };
    const handleSelectChange = (e, name) => {
        let data = { ...state };
        if (name === 'port') {
            data['selectedPortOption'] = e;
        } else if (name === 'type') {
            data['selectedTypeOption'] = e;
            data.specialityList = [];
        } else if (name === 'role') {
            let orderType = data.orderType;
            if (data.type === 'captain' || orderType) {
                if (orderType === 'store' && data.selectedDepOption === null) {
                    Swal.fire("Error", "Please Select department", "error");
                } else {
                    data['selectedRoleOption'] = e;
                    let departments = [];
                    if (orderType === 'store') {
                        data.selectedDepOption.map((item, index) => {
                            departments.push(item.department);
                        });
                    }
                    if (data.type !== 'captain') {
                        let obj = {
                            departments: orderType === 'store' ? departments : ["Main & Fresh"],
                            roleId: e.value,
                            type: orderType
                        };
                        props.roleAssignment(obj);
                    }
                }
            } else {
                Swal.fire("Error", "Please Select Order Type", "error");
            }
        } else if (name === 'country') {
            data['selectedCountryOption'] = e;
            data['port'] = '';
            data['portOption'] = [];
            data['selectedPortOption'] = null;
            let query = "countryCode=" + e.countryCode;
            props.getAllPort(query);
        } else if (name === 'department') {
            data['selectedDepOption'] = e;
        } else if (name === 'orderType') {
            data['selectedOrderTypeOption'] = e;
            if (e.value === 'provision') {
                data["selectedDepOption"] = null;
            }
        } else {
            data["selectedDepOption"] = null;
            data["departmentOption"] = e.departments;
            data['selectedVesselOption'] = e;
        }
        if (name !== 'department') {
            data[name] = e.value;
        }
        data['errorMessage'] = '';
        data['isError'] = false;
        setState(data);
    };
    const createHandler = () => {
        if (validateForm()) {
            const { name, country, port, type, selectedRoleOption, vessel, email, phone, websiteUrl, specialityList, password, selectedDepOption } = state;
            let obj = {
                subType: type,
                type: "user",
                name,
                countryId: country,
                portId: port,
                vesselId: vessel,
                email,
                phone,
                departments: selectedDepOption,
                roleId: selectedRoleOption.value,
                roleName: selectedRoleOption.label,
                roles: selectedRoleOption.roles,
                permission: selectedRoleOption.permission,
                websiteUrl,
                speciality: specialityList,
                password
            };
            props.createUser(obj);

        }
    };
    const validateForm = () => {
        let isValid = true;
        let message = "";
        if (state.type === '') {
            message = "Please select user type";
            isValid = false;
        } else if (state.country === '') {
            message = "Please select country";
            isValid = false;
        } else if (state.port === '') {
            message = "Please select port";
            isValid = false;
        }
        else if (state.vessel === '') {
            message = "Please select vessel";
            isValid = false;
        } else if (state.role === '') {
            message = "Please select role";
            isValid = false;
        } else if (state.password.length < 8) {
            message = 'password should be 8 characters';
            isValid = false;
        }
        else if (state.password !== state.confirmPassword) {
            message = 'Password and Confirmed Password does not match';
            isValid = false;
        }


        if (!isValid) {
            Swal.fire("Error", message, "error");
        }
        return isValid;
    };
    const navbarOptions = [
        {
            img: 'left-arrow.svg',
            className: "orange",
            name: "Go back",
            path: "/",
            isLogout: false,
            isCustom: true,
            onClick: () => props.history.goBack()
        }
    ];
    return (
        <Wrapper>
            <DashboardNavbar title="Create User" navbarOptions={navbarOptions} className="mesh-board-wrap d-flex align-items-end" />
            <div className="formBox mt-4">
                <UserForm {...state} history={props.history} removeItem={removeItem} addItem={addItem} handleFormChange={handleFormChange} handleSelectChange={handleSelectChange} createHandler={createHandler} apiCallStatus={props.apiCallStatus} apiCallName="createUser" />
            </div>
        </Wrapper>
    );
}
const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    portReducer: state.portReducer,
    countryReducer: state.countryReducer,
    roles: state.authReducer.roles,
    userReducer: state.userReducer,
    roleList: state.commonReducer.roleList,
    vesselList: state.commonReducer.vesselList,
    portList: state.commonReducer.portList,
    countryList: state.commonReducer.countryList,
    isSubAdmin: state.authReducer.isSubAdmin,
});
const mapDispatchToProps = (dispatch, ownProps) => ({
    createUser: (data) => dispatch(createUser(data)),
    ApiClearAction: () => dispatch(ApiClearAction()),
    ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
    roleAssignment: (data) => dispatch(roleAssignment(data)),
    getAllCountry: () => dispatch(getAllCountry()),
    userListing: (offset, type, filterQuery) => dispatch(userListing(offset, type, filterQuery)),
    getAllVessel: () => dispatch(getAllVessel()),
    getAllPort: (filterQuery) => dispatch(getAllPort(filterQuery)),
    getAllRoles: () => dispatch(getAllRoles())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateUser);
