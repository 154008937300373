import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetOrderAction, AppendOrderAction, GetCartAction, GetCartCsvAction, AppendCartAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../Services/common';
export function orderListing(offset, filterQuery) {
  return async (dispatch) => {
    if (offset !== 0) {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "appendOrderList" }));
    } else {
      dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrder" }));
      dispatch(GetOrderAction([], 0));
    }
    let url = "";
    if (filterQuery !== "" || filterQuery !== undefined) {
      url = Constant.apiURl + "/orders/" + offset + "/20" + filterQuery;
    } else {
      url = Constant.apiURl + "/orders/" + offset + "/20";
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      if (offset !== 0) {
        dispatch(AppendOrderAction(myJson.data));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "appendOrderList",
            message: "",
          })
        );
      } else {
        dispatch(GetOrderAction(myJson.data, myJson.count));
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    } else {
      if (offset !== 0) {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "appendOrderList",
            message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "getOrder",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function cartListing(offset, filterQuery, isDownloadCsv) {
  return async (dispatch) => {
    let apiCallFor = "getCart";
    if (isDownloadCsv) {
      apiCallFor = "downloadCartCSV"
    } else if (offset !== 0) {
      apiCallFor = "appendCartList"
    }
    dispatch(Actions.ApiRequestedAction({ apiCallFor }));
    if (offset === 0) {
      dispatch(GetCartAction([], 0));
    }
    let url = "";
    if (filterQuery !== "" || filterQuery !== undefined) {
      url = Constant.apiURl + "/orders/cart-list/" + offset + "/20" + filterQuery;
    } else {
      url = Constant.apiURl + "/orders/cart-list/" + offset + "/20";
    }
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 200) {
      if (offset !== 0) {
        dispatch(AppendCartAction(myJson.data));
      } else if (isDownloadCsv) {
        let newArray = [];
        let data = [...myJson.data];
        for (let index = 0; index < data.length; index++) {
          let placeOfDelivery = data[index].placeOfDelivery.label
          let obj = {
            vesselName: data[index].vesselName,
            customerName: data[index].customerName,
            type: capitalizeFirstLetter(data[index].type),
            arrivalTime: moment(data[index].arrivalTime).format("DD.MM.YYYY"),
            placeOfDelivery,
            supplierName: data[index].supplierName,
            totalPrice: data[index].totalPrice ? parseFloat(data[index].totalPrice.toFixed(2)) + ' ' + data[index].currency : '0',
            orderStatus: data[index].orderStatus
          };
          newArray.push(obj);
        }
        dispatch(GetCartCsvAction(newArray, myJson.count));
      } else {
        dispatch(GetCartAction(myJson.data, myJson.count));
      }
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor,
          message: "",
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor,
          message: myJson.errorMessage ? myJson.errorMessage : myJson.message,
        })
      );
    }
  };
}
export function nextDelieveryDetail(offset, filterQuery) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "getOrder" }));
    dispatch(GetOrderAction([], 0));
    let url = Constant.apiURl + "/orders/" + offset + "/20";
    let myJson = await FETCH("GET", url);
    if (myJson && myJson.code === 201) {
      dispatch(GetOrderAction(myJson.data, myJson.count));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "getOrder",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "getOrder",
          message: myJson.message,
        })
      );
    }
  };
}
