import { combineReducers } from "redux";
import apicallStatusReducer from "./../Views/ApiCallStatus/Reducer/apicallStatusReducer";
import authReducer from "./../Views/Auth/Reducer/auth";
import portReducer from "../Views/Port/Reducer/portReducer";
import countryReducer from "./../Views/Country/Reducer/countryReducer";
import captainReducer from "./../Views/Captain/Reducer/captainReducer";
import userReducer from "./../Views/User/Reducer/userReducer";
import commonReducer from "./../Views/Common/Reducer/commonReducer";
import vesselReducer from "./../Views/Vessel/Reducer/vesselReducer";
import orderReducer from "./../Views/Orders/Reducer/orderReducer";
import supplierReducer from "./../Views/Supplier/Reducer/supplierReducer";
import packageReducer from "./../Views/Package/Reducer/packageReducer";
import dashboardReducer from "./../Views/Dashboard/Reducer/dashboardReducer";
import quotationReducer from "./../Views/Quotations/Reducers/quotatiosReducer";
import uploadReducer from "./../Views/fileUpload/Reducer/uploadReducer";
import departmentReducer from "../Views/Department/Reducer/departmentReducer";
import roleReducer from "./../Views/roles/Reducer/roleReducer";
import orderBasketReducer from "./../Views/OrderBasket/Reducer/orderBasketReducer";
import socketReducer from "./../Views/SocketConnection/Reducer/socketReducer";
import reminderReducer from "./../Views/Reminder/Reducer/reminderReducer";
import companyReducer from "./../Views/Company/Reducer/CompanyReducer";

export default combineReducers({
  apicallStatusReducer,
  authReducer,
  portReducer,
  countryReducer,
  captainReducer,
  userReducer,
  vesselReducer,
  commonReducer,
  orderReducer,
  supplierReducer,
  packageReducer,
  dashboardReducer,
  quotationReducer,
  uploadReducer,
  departmentReducer,
  roleReducer,
  orderBasketReducer,
  socketReducer,
  reminderReducer,
  companyReducer,
});
