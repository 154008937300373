import $ from "jquery";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { currencyFormatter } from "../../../../Services/common";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { CsvDownload } from "../../../Common/Components/CsvDownload";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import { changeStatus, getQuotationDetail, saveQuotation } from "../../Apicalls/quotations";
import { UploadQuotationModal } from "../PrepareQuotation/UploadQuotationModal";

const headers = [
    { label: "ItemCode", key: "itemCode" },
    { label: "IMPA", key: "alternativeCode" },
    { label: "Department", key: "department" },
    { label: "Description", key: "description" },
    { label: "Explanation", key: "explanation" },
    { label: "Quantity", key: "order" },
    { label: "Unit", key: "standardPackagingUnit" },
    { label: "Price", key: "pricePerBaseUnit" },
    { label: "Total Price", key: "totalPrice" },
    { label: "Remark", key: "remark" },
];

const csvLinkRef = React.createRef();

function SupplyConfirmation(props) {
    const [totalAmount, setTotalAmount] = useState(0.0);

    const [state, setState] = useState({
        isHitApi: false,
        price: 0.0,
        basketId: "",
        quotationId: "",
        orderDetail: {},
        changedItem: [],
        quotationDetailList: [],
        csvData: [],
        adminCatalogs: [],
        isButtonShow: true,
        savingQuotation: false,
        showModal: false
    });

    useEffect(() => {
        ReactTooltip.rebuild();
        if (!state.isHitApi) {
            const { quotationId } = props.match.params;
            props.getQuotationDetail(quotationId, props.userId);
            let data = { ...state };
            data.isHitApi = true;
            data.quotationId = quotationId;
            setState(data);
        }
        if (
            props.apiCallStatus.apiCallFor === "getQuotationDetail" &&
            props.apiCallStatus.isCompleted &&
            !props.apiCallStatus.isFailed
        ) {
            let csvData = [];
            for (
                let index = 0;
                index < props.quotationReducer.quotationDetailList.length;
                index++
            ) {
                const element = props.quotationReducer.quotationDetailList[index];

                const csvObj = {
                    itemCode: element.itemCode,
                    alternativeCode: element.alternativeCode,
                    department: element.department,
                    description: element.description,
                    explanation: element.explanation,
                    order: element.order,
                    standardPackagingUnit:
                        element.standardPackagingUnit === ""
                            ? element.pricePerPackagingUnit
                            : element.standardPackagingUnit,
                    pricePerBaseUnit: element.pricePerBaseUnit,
                    totalPrice: `=(F${[index + 2]}*H${[index + 2]})`,
                    remark: element.remark || "",
                };
                csvData.push(csvObj);
            }

            // EMPTY ROW
            csvData.push({
                itemCode: '',
                alternativeCode: '',
                department: '',
                description: '',
                explanation: '',
                order: '',
                standardPackagingUnit: '',
                pricePerBaseUnit: '',
                totalPrice: '',
                remark: '',
            });

            // SHOW TOTAL SUM
            csvData.push({
                itemCode: '',
                alternativeCode: '',
                department: '',
                description: '',
                explanation: '',
                order: '',
                standardPackagingUnit: '',
                pricePerBaseUnit: 'Total Sum',
                totalPrice: `=SUM(I2:I${csvData.length})`,
                remark: '',
            });

            const quotationDetailList = props.quotationReducer.quotationDetailList;
            let price = 0.0;
            for (let index = 0; index < quotationDetailList.length; ++index) {
                const { qtdPrice, order } = quotationDetailList[index];
                price += (+qtdPrice || 0.0) * (+order);
            }

            setState({
                ...state,
                quotationDetailList,
                orderDetail: props.quotationReducer.orderDetail,
                basketId: props.quotationReducer.basketId,
                adminCatalogs: props.quotationReducer.adminCatalogs,
                csvData,
                price,
                isHitApi: true,
            });

            setTimeout(() => {
                $("input[type=number]").on("focus", function () {
                    $(this).on("keydown", function (event) {
                        if (
                            event.keyCode === 38 ||
                            event.keyCode === 40 ||
                            event.key === "-"
                        ) {
                            event.preventDefault();
                        }
                    });
                });
            }, 500);
            props.ApiClearAction();
        }

        if (props.apiCallStatus.apiCallFor === "saveQuotation") {
            if (props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed) {
                Swal.fire(
                    "Success",
                    "Your quotation has been saved successfully!",
                    "success"
                );
                const isAdmin = props.userType === "user" && props.subType === "user";
                const status = isAdmin ? "supplied" : "pending_approval";
                props.changeStatus({
                    quotationId: state.quotationId,
                    status,
                });
                props.history.push("/open-orders");
                props.ApiClearAction();
            } else {
                setState({
                    ...state,
                    savingQuotation: false,
                });
            }
        }
    }, [state.isHitApi, props.apiCallStatus]);

    const handleTableChange = (
        itemCode,
        price,
        orderId,
        order,
        packageUnit,
        remark,
        index
    ) => {
        let orderDetail = cloneDeep(state.orderDetail);
        let quotationDetailList = cloneDeep(state.quotationDetailList);
        let newItem = state.changedItem;
        if (price === "") {
            const add = newItem.filter((item) => {
                return item.itemCode !== itemCode;
            });
            delete orderDetail[index];
            delete newItem[index];
            newItem = add;
        } else {
            if (order) {
                let obj = {
                    itemCode,
                    price,
                    orderId,
                    order,
                    packageUnit,
                    remark,
                };
                if (!orderDetail[index]) {
                    newItem.push(obj);
                }
                orderDetail[index] = obj;
            } else {
                let inputId = `#price${index}`;
                $(inputId).val("");
                Swal.fire("Error", "Please add the number of quantity", "error");
            }
        }
        const sum = calculateSum(orderDetail);
        quotationDetailList[index]["remark"] = remark;
        quotationDetailList[index]["price"] = !!price ? price : 0;
        quotationDetailList[index]["qtdPrice"] = !!price ? price : 0;
        quotationDetailList[index]["packageUnit"] = packageUnit;
        quotationDetailList[index]["order"] = order;
        setState({
            ...state,
            price: sum,
            orderDetail,
            changedItem: newItem,
            quotationDetailList,
        });
    };

    const handleQuantityChange = (index, order, name, subIndex) => {
        let orderDetail = cloneDeep(state.orderDetail);
        let quotationDetailList = cloneDeep(state.quotationDetailList);
        let newItem = state.changedItem;
        if (!order) {
            const add = newItem.filter((_, idx) => idx !== index);
            delete orderDetail[index];
            delete newItem[index];
            newItem = add;
        } else {
            if (name === "order") {
                quotationDetailList[index][name] = order;
            } else if (name === "standardPackagingUnit") {
                quotationDetailList[index][name] = order;
            } else {
                quotationDetailList[index]["estimatedPrice"][subIndex][
                    "pricePerCountry"
                ][0][name] = order;
            }
        }
        const rec = quotationDetailList[index];
        const { _id, itemCode, qtdPrice, standardPackagingUnit, remark } = rec;
        const obj = {
            orderDetailId: _id,
            itemCode,
            price: !!qtdPrice ? qtdPrice : 0,
            order: rec?.order,
            packageUnit: standardPackagingUnit,
            remark,
        };
        if (!orderDetail[index]) {
            newItem.push(obj);
        }
        orderDetail[index] = obj;
        setState({
            ...state,
            orderDetail,
            quotationDetailList,
            changedItem: newItem,
        });
    };

    const calculateSum = (data) => {
        let keys = Object.keys(data);
        let price = 0;
        let totalPrices = 0.0;
        let totalPrice2 = 0.0;
        let totalmeshprice = 0.0;
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (data[element] && data[element].price !== "") {
                const priceInt =
                    data[element].price !== "undefined"
                        ? parseFloat(data[element].price)
                        : data[element].price;
                const orderInt =
                    data[element].order !== "undefined"
                        ? parseFloat(data[element].order)
                        : data[element].order;
                if (!isNaN(priceInt) && !isNaN(orderInt)) {
                    totalPrice2 += priceInt;
                    totalPrices += ((priceInt * 100) / 97.5) * orderInt;
                    price += priceInt * orderInt;
                    totalmeshprice += (priceInt * 100) / 97.5;
                    setTotalAmount(price.toFixed(2));
                }
            }
        }
        return price.toFixed(2);
    };

    const prepareQuotationItems = () => {
        let items = [];
        /*
        When supplier sends the quotation without changing any items, the above `items` variable would be [].
        Hence, pass all the records for this quotation.
        */
        if (items.length === 0) {
            const list = state.quotationDetailList;
            for (let index = 0; index < list.length; ++index) {
                const obj = list[index];
                const finalObj = {
                    baseQuantityAvg: obj?.baseQuantityAvg,
                    itemCode: obj?.itemCode,
                    alternativeCode: obj?.alternativeCode,
                    price: parseFloat(obj?.qtdPrice || 0),
                    order: obj?.order,
                    orderId: obj?.orderId,
                    packageUnit: obj?.standardPackagingUnit,
                    remark: obj?.remark,
                    department: obj?.department || "",
                    prefixedItemCode: obj?.prefixedItemCode || "",
                    orderDetailId: obj?._id || "",
                    availability: obj?.availability || "",
                    priceType: obj?.priceType || "",
                };
                items.push(finalObj);
            }
        }

        return items;
    };

    const confirmSupply = () => {
        Swal.fire({
            title: "Supply Confirmation",
            text: "Are you sure you want to proceed?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                const items = prepareQuotationItems();
                setState({
                    ...state,
                    savingQuotation: true,
                });
                props.saveQuotation({
                    quotationId: state.quotationId,
                    items,
                });
            }
        });
    };

    const handleChange = (e) => {
        let name = e.target.name;
        let val = e.target.value;
        setState({
            ...state,
            [name]: val,
        });
    };

    const getNavbarOptions = () => {
        const options = [
            {
                img: "left-arrow.svg",
                className: `orange ${state.savingQuotation ? 'btn-disable' : ''}`,
                name: "Go back",
                path: "/",
                isLogout: false,
                isCustom: true,
                onClick: () => props.history.goBack(),
            },
            {
                img: "correct-icon.svg",
                className: `dark-blue ${state.savingQuotation ? 'btn-disable' : ''}`,
                name: "Confirm<br /> Supply",
                isLogout: false,
                isCustom: true,
                onClick: () => confirmSupply(),
            }
        ];

        return options;
    };

    const apiCallForCSVData = () => {
        csvLinkRef.current.link.click();
    };

    const handleModalChange = (showModal) => {
        setState({
            ...state,
            showModal,
        });
    };

    const isSupplier = props.userType === 'supplier';

    return (
        <DashboardWrapper
            title="Quotation"
            navbarOptions={getNavbarOptions()}
            className="mesh-board-wrap d-flex align-items-end"
        >
            {/* RFQ Detials Section */}
            <div className="rfq-details-wrap ordCatg-table-wrap sup-rfq-wrap d-flex align-items-start">
                <div className="rfq-durations rfq-details-items  descriptrfq">
                    <h2 className="mt-2 font-weight-bold">Vessel Name:</h2>
                    <div className="inner-content text-left">
                        <h2 className="font-weight-bold">
                            Del. Place:{" "}
                            <span className="font-weight-bold mt-1 d-inline-block"></span>
                        </h2>
                        <h2 className=" font-weight-bold">
                            Del. date:{" "}
                            <span className="font-weight-bold mt-1 d-inline-block"></span>
                        </h2>
                        <h2 className="font-weight-bold">
                            Agent Detail:{" "}
                            <span className="font-weight-bold mt-1 d-inline-block"></span>
                        </h2>
                        <h2 className="font-weight-bold">
                            Creation Date:{" "}
                            <span className="font-weight-bold mt-1 d-inline-block"></span>
                        </h2>
                    </div>
                </div>
                {Object.size(props.quotationReducer.orderDetail) > 0 ? (
                    <div className="rfq-rating rfq-details-items">
                        <h2 className="mt-2">
                            {props.quotationReducer.orderDetail
                                ? props.quotationReducer.orderDetail.vesselName
                                : ""}
                        </h2>
                        <h2 className="mt-2">
                            {props.quotationReducer.orderDetail &&
                                props.quotationReducer.orderDetail.placeOfDelivery
                                ? props.quotationReducer.orderDetail.placeOfDelivery.label
                                : ""}
                        </h2>
                        <h2 className="mt-2">
                            {props.quotationReducer.orderDetail
                                ? moment(props.quotationReducer.orderDetail.arrivalTime)
                                    .utc()
                                    .format("YYYY-MM-DD")
                                : ""}
                        </h2>
                        <h2 className="mt-2">
                            {props.quotationReducer.orderDetail &&
                                props.quotationReducer.orderDetail.agentDetail
                                ? props.quotationReducer.orderDetail.agentDetail
                                : "--"}
                        </h2>
                        <h2 className="mt-2">
                            {props.quotationReducer.orderDetail
                                ? moment(props.quotationReducer.orderDetail.createdAt)
                                    .utc()
                                    .format("YYYY-MM-DD")
                                : ""}
                        </h2>
                    </div>
                ) : (
                    ""
                )}
                <div className="rfq-durations rfq-details-items  descriptrfq ml-10">
                    <div className="inner-content text-left">
                        <h2 className="mt-0 font-weight-bold"></h2>
                        <h2 className="mt-0 font-weight-bold">Total items: </h2>
                        <h2 className="mt-0 font-weight-bold">Total Price: </h2>
                    </div>
                </div>
                <div className="rfq-rating rfq-details-items">
                    <h2 className="mt-2"></h2>
                    <h2 className="mt-2">{state.quotationDetailList.length}</h2>
                    <h2 className="mt-2">{currencyFormatter(state.price)}</h2>
                </div>
            </div>
            <div className="ordCatg-table-wrap mt-3 system-cat-filter">
                <div className="card-bx-sty">
                    <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
                        <div className="fil-btn-wrap-ctn d-flex align-items-center flex-fill">
                            <CsvDownload
                                data={state.csvData}
                                csvLinkRef={csvLinkRef}
                                filename={"Quotation_Supply_Confirmation.csv"}
                                headers={headers}
                                apiCallForCSVData={() => apiCallForCSVData()}
                            />
                            <button className="btn btn-blue ml-2" onClick={() => {
                                setState({
                                    ...state,
                                    showModal: true,
                                });
                            }}>Upload Quotation</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* RFQ Detials Section End */}
            <div className="ordCatg-table-wrap mt-3 sup-rfq-wrap">
                {/* Table */}
                <div className="ordCatg-InnTable store-catgTable py-3 sendRfq-t">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">Sr. No.</th>
                                    <th className="text-left">Item Code</th>
                                    <th className="text-center">IMPA</th>
                                    <th className="text-center">Department</th>
                                    <th className="text-center">Description</th>
                                    <th className="text-center">Explanation</th>
                                    <th className="text-left">Quantity</th>
                                    <th className="text-center">Unit</th>
                                    <th className="text-center">Package Size</th>
                                    <th className="text-center">Net Price</th>
                                    <th className="text-center">Total Price</th>
                                    <th className="text-center">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.apiCallStatus.isStarted.indexOf("getQuotationDetail") !==
                                    -1 ? (
                                    <tr>
                                        <td colSpan="20">
                                            <div className="loader-img text-center">
                                                <img style={{ width: "46px" }} src={loader} alt="" />
                                            </div>
                                        </td>
                                    </tr>
                                ) : state.quotationDetailList &&
                                    state.quotationDetailList.length > 0 ? (
                                    state.quotationDetailList
                                        .sort((a, b) => a.alternativeCode - b.alternativeCode)
                                        .map((item, index) => {
                                            let subIndex = 0;
                                            if (
                                                item.estimatedPrice &&
                                                item.estimatedPrice.length > 0
                                            ) {
                                                subIndex = item.estimatedPrice.findIndex(
                                                    (x) => x.itemCode === item.itemCode
                                                );
                                                if (subIndex === -1) {
                                                    subIndex = 0;
                                                }
                                            }

                                            const priceValue = item?.price || item?.qtdPrice || "0";
                                            const order = item?.order;
                                            const packageUnit = item.standardPackagingUnit;
                                            const remark = item.remark;
                                            const newTotalPrice = order * priceValue;

                                            return (
                                                <tr key={"quotationSupplyConfirmation-" + index}>
                                                    <td>
                                                        <span className="text-center td-wrap-sty">
                                                            {index + 1}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="text-center td-wrap-sty">
                                                            {item.itemCode}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="td-wrap-sty text-center">
                                                            {item.alternativeCode}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="text-center td-wrap-sty">
                                                            {item.department}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="text-left td-wrap-sty">
                                                            {item.description}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className="text-left td-wrap-sty mw-15">
                                                            {item.explanation}
                                                        </span>
                                                    </td>

                                                    <td className="button-round-td">
                                                        {
                                                            <input
                                                                className="whi-round td-wrap-sty text-right"
                                                                type="number"
                                                                value={order}
                                                                onChange={(e) =>
                                                                    handleQuantityChange(
                                                                        index,
                                                                        e.target.value,
                                                                        "order",
                                                                        subIndex
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    </td>

                                                    <td>
                                                        <span className="td-wrap-sty text-center">
                                                            {packageUnit}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="td-wrap-sty text-center">
                                                            {item.baseQuantityAvg}
                                                        </span>
                                                    </td>

                                                    <td className="button-round-td">
                                                        <span className="td-wrap-sty text-right">
                                                            {item?.qtdPrice || "0"}
                                                        </span>
                                                    </td>

                                                    <td>
                                                        <span className="td-wrap-sty text-right">
                                                            {newTotalPrice.toFixed(2)}
                                                        </span>
                                                    </td>

                                                    <td className="button-round-td" data-tip={remark}>
                                                        <input
                                                            className="whi-round text-right"
                                                            type="text"
                                                            value={remark}
                                                            id={`remark${index}`}
                                                            onChange={(e) =>
                                                                handleTableChange(
                                                                    item.itemCode,
                                                                    priceValue,
                                                                    item.orderId,
                                                                    order,
                                                                    packageUnit,
                                                                    e.target.value,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                ) : state.quotationDetailList &&
                                    state.quotationDetailList.length === 0 ? (
                                    <tr>
                                        <td colSpan="40" className="text-center">
                                            No Data Found
                                        </td>
                                    </tr>
                                ) : (
                                    ""
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="tableFoot-content mt-4">
                        <ul className="list-inline pl-0 d-flex align-items-center justify-content-end deli-info-list mb-2">
                            <li>
                                Total items:<span>{state.quotationDetailList.length}</span>
                            </li>
                            <li>
                                Grand Total:<span>{totalAmount} USD</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <UploadQuotationModal
                showModal={state.showModal}
                handleModalChange={handleModalChange}
                ClearApiByNameAction={props.ClearApiByNameAction}
                apiCallStatus={props.apiCallStatus}
                quotationId={state.quotationId}
                status={new URLSearchParams(window.location.search).get('status') || ''}
                state={state}
                setState={setState}
            />
        </DashboardWrapper>
    );
}

const mapStateToProps = (state, ownProps) => ({
    apiCallStatus: state.apicallStatusReducer,
    quotationReducer: state.quotationReducer,
    userId: state.authReducer.userId,
    userType: state.authReducer.userType,
    subType: state.authReducer.subType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    ApiClearAction: () => dispatch(ApiClearAction()),
    saveQuotation: (data) => dispatch(saveQuotation(data)),
    changeStatus: (payload) => dispatch(changeStatus(payload)),
    getQuotationDetail: (quotationId, supplierId) => dispatch(getQuotationDetail(quotationId, supplierId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplyConfirmation);
