import React from "react";
import Select from "react-select";

export function CustomerForm(props) {
    const validateForm = () => {
        const form = document.getElementsByClassName('needs-validation')[0];
        let isValid = true;
        if (form.checkValidity() === false) {
            isValid = false;
            form.classList.add('was-validated');
        }
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if (isValid) {
            if (props.isEdit) {
                props.updateUser();
            } else {
                props.createHandler();
            }
        }
    };

    return (
        <form className="needs-validation" onSubmit={(e) => handleSubmit(e)} noValidate>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="companyName" name="companyName" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Company Name"
                            value={props.companyName}
                            required />
                        <label htmlFor="companyName" className="foating-labels-sty">Company Name</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="number" id="fleetSize" name="fleetSize" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Fleet Size"
                            value={props.fleetSize}
                            required />
                        <label htmlFor="fleetSize" className="foating-labels-sty">Fleet Size</label>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="email" id="email" name="email" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Email"
                            value={props.email}
                            required />
                        <lable htmlFor="email" className="foating-labels-sty">General Email</lable>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="name" name="name" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Name"
                            value={props.name}
                            required />
                        <label htmlFor="name" className="foating-labels-sty">Name</label>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="text" id="websiteUrl" name="websiteUrl" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Website"
                            value={props.websiteUrl}
                            required />
                        <lable htmlFor="websiteUrl" className="foating-labels-sty">Website URL</lable>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select Country"
                                value={props.selectedCountryOption}
                                onChange={(e) => { props.handleSelectChange(e, 'country'); }}
                                options={props.countryOption}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group d-flex align-items-center">
                        <div className="custom-select-menu text-bx-wrap custom-select2 input-type-select w-100 mw-100">
                            <Select
                                className="custom-select-wrap" classNamePrefix="react-select" placeholder="Select City"
                                value={props.selectedPortOption}
                                onChange={(e) => { props.handleSelectChange(e, 'port'); }}
                                options={props.portOption}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {props.isEdit ? "" : <div className="row">
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="password" id="password" name="password" className="form-control" onChange={(e) => { props.handleFormChange(e); }} value={props.password} placeholder="Password" autoComplete="new-password" required />
                        <lable htmlFor="password" className="foating-labels-sty">Password</lable>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group floating-label-wrap">
                        <input type="password" id="confirmPassword" name="confirmPassword" className="form-control" onChange={(e) => { props.handleFormChange(e); }} value={props.confirmPassword} placeholder="Confirm Password" autoComplete="new-password" required />
                        <lable htmlFor="confirmPassword" className="foating-labels-sty">Confirm Password</lable>
                    </div>
                </div>
            </div>}

            <div className="row">
                <div className="col-sm-12">
                    <div className="form-group floating-label-wrap">
                        <textarea id="description" name="description" className="form-control" onChange={(e) => { props.handleFormChange(e); }} placeholder="Description" value={props.description} />
                        <lable htmlFor="description" className="foating-labels-sty">Description</lable>
                    </div>
                </div>
            </div>

            {props.apiCallStatus.apiCallFor === props.apiCallName && props.apiCallStatus.isCompleted && props.apiCallStatus.isFailed ?
                <div className="alert alert-danger">
                    {props.apiCallStatus.message}
                </div>
                : ""}

            {props.errorMessage !== "" && props.errorType !== "" ?
                <div className="alert alert-danger">
                    {props.errorMessage}
                </div>
                : ""}

            <div className="d-flex align-items-center flex-column flex-sm-row">
                <div className="form-btn d-flex justify-content-end flex-fill">
                    {props.apiCallStatus.apiCallFor === props.apiCallName && !props.apiCallStatus.isCompleted && !props.apiCallStatus.isFailed ?
                        <div className="loader-img text-center">
                            <img style={{ width: "46px" }} src={require("../../../assets/images/Spinner-1s-200px.gif")} alt='' />
                        </div>
                        : ""}
                    <button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn themeReguler-btn bg-sky-blue">{props.isEdit ? "Update" : "Submit"}</button>
                </div>
            </div>
        </form>
    );
}