import React, { Component, useEffect, useState } from "react";
import { Constant } from "../../../../Constants/constant";
import { FETCH } from "../../../../Services/fetch";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import { getRfqList } from "../../../Orders/ApiCalls/supplier";
import { connect } from "react-redux";
import { DashboardWrapper } from "../../../Dashboard/Components/dashboarWrapper";
import cloneDeep from "lodash.clonedeep";
import { sendQuotation } from "../../../Quotations/Apicalls/quotations";
import loader from "../../../../assets/images/Spinner-1s-200px.gif";
import Swal from "sweetalert2";

const CompareOrderList = (props) => {
  const [documents, setDocuments] = useState([]);

  const [state, setState] = useState({
    isHitApi: false,
    price: 0,
    orderDetail: {},
    orderId: "",
    orderType: "",
    quotationDetailList: [],
    csvData: [],
    order: "",
    price: "",
    packageUnit: "",
    remark: "",
  });
  const orderId = window.location.pathname.split("/")[2];
  const supplierId = window.location.pathname.split("/")[3];
  const quotationId = window.location.pathname.split("/")[4];

  useEffect(() => {
    if (orderId) {
      getOrderData();
    }
  }, []);

  useEffect(() => {
    if (!state.isHitApi) {
      const urlParams = new URLSearchParams(window.location.search);
      const orderType = urlParams.get("orderType");
      props.getRfqList(orderId, supplierId, quotationId, "order");
      let data = { ...state };
      data.isHitApi = true;
      data.orderId = orderId;
      data.orderType = orderType;
      setState(data);
    }
    if (
      props.apiCallStatus.apiCallFor === "getRfqList" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      let quotationDetailList = props.rfqList;
      setState({
        ...state,
        quotationDetailList,
        isHitApi: true,
      });
      props.ApiClearAction();
      getTotalPrice(state.orderType, quotationDetailList);
    }
    if (
      props.apiCallStatus.apiCallFor === "sendQuotation" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      Swal.fire(
        "Success",
        "Your order confirmation has been uploaded successfully",
        "success"
      );
      props.history.push("/open-orders");
      props.ApiClearAction();
    }
    if (
      props.apiCallStatus.apiCallFor === "sendQuotation" &&
      props.apiCallStatus.isCompleted &&
      props.apiCallStatus.isFailed
    ) {
      Swal.fire(
        "Error",
        props.apiCallStatus.message &&
          typeof props.apiCallStatus.message === "string"
          ? props.apiCallStatus.message
          : "Error in saving quotation",
        "error"
      );
      props.ApiClearAction();
    }
  });

  const getTotalPrice = (type, data) => {
    let totalPrice = 0;
    let totalnetprices = 0;
    data.map((item) => {
      let order = item?.quantity?.[0]?.order || 0;
      let price = item?.qtdPrice || 0;
      let newTotalPrice = order * price;
      totalPrice = totalPrice + price;
      totalnetprices = totalnetprices + newTotalPrice;
    });
  };

  const handleTableChange = (value, name, index, key) => {
    let quotationDetailList = cloneDeep(state.quotationDetailList);
    if (key) {
      let newArry = quotationDetailList;

      if (name === "remark") {
        if (newArry[index]["quantity"][0]) {
          newArry[index]["quantity"][0][name] = value;
        } else {
          let quantity = {
            remark: value,
            pricePerCountry: {
              price: 0,
            },
          };
          newArry[index]["quantity"].push(quantity);
        }
      } else if (name === "order") {
        newArry[index]["quantity"][0][name] = value;
      } else if (name === "pricePerUnit") {
        newArry[index]["qtdPrice"] = value;
      } else if (name === "baseQuantity") {
        newArry[index][name] = value;
        newArry[index]["baseQuantityAvg"] = value;
      } else {
        newArry[index][name] = value;
      }
      quotationDetailList = newArry;
    } else {
      if (name === "remark") {
        if (quotationDetailList[index]["quantity"][0]) {
          quotationDetailList[index]["quantity"][0][name] = value;
          quotationDetailList[index][name] = value;
        } else {
          let quantity = {
            remark: value,
            pricePerCountry: {
              price: 0,
            },
          };
          quotationDetailList[index]["quantity"].push(quantity);
        }
      } else if (name === "baseQuantity") {
        quotationDetailList[index][name] = value;
        quotationDetailList[index]["baseQuantityAvg"] = value;
        quotationDetailList[index]["baseQuantity"] = value;
      } else if (name === "order") {
        quotationDetailList[index]["quantity"][0][name] = value;
        quotationDetailList[index][name] = value;
      } else if (name === "pricePerUnit") {
        quotationDetailList[index]["qtdPrice"] = value;
      } else {
        quotationDetailList[index][name] = value;
      }
    }
    setState({
      ...state,
      quotationDetailList,
    });
  };

  const getOrderData = async () => {
    try {
      let { data } = await FETCH(
        "GET",
        Constant.apiURl + "/orders/d/documents/" + orderId
      );
      setDocuments(
        data.map((element) => {
          return { uri: element?.file?.filename };
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const getNavbarOptions = () => {
    let options = [
      {
        img: "left-arrow.svg",
        className: "orange",
        name: "Go back",
        path: "/",
        isLogout: false,
        isCustom: true,
        onClick: () => props.history.goBack(),
      },
    ];

    return options;
  };

  return (
    <DashboardWrapper
      title={`Compare Order`}
      navbarOptions={getNavbarOptions()}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="row px-3 mt-3">
        <div
          className="col-12 col-lg-6 pl-0 pr-3 pt-0 "
          style={{ borderRight: "1px solid black" }}
        >
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={documents}
            style={{ borderRadius: "5px" }}
          />
        </div>
        <div className="ordCatg-InnTable store-catgTable col-12 col-lg-6  mt-3 mt-lg-0 ">
          <div className="maxHeight">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-left  text-nowrap">Sr. No.</th>
                  <th className="text-left  text-nowrap">ItemCode</th>
                  <th className="text-left  text-nowrap">IMPA</th>
                  <th className="text-left  text-nowrap">Department</th>
                  <th className="text-left  text-nowrap">Description</th>
                  <th className="text-left  text-nowrap">Availability</th>
                  <th className="text-left  text-nowrap">Quantity</th>
                  <th className="text-left  text-nowrap">Unit</th>
                  <th className="text-left  text-nowrap">Packaging Size</th>
                  <th className="text-left  text-nowrap">Price Per Unit</th>
                  <th className="text-left  text-nowrap">Total Price</th>
                  <th className="text-left  text-nowrap">Remarks</th>
                  <th className="text-left  text-nowrap"></th>
                </tr>
              </thead>
              <tbody>
                {props.apiCallStatus.isStarted.indexOf("getRfqList") !== -1 ? (
                  <tr>
                    <td colSpan="20">
                      <div className="loader-img text-center">
                        <img style={{ width: "46px" }} src={loader} alt="" />
                      </div>
                    </td>
                  </tr>
                ) : state.quotationDetailList &&
                  state.quotationDetailList.length !== 0 ? (
                  <ShowOrderDetails
                    data={state.quotationDetailList.sort(
                      (a, b) => a.alternativeCode - b.alternativeCode
                    )}
                    quotationId={quotationId}
                    userType={props.userType}
                    orderStatus={props.orderStatus}
                    orderDetail={props.orderDetail}
                    handleTableChange={handleTableChange}
                  />
                ) : state.quotationDetailList &&
                  state.quotationDetailList.length === 0 ? (
                  <tr>
                    <td colSpan="40" className="text-center">
                      No Data Found
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button
          className="btn tb-btn-rounded orng ml-2"
          onClick={() => {
            props.history.goBack();
          }}
        >
          Save and Exit
        </button>
      </div>
    </DashboardWrapper>
  );
};

const ShowOrderDetails = ({
  data,
  userType,
  orderStatus,
  orderDetail,
  handleTableChange,
  quotationId,
  // onSubmit,
  // isSuccess,
}) => {
  const [isClassColor, setISClassColor] = useState({
    color: "",
    id: "",
  });

  const onSubmit = async (
    itemCode,
    orderDetailId,
    order,
    price,
    packageUnit,
    remark
  ) => {
    try {
      let myJson = await FETCH(
        "PUT",
        Constant.apiURl + "/quotation/update/values",
        {
          quotationId: quotationId,
          items: [
            { itemCode, orderDetailId, order, price, packageUnit, remark },
          ],
        }
      );
      if (myJson.code === 200) {
        setISClassColor({
          color: "green",
          id: orderDetailId,
        });
      } else {
        setISClassColor({
          color: "red",
          id: orderDetailId,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      {React.Children.toArray(
        data.map((item, index) => {
          const order = item?.quantity?.[0]?.order || 0;
          const baseQuantity = item?.baseQuantity || 0;
          const pricePerUnit = item?.qtdPrice || 0;
          const standardPackagingUnit = item?.standardPackagingUnit || "";
          const remark = item?.remark || "";
          const department = item?.department || "";
          let newTotalPrice = pricePerUnit * order;
          return (
            <tr>
              <td>
                <span className="text-center td-wrap-sty">{index + 1}</span>
              </td>
              <td>
                <span className="text-center td-wrap-sty">{item.itemCode}</span>
              </td>
              <td>
                <span className="td-wrap-sty text-center">
                  {item.alternativeCode}
                </span>
              </td>
              <td>
                <span className="text-left td-wrap-sty">{department}</span>
              </td>
              <td>
                <span className="text-left td-wrap-sty">
                  {item.description}
                </span>
              </td>
              <td>
                <span className="text-left td-wrap-sty">
                  {item.availability}
                </span>
              </td>
              <td>
                <input
                  className="whi-round text-right"
                  type="number"
                  value={order}
                  id={`order${index}`}
                  onChange={(e) => {
                    handleTableChange(e.target.value, "order", index, null);
                  }}
                />
              </td>
              <td>
                <span className="td-wrap-sty text-center">
                  {standardPackagingUnit}
                </span>
              </td>
              <td>
                {userType === "customer" ? (
                  <span className="text-center td-wrap-sty">
                    {baseQuantity}
                  </span>
                ) : (
                  <input
                    className="whi-round text-right"
                    type="number"
                    value={baseQuantity}
                    id={`baseQuantity${index}`}
                    onChange={(e) => {
                      handleTableChange(
                        e.target.value,
                        "baseQuantity",
                        index,
                        null
                      );
                    }}
                  />
                )}
              </td>
              <td>
                <input
                  className="whi-round text-right"
                  type="number"
                  value={pricePerUnit}
                  id={`pricePerUnit`}
                  onChange={(e) => {
                    handleTableChange(
                      e.target.value,
                      "pricePerUnit",
                      index,
                      null
                    );
                  }}
                />
              </td>
              <td>
                <span className="td-wrap-sty text-right">
                  {parseFloat(newTotalPrice).toFixed(2)}{" "}
                  {orderDetail && orderDetail.vesselCurrency
                    ? orderDetail.vesselCurrency
                    : ""}
                </span>
              </td>

              <td>
                {/* {userType !== "supplier" && orderStatus !== "confirmed" ? ( */}
                <input
                  className="whi-round text-right"
                  type="text"
                  value={remark}
                  id={`remark${index}`}
                  onChange={(e) => {
                    handleTableChange(e.target.value, "remark", index, null);
                  }}
                />
              </td>
              <td>
                <button
                  className={`btn tb-btn-rounded ${
                    isClassColor.color && isClassColor.id === item._id
                      ? isClassColor.color
                      : "orng"
                  }  ml-2`}
                  onClick={() => {
                    onSubmit(
                      item.itemCode,
                      item._id,
                      order,
                      pricePerUnit,
                      baseQuantity,
                      remark
                    );
                  }}
                >
                  Update
                </button>
              </td>
            </tr>
          );
        })
      )}
    </>
  );
};
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  quotationReducer: state.quotationReducer,
  rfqList: state.orderReducer.rfqList,
  orderStatus: state.orderReducer.orderStatus,
  orderDetail: state.orderReducer.orderDetail,
  userId: state.authReducer.userId,
  userType: state.authReducer.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendQuotation: (data) => dispatch(sendQuotation(data)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  getRfqList: (orderId, supplierId, quotationId, type) =>
    dispatch(getRfqList(orderId, supplierId, quotationId, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareOrderList);
