import React from "react";

export function OrderDetailModal(props) {
  const calculateOrderedProduct = (category) => {
    let sum = 0;
    let keys = Object.keys(props.orderDetail);
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      if (
        props.orderDetail[element].category === category &&
        props.orderDetail[element].order !== ""
      ) {
        sum +=
          parseFloat(props.orderDetail[element].order) *
          props.orderDetail[element].orKg;
      }
    }
    return sum.toFixed(2);
  };
  return (
    <div
      className="modal fade theme-Roundedmodal "
      id="orderDetailModal"
      tabIndex="-1"
      aria-labelledby="orderDetailModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {/* <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img className="img-fluid" src={require('../../../../../assets/images/cancel-icon.svg')} alt="icon" />
                        </button>
                    </div> */}
          <div className="modal-body">
            <div className="modal-card d-flex align-items-center">
              <label>Order details:</label>
              {/* <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0">
                                <Select
                                    className="custom-select-wrap small-select-sty" classNamePrefix="react-select" placeholder="Category"
                                    value={props.selectedOption}
                                    onChange={props.handleChange}
                                    options={options}
                                />
                            </div> */}
            </div>
            <div className="modal-card d-flex align-items-center">
              <h1 className="modalCard-text d-flex align-items-center w-100 m-0">
                Remaining Pts:{" "}
                <span className="modalCard-value flex-fill text-center">
                  {props.totalUsedPoints.total} / 10.000
                </span>
                <span className="font-weight-bold">
                  {100 - props.totalUsedPoints.percentage}%
                </span>
              </h1>
            </div>
            <div className="modal-card d-flex align-items-center">
              <h1 className="modalCard-text font-weight-bold m-0">
                Provision needed until next order in kg:
              </h1>
            </div>
            <div className="modalTable-wrap table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Products</th>
                    <th className="text-right">Needed</th>
                    <th className="text-center">Ordered</th>
                  </tr>
                </thead>
                <tbody>
                  {props.category.map((item, index) => {
                    return (
                      <tr key={"category--" + index}>
                        <td>{item.category}</td>
                        <td className="text-right">
                          {(
                            (parseFloat(item.qtyPerDay) / 1000) *
                            props.numOfCrew *
                            props.numOfDays
                          ).toFixed(2)}
                        </td>
                        <td className="text-center">
                          {calculateOrderedProduct(item.category)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-card d-flex align-items-center nutri-head-wrap mt-4">
              <h1 className="modalCard-text font-weight-normal m-0">
                Nutrition per crew per day
              </h1>
            </div>
            <div className="nutrTable-wrap d-flex align-items-start">
              <div className="nutr-items-wrap">
                <ul className="nutr-indicator-wrap">
                  <li className="semi-bold">Type</li>
                  <li>Energy Kcal</li>
                  <li>Protein gr.</li>
                  <li>Fat gr.</li>
                  <li>Carb. gr.</li>
                  <li>Sugar. gr.</li>
                  <li>Fibres gr.</li>
                </ul>
              </div>
              <div className="nutr-items-wrap">
                <ul className="nutr-indicator-wrap">
                  <li className="semi-bold">Indication</li>
                  <li>2800</li>
                  <li>101</li>
                  <li>92</li>
                  <li>370</li>
                  <li></li>
                  <li>42</li>
                </ul>
              </div>
              <div className="nutr-items-wrap">
                <ul className="nutr-order-wrap">
                  <li className="semi-bold">Ordered</li>
                  <li>
                    {props.totalUsedPoints.energy
                      ? props.totalUsedPoints.energy
                      : "0"}
                  </li>
                  <li>
                    {props.totalUsedPoints.protein
                      ? props.totalUsedPoints.protein
                      : "0"}
                  </li>
                  <li>
                    {props.totalUsedPoints.fat
                      ? props.totalUsedPoints.fat
                      : "0"}
                  </li>
                  <li>
                    {props.totalUsedPoints.carbohydrates
                      ? props.totalUsedPoints.carbohydrates
                      : "0"}
                  </li>
                  <li>
                    {props.totalUsedPoints.sugar
                      ? props.totalUsedPoints.sugar
                      : "0"}
                  </li>
                  <li>
                    {props.totalUsedPoints.dietaryFibres
                      ? props.totalUsedPoints.dietaryFibres
                      : "0"}
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-btn-wrap d-flex justify-content-end">
              <button
                type="button"
                className="close footer-close-btn w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function NutritionDetail(props) {
  return (
    <div
      className="modal fade theme-Roundedmodal "
      id="NutritaionModal"
      tabIndex="-1"
      aria-labelledby="NutritaionModal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            {/* Close button */}
            {/* <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <img className="img-fluid" src={require('../../../../../assets/images/cancel-icon.svg')} alt="icon" />
                  </button>
              </div> */}
            {/* Modal Content */}
            <div className="modal-card d-flex align-items-center nutri-head-wrap">
              <h1 className="modalCard-text semi-bold m-0 text-black">
                Nutrition per crew per day
              </h1>
            </div>
            <div className="nutrTable-wrap d-flex align-items-start">
              <div className="nutr-items-wrap">
                <ul className="nutr-indicator-wrap">
                  <li className="semi-bold">Type</li>
                  <li>Energy Kcal</li>
                  <li> Protein gr.</li>
                  <li>Fat gr.</li>
                  <li>Carb. gr.</li>
                  <li>Sugar. gr.</li>
                  <li>Fibres gr.</li>
                </ul>
              </div>
              <div className="nutr-items-wrap">
                <ul className="nutr-indicator-wrap">
                  <li className="semi-bold">Indication</li>
                  <li>2800</li>
                  <li>101</li>
                  <li>92</li>
                  <li>370</li>
                  <li></li>
                  <li>42</li>
                </ul>
              </div>
              <div className="nutr-items-wrap">
                <ul className="nutr-order-wrap">
                  <li className="semi-bold">Ordered</li>
                  <li>
                    {props.selectedItem.energy
                      ? (props.selectedItem.energy / 10).toFixed(2)
                      : "0"}
                  </li>
                  <li>
                    {props.selectedItem.protein
                      ? (props.selectedItem.protein / 10).toFixed(2)
                      : "0"}
                  </li>
                  <li>
                    {props.selectedItem.fat
                      ? (props.selectedItem.fat / 10).toFixed(2)
                      : "0"}
                  </li>
                  <li>
                    {props.selectedItem.carbohydrates
                      ? (props.selectedItem.carbohydrates / 10).toFixed(2)
                      : "0"}
                  </li>
                  <li>
                    {props.selectedItem.sugar
                      ? (props.selectedItem.sugar / 10).toFixed(2)
                      : "0"}
                  </li>
                  <li>
                    {props.selectedItem.dietaryFibres
                      ? (props.selectedItem.dietaryFibres / 10).toFixed(2)
                      : "0"}
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-btn-wrap d-flex justify-content-end">
              <button
                type="button"
                className="close footer-close-btn w-100"
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
