import React from 'react';
import { CSVLink } from "react-csv";
export const CsvDownload = (props) => (

    <>
        <a className="btn btn-yellow" onClick={() => props.apiCallForCSVData()}>{props.btnName ? props.btnName : 'Download CSV'} </a>
        <CSVLink
            data={props.data}
            headers={props.headers}
            filename={props.filename}
            className="hidden"
            ref={props.csvLinkRef}
            target="_blank"
        />

    </>
);