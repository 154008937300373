import React, { useEffect, useState } from "react";
import { customerListing } from "../../Common/ApiCalls/customerList";
import { ClearApiByNameAction } from "../../ApiCallStatus/Actions/action";
import { changePassword } from "../../User/ApiCalls/user";
import { deleteSubAdmin } from "../ApiCalls/subAdmin";
import { connect } from "react-redux";
import "../css/roles.css";
import DatePicker from "react-datepicker";
import loader from "../../../assets/images/Spinner-1s-200px.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
// import { getCookie, setCookie } from '../../../Services/cookies';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { ChangePasswordModal } from "../../Common/Components/changePasswordModal";
import { DashboardWrapper } from "../../Dashboard/Components/dashboarWrapper";

function RolesListing(props) {
  const [state, setState] = useState({
    subAdminList: [],
    activePage: 0,
    startDate: null,
    endDate: null,
    name: "",
    filterQuery: "",
    totalCount: 0,
    isHitApi: false,
    selectedUser: "",
    showModal: "",
  });
  useEffect(() => {
    if (!state.isHitApi) {
      if (props.isSubAdmin) {
        props.history.push("/not-allowed");
      } else {
        let data = { ...state };
        data.isHitApi = true;
        setState(data);
        props.customerListing(0, "sub-admin", "");
      }
    }
    if (
      (props.apiCallStatus.apiCallFor === "getCustomer" ||
        props.apiCallStatus.apiCallFor === "appendCustomerList") &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      setState({
        ...state,
        subAdminList: props.commonReducer.customerList,
        totalCount: props.commonReducer.totalCustomer,
        isHitApi: true,
      });
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
    if (
      props.apiCallStatus.apiCallFor === "deleteSubAdmin" &&
      props.apiCallStatus.isCompleted &&
      !props.apiCallStatus.isFailed
    ) {
      Swal.fire("Deleted!", "Port has been deleted!", "success");
      let data = { ...state };
      let from = moment(
        new Date(new Date().getTime() - 60 * 60 * 24 * 30 * 1000)
      ).format("YYYY-MM-DD");
      let to = moment(new Date()).add(1, "days").format("YYYY-MM-DD");
      let filterQuery = `&from=${from}&to=${to}`;
      data.isHitApi = true;
      data.from = from;
      data.to = to;
      data.filterQuery = filterQuery;
      data.activePage = 0;
      setState(data);
      props.customerListing(0, "sub-admin", filterQuery);
      setState(data);
      props.ClearApiByNameAction(props.apiCallStatus.apiCallFor);
    }
  }, [props]);
  const removeRecord = (id) => {
    Swal.fire({
      title: "Delete Sub Admin",
      text: "Are you sure you want to delete this Sub Admin?",
      icon: "warning",
      buttons: true,
      showCancelButton: true,
      dangerMode: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteSubAdmin(id);
      }
    });
  };
  const fetchMoreData = () => {
    let { activePage, filterQuery } = state;
    activePage += 1;
    setState({
      ...state,
      activePage,
    });
    props.customerListing(activePage, "sub-admin", filterQuery);
  };
  const handleChange = (id, val) => {
    setState({
      ...state,
      [id]: val,
    });
  };
  const applyFilters = () => {
    let data = { ...state };
    let filterQuery = "";
    if (data.startDate !== null && data.endDate !== null) {
      let from = moment(data.startDate).format("YYYY-MM-DD");
      let to = moment(data.endDate).add(1, "days").format("YYYY-MM-DD");
      filterQuery = `&from=${from}&to=${to}`;
    } else if (data.startDate && data.endDate === null) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select both date.",
      });
    } else if (data.name) {
      filterQuery += `&name=${data.name}`;
    }
    if (filterQuery) {
      props.customerListing(0, "sub-admin", filterQuery);
      data.isHitApi = true;
      data.activePage = 0;
      setState(data);
    }
  };
  const clearFilters = () => {
    let data = { ...state };
    data.activePage = 0;
    data.startDate = null;
    data.endDate = null;
    data.name = "";
    props.customerListing(0, "sub-admin", "");
    data.isHitApi = true;
    data.filterQuery = "";
    setState(data);
  };
  const handleModalChange = (val, userId) => {
    setState({
      ...state,
      selectedUser: userId,
      showModal: val,
    });
  };
  const navbarOptions = [
    {
      img: "left-arrow.svg",
      className: "orange",
      name: "Go back",
      path: "/",
      isLogout: false,
      isCustom: true,
      onClick: () => props.history.goBack(),
    },
  ];

  return (
    <DashboardWrapper
      title="Sub Admins"
      navbarOptions={navbarOptions}
      className="mesh-board-wrap d-flex align-items-end"
    >
      <div className="">
        <div className="ordCatg-table-wrap mt-4 system-cat-filter role-content-wrap">
          <div className="card-bx-sty">
            <div className="card-bx-sty table-card-wrap d-flex align-items-center flex-column flex-lg-row  prov-cat-filters">
              <div className="fil-wrap-ctn d-flex align-items-center flex-column flex-sm-row mb-2 mb-lg-0 subadmindateinput">
                <div className=" date-range-picker d-flex align-items-center">
                  <span className="d-inline-block mr-1">
                    <DatePicker
                      selected={state.startDate}
                      onChange={(val) => handleChange("startDate", val)}
                      maxDate={new Date()}
                      className="link-style"
                    />
                  </span>
                  to
                  <span className="d-inline-block ml-1">
                    <DatePicker
                      selected={state.endDate}
                      onChange={(val) => handleChange("endDate", val)}
                      disabled={state.startDate === null ? true : false}
                      minDate={
                        state.startDate !== null
                          ? new Date(moment(state.startDate).add(1, "days"))
                          : new Date(moment().add(1, "days"))
                      }
                      className="link-style"
                    />
                  </span>
                </div>
                <div className="custom-select-menu text-bx-wrap custom-select2 mr-2 p-0 filter-searchbar">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => handleChange("name", e.target.value)}
                      value={state.name}
                      placeholder="Search by name"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex  vesellisting  flex-fill ml-1">
                <button
                  type="button"
                  className="btn btn-yellow"
                  onClick={() => applyFilters()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-yellow ml-1"
                  onClick={() => clearFilters()}
                >
                  Clear
                </button>
                <div className="d-flex flex-fill justify-content-end ml-1">
                  <Link
                    to="/add-SubAdmin"
                    className="btn btn-yellow bg-dark-green"
                  >
                    Add Sub Admin
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={state.subAdminList.length}
          next={fetchMoreData}
          height={"80vh"}
          hasMore={state.totalCount <= state.subAdminList.length ? false : true}
          loader={
            <div
              colSpan="12"
              className="loadmore d-flex align-items-center justify-content-center"
            >
              {" "}
              <img src={loader} alt="" />
            </div>
          }
        >
          <div className="table-responsive mt-4">
            <table className="table theme-table-wrap with-action-btns">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Created At </th>
                  <th scope="col">Action </th>
                </tr>
              </thead>
              <tbody>
                {props.apiCallStatus.isStarted.indexOf("getCustomer") !== -1 ? (
                  <td colSpan="10">
                    <div className="loader-img text-center ">
                      <img style={{ width: "46px" }} src={loader} alt="" />
                    </div>
                  </td>
                ) : state.subAdminList && state.subAdminList.length === 0 ? (
                  <td colSpan="40" className="text-center">
                    No Data Found
                  </td>
                ) : (
                  state.subAdminList &&
                  state.subAdminList.length > 0 &&
                  state.subAdminList.map((item, index) => {
                    return (
                      <tr key={"subAdmin-" + index + 1}>
                        <td>{index + 1}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{moment(item.createdAt).format("LLL")}</td>
                        <td>
                          {props.userType === "user" && !props.isSubAdmin ? (
                            <button
                              className="btn btn-change-password-tab"
                              onClick={() =>
                                handleModalChange("changePassword", item.id)
                              }
                            ></button>
                          ) : (
                            ""
                          )}

                          <Link
                            to={"/edit-SubAdmin/" + item.id}
                            className="btn btn-edit-tab mr-2"
                          ></Link>
                          <button
                            type="button"
                            onClick={() => removeRecord(item.id)}
                            className="btn btn-dlt-tab"
                          ></button>
                          {/* <button type="button" className="btn btn-block-tab"></button> */}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
        <ChangePasswordModal
          ClearApiByNameAction={props.ClearApiByNameAction}
          apiCallStatus={props.apiCallStatus}
          changePassword={props.changePassword}
          selectedUser={state.selectedUser}
          showModal={state.showModal}
          handleModalChange={(val, userId) => handleModalChange(val, userId)}
        />
      </div>
    </DashboardWrapper>
  );
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apicallStatusReducer,
  roleReducer: state.roleReducer,
  commonReducer: state.commonReducer,
  isSubAdmin: state.authReducer.isSubAdmin,
  userType: state.authReducer.userType,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  ClearApiByNameAction: (apiName) => dispatch(ClearApiByNameAction(apiName)),
  changePassword: (data) => dispatch(changePassword(data)),
  customerListing: (offset, type, filterQuery) =>
    dispatch(customerListing(offset, type, filterQuery)),
  deleteSubAdmin: (adminId) => dispatch(deleteSubAdmin(adminId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RolesListing);
