// Public Routes
import WelcomeNote from "../Views/Auth/Components/customerWelcomeNote";
import ForgotPassword from "../Views/Auth/Components/forgotPassword";
import Login from "../Views/Auth/Components/login";
import LoginSupplier from "../Views/Auth/Components/loginSupplier&Admin";
import ResetPassword from "../Views/Auth/Components/resetPassword";
import VerifyAccount from "../Views/Auth/Components/verifyAccount";
// Roles
import addRole from "../Views/roles/Components/addRole";
import addSubAdmin from "../Views/roles/Components/addSubAdmin";
import allQuotes from "../Views/roles/Components/allQuotes";
import createQuotes from "../Views/roles/Components/createQuotes";
import editRole from "../Views/roles/Components/editRole";
import editSubAdmin from "../Views/roles/Components/editSubAdmin";
import Roles from "../Views/roles/Components/roles";
import rolesListing from "../Views/roles/Components/rolesListing";
import subAdmins from "../Views/roles/Components/subAdmins";

import PrepareQuotationOrder from "../Views/Catalogues/PrepareQuotationOrder";
import SupplierOrderCrud from "../Views/Catalogues/SupplierOrderCrud";
import SupplierOrderOverview from "../Views/Catalogues/SupplierOrderOverview";
import SupplierOverviewCatalogue from "../Views/Catalogues/SupplierOverviewCatalogue";
import CatalogueOverview from "../Views/Dashboard/Components/CatalogueOverview";
import ContractOffice from "../Views/Dashboard/Components/ContractOffice";
import ContractVessel from "../Views/Dashboard/Components/ContractVessel";
import CurrentOrders from "../Views/Dashboard/Components/CurrentOrders";
import FleetOverview from "../Views/Dashboard/Components/FleetOverview";
import OrderDetailDescription from "../Views/Dashboard/Components/OrderDetailDescription";
import SupplierDashboard from "../Views/Dashboard/Components/SupplierDashboard.jsx";
import PriceListing from "../Views/Dashboard/Components/SupplierPriceList";
import TopClients from "../Views/Dashboard/Components/TopClients";
import TopItems from "../Views/Dashboard/Components/TopItems";
import TopSupplier from "../Views/Dashboard/Components/TopSupplier";
import TopSupplyHub from "../Views/Dashboard/Components/TopSupplyHub";
import MeshBoard from "../Views/Dashboard/Components/meshBoard";
import vesselOverview from "../Views/Dashboard/Components/vesselOverview";
import provisionCatalogue from "../Views/Orders/Components/Catalogue/ProvisionCatalogue/provisionCatalogueContainer";
import SendRfq from "../Views/Orders/Components/Catalogue/SendRfq/sendRfq";
import StoreCatalogue from "../Views/Orders/Components/Catalogue/StoreCatalogue/storeCatalogueContainer";
import Checkout from "../Views/Orders/Components/Checkout/checkoutContainer";
import CreateProvisionOrder from "../Views/Orders/Components/Create/CreateOrder/createOrder";
import CreateStoreOrder from "../Views/Orders/Components/Create/CreateStoreOrder/createStoreOrder";
import InProgressOrderList from "../Views/Orders/Components/OrderList/inProgressOrderList";
import OpenOrderList from "../Views/Orders/Components/OrderList/openOrderList";
import OrderDetail from "../Views/Orders/Components/OrderList/orderDetail";
import ConfirmOrder from "../Views/Quotations/Components/ConfirmOrder/confirmOrder";
import InProgressQuotationList from "../Views/Quotations/Components/InProgressQuotationList/InProgressQuotationList";
import PrepareQuotation from "../Views/Quotations/Components/PrepareQuotation/PrepareQuotation";
import CompareQuotation from "../Views/Quotations/Components/compareQuotaion/CompareQuotation";
import SupplierProfile from "../Views/Supplier/Components/Profile/supplierprofile";
import SupplierRanking from "../Views/Supplier/Components/Ranking/supplierRanking";
import marketPlace from "../Views/marketplace/marketplace";
import UpgradeClient from "../Views/upgrademesh/UpgradeClient";
import UpgradeMesh from "../Views/upgrademesh/upgrademesh";

//User
import UploadUsers from "../Views/User/Components/UploadUsers";
import CreateUser from "../Views/User/Components/createUser";
import EditUser from "../Views/User/Components/editUser";
import UserListing from "../Views/User/Components/userListing";

// Customer
import CreateCustomer from "../Views/Dashboard/Components/CreateCustomer";

//File
import EditCatalogue from "../Views/fileUpload/Components/editCatalogue";
import UploadFile from "../Views/fileUpload/Components/fileUpload";
import SupplierCatalogueList from "../Views/fileUpload/Components/supplierCatalogue";

// Captain
import CaptainListing from "../Views/Captain/Components/CaptainListing.jsx";
import CreateCaptain from "../Views/Captain/Components/createCaptain";
import EditCaptain from "../Views/Captain/Components/editCaptain";

// Port
import UploadPorts from "../Views/Port/Components/UploadPorts";
import CreatePort from "../Views/Port/Components/createPort";
import EditPort from "../Views/Port/Components/editPort";
import PortList from "../Views/Port/Components/portListing";

// Package
import CreatePackage from "../Views/Package/Components/createPackage";
import EditPackage from "../Views/Package/Components/editPackage";
import PackageList from "../Views/Package/Components/packageListing";

// Vessel
import UploadVessels from "../Views/Vessel/Components/UploadVessels";
import CreateVessel from "../Views/Vessel/Components/createVessel";
import VesselListing from "../Views/Vessel/Components/vesselListing";

// Country
import CountryList from "../Views/Country/Components/countryListing";
import CreateCountry from "../Views/Country/Components/createCountry";
import EditCountry from "../Views/Country/Components/editCountry";

// Department
import CreateDepartment from "../Views/Department/Components/createDepartment";
import DepartmentList from "../Views/Department/Components/departmentListing";
import EditDepartment from "../Views/Department/Components/editDepartment";

// Supplier
import CreateSupplier from "../Views/Supplier/Components/CRUD/createSupplier";
import EditSupplier from "../Views/Supplier/Components/CRUD/editSupplier";
import SupplierListing from "../Views/Supplier/Components/CRUD/supplierListing";
// import SupplierComparison from "../Views/Supplier/Components/supplierComparison";
// Customer listing
// import CustomerList from "../Views/Customer/Components/customerList";
import SupplierSelection from "../Views/Orders/Components/Selection/supplierSelection";
//Mesh Market
import MarketDashboard from "../Views/meshMarket/components/MarketDashboard";
// Rfq Request
import RfqRequest from "../Views/Rfq-Request/RfqRequest";
// Medicine Journey
import MedicineInventory from "../Views/customerJourney/MedicineInventory";
// Order Basket
import OrderBasket from "../Views/OrderBasket/Components/orderBasket";
// Mesh Questionnarie
import CreateQuestionnarie from "../Views/Vessel/Components/Questionaire/createQuestionnarie";
import VesselInfo from "../Views/Vessel/Components/vesselInfo";
//Not Found
import NotAllowed from "../Views/Common/Components/notAllowed";
import Notfound from "../Views/Common/Components/notfound";
import customerList from "../Views/Customer/Components/customerList";
import CompareOrderList from "../Views/Orders/Components/OrderList/compareOrderList";
import inventoryListing from "../Views/Orders/Components/OrderList/inventoryListing";
import createReminder from "../Views/Reminder/Components/createReminder";
import editReminder from "../Views/Reminder/Components/editReminder";
import reminderListing from "../Views/Reminder/Components/reminderListing";
import supplierComparison from "../Views/Supplier/Components/Comparision/supplierComparison";

// COMPANY
import CreateCompany from "../Views/Company/Components/CreateCompany";
import ListCompanies from "../Views/Company/Components/ListCompanies";

// QUOTATION
import SupplyConfirmation from "../Views/Quotations/Components/SupplyConfirmation";
import CaptainSignup from "../Views/Auth/Components/CaptainSignup.jsx";

export const AllRoutes = [
  // Public Routes
  { isPrivate: false, path: "/", name: "", component: Login },
  { isPrivate: false, path: "/login", name: "Login", component: Login },
  {
    isPrivate: false,
    path: "/LoginSupplier",
    name: "LoginSupplier",
    component: LoginSupplier,
  },
  // { isPrivate: false, path: "/signUp", name: "Sign Up", component: SignUp },
  {
    isPrivate: false,
    path: "/captain-signup",
    name: "Sign Up",
    component: CaptainSignup,
  },
  {
    isPrivate: false,
    path: "/forgotPasword",
    name: "Forgot Password",
    component: ForgotPassword,
  },
  {
    isPrivate: false,
    path: "/resetPasword/:id",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    isPrivate: false,
    path: "/verifyAccount/:userId",
    name: "Verify Your Account",
    component: VerifyAccount,
  },

  /* Roles screen */
  { isPrivate: true, name: "Roles", path: "/roles", component: Roles },
  {
    isPrivate: true,
    name: "Roles Listing",
    path: "/role-listing",
    component: rolesListing,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Add Role",
    path: "/addRole",
    component: addRole,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Edit Role",
    path: "/edit-role/:id",
    component: editRole,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Sub Admins",
    path: "/subAdmins",
    component: subAdmins,
  },
  {
    isPrivate: true,
    name: "Add Sub Admin",
    path: "/add-SubAdmin",
    component: addSubAdmin,
  },
  {
    isPrivate: true,
    name: "Edit Sub Admin",
    path: "/edit-SubAdmin/:id",
    component: editSubAdmin,
  },
  {
    isPrivate: true,
    name: "Create Quotes",
    path: "/createQuotes",
    component: createQuotes,
  },
  {
    isPrivate: true,
    name: "All Quotes",
    path: "/all-quotes",
    component: allQuotes,
  },

  {
    isPrivate: false,
    path: "/welcome",
    name: "Welcome Note",
    component: WelcomeNote,
  },
  {
    isPrivate: true,
    name: "Upgrade Mesh",
    path: "/upgrademesh",
    component: UpgradeMesh,
  },
  {
    isPrivate: true,
    name: "Upgrade Client",
    path: "/upgrade-client",
    component: UpgradeClient,
    roles: ["supplier", "customer"],
  },
  {
    isPrivate: true,
    name: "Supplier Ranking",
    path: "/supplier-ranking",
    component: SupplierRanking,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    name: "Order Details",
    path: "/order-detail/:id",
    key: "orderDetail",
    component: OrderDetail,
  },
  {
    isPrivate: true,
    name: "Supply Confirmation",
    path: "/supply-confirmation/:quotationId",
    key: "supplyConfirmation",
    component: SupplyConfirmation,
    roles: ["supplier"],
  },
  {
    isPrivate: true,
    path: "/supplier-profile/:id",
    name: "Supplier Profile",
    component: SupplierProfile,
  },
  {
    isPrivate: true,
    path: "/create-store-order/:vesselId",
    name: "Order Store",
    component: CreateStoreOrder,
  },
  {
    isPrivate: true,
    path: "/open-orders",
    name: "Open Orders",
    key: "openOrder",
    component: OpenOrderList,
  },
  {
    isPrivate: true,
    path: "/compare-orders/:id/:id/:id",
    name: "compare Orders",
    key: "compareOder",
    component: CompareOrderList,
  },
  {
    isPrivate: true,
    path: "/inprogress-orders",
    name: "InProgress Orders",
    key: "inProgressOrders",
    component: InProgressOrderList,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    path: "/inprogress-quotations",
    key: "inprogressQuotation",
    name: "Inprogress Quotation",
    component: InProgressQuotationList,
  },
  {
    isPrivate: true,
    path: "/create-provision-order/:vesselId",
    name: "Create Provision Order",
    component: CreateProvisionOrder,
  },
  {
    isPrivate: true,
    path: "/provision-catalogue/:orderId",
    name: "Provision Catalogue",
    component: provisionCatalogue,
  },
  {
    isPrivate: true,
    path: "/marketPlace",
    name: "Market Place",
    component: marketPlace,
  },
  {
    isPrivate: true,
    path: "/store-catalogue/:orderId",
    name: "Store Catalogue",
    component: StoreCatalogue,
  },
  {
    isPrivate: true,
    path: "/checkout/:id",
    name: "Checkout",
    component: Checkout,
  },
  {
    isPrivate: true,
    path: "/meshBoard",
    name: "meshBoard",
    component: MeshBoard,
  },

  /* Port */
  {
    isPrivate: true,
    path: "/port-listing",
    name: "Port Listing",
    key: "portList",
    component: PortList,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    path: "/create-port",
    name: "Create Port",
    key: "addPort",
    component: CreatePort,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-port/:id",
    name: "Edit Port",
    key: "editPort",
    component: EditPort,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Upload Ports",
    key: "uploadPorts",
    path: "/upload-ports",
    component: UploadPorts,
    roles: ["user"],
  },
  /* Reminder */
  {
    isPrivate: true,
    path: "/reminder-listing",
    name: "Reminder Listing",
    key: "reminderList",
    component: reminderListing,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-reminder",
    name: "Create Reminder",
    key: "addReminder",
    component: createReminder,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-reminder/:id",
    name: "Edit Reminder",
    key: "editReminder",
    component: editReminder,
    roles: ["user"],
  },

  /* Country */
  {
    isPrivate: true,
    path: "/country-listing",
    name: "Country Listing",
    key: "countryList",
    component: CountryList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-country",
    name: "Create Country",
    key: "addCountry",
    component: CreateCountry,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-country/:id",
    name: "Edit Country",
    key: "editCountry",
    component: EditCountry,
    roles: ["user"],
  },

  /* Department */
  {
    isPrivate: true,
    path: "/department-listing",
    name: "Department Listing",
    key: "departmentList",
    component: DepartmentList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-department",
    name: "Create Department",
    key: "addDepartment",
    component: CreateDepartment,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-department/:id",
    name: "Edit Department",
    key: "editDepartment",
    component: EditDepartment,
    roles: ["user"],
  },

  /* User */
  {
    isPrivate: true,
    name: "User Listing",
    path: "/user-listing",
    key: "userList",
    component: UserListing,
  },
  {
    isPrivate: true,
    name: "Create User",
    path: "/create-user",
    key: "addUser",
    component: CreateUser,
  },
  {
    isPrivate: true,
    name: "Upload Users",
    key: "uploadUsers",
    path: "/upload-users",
    component: UploadUsers,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Create Customer",
    path: "/create-customer",
    key: "addCustomer",
    component: CreateCustomer,
  },
  {
    isPrivate: true,
    name: "Edit User",
    path: "/edit-user/:id",
    key: "editUser",
    component: EditUser,
  },

  /* Captain */
  {
    isPrivate: true,
    name: "Captain Listing",
    path: "/captain-listing",
    key: "captainList",
    component: CaptainListing,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Create Captain",
    path: "/create-captain",
    key: "addCaptain",
    component: CreateCaptain,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Edit Captain",
    path: "/edit-captain/:id",
    key: "editCaptain",
    component: EditCaptain,
    roles: ["user"],
  },

  /* File Upload */
  {
    isPrivate: true,
    path: "/file-upload",
    key: "fileUpload",
    component: UploadFile,
    roles: ["user", "supplier"],
  },
  {
    isPrivate: true,
    path: "/supplier-catalogue-listing",
    key: "catalogueList",
    component: SupplierCatalogueList,
    roles: ["user", "supplier"],
  },
  {
    isPrivate: true,
    path: "/edit-catalogue/:id/:type",
    key: "editCatalogue",
    component: EditCatalogue,
    roles: ["user", "supplier"],
  },

  /* Vessel */
  {
    isPrivate: true,
    name: "Vessel Listing",
    key: "vesselList",
    path: "/vessel-listing",
    component: VesselListing,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    name: "Create Vessel",
    key: "addVessel",
    path: "/create-vessel",
    component: CreateQuestionnarie,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Upload Vessels",
    key: "uploadVessels",
    path: "/upload-vessels",
    component: UploadVessels,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Vessel Config",
    key: "configVessel",
    path: "/config",
    component: CreateVessel,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    name: "Edit Vessel",
    key: "editVessel",
    path: "/edit-vessel/:id",
    component: CreateQuestionnarie,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Vessel Info",
    key: "vesselInfo",
    path: "/vessel-info/:vesselId",
    name: "Vessel Information",
    component: VesselInfo,
    roles: ["user", "customer", "supplier"],
  },

  /* Package */
  {
    isPrivate: true,
    path: "/package-listing",
    key: "packageList",
    name: "Package Listing",
    component: PackageList,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-package",
    key: "addPackage",
    name: "Create Package",
    component: CreatePackage,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-package/:id",
    key: "editPackage",
    name: "Edit Package",
    component: EditPackage,
    roles: ["user"],
  },

  /* Customer */
  {
    isPrivate: true,
    path: "/customer-listing",
    key: "customerList",
    component: customerList,
    roles: ["user", "supplier"],
  },

  /* Company */
  {
    isPrivate: true,
    path: "/company-listing",
    name: "Company Listing",
    key: "companyList",
    component: ListCompanies,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/create-company",
    name: "Create Company",
    key: "addCompany",
    component: CreateCompany,
    roles: ["user"],
  },
  {
    isPrivate: true,
    path: "/edit-company/:id",
    name: "Edit Company",
    key: "editCompany",
    component: CreateCompany,
    roles: ["user"],
  },

  /* Supplier */
  {
    isPrivate: true,
    name: "Supplier Listing",
    key: "supplierList",
    path: "/supplier-listing",
    component: SupplierListing,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    name: "Create Supplier",
    key: "addSupplier",
    path: "/create-supplier",
    component: CreateSupplier,
    roles: ["user"],
  },
  {
    isPrivate: true,
    name: "Edit Supplier",
    key: "editSupplier",
    path: "/edit-supplier/:id",
    component: EditSupplier,
    roles: ["user"],
  },

  /* Dashboards */
  {
    isPrivate: true,
    path: "/contract-vessel",
    name: "ContractVessel",
    component: ContractVessel,
  },
  {
    isPrivate: true,
    path: "/catalogue-overview",
    name: "CatalogueOverview",
    component: CatalogueOverview,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    path: "/contract-office",
    name: "ContractOffice",
    key: "contractOffice",
    component: ContractOffice,
  },
  {
    isPrivate: true,
    path: "/CurrentOrders",
    name: "CurrentOrders",
    component: CurrentOrders,
  },
  {
    isPrivate: true,
    path: "/fleet-overview",
    name: "FleetOverview",
    component: FleetOverview,
  },
  {
    isPrivate: true,
    path: "/send/:type/:supplierId/:orderId",
    key: "SendRfq",
    name: "SendRfq",
    component: SendRfq,
  },
  {
    isPrivate: true,
    path: "/SupplierOrderCrud",
    name: "SupplierOrderCrud",
    component: SupplierOrderCrud,
  },
  {
    isPrivate: true,
    path: "/Supplier-Comparison/:basketId",
    name: "Supplier Comparison",
    component: supplierComparison,
  },
  {
    isPrivate: true,
    path: "/supplier-order-overview",
    name: "SupplierOrderOverview",
    component: SupplierOrderOverview,
  },
  {
    isPrivate: true,
    path: "/prepare-quotation/:quotationId",
    name: "PrepareQuotation",
    component: PrepareQuotation,
  },
  {
    isPrivate: true,
    path: "/compare-quotation/:orderId",
    key: "compareQuotation",
    name: "CompareQuotation",
    component: CompareQuotation,
  },
  {
    isPrivate: true,
    path: "/PrepareQuotationOrder",
    name: "PrepareQuotationOrder",
    component: PrepareQuotationOrder,
  },
  {
    isPrivate: true,
    path: "/confirm-order/:quotationId",
    name: "ConfirmOrder",
    component: ConfirmOrder,
  },
  {
    isPrivate: true,
    path: "/supplier-dashboard",
    name: "SupplierDashboard",
    component: SupplierDashboard,
  },
  {
    isPrivate: true,
    path: "/vesselOverview",
    name: "vesselOverview",
    component: vesselOverview,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    path: "/order-detail-description/:orderId/:supplierId",
    name: "OrderDetailDescription",
    component: OrderDetailDescription,
    roles: ["user", "customer", "supplier"],
  },
  {
    isPrivate: true,
    path: "/top-supply-hub",
    name: "topSupplyHub",
    component: TopSupplyHub,
    roles: ["user", "customer"],
  },
  {
    isPrivate: true,
    path: "/top-clients",
    name: "TopClients",
    component: TopClients,
  },
  {
    isPrivate: true,
    path: "/top-items",
    name: "TopItems",
    component: TopItems,
  },
  {
    isPrivate: true,
    path: "/price-listing",
    name: "PriceListing",
    component: PriceListing,
  },
  {
    isPrivate: true,
    path: "/top-supplier",
    name: "topSupplier",
    component: TopSupplier,
  },
  {
    isPrivate: true,
    path: "/SupplierOverviewCatalogue",
    name: "SupplierOverviewCatalogue",
    component: SupplierOverviewCatalogue,
  },
  {
    isPrivate: true,
    path: "/supplier-selection/:id",
    name: "SupplierSelection",
    component: SupplierSelection,
  },

  /* Rfq Request */
  {
    isPrivate: true,
    path: "/Rfq-Request",
    name: "Rfq Request",
    component: RfqRequest,
  },

  /* Mesh Dashboard  */
  {
    isPrivate: true,
    path: "/mesh-market-dashboard",
    name: "Marker Dashboard",
    component: MarketDashboard,
  },

  /* Inventory List  */
  {
    isPrivate: true,
    path: "/inventory-list",
    name: "Inventory List",
    component: inventoryListing,
    roles: ["user", "customer"],
  },
  /* Medicine Inventory  */
  {
    isPrivate: true,
    path: "/medicine-inventory",
    name: "Medicine Inventory",
    component: MedicineInventory,
  },
  /* Order Basket  */
  {
    isPrivate: true,
    path: "/order-basket",
    name: "Order Basket",
    key: "orderBasket",
    component: OrderBasket,
    roles: ["user", "customer"],
  },

  /* Not Allowed*/
  { path: "/not-allowed", name: "Not Allowed", component: NotAllowed },
  /* Page not found */
  { isPrivate: true, component: Notfound },
];

export function getRoutePathByName(name) {
  let index = AllRoutes.findIndex((x) => x.name === name);
  let path = "";
  if (index !== -1) {
    path = AllRoutes[index].path;
  }
  return path;
}

export function getRoutePathBykey(key) {
  let index = AllRoutes.findIndex((x) => x.key === key);
  let path = "";
  if (index !== -1) {
    path = AllRoutes[index].path;
  }
  return path;
}

export function getRoutesDetail() {
  let obj = {};
  AllRoutes.forEach((element) => {
    if (element.key) {
      obj[element.key] = element.path;
    }
  });
  return obj;
}
