import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "./../../../Constants/constant";
import {
  basketOrdersAction,
  updateIgnoreDepartAction,
} from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
import { get, set } from 'idb-keyval';

const writeOrderByIds = async (orderBasketResponse) => {
  const { data } = orderBasketResponse;
  if (Array.isArray(data) && data.length !== 0) {
    let orderByIds = await get("orderByIds");
    if (!orderByIds) {
      orderByIds = {};
      await Promise.all(
        data.map(async ({ _id }) => {
          const url = `${Constant.apiURl}/orders/orderById`;
          const myJson = await FETCH("POST", url, { orderId: _id });
          orderByIds[_id] = myJson;
        })
      );
      await set("orderByIds", orderByIds);
    }
    return true;
  }
  return false;
};

export function basketOrders(id, showCustomerOrders = false) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "basketOrders" }));
    let myJson = await FETCH("POST", Constant.apiURl + "/orders/order/basket", {
      showCustomerOrders,
      captainId: id,
    });
    if (myJson && myJson.code === 201) {
      await set("orderBasketResponse", myJson);
      await writeOrderByIds(myJson);
      const { provisionBasketId, provisionAdditionalBasketId, storeBasketId, storeAdditionalBasketId } = myJson;
      const basketIdsMapping = {
        [provisionBasketId]: {
          isProvision: true,
          isStore: false,
          isProvisionAdditional: false,
          isStoreAdditional: false,
        },
        [storeBasketId]: {
          isProvision: false,
          isStore: true,
          isProvisionAdditional: false,
          isStoreAdditional: false,
        },
        [provisionAdditionalBasketId]: {
          isProvision: false,
          isStore: false,
          isProvisionAdditional: true,
          isStoreAdditional: false,
        },
        [storeAdditionalBasketId]: {
          isProvision: false,
          isStore: false,
          isProvisionAdditional: false,
          isStoreAdditional: true,
        },
      };
      await set("basketIdsMapping", basketIdsMapping);
      let provisionSupplier = { id: myJson.provisionSupplierId, name: "" };
      let storeSupplier = { id: myJson.storeSupplierId, name: "" };
      const provisionAdditionalSupplier = { id: "", name: "" };
      const storeAdditionalSupplier = { id: "", name: "" };
      if (myJson.suppliers !== undefined && myJson.suppliers.length > 0) {
        let provisionIndex = myJson.suppliers.findIndex(
          (x) => x._id === myJson.provisionSupplierId
        );
        let storeIndex = myJson.suppliers.findIndex(
          (x) => x._id === myJson.storeSupplierId
        );
        if (provisionIndex !== -1) {
          provisionSupplier["name"] = myJson.suppliers[provisionIndex].name;
        }
        if (storeIndex !== -1) {
          storeSupplier["name"] = myJson.suppliers[storeIndex].name;
        }
      }
      dispatch(
        basketOrdersAction(
          myJson.data,
          myJson.provisionBasketId,
          myJson.storeBasketId,
          provisionSupplier,
          storeSupplier,
          myJson.ignoreDepart,
          myJson.storeArrivalTime,
          myJson.provisionArrivalTime,
          myJson.proceedToSupplierComparison,
          myJson.remainingDepartments,
          myJson.vessels,
          myJson.provisionAdditionalBasketId,
          myJson.provisionAdditionalArrivalTime,
          myJson.storeAdditionalBasketId,
          myJson.storeAdditionalArrivalTime,
          provisionAdditionalSupplier,
          storeAdditionalSupplier
        )
      );
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "basketOrders",
          message: myJson.message,
        })
      );
    } else {
      const orderBasketResponse = await get("orderBasketResponse");
      if (orderBasketResponse) {
        const {
          data, provisionBasketId, storeBasketId, ignoreDepart, storeArrivalTime, provisionArrivalTime,
          proceedToSupplierComparison, remainingDepartments, vessels, provisionSupplierId, storeSupplierId,
          suppliers, provisionAdditionalBasketId, provisionAdditionalArrivalTime, storeAdditionalBasketId,
          storeAdditionalArrivalTime,
        } = orderBasketResponse;
        let provisionSupplier = { id: provisionSupplierId, name: "" };
        let storeSupplier = { id: storeSupplierId, name: "" };
        const provisionAdditionalSupplier = { id: "", name: "" };
        const storeAdditionalSupplier = { id: "", name: "" };
        if (suppliers !== undefined && suppliers.length > 0) {
          let provisionIndex = suppliers.findIndex(
            (x) => x._id === provisionSupplierId
          );
          let storeIndex = suppliers.findIndex(
            (x) => x._id === storeSupplierId
          );
          if (provisionIndex !== -1) {
            provisionSupplier["name"] = suppliers[provisionIndex].name;
          }
          if (storeIndex !== -1) {
            storeSupplier["name"] = suppliers[storeIndex].name;
          }
        }
        dispatch(
          basketOrdersAction(
            data,
            provisionBasketId,
            storeBasketId,
            provisionSupplier,
            storeSupplier,
            ignoreDepart,
            storeArrivalTime,
            provisionArrivalTime,
            proceedToSupplierComparison,
            remainingDepartments,
            vessels,
            provisionAdditionalBasketId,
            provisionAdditionalArrivalTime,
            storeAdditionalBasketId,
            storeAdditionalArrivalTime,
            provisionAdditionalSupplier,
            storeAdditionalSupplier
          )
        );
        dispatch(
          Actions.ApiFulfilledAction({
            apiCallFor: "basketOrders",
            message: myJson.message,
          })
        );
      } else {
        dispatch(
          Actions.ApiRejectedAction({
            statusCode: myJson.statusCode,
            apiCallFor: "basketOrders",
            message: myJson.message,
          })
        );
      }
    }
  };
}

export function updateCartETA(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "updateCartETA" }));
    const url = `${Constant.apiURl}/orders/update/eta`;
    const myJson = await FETCH("POST", url, data);
    if (myJson && myJson.code === 200) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "updateCartETA",
          message: myJson.message,
        })
      );
    } else {
      if (!navigator.onLine) {
        localStorage.setItem(url, JSON.stringify(data));
      }
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "updateCartETA",
          message: myJson.message,
        })
      );
    }
  };
}

export function sendAdditionalOrder(data) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "sendAdditionalOrder" }));
    let myJson = await FETCH(
      "PUT",
      Constant.apiURl + "/orders/for-customers",
      { orderIds: data }
    );
    if (myJson && myJson.code === 200) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "sendAdditionalOrder",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "sendAdditionalOrder",
          message: myJson.message,
        })
      );
    }
  };
}

export function ignoreDepartmentCart(data) {
  return async (dispatch) => {
    dispatch(
      Actions.ApiRequestedAction({
        apiCallFor: "ignoreDepartmentCart",
        message: data.cartId,
      })
    );
    let myJson = await FETCH(
      "POST",
      Constant.apiURl + "/orders/ignore/department",
      data
    );
    if (myJson && myJson.code === 201) {
      dispatch(updateIgnoreDepartAction(data));
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "ignoreDepartmentCart",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "ignoreDepartmentCart",
          message: myJson.message,
        })
      );
    }
  };
}

export function deleteOrder(orderId) {
  return async (dispatch) => {
    dispatch(Actions.ApiRequestedAction({ apiCallFor: "deleteOrder" }));
    const url = `${Constant.apiURl}/orders/${orderId}`;
    const myJson = await FETCH("DELETE", url);
    if (myJson && myJson.statusCode === 204) {
      dispatch(
        Actions.ApiFulfilledAction({
          apiCallFor: "deleteOrder",
          message: myJson.message,
        })
      );
    } else {
      dispatch(
        Actions.ApiRejectedAction({
          statusCode: myJson.statusCode,
          apiCallFor: "deleteOrder",
          message: myJson.message,
        })
      );
    }
  };
}