import * as Action from "./../Actions/action";
import InitialState from "./../../../Constants/initialState";
import cloneDeep from "lodash/cloneDeep";
export default function CommonReducer(state = InitialState.common, action) {
  switch (action.type) {
    case Action.getAllCountry:
      let s = cloneDeep(state);
      s.countryList = action.payload.list;
      return s;
    case Action.getAllPort:
      let s1 = cloneDeep(state);
      s1.portList = action.payload.list;
      return s1;
    case Action.getAllCaptain:
      let s2 = cloneDeep(state);
      s2.captainList = action.payload.list;
      return s2;
    case Action.getAllCook:
      let s3 = cloneDeep(state);
      s3.cookList = action.payload.list;
      return s3;
    case Action.getAllVessel:
      let s4 = cloneDeep(state);
      s4.vesselList = action.payload.list;
      return s4;
    case Action.getCurrentUserVessel:
      let s5 = cloneDeep(state);
      s5.currentUserVessel = action.payload.list;
      return s5;
    case Action.getVesselById:
      let s6 = cloneDeep(state);
      s6.vesselById = action.payload.data;
      return s6;
    case Action.getCutomer:
      let s7 = cloneDeep(state);
      s7.customerList = action.payload.list;
      s7.totalCustomer = action.payload.count;
      return s7;
    case Action.AppendCustomerList:
      var s8 = cloneDeep(state);
      var arr = s8.customerList.slice();
      s8.customerList = arr.concat(action.payload.list);
      return s8;
    case Action.getCurrencyCode:
      let s9 = cloneDeep(state);
      s9.currencyCodeList = action.payload.list;
      return s9;
    case Action.getTopSupplyHub:
      let s10 = cloneDeep(state);
      s10.topSupplyHubList = action.payload.list;
      return s10;
    case Action.getTopSupplier:
      let s11 = cloneDeep(state);
      s11.topSupplierList = action.payload.list;
      return s11;
    case Action.getAllDepartment:
      let s12 = cloneDeep(state);
      s12.departmentList = action.payload.list;
      return s12;
    case Action.getVesselKind:
      let s13 = cloneDeep(state);
      s13.vesselKindList = action.payload.list;
      return s13;
    case Action.getCrewListing:
      let s14 = cloneDeep(state);
      s14.crewList = action.payload.list;
      return s14;
    case Action.getRotationListing:
      let s15 = cloneDeep(state);
      s15.rotationList = action.payload.list;
      return s15;
    case Action.getCutomerEmail:
      let s16 = cloneDeep(state);
      s16.customerEamils = action.payload.list;
      s16.totalEmails = action.payload.count;
      return s16;
    case Action.getTopCustomer:
      let s17 = cloneDeep(state);
      s17.topCustomerList = action.payload.list;
      return s17;
    case Action.getAllRole:
      let s18 = cloneDeep(state);
      s18.roleList = action.payload.list;
      return s18;
    case Action.getOrderCount:
      let s19 = cloneDeep(state);
      s19.orderCount = action.payload.count;
      s19.userOrderId = action.payload.userOrderId;
      return s19;
    case Action.getTopVessel:
      let s20 = cloneDeep(state);
      s20.topVesselList = action.payload.list;
      return s20;
    case Action.getVesselWiseStatList:
      let s21 = cloneDeep(state);
      s21.vesselWiseStatList = action.payload.list;
      return s21;
    case Action.getVesselWiseStat:
      let s22 = cloneDeep(state);
      s22.vesselWiseStats = action.payload.list;
      return s22;
    case Action.getVesselDropdown:
      let s23 = cloneDeep(state);
      s23.vesselDropdown = action.payload.data;
      return s23;
    default:
      return state;
  }
}
