import * as Actions from "../../ApiCallStatus/Actions/action";
import { Constant } from "../../../Constants/constant";
import { GetTopVesselAction } from "../Actions/action";
import { FETCH } from "../../../Services/fetch";
export function topVessel(data, userType, from, to, filterQuery) {
    return async (dispatch) => {
        dispatch(Actions.ApiRequestedAction({ apiCallFor: "topVessel" }));
        let url = "";
        let bodyContent = {};
        if (userType) {
            userType === "supplier" ? bodyContent['supplierId'] = data : userType === "customer" ? bodyContent['vesselId'] = data : bodyContent['customerId'] = data
        }
        if (from && to) {
            bodyContent['from'] = from
            bodyContent['to'] = to
        }
        if (filterQuery && filterQuery.supplierId) {
            bodyContent['supplierId'] = filterQuery.supplierId
        }
        if (filterQuery && filterQuery.portId) {
            bodyContent['portId'] = filterQuery.portId;
        }
        if (filterQuery && filterQuery.itemCode) {
            bodyContent['itemCode'] = filterQuery.itemCode
        }
        url = Constant.apiURl + "/quotation/topVessel";
        let myJson = await FETCH("POST", url, {
            ...bodyContent,
        });
        if (myJson && myJson.code === 201) {
            dispatch(GetTopVesselAction(myJson.data));
            dispatch(
                Actions.ApiFulfilledAction({
                    apiCallFor: "topVessel",
                    message: myJson.message,
                })
            );
        } else {
            dispatch(
                Actions.ApiRejectedAction({
                    statusCode: myJson.statusCode,
                    apiCallFor: "topVessel",
                    message: myJson.message,
                })
            );
        }
    };
}